import React from 'react';

import { SectionHeader } from '../../../components';

import { ParagraphRenderer } from './paragraphRenderer';

type SectionHeadingProps = {
  heading?: string;
  headingClassName?: string;
  paragraphs?: string[];
  testId?: string;
};

export const SectionHeading = ({
  heading,
  headingClassName,
  paragraphs,
  testId,
}: SectionHeadingProps) => (
  <>
    {heading && (
      <SectionHeader
        title={heading}
        className={headingClassName}
        testId={testId + '-section-header'}
      />
    )}
    {paragraphs && paragraphs.length > 0 && (
      <div className="micro" data-testid={testId + '-text'}>
        <ParagraphRenderer paragraphs={paragraphs} />
      </div>
    )}
  </>
);
