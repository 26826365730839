import type { MarketVehicleConfiguration } from '@vcc-package/leads-utils/api';
import { SeriesTypes } from '@vcc-package/leads-utils/api';

export interface vehicleGroups {
  vehicles: MarketVehicleConfiguration[];
  key: SeriesTypes;
  translationKey: string;
}

const sortedGroups: vehicleGroups['key'][] = [SeriesTypes.SUV];

export const groupVehicles = (
  vehicles: MarketVehicleConfiguration[],
): vehicleGroups[] => {
  const vehicleGroups: Record<string, vehicleGroups> = {};
  for (let i = 0; i < vehicles.length; i++) {
    const seriesType = vehicles[i].presentation.seriesType;
    if (vehicleGroups[seriesType]) {
      vehicleGroups[seriesType].vehicles.push(vehicles[i]);
    } else {
      vehicleGroups[seriesType] = {
        key: seriesType,
        vehicles: [vehicles[i]],
        translationKey: `ModelSelectorGroup_${seriesType}`,
      };
    }
  }

  const unsortedGroups = Object.values(vehicleGroups).filter(
    (vehicleGroup) => !sortedGroups.includes(vehicleGroup.key),
  );

  return sortedGroups
    .map((key) => vehicleGroups[key])
    .concat(unsortedGroups)
    .filter(Boolean);
};
