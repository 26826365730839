import { useCallback } from 'react';

import { useLeadsContext } from '../src/context';

type Props = { mainlandFilter?: boolean };
export const useSearchAddress = ({ mainlandFilter }: Props) => {
  const { API, features } = useLeadsContext();

  return useCallback(
    async (address: string) =>
      await API.searchAddress({
        address,
        mainlandFilter: mainlandFilter || false,
        useLoqate: features.useLoqate || false,
      }),
    [API, mainlandFilter, features.useLoqate],
  );
};
