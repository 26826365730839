import React from 'react';

type FieldLegendProps = {
  title: string;
  required?: boolean;
};

export const FieldLegend = ({ title, required = false }: FieldLegendProps) => {
  return (
    <h2 className="font-20 font-medium">
      {title} {required ? '*' : ''}
    </h2>
  );
};
