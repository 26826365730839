import { ImageWithCheckpointsProps } from '@vcc-www/image-with-checkpoints/ImageWithCheckpoints.types';
import { PxStepsEntry } from '../components/PxSteps';

const pxImageWithCheckpointsMock: ImageWithCheckpointsProps = {
  title: 'Part exchange',
  body: 'Get the latest Volvo for less by trading in your current vehicle with us.',
  image: {
    src: 'https://www.volvocars.com/images/v/-/media/project/contentplatform/data/media/my23/homepage/homepage-gallery-01-21x9.jpg',
    alt: 'Wide 16:9 placeholder',
  },
  checkpoints: [
    {
      title: 'Easy online valuation',
    },
    {
      title: 'Fair part exchange',
    },
    {
      title: 'Any brand is our brand',
    },
  ],
};

const pxStepsMock: PxStepsEntry = {
  title: 'How it works',
  stepItems: [
    {
      title: 'Get an indicative valuation online',
      body: 'First, get an indicative valuation on your current car by using our online tool. Please bear in mind that this valuation will decline if you are ordering a car with a long delivery time.',
    },
    {
      title: 'Place your order',
      body: 'When placing your order, you confirm your interest in part exchanging your current vehicle and can use the valuation sum to define the deposit on your new car.',
    },
    {
      title: 'Inspection by retailer',
      body: 'A retailer will contact you to schedule a confirmed valuation on your current car, which will take place a couple of weeks before your new car is ready.',
    },
    {
      title: 'Confirm the deal',
      body: 'We will then email you the final Part Exchange contribution for you to agree on and confirm.',
    },
    {
      title: 'Make your down payment',
      body: 'Once the valuation is confirmed, you can use the value as a whole or in part, to contribute to the deposit on your new car.',
    },
    {
      title: 'Leave and collect',
      body: 'Your new car is now ready for collection. Just bring the vehicle you are trading in, leave it with us, and drive away in your new Volvo.',
    },
  ],
  notCenterAligned: true,
};

export const contentMock = {
  imageWithCheckpointsData: pxImageWithCheckpointsMock,
  stepsData: pxStepsMock,
};
