'use client';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import throttle from 'lodash/throttle';
import { Controller, useFormContext } from 'react-hook-form';
import { cssJoin } from '@volvo-cars/css/utils';
import { ErrorMessage } from '@volvo-cars/react-forms';
import { IconButton } from '@volvo-cars/react-icons';
import { TranslationKey } from '@vcc-package/leads-utils/api';
import { groupVehicles } from '@vcc-package/leads-utils/util';
import { useVisibleVehicles } from '@vcc-package/leads-utils/hooks';
import { globalStyling } from '@vcc-package/leads-utils/constants';

import { SectionHeader, VehicleCard } from '../../components';
import { useLeadsContext } from '../../context/leadsContext';
import { useModel } from '../../context/modelContext';
import {
  useFlexFormContext,
  useFlexFormVehiclePickerContext,
} from '../providers/FlexibleFormsProvider';

export const VehiclePicker = () => {
  const { translate } = useLeadsContext();
  const vehiclePickerProps = useFlexFormVehiclePickerContext();
  const { control, clearErrors, setValue } = useFormContext();

  const { inOverlay } = useFlexFormContext();

  const { onModelSelect, selectedModel } = useModel();
  const vehicles = useVisibleVehicles();

  const refScrollWrapper = useRef<HTMLDivElement>(null);

  const [leftStartPos, setLeftStartpos] = useState<number | null>(null);
  const travelDistance = 250;
  const { canScrollForward, canScrollBack, doScroll } = useVehiclePickerScroll(
    refScrollWrapper,
    travelDistance,
    leftStartPos,
  );
  const vehicleGroups = useMemo(() => groupVehicles(vehicles), [vehicles]);

  const required = vehiclePickerProps?.required ?? false;
  const name = 'modelId';

  useEffect(() => {
    setValue(name, selectedModel);
  }, [selectedModel, setValue]);

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: {
          value: required,
          message: translate(TranslationKey.REQUIRED_FIELD).replaceAll(
            '$0',
            translate(TranslationKey.MODELSELECTOR_HEADER),
          ),
        },
      }}
      render={({ field: { onChange, ref, value }, fieldState: { error } }) => (
        <div data-testid="vehicle-picker">
          <SectionHeader
            title={`${translate(TranslationKey.MODELSELECTOR_HEADER)} ${
              required ? '*' : ''
            }`}
          />
          <div
            className="flex flex-col gap-24"
            style={{ maxWidth: 'calc(100vw - 48px)' }}
          >
            <div
              ref={refScrollWrapper}
              className="overflow-y-hidden overflow-x-auto flex-nowrap flex w-full items-start gap-8 scrollbar-none relative"
              style={{
                marginLeft: -4,
                width: 'calc(100% + 4px)',
                maxWidth: inOverlay ? globalStyling.columnMaxWidth : '100%',
              }}
            >
              {vehicleGroups.map((group, i) => {
                return (
                  <div
                    key={group.key}
                    className={cssJoin(
                      'flex flex-col gap-8',
                      i > 0 && 'border-l pl-8',
                    )}
                  >
                    <div className="text-start">
                      <span
                        style={{
                          position: 'sticky',
                          left: 2,
                          display: 'inline-block',
                        }}
                      >
                        <small className="micro">
                          {translate(group.translationKey)}
                        </small>
                      </span>
                    </div>
                    <div className="flex gap-8 items-start ml-4">
                      {group.vehicles.map((vehicle) => (
                        <VehicleCard
                          key={vehicle.id}
                          vehicle={vehicle}
                          onClick={() => {
                            onModelSelect(vehicle);
                            onChange(vehicle.id);
                            clearErrors(name);
                          }}
                          selected={
                            value?.modelKey === vehicle.modelKey ||
                            value?.id === vehicle.id ||
                            value === vehicle.id
                          }
                          setLeftStartpos={setLeftStartpos}
                        />
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>

            {error && (
              <ErrorMessage errorMessage={error.message} id={name + '-error'} />
            )}

            <div className="self-end flex gap-16">
              <IconButton
                icon="chevron-back"
                ref={ref}
                aria-label="Back"
                aria-disabled={!canScrollBack}
                onClick={() => doScroll('back')}
                data-testid="scroll-back"
              />
              <IconButton
                icon="chevron-forward"
                aria-label="Forward"
                aria-disabled={!canScrollForward}
                onClick={() => doScroll('forward')}
                data-testid="scroll-forward"
              />
            </div>
          </div>
        </div>
      )}
    />
  );
};

export const useVehiclePickerScroll = (
  ref: React.RefObject<HTMLDivElement | null>,
  travelDistance: number,
  leftStartPos: number | null,
) => {
  const [canScrollBack, setCanScrollBack] = useState(false);
  const [canScrollForward, setCanScrollForward] = useState(true);
  const [mounted, setMounted] = useState(false);

  const doScroll = useCallback(
    (direction: 'forward' | 'back') => {
      const el = ref?.current;
      if (!el) return;

      el.scrollLeft =
        direction === 'forward'
          ? el.scrollLeft + travelDistance
          : Math.max(el.scrollLeft - travelDistance, 0);
    },
    [travelDistance, ref],
  );

  useEffect(() => {
    const el = ref?.current;
    if (!el) return;

    const onScroll = throttle(
      () => {
        setCanScrollBack(el.scrollLeft > 0);
        setCanScrollForward(
          el.scrollWidth - el.clientWidth - el.scrollLeft > 0,
        );
      },
      200,
      {
        leading: true,
        trailing: true,
      },
    );

    if (!mounted) {
      el.scrollLeft = leftStartPos ?? 500;
    }

    setTimeout(() => {
      el.style.scrollBehavior = 'smooth';
      if (!mounted) {
        el.scrollLeft = leftStartPos ?? 0;
      }
      setMounted(true);
    }, 200);

    el.addEventListener('scroll', onScroll);
    return () => {
      el.removeEventListener('scroll', onScroll);
    };
  }, [doScroll, ref, mounted, leftStartPos]);

  return { canScrollBack, canScrollForward, doScroll };
};
