import { cssMerge } from '@volvo-cars/css/utils';
import React, { type ReactNode } from 'react';

export const TickIcon = ({
  children,
  className,
  'data-testid': testID,
}: {
  children: ReactNode;
  className?: string;
  'data-testid'?: string;
}) => {
  const CSSClasses = [
    // flexbox
    'flex flex-shrink',
    // alignment
    'items-center justify-center',
    // shape
    'rounded-full border',
    // background & border colors
    'bg-surface-accent-blue border-ornament',
    // size
    'h-24 w-24',
    // custom class
    className,
  ];

  return (
    <div className={cssMerge(CSSClasses)} data-testid={testID}>
      <svg
        viewBox="-2 -2.5 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Checkmark">
          <path
            id="Shape"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.8455 3.36224L5.19337 9.69006L1.15625 5.84968L1.84548 5.12514L5.19337 8.30988L11.1563 2.6377L11.8455 3.36224Z"
            fill="white"
          />
        </g>
        {children}
      </svg>
    </div>
  );
};
