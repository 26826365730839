import React from 'react';

import {
  FlexFormFieldType,
  TranslationKey,
} from '@vcc-package/leads-utils/api';
import { globalStyling } from '@vcc-package/leads-utils/constants';

import { SectionHeader } from '../../components/SectionHeader';
import {
  AccompaniedField,
  AddressOneField,
  AddressTwoField,
  B2BField,
  CityField,
  CombinedAddressField,
  ConditionOfCar,
  ContactChannelsField,
  ContactSlotsField,
  DistrictField,
  EmailAsyncField,
  EmailField,
  FirstnameField,
  GenderField,
  GenericTextField,
  LandlineField,
  LegalAddressAsyncField,
  MobilePhoneAsyncField,
  MobilePhoneField,
  NotesField,
  PlainAddressField,
  PostalCodeField,
  ProductsOfInterestField,
  SecondSurnameField,
  SurnameField,
  TitleField,
} from '../fields';
import {
  useFlexFormContext,
  useFlexFormMainSectionContext,
} from '../providers/FlexibleFormsProvider';
import { RowContainer } from '../rowContainer';
import { useLeadsContext } from '../../context';
import { PrefillDataBanner } from '../../components/PrefillDataBanner';
import { ContactSlotsSingleField } from '../fields/contactSlotsSingleField';

type FieldElement = JSX.Element | FlexFormFieldType.SPACE | null;

export const MainSection = (): JSX.Element | null => {
  const mainSectionProps = useFlexFormMainSectionContext();
  const { useSingleColumn, hideRequired, translate } = useFlexFormContext();
  const { prefilledData } = useLeadsContext();
  if (!mainSectionProps || !translate) {
    throw new Error(
      "mainSectionProps or rootLegacyProps hasn't been initialized",
    );
  }
  const {
    title,
    validationRules,
    b2b,
    allowManualPhoneValidation,
    hideFields,
  } = mainSectionProps;

  const rows = mainSectionProps.rows ?? [];
  const options = mainSectionProps.options ?? {};
  const customTranslations = mainSectionProps.customTranslation ?? {};

  const inputs = rows.map((fields, i) => {
    const fieldPairs: FieldElement[] = fields.map((field) => {
      if (hideFields && hideFields.includes(field)) return null;

      switch (field) {
        case FlexFormFieldType.TITLE_CAR_DETAILS: {
          return (
            <SectionHeader
              key={FlexFormFieldType.TITLE_CAR_DETAILS}
              title={translate(TranslationKey.CAR_DETAILS)}
              className="mb-0 mt-16 lg:mt-24"
            />
          );
        }
        case FlexFormFieldType.LICENSE_PLATE: {
          return (
            <GenericTextField
              translate={translate}
              validation={validationRules}
              hideRequired={hideRequired}
              key={FlexFormFieldType.LICENSE_PLATE}
              customTranslation={customTranslations}
              name={FlexFormFieldType.LICENSE_PLATE}
              labelTranslationKey={TranslationKey.LICENSE_PLATE}
              autoComplete="off"
            />
          );
        }
        case FlexFormFieldType.MILEAGE: {
          return (
            <GenericTextField
              translate={translate}
              validation={validationRules}
              hideRequired={hideRequired}
              key={FlexFormFieldType.MILEAGE}
              customTranslation={customTranslations}
              name={FlexFormFieldType.MILEAGE}
              labelTranslationKey={TranslationKey.MILEAGE}
              autoComplete="off"
              hintTranslationKey={TranslationKey.DIGITS_ONLY}
            />
          );
        }
        case FlexFormFieldType.MODEL_YEAR: {
          return (
            <GenericTextField
              translate={translate}
              validation={validationRules}
              hideRequired={hideRequired}
              key={FlexFormFieldType.MODEL_YEAR}
              customTranslation={customTranslations}
              name={FlexFormFieldType.MODEL_YEAR}
              labelTranslationKey={TranslationKey.MODEL_YEAR}
              autoComplete="off"
              hintTranslationKey={TranslationKey.FOUR_DIGITS_ONLY}
            />
          );
        }
        case FlexFormFieldType.CONDITION_OF_CAR: {
          return (
            <ConditionOfCar
              translate={translate}
              validation={validationRules}
              hideRequired={hideRequired}
              key={FlexFormFieldType.CONDITION_OF_CAR}
              customTranslation={customTranslations}
              options={options}
            />
          );
        }
        case FlexFormFieldType.TITLE_YOUR_DETAILS: {
          return (
            <SectionHeader
              key={FlexFormFieldType.TITLE_YOUR_DETAILS}
              title={translate('YourContactDetails')}
              className="mb-0 mt-16 lg:mt-24"
            />
          );
        }
        case FlexFormFieldType.TITLE: {
          return (
            <TitleField
              translate={translate}
              validation={validationRules}
              options={options}
              key={FlexFormFieldType.TITLE}
              customTranslation={customTranslations}
              hideRequired={hideRequired}
            />
          );
        }
        case FlexFormFieldType.GENDER: {
          return (
            <GenderField
              translate={translate}
              validation={validationRules}
              options={options}
              key={FlexFormFieldType.GENDER}
              customTranslation={customTranslations}
              hideRequired={hideRequired}
            />
          );
        }
        case FlexFormFieldType.FIRSTNAME: {
          return (
            <FirstnameField
              translate={translate}
              validation={validationRules}
              key={FlexFormFieldType.FIRSTNAME}
              customTranslation={customTranslations}
              hideRequired={hideRequired}
            />
          );
        }
        case FlexFormFieldType.FULL_NAME: {
          return (
            <GenericTextField
              translate={translate}
              validation={validationRules}
              hideRequired={hideRequired}
              key={FlexFormFieldType.FULL_NAME}
              customTranslation={customTranslations}
              name={FlexFormFieldType.FULL_NAME}
              labelTranslationKey={TranslationKey.FIRST_NAME_FIELD_LABEL}
              autoComplete="name"
            />
          );
        }
        case FlexFormFieldType.SURNAME: {
          return (
            <SurnameField
              translate={translate}
              validation={validationRules}
              key={FlexFormFieldType.SURNAME}
              customTranslation={customTranslations}
              hideRequired={hideRequired}
            />
          );
        }
        case FlexFormFieldType.SECOND_SURNAME: {
          return (
            <SecondSurnameField
              translate={translate}
              validation={validationRules}
              key={FlexFormFieldType.SECOND_SURNAME}
              customTranslation={customTranslations}
              hideRequired={hideRequired}
            />
          );
        }
        case FlexFormFieldType.MOBILE_PHONE: {
          return (
            <MobilePhoneField
              translate={translate}
              validation={validationRules}
              key={FlexFormFieldType.MOBILE_PHONE}
              customTranslation={customTranslations}
              hideRequired={hideRequired}
            />
          );
        }
        case FlexFormFieldType.MOBILE_PHONE_ASYNC: {
          return (
            <MobilePhoneAsyncField
              allowManualPhoneValidation={allowManualPhoneValidation}
              translate={translate}
              validation={validationRules}
              key={FlexFormFieldType.MOBILE_PHONE_ASYNC}
              customTranslation={customTranslations}
              hideRequired={hideRequired}
            />
          );
        }
        case FlexFormFieldType.LANDLINE: {
          return (
            <LandlineField
              translate={translate}
              validation={validationRules}
              key={FlexFormFieldType.LANDLINE}
              customTranslation={customTranslations}
              hideRequired={hideRequired}
            />
          );
        }
        case FlexFormFieldType.EMAIL: {
          return (
            <EmailField
              translate={translate}
              validation={validationRules}
              key={FlexFormFieldType.EMAIL}
              customTranslation={customTranslations}
              hideRequired={hideRequired}
            />
          );
        }
        case FlexFormFieldType.EMAIL_ASYNC: {
          return (
            <EmailAsyncField
              translate={translate}
              validation={validationRules}
              key={FlexFormFieldType.EMAIL_ASYNC}
              customTranslation={customTranslations}
              hideRequired={hideRequired}
            />
          );
        }
        case FlexFormFieldType.LEGAL_ADDRESS_ASYNC: {
          return (
            <LegalAddressAsyncField
              translate={translate}
              validation={validationRules}
              key={FlexFormFieldType.LEGAL_ADDRESS_ASYNC}
              customTranslation={customTranslations}
              hideRequired={hideRequired}
            />
          );
        }
        case FlexFormFieldType.PLAIN_ADDRESS: {
          return (
            <PlainAddressField
              translate={translate}
              key={FlexFormFieldType.PLAIN_ADDRESS}
              customTranslation={customTranslations}
              validation={validationRules}
            />
          );
        }
        case FlexFormFieldType.ADDRESS_ASYNC_WITH_MANUAL: {
          return (
            <CombinedAddressField
              translate={translate}
              validation={validationRules}
              key={FlexFormFieldType.ADDRESS_ASYNC_WITH_MANUAL}
              customTranslation={customTranslations}
              hideRequired={hideRequired}
            />
          );
        }
        case FlexFormFieldType.POSTAL_CODE: {
          return (
            <PostalCodeField
              translate={translate}
              validation={validationRules}
              key={FlexFormFieldType.POSTAL_CODE}
              customTranslation={customTranslations}
              hideRequired={hideRequired}
            />
          );
        }
        case FlexFormFieldType.DISTRICT: {
          return (
            <DistrictField
              translate={translate}
              validation={validationRules}
              key={FlexFormFieldType.DISTRICT}
              customTranslation={customTranslations}
              hideRequired={hideRequired}
            />
          );
        }
        case FlexFormFieldType.CITY: {
          return (
            <CityField
              translate={translate}
              validation={validationRules}
              key={FlexFormFieldType.CITY}
              customTranslation={customTranslations}
              hideRequired={hideRequired}
            />
          );
        }
        case FlexFormFieldType.ADDRESS_ONE: {
          return (
            <AddressOneField
              translate={translate}
              validation={validationRules}
              key={FlexFormFieldType.ADDRESS_ONE}
              customTranslation={customTranslations}
              hideRequired={hideRequired}
            />
          );
        }
        case FlexFormFieldType.ADDRESS_TWO: {
          return (
            <AddressTwoField
              translate={translate}
              validation={validationRules}
              key={FlexFormFieldType.ADDRESS_TWO}
              customTranslation={customTranslations}
              hideRequired={hideRequired}
            />
          );
        }
        case FlexFormFieldType.NOTES: {
          return (
            <NotesField
              translate={translate}
              validation={validationRules}
              key={FlexFormFieldType.NOTES}
              customTranslation={customTranslations}
              hideRequired={hideRequired}
            />
          );
        }
        case FlexFormFieldType.B2B: {
          if (b2b?.shouldRender) {
            return (
              <B2BField
                translate={translate}
                options={b2b}
                key={FlexFormFieldType.B2B}
                customTranslation={customTranslations}
                hideRequired={hideRequired}
              />
            );
          }
          return null;
        }
        case FlexFormFieldType.ACCOMPANIED: {
          return (
            <AccompaniedField
              translate={translate}
              fieldType={FlexFormFieldType.ACCOMPANIED}
              defaultValue={false}
              key={FlexFormFieldType.ACCOMPANIED}
              customTranslation={customTranslations}
              hideRequired={hideRequired}
            />
          );
        }
        case FlexFormFieldType.PRODUCTS_OF_INTEREST: {
          return (
            <ProductsOfInterestField
              translate={translate}
              validation={validationRules}
              options={options}
              key={FlexFormFieldType.PRODUCTS_OF_INTEREST}
            />
          );
        }
        case FlexFormFieldType.CONTACT_CHANNELS: {
          return (
            <ContactChannelsField
              translate={translate}
              validation={validationRules}
              options={options}
              key={FlexFormFieldType.CONTACT_CHANNELS}
              hideRequired={hideRequired}
            />
          );
        }
        case FlexFormFieldType.CONTACT_SLOTS: {
          return (
            <ContactSlotsField
              translate={translate}
              validation={validationRules}
              options={options}
              key={FlexFormFieldType.CONTACT_SLOTS}
              hideRequired={hideRequired}
            />
          );
        }
        case FlexFormFieldType.CONTACT_SLOTS_SINGLE: {
          return (
            <ContactSlotsSingleField
              translate={translate}
              validation={validationRules}
              options={options}
              key={FlexFormFieldType.CONTACT_SLOTS_SINGLE}
              hideRequired={hideRequired}
            />
          );
        }
        case FlexFormFieldType.SPACE:
          return useSingleColumn ? null : FlexFormFieldType.SPACE;
        default: {
          console.warn(`(${field}) was not recognized, was it misspelled?`);
          return null;
        }
      }
    });

    return isEmptyPair(fieldPairs) ? null : (
      <RowContainer key={`inputpair_${i}`} useSingleColumn={useSingleColumn}>
        {fieldPairs}
      </RowContainer>
    );
  });
  const translatedTitle = title && translate(title);
  return (
    <div>
      {translatedTitle && (
        <SectionHeader title={translatedTitle} testId="main-section-header" />
      )}

      {prefilledData && <PrefillDataBanner translate={translate} />}

      <div className={'flex flex-col ' + globalStyling.fieldGap}>{inputs}</div>
    </div>
  );
};

// To prevent rendering empty containers (example: b2b)
const isEmptyPair = ([el1, el2]: FieldElement[]) =>
  el1 === null && (!el2 || el2 === FlexFormFieldType.SPACE);
