import { NEXT_PUBLIC_HOST } from 'src/utils/constants';

export const logError = (error: any, extraErrorInfo?: any) => {
  delete error?.params?.headers?.['VCC-Api-Key'];
  console.error(error, extraErrorInfo ?? '');

  if (error?.code === 'ECONNRESET') return;

  const environment = new URL(NEXT_PUBLIC_HOST).host;

  const hostStatusCodeBlock = {
    type: 'section',
    fields: [
      {
        type: 'mrkdwn',
        text: `*Host:*\n${process.env.HOSTNAME}\n*Environment:*\n${environment}`,
      },
    ],
  };

  if (error?.statusCode) {
    hostStatusCodeBlock.fields.push({
      type: 'mrkdwn',
      text: `*Status code:*\n${error?.statusCode}`,
    });
  }

  const blocks = [
    {
      type: 'header',
      text: {
        type: 'plain_text',
        text: `${error?.type} error`,
      },
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*Message:*\n${error?.message}`,
      },
    },
    hostStatusCodeBlock,
  ];

  if (error?.endpoint) {
    blocks.push({
      type: 'section',
      fields: [
        { type: 'mrkdwn', text: `*Request URL:*\n${error?.endpoint}` },
        {
          type: 'mrkdwn',
          text: `*Request params:*\n${JSON.stringify(error?.params)}`,
        },
      ],
    });
  }

  const params = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ blocks }),
  };

  if (
    typeof window === 'undefined' &&
    process.env.NODE_ENV === 'production' &&
    process.env.HOSTNAME !== 'development' &&
    process.env.SLACK_WEBHOOK_URL
  ) {
    // Posts to #cas-learn-finance-options-error
    fetch(process.env.SLACK_WEBHOOK_URL, params);
  }
};
