import type { ResponseCookie } from 'next/dist/compiled/@edge-runtime/cookies';
import type { NextResponse } from 'next/server';

export type CookieSetterObj = {
  key: string;
  value: string;
  options?: Partial<ResponseCookie>;
};
export type CookieSetter = (
  key: string,
  value: string,
  options?: Partial<ResponseCookie>,
) => void;

const restrictedKeys = [
  'expires',
  'path',
  'domain',
  'secure',
  'samesite',
  'httponly',
];

export const parseCookieString = (
  cookies: string | null,
): Record<string, CookieSetterObj> => {
  const cookieDict: Record<string, CookieSetterObj> = {};

  if (!cookies) {
    return cookieDict;
  }

  cookies.split(';').forEach((cookie: string) => {
    const [first, ...rest] = cookie.split('=');
    const key = first.trim();
    const value = rest.join('=');
    const options = {} as Partial<ResponseCookie>;

    if (!key) {
      return;
    }

    if (restrictedKeys.includes(key.toLowerCase())) {
      const lastCookie = Object.values(cookieDict).pop();
      if (!lastCookie) {
        console.error('Invalid cookie key', key);
        return;
      }
      lastCookie.options = lastCookie.options ?? {};
      switch (key.toLowerCase()) {
        case 'expires':
          lastCookie.options.expires =
            (value as any) instanceof Date
              ? (value as unknown as Date)
              : new Date(value);
          break;
        case 'path':
          lastCookie.options.path = value;
          break;
        case 'domain':
          lastCookie.options.domain = value;
          break;
        case 'secure':
          lastCookie.options.secure = true;
          break;
        case 'httponly':
          lastCookie.options.httpOnly = true;
          break;
        case 'samesite':
          lastCookie.options.sameSite = value as 'strict' | 'lax' | 'none';
          break;
        default:
          break;
      }
      return;
    }

    cookieDict[key.trim()] = { key, value, options };
  });

  return cookieDict;
};

export const getCookieValue = (
  cookies: string | null,
  key: string,
): string | null => {
  const cookieDict = parseCookieString(cookies);

  return cookieDict[key]?.value ?? null;
};

const metaSerializer = (options: Partial<ResponseCookie> | undefined) => {
  if (!options) {
    return '';
  }

  return (
    ';' +
    Object.entries(options)
      .map(([key, value]) => {
        if (!value) {
          return '';
        }
        if (typeof value === 'boolean') {
          return value ? key : '';
        }
        return `${key}=${value}`;
      })
      .join('; ')
  );
};

export const serverSideCookieSetter = (
  res: NextResponse<unknown> | undefined,
): CookieSetter => {
  return (key: string, value: string, options?: Partial<ResponseCookie>) => {
    if (!res) {
      //We try with the cookies object, but it's not guaranteed to work
      return;
    }
    res?.cookies.set({
      name: key,
      value,
      ...options,
      expires: options?.expires ? new Date(options.expires) : undefined,
    });
  };
};

export const clientSideCookieSetter: CookieSetter = (key, value, options) => {
  if (typeof document === 'undefined') {
    console.info('Cannot set cookie on server side');
    return;
  }
  const meta = metaSerializer(options);
  let cookieString = `${key}=${value}${meta}`;
  document.cookie = cookieString;
};
