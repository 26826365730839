import { FlexFormFieldType } from '../api';
import type { FormValues } from '../types/flexibleForm';

export const getCarInfoFromFormValues = (formValues: FormValues) => {
  return !formValues?.[FlexFormFieldType.LICENSE_PLATE]
    ? ''
    : `Car information:
      License plate: ${formValues?.[FlexFormFieldType.LICENSE_PLATE]}
      Mileage: ${formValues?.[FlexFormFieldType.MILEAGE] ?? ''}
      Model year: ${formValues?.[FlexFormFieldType.MODEL_YEAR] ?? ''}
      Condition: ${formValues?.[FlexFormFieldType.CONDITION_OF_CAR] ?? ''}`;
};
