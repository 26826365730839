import type { MarketConfiguration } from '@vcc-package/leads-utils/api';
import type { LeadsApp } from '@vcc-package/leads-utils/constants';
import { LeadsApplications, urls } from '@vcc-package/leads-utils/constants';

import type { CookieSetter } from './cookie';
import { getCookieValue } from './cookie';

type FeatureSessionData = Record<string, boolean>;

export const FF_SESSION_KEY = 'VLC-FF';
const FF_MAX_AGE = 60 * 60 * 24; // 1 day

export const removeNulls = (
  obj: Record<string, boolean | null>,
): Record<string, boolean> => {
  const newObj: Record<string, boolean> = {};
  for (const key in obj) {
    newObj[key] = obj[key] ?? false;
  }
  return newObj;
};

export const sortDictionary = (
  obj: Record<string, boolean | null>,
): Record<string, boolean> => {
  return Object.keys(obj)
    .sort()
    .reduce(
      (newObj, key) => {
        newObj[key] = obj[key] ?? false;
        return newObj;
      },
      {} as Record<string, boolean>,
    );
};

export const getFeatureFlags = (
  marketConfig: MarketConfiguration,
): FeatureSessionData => {
  return sortDictionary({
    ...getDynamicFeatureFlags(marketConfig),
    ...removeNulls(marketConfig.features),
  });
};

export const getDynamicFeatureFlags = (
  marketConfig: MarketConfiguration,
): FeatureSessionData => {
  try {
    return sortDictionary({
      ...removeNulls(marketConfig.dynamicFeatures),
    });
  } catch (e: any) {
    console.error(e);
    return {};
  }
};

const getTimestampFromHeader = (header: string | null): number => {
  const now = Math.floor(Date.now() / 1000);
  return header ? parseInt(header?.split(':')[0] ?? `${now}`, 10) : now;
};

export const createCookieString = (
  appId: LeadsApp,
  header: string,
  siteSlug: string,
) => {
  const cookieKey = `${FF_SESSION_KEY}-${siteSlug}`;
  const expire = new Date((getTimestampFromHeader(header) + FF_MAX_AGE) * 1000);

  const app = LeadsApplications[appId];
  const path = urls[app.appId].root.replace('[market]', siteSlug);

  return {
    key: cookieKey,
    value: header,
    options: {
      expires: expire,
      path,
    },
  };
};

export const getFeatureFlagHeader = (
  cookies: string | null,
  featureFlagHeader: string | null,
  siteSlug: string,
) => {
  try {
    const cookieValue = getHeaderFromCookie(cookies, siteSlug);
    const timestamp = getTimestampFromHeader(
      cookieValue ?? featureFlagHeader ?? null,
    );
    const expire = timestamp + FF_MAX_AGE;
    const isExpired = expire < Math.floor(Date.now() / 1000);

    if (cookieValue && !isExpired) {
      return {
        header: cookieValue,
        timestamp,
      };
    }

    return {
      header: featureFlagHeader ?? null,
      timestamp,
    };
  } catch (e: any) {
    console.error(e);
    return {
      header: null,
      timestamp: Date.now(),
    };
  }
};

export const getHeaderFromCookie = (
  cookies: string | null,
  siteSlug: string,
): string | null => {
  const cookieKey = `${FF_SESSION_KEY}-${siteSlug}`;

  const cookieValue = getCookieValue(cookies, cookieKey);

  const timestamp = getTimestampFromHeader(cookieValue);
  const isExpired = timestamp + FF_MAX_AGE < Math.floor(Date.now() / 1000);

  return isExpired ? null : cookieValue;
};

export function generateClientId(gaVersion: string = '1.1') {
  const timestamp = Math.floor(new Date().getTime() / 1000);
  const randomNum = Math.floor(Math.random() * 1000000000);
  return `GA${gaVersion}.${randomNum}.${timestamp}`;
}

type getGaCookieOptions = {
  cookies: string | null;
  setter: CookieSetter;
};
// Check if the _ga cookie is set, if not, set it
export function getGaCookie(options: getGaCookieOptions): string {
  const { cookies, setter } = options;

  const gid = getCookieValue(cookies, '_gid');
  if (gid === null) {
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 1); // Set expiry to 1 day from now
    setter('_gid', generateClientId(), {
      expires: expiryDate,
      path: '/',
    });
  }

  const ga = getCookieValue(cookies, '_ga');

  if (ga === null) {
    const clientId = generateClientId();
    const expiryDate = new Date();
    expiryDate.setFullYear(expiryDate.getFullYear() + 2); // Set expiry to 1 year from now

    setter('_ga', clientId, {
      expires: expiryDate,
      path: '/',
    });

    return clientId;
  }

  return ga;
}
