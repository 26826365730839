import { Action } from '@vcc-www/utils/personalization';

type props = {
  action: Action;
  emailAddress: string;
  emailOrigin: string;
};

export const sendEvent = async ({
  action,
  emailAddress,
  emailOrigin,
}: props) => {
  const Evergage = window?.Evergage;
  if (typeof Evergage?.sendEvent === 'undefined') {
    console.warn('Evergage.sendEvent is undefined');
    return;
  }

  const user = {
    attributes: {
      emailAddress,
      emailOrigin,
    },
  };

  try {
    return await Evergage.sendEvent({
      action,
      user,
    });
  } catch (error) {
    console.info('Error sending Evergage event', error);
  }
};
