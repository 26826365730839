interface AuthTokenResponse {
  authToken: string;
}

export const getAuthToken = async (apiRoute: string, loginCode: string) => {
  try {
    const res = await fetch(apiRoute, {
      method: 'POST',
      body: JSON.stringify({
        code: loginCode,
      }),
    });

    if (res.ok) {
      const authResponse: AuthTokenResponse = await res.json();
      return authResponse;
    } else {
      const authError = await res.json();
      const error = new Error(authError.message);
      return Promise.reject(error);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
