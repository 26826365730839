import type { Query } from '@vcc-package/leads-utils/types';
import type { Package } from '@vcc-package/leads-utils/api';

/**
 * Checks the given query & application Package for campaign codes (query prioritized)
 * @returns Campaign code or null
 */
export const getCampaignCode = ({
  isB2B,
  purposeConfig,
  query,
}: {
  isB2B: boolean;
  purposeConfig: Package | undefined;
  query: Query;
}) => {
  const { campaigncode, b2bcampaigncode } = query;

  const calculatedCampaignCode = isB2B
    ? b2bcampaigncode || purposeConfig?.campaignCodes?.b2BCampaignCode
    : campaigncode || purposeConfig?.campaignCodes?.b2CCampaignCode;

  if (!calculatedCampaignCode) {
    throw new Error('No campaigncode was provided');
  }

  return calculatedCampaignCode;
};
