'use client';

import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Block, ExtendCSS } from 'vcc-ui';
import { useRouteFields } from '@vcc-www/api/sitecore9';
import {
  isSupported,
  STAGGER_DELAY,
  TRANSFORM_DURATION,
  OPACITY_DURATION,
  SCALE_DURATION,
} from './utils';

interface AnimateProps {
  opacity?: boolean;
  transform?: boolean;
  scale?: boolean;
}

export type Props = {
  /**
   * When using AnimatedReveal multiple times in a row and you want a staggering
   * delay effect on the reveals.
   *
   * Example
   * ```tsx
   * <AnimatedReveal>
   *   <div>Appears immediately</div>
   * </AnimatedReveal>
   * <AnimatedReveal stagger={1}>
   *   <div>Appears after 100ms</div>
   * </AnimatedReveal>
   * <AnimatedReveal stagger={2}>
   *   <div>Appears after 200ms</div>
   * </AnimatedReveal>
   * ```
   */
  stagger?: number;

  /**
   * rootMargin for the IntersectionObserver - what margin on the trigger point
   * for when the element enters the view.
   *
   * Default is `-50` - the element will not appear until atleast 50px of it is
   * in the view.
   *
   * If the element is much larger you can play around with a larger negative
   * number.
   */
  rootMargin?: number;

  /**
   * Transform animation duration.
   *
   * Default is `1000`ms.
   */
  transformDuration?: number;

  /**
   * Opacity animation duration.
   *
   * Default is `2000`ms.
   */
  opacityDuration?: number;

  /**
   * Manually enable the reveal animations for component.
   * Useful when you don't need whole page to be animated.
   */
  enable?: boolean;

  /**
   * Which animations should be enabled
   */
  animate?: AnimateProps;
};

const AnimatedReveal: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  stagger = 0,
  rootMargin = 50,
  transformDuration = TRANSFORM_DURATION,
  opacityDuration = OPACITY_DURATION,
  enable,
  animate = {
    opacity: true,
    transform: true,
  },
}) => {
  const { enableRevealAnimations } = useRouteFields();
  const [enabled, setEnabled] = useState(
    enableRevealAnimations || Boolean(enable),
  );
  const [completed, setCompleted] = useState(false);
  const [show, setShow] = useState(false);
  const [wrapper, setWrapper] = useState<HTMLElement | SVGSVGElement | null>(
    null,
  );
  const mounted = useRef(false);

  const delay = STAGGER_DELAY * stagger;

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (typeof enable === 'boolean') {
      setEnabled(enable);
    }
  }, [enable, setEnabled, enabled, enableRevealAnimations]);

  const wrapperCSSMemo = React.useMemo(
    () =>
      wrapperCSS({
        show,
        delay,
        transformDuration,
        opacityDuration,
        completed,
        animate,
      }),
    [show, delay, transformDuration, opacityDuration, completed, animate],
  );

  useEffect(() => {
    if (enabled && wrapper?.children?.length) {
      if (isSupported) {
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
              setShow(true);
              setTimeout(() => {
                if (mounted.current) {
                  setCompleted(true);
                }
              }, opacityDuration + delay);

              observer.disconnect();
            }
          },
          {
            threshold: [0, 1],
            rootMargin: `${rootMargin}px`,
          },
        );

        observer.observe(wrapper.children[0]);

        return () => {
          if (observer) {
            observer.disconnect();
          }
        };
      } else {
        setEnabled(false);
      }
    }
  }, [enabled, delay, setEnabled, rootMargin, wrapper, opacityDuration]);

  const refHandler = useCallback(
    (ref: any) => {
      setWrapper(ref);
    },
    [setWrapper],
  );

  return enabled ? (
    <Block ref={refHandler} extend={wrapperCSSMemo}>
      {children}
    </Block>
  ) : (
    <>{children}</>
  );
};

export default AnimatedReveal;

const wrapperCSS = ({
  show,
  delay,
  transformDuration,
  opacityDuration,
  completed,
  animate,
}: {
  show: boolean;
  delay: number;
  transformDuration: number;
  opacityDuration: number;
  completed: boolean;
  animate: AnimateProps;
}): ExtendCSS => ({
  display: 'contents',
  '> *': {
    extend: {
      condition: !completed,
      style: {
        transition: `transform ${transformDuration}ms ${delay}ms, opacity ${opacityDuration}ms ${delay}ms, scale ${SCALE_DURATION}ms ${delay}ms !important`,
        extend: [
          {
            condition: animate.transform,
            style: {
              transform: `translate3D(0px, ${show ? 0 : 50}px, 0px) ${
                animate.scale ? `scale(${show ? 1 : 1.2})` : ''
              } !important`,
            },
          },
          {
            condition: animate.opacity,
            style: {
              opacity: `${show ? 1 : 0} !important`,
            },
          },
        ],
      },
    },
  },
});
