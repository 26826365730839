import React, { useState } from 'react';
import { Block, Flex, Link, Text } from 'vcc-ui';
import { Expand } from '@volvo-cars/react-accordion';
import { maxContentWidth12Columns } from '@vcc-www/constants/layout';
import {
  BaseRowProps,
  TableComponentType,
  TableConfig,
} from './ComparisonTable.types';
import { getData } from './utils';

export const TableMobile: TableComponentType = ({
  config,
  data,
  amountToShowCollapsedOnMobile = 4,
  showMoreText,
  showLessText,
  testId,
  onExpandClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container testId={testId}>
      <InnerContainer>
        <HeaderContainer>
          {config.map((column, ind) => (
            <Header
              testId={testId}
              key={`comparison-table-mobile-column-header-${ind}`}
            >
              {column.mobileHeaderComponent || column.headerComponent}
            </Header>
          ))}
        </HeaderContainer>
        {data.slice(0, amountToShowCollapsedOnMobile).map((item, ind) => (
          <Item testId={testId} key={ind} item={item} config={config} />
        ))}
        <Expand isExpanded={isOpen}>
          {data.slice(amountToShowCollapsedOnMobile).map((item, ind) => (
            <Item
              testId={testId}
              key={`collapsed-${ind}`}
              item={item}
              config={config}
            />
          ))}
        </Expand>
        <Block extend={{ margin: '0 auto 0 0' }}>
          <Link
            data-testid={`${testId}__expand-button`}
            onClick={() => {
              setIsOpen(!isOpen);
              onExpandClick?.(!isOpen);
            }}
            arrow={isOpen ? 'up' : 'down'}
          >
            {isOpen ? showLessText : showMoreText}
          </Link>
        </Block>
      </InnerContainer>
    </Container>
  );
};

const Item = <TEntity extends BaseRowProps>({
  item,
  config,
  testId,
}: React.PropsWithChildren<{
  item: TEntity;
  config: TableConfig<TEntity>;
}> & { testId?: string }) => (
  <Row testId={testId}>
    <RowTitle testId={testId}>{item.rowTitle}</RowTitle>
    <CellContainer>
      {config.map(({ getValue }, ind) => (
        <Cell data={getData(getValue, item)} key={ind} />
      ))}
    </CellContainer>
  </Row>
);

const Container: React.FC<React.PropsWithChildren<{ testId?: string }>> = ({
  testId,
  ...props
}) => (
  <Flex
    data-testid={`${testId}__table__mobile`}
    extend={({ theme: { color, baselineGrid } }) => ({
      background: color.background.secondary,
      padding: `0 ${3 * baselineGrid}px ${2 * baselineGrid}px`,
      marginBottom: 5 * baselineGrid,

      fromL: { display: 'none' },
    })}
    {...props}
  />
);

const InnerContainer: React.FC<React.PropsWithChildren<unknown>> = (props) => (
  <Flex
    extend={{
      maxWidth: maxContentWidth12Columns / 2,
      width: '100%',
      margin: '0 auto',
    }}
    {...props}
  />
);

const HeaderContainer: React.FC<React.PropsWithChildren<unknown>> = (props) => (
  <Flex
    extend={({ theme: { baselineGrid } }) => ({
      flexDirection: 'row',
      padding: `${3 * baselineGrid}px 0`,
    })}
    {...props}
  />
);

const Header: React.FC<React.PropsWithChildren<{ testId?: string }>> = ({
  testId,
  ...props
}) => (
  <Flex
    data-testid={`${testId}__column__header`}
    extend={({ theme: { baselineGrid } }) => ({
      flex: 1,
      paddingRight: 2 * baselineGrid,

      ':last-child': { paddingRight: 0 },

      fromM: { textAlign: 'center', padding: `0 ${baselineGrid}px 0` },
    })}
    {...props}
  />
);

const Row: React.FC<React.PropsWithChildren<{ testId?: string }>> = ({
  testId,
  ...props
}) => (
  <Flex
    data-testid={`${testId}__row`}
    extend={({ theme: { color, baselineGrid } }) => ({
      padding: `${baselineGrid}px 0 ${2 * baselineGrid}px`,
      borderTop: `1px solid ${color.ornament.divider}`,
    })}
    {...props}
  />
);

const RowTitle: React.FC<React.PropsWithChildren<{ testId?: string }>> = ({
  testId,
  ...props
}) => (
  <Text
    data-testid={`${testId}__row__title`}
    variant="kelly"
    subStyle="emphasis"
    extend={({ theme: { baselineSubGrid } }) => ({
      marginBottom: 5 * baselineSubGrid,
    })}
    {...props}
  />
);

const CellContainer: React.FC<React.PropsWithChildren<unknown>> = (props) => (
  <Flex extend={{ flexDirection: 'row' }} {...props} />
);

const Cell: React.FC<
  React.PropsWithChildren<{ data: React.ReactNode | null }>
> = ({ data }) => (
  <Flex
    extend={({ theme: { color } }) => ({
      color: color.foreground.primary,
      textAlign: 'center',
      flex: 1,
      alignItems: 'center',
    })}
  >
    <Text>{data}</Text>
  </Flex>
);
