import React from 'react';
import { ListItemLinkProps } from './list-item.types';
import { getTitleColor } from './list-item.utils';
import { cssJoin } from '@volvo-cars/css/utils';
import styles from './list-item.module.css';
import { Icon } from '@volvo-cars/react-icons';

export const ListItemLink = ({
  interactive = true,
  isOpen = false,
  isPriceBreakDown,
  linkText,
  linkTextSources,
  linkTextClosed,
  linkTextClosedSources,
  linkTextOpened,
  linkTextOpenedSources,
  linkVariant,
  onHover,
  onPress,
  testId,
}: ListItemLinkProps) => {
  const linkTextValue = (isOpen ? linkTextOpened : linkTextClosed) || linkText;
  const linkTextValueSources = linkText
    ? linkTextSources
    : isOpen
      ? linkTextOpenedSources
      : linkTextClosedSources;

  return linkVariant === 'chevron-expandable' ? (
    <div
      className={cssJoin(
        'flex-col',
        styles['list-item-link-arrow-icon'],
        isOpen && styles['list-item-link-rotate'],
      )}
    >
      <Icon
        icon="chevron-down"
        size={16}
        color={onPress ? 'secondary' : onHover ? 'accent-blue' : 'primary'}
      />
    </div>
  ) : linkVariant === 'text' ? (
    <small
      className={cssJoin(
        'micro link-underlined',
        isPriceBreakDown && styles['list-item-link-price-breakdown'],
        getTitleColor({
          interactive,
          onPress,
          onHover,
        }),
      )}
      data-sources={linkTextValueSources}
      aria-label={linkTextValue}
      data-testid={`${testId}-${isOpen ? 'less-info' : 'more-info'}-text`}
    >
      {linkTextValue}
    </small>
  ) : linkVariant === 'chevron-link' ? (
    <Icon
      icon="chevron-forward"
      size={16}
      color={onPress ? 'secondary' : onHover ? 'accent-blue' : 'primary'}
    />
  ) : null;
};
