import { OfferComparisonFinanceOption } from './OfferComparison.types';

export const servicesToOffers = (
  services: OfferComparisonFinanceOption['services'],
) => {
  return services.map((service) => ({
    id: service?.id,
    name: service?.displayName,
    description: service?.shortDescription,
    fullDescription: service?.description,
  }));
};

export const financeOptionToLocalization = (
  financeOption: OfferComparisonFinanceOption,
) => {
  return {
    name: financeOption?.displayName,
    description: financeOption?.shortDescription,
    fullDescription: financeOption?.description,
  };
};
