import React from 'react';
import { TableComponentType } from './ComparisonTable.types';
import { TableDesktop } from './Desktop';
import { TableMobile } from './Mobile';

const ComparisonTable: TableComponentType = (props) => (
  <>
    <TableDesktop {...props} />
    <TableMobile {...props} />
  </>
);

export default ComparisonTable;
