import type { Global } from '@volvo-cars/tracking';

import { urls } from './urls';

export const subPageNameMap: Record<string, Global['subPageName']> = {
  [urls.accessory.root]: 'form',
  [urls.accessory.form]: 'form',
  [urls.accessory.finished]: 'thank you',
  [urls.contact.root]: 'form',
  [urls.contact.form]: 'form',
  [urls.contact.finished]: 'thank you',
  [urls.callback.root]: 'form',
  [urls.callback.finished]: 'thank you',
  [urls.kmu.root]: 'form',
  [urls.kmu.form]: 'form',
  [urls.kmu.finished]: 'thank you',
  [urls.quote.root]: 'model selector',
  [urls.quote.retailer]: 'sales agent selector',
  [urls.quote.form]: 'form',
  [urls.quote.finished]: 'thank you',
};
