import type { SiteSlug } from '@volvo-cars/market-sites';

import { LeadsApp } from './leadsApplications';

const siteSlugPlaceholder = '[market]';

const getPaths = (basePath: string) => ({
  root: `/${siteSlugPlaceholder}/${basePath}`,
  form: `/${siteSlugPlaceholder}/${basePath}/form`,
  finished: `/${siteSlugPlaceholder}/${basePath}/finished`,
});

export const urls = {
  [LeadsApp.AccessoryRequest]: getPaths('accessory-request'),
  [LeadsApp.CallbackRequest]: getPaths('callback-request'),
  [LeadsApp.ContactRequest]: getPaths('contact-request'),
  [LeadsApp.KeepMeUpdated]: getPaths('keep-me-updated'),
  [LeadsApp.QuoteRequest]: {
    ...getPaths('quote'),
    retailer: `/${siteSlugPlaceholder}/quote/retailer`,
  },
};

export const getParsedUrl = (url: string, siteSlug: SiteSlug) =>
  url.replace(siteSlugPlaceholder, siteSlug);
