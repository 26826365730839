import React from 'react';

import type { DealerModel } from '@vcc-package/leads-utils/api';

import { Card } from '../../../../components';

interface RetailerItemProps {
  id: any;
  item: DealerModel;
  selected?: boolean;

  onClick(dealer: DealerModel): void;
}

export const RetailerItem = ({
  item,
  selected = false,
  onClick,
}: RetailerItemProps) => {
  const setSelected = () => {
    onClick(item);
  };

  return (
    <Card
      ariaSelected={selected}
      dataTestid={`retailersuggestion_${item.name}`}
      onClick={() => setSelected()}
    >
      <div className="flex flex-col items-start gap-8">
        <p className="text-primary font-medium">{item.name}</p>
        <div className="text-secondary w-full text-start">
          <p className="flex-row justify-between items-center w-full">
            <span className="flex">
              {item.address ? item.address.addressLine1 : '-'},{' '}
            </span>
            <span className="flex">
              {item.distanceToAddress ? item.distanceToAddress : null}
            </span>
          </p>
          <p>
            {item.address?.city ?? ''}{' '}
            {item.address?.postalCode ? `, ${item.address.postalCode}` : ''}{' '}
            {item.address?.district ?? ''}
          </p>
        </div>
      </div>
    </Card>
  );
};
