'use client';

import { useCallback, useMemo, useState } from 'react';
import { SingleSelectProps } from './single-select.types';
import {
  getOnChangeHandler,
  getOptionsWithTitles,
} from './single-select.utils';

export const useSingleSelect = ({
  defaultSelectedIndex,
  disabled,
  onChange,
  options,
  readOnly,
}: SingleSelectProps) => {
  const [currentIndex, setCurrentIndex] = useState<number | null | undefined>(
    defaultSelectedIndex === null ? null : defaultSelectedIndex || 0,
  );

  const onChangeHandlerFn = useCallback(
    () =>
      getOnChangeHandler({
        disabled,
        onChange,
        readOnly,
        setCurrentIndex,
      }),
    [onChange, disabled, readOnly, setCurrentIndex],
  );

  const optionsWithTitles = useMemo(
    () => getOptionsWithTitles({ options }),
    [options],
  );

  return {
    currentIndex,
    optionsWithTitles,
    onChangeHandler: onChangeHandlerFn(),
  };
};
