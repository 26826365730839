import type { Translations } from '@vcc-package/leads-utils/types';
import type { GetAllTranslationsResponse } from '@vcc-package/leads-utils/api';

/**
 * Takes the key-value-pair returned from the API & creates a Translation object
 * @returns Translation object
 */
export const getTranslationsObject = ({
  translations,
}: GetAllTranslationsResponse): Translations | undefined => {
  if (!translations) {
    console.error('No translations available');
    return;
  }

  return Object.fromEntries(
    translations.map(({ key, value }) => [key, value ?? `PLACEHOLDER_${key}`]),
  );
};
