import Cookies, { CookieAttributes } from 'js-cookie';
import { hasConsentForGroup, ConsentGroups } from './consent';

export function getCookie(name: string) {
  return Cookies.get(name);
}

export function setPerformanceCookie(
  name: string,
  value: string,
  options: CookieAttributes = {},
) {
  if (hasConsentForGroup(ConsentGroups.PERFORMANCE)) {
    Cookies.set(name, value, {
      secure: window.location.protocol === 'https:',
      ...options,
    });
  }
}

export function setFunctionalCookie(
  name: string,
  value: string,
  options: CookieAttributes = {},
) {
  if (hasConsentForGroup(ConsentGroups.FUNCTIONAL)) {
    Cookies.set(name, value, {
      secure: window.location.protocol === 'https:',
      ...options,
    });
  }
}
