import React from 'react';

const parseLinkExpression = /\[(.*?)\]\((.*?)\)/;
const splitOnLinksExpression = /(\[.*?\]\(.*?\))/;

export const TextWithLink = ({
  children,
}: React.PropsWithChildren<unknown>): JSX.Element | null => {
  return <>{typeof children == 'string' ? parseLinks(children) : children}</>;
};

const parseLink = (element: string, index: number) => {
  const match = parseLinkExpression.exec(element);

  return match ? (
    <a key={index} href={match[2]} target="_blank" rel="noopener noreferrer">
      {match[1]}
    </a>
  ) : (
    element
  );
};

const parseLinks = (text: string) =>
  text.split(splitOnLinksExpression).map(parseLink);
