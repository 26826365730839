'use client';
// TODO: Fix eslint issues the next time this file is edited.
/* eslint-disable react/button-has-type */
// TODO: Fix eslint issues the next time this file is edited.
/* eslint-disable no-restricted-imports */
import React from 'react';
import { useRouter, useSearchParams, usePathname } from 'next/navigation';
import {
  AccordionDetails,
  AccordionSummary,
} from '@volvo-cars/react-accordion';
import { Track, TrackingProvider, useTracker } from '@volvo-cars/tracking';
import { Markdown } from '@vcc-package/text';

import { type Question } from 'src/components/organisms/FaqQuestion';
import { FaqSearch } from 'src/components/organisms/FaqSearch';
import { Spacing } from '../../layout/Spacing';
import { type cbvFaqListGroup } from 'src/content-management/editorial-components/FaqList/FaqList.props';
import type { LinkEntry } from '@volvo-cars/content-management-client';

export interface FaqTopicsProps {
  type: 'faqTopics';
  title: string;
  subtitle?: string;
  body?: string;
  searchInputPlaceholder: string;
  resultsFoundText: string;
  noResultsFoundText: string;
  topicsTitle: string;
  topics: Topic[];
  viewAllText: string;
}

interface Topic {
  pageLink: LinkEntry;
  categories: cbvFaqListGroup[];
}

export const FaqTopics: React.FC<React.PropsWithChildren<FaqTopicsProps>> = ({
  title,
  subtitle,
  body,
  searchInputPlaceholder,
  resultsFoundText,
  noResultsFoundText,
  topicsTitle,
  topics,
  viewAllText,
}) => {
  const flattenedQuestions: Question[] = topics.reduce((acc, curr) => {
    curr.categories?.forEach((category) => {
      acc.push(
        ...(category.questions || []).map((question) => ({
          ...question,
          topic: curr.pageLink.text,
        })),
      );
    });

    return acc;
  }, [] as Question[]);

  return (
    <TrackingProvider eventCategory="faq topics">
      <Spacing>
        <div
          data-testid="faq-topics"
          className="container-md mt-32 pt-32 lg:pt-48 lg:mt-48"
          data-bleed
        >
          <div className="px-32 md:px-48 lg:px-64 mb-48 lg:mb-48 lg:pb-48">
            <h1 data-testid="faq-topics__title" className="heading-1 mb-16">
              {title}&nbsp;
              {subtitle && <span className="text-secondary">{subtitle}</span>}
            </h1>
            {body && <Markdown markdown={body} />}
            <div className="mt-48">
              <FaqSearch
                placeholder={searchInputPlaceholder}
                resultsFoundText={resultsFoundText}
                noResultsFoundText={noResultsFoundText}
                questions={flattenedQuestions}
                testId="faq-topics"
              />
            </div>
          </div>
          <div
            data-testid="faq-topics__topics"
            className="bg-secondary p-32 md:p-48 lg:p-64"
          >
            <h2
              data-testid="faq-topics__topics__title"
              className="heading-2 mb-16 lg:mb-24"
            >
              {topicsTitle}
            </h2>
            {topics.map((topic) => (
              <TopicItem
                key={topic.pageLink.text}
                {...topic}
                viewAllText={viewAllText}
              />
            ))}
          </div>
        </div>
      </Spacing>
    </TrackingProvider>
  );
};

const TopicItem: React.FC<
  React.PropsWithChildren<Topic & Pick<FaqTopicsProps, 'viewAllText'>>
> = ({ pageLink, categories, viewAllText }) => {
  const ga4Tracker = useTracker({}, { mode: 'ga4' });
  const router = useRouter();
  const searchparams = useSearchParams();
  const pathname = usePathname();

  return (
    <AccordionDetails
      data-testid="faq-topics__topics__accordion"
      defaultOpen={searchparams?.get('topic') === pageLink.text}
      onToggle={(open) => {
        ga4Tracker.customEvent({
          eventCategory: 'faq-topics',
          eventAction: open ? 'accordion|expand' : 'accordion|collapse',
          eventLabel: pageLink?.text ?? '',
        });

        if (open) {
          router.replace(`${pathname?.split('?')[0]}?topic=${pageLink.text}`, {
            scroll: false,
          });
        }
      }}
    >
      <AccordionSummary iconAlignment="center" className="py-4">
        <p
          data-testid="faq-topics__topics__item__title"
          className="font-20 font-medium py-16"
        >
          {pageLink.text}
        </p>
      </AccordionSummary>
      <div className="flex-col items-start pb-16">
        <TopicItemCategory
          href={pageLink.href}
          text={viewAllText}
          topic={pageLink.text ?? ''}
        />
        {categories.map((category) => {
          const encodedCategory = encodeURIComponent(category.title ?? '');

          return (
            <TopicItemCategory
              key={category.title}
              href={`${pageLink.href}?category=${encodedCategory}`}
              text={category.title ?? ''}
              topic={pageLink.text ?? ''}
            />
          );
        })}
      </div>
    </AccordionDetails>
  );
};

const TopicItemCategory: React.FC<
  React.PropsWithChildren<{
    href: string;
    text: string;
    topic: string;
  }>
> = ({ href, text, topic }) => {
  return (
    <Track eventLabel={`${topic} | ${text}`} eventAction="link|click">
      <a
        data-testid="faq-topics__topics__item_category"
        href={href}
        className="button-text"
      >
        {text}
      </a>
    </Track>
  );
};
