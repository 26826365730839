'use client';
import React from 'react';

import { TranslationKey } from '@vcc-package/leads-utils/api';
import { getInputErrorMessages } from '@vcc-package/leads-utils/hooks';
import { NonApiFieldName } from '@vcc-package/leads-utils/types';
import { useFormContext } from 'react-hook-form';

import { SectionHeader } from '../../components';
import { ParagraphRenderer, RadioField } from '../../flexible-forms/components';
import {
  useFlexFormContext,
  useFlexFormKvkkSectionContext,
} from '../providers/FlexibleFormsProvider';

export const KvkkSection = (): JSX.Element | null => {
  const rootLegacyProps = useFlexFormContext();
  const kvkkSectionLegacyProps = useFlexFormKvkkSectionContext();
  if (!kvkkSectionLegacyProps || !rootLegacyProps) {
    throw new Error('No rootLegacyProps / kvkkSectionLegacyProps provided');
  }

  const { translate } = rootLegacyProps;
  const { options, paragraph, title } = kvkkSectionLegacyProps;

  if (!translate) {
    throw new Error('No translate provided');
  }

  const { control } = useFormContext();
  const messages = getInputErrorMessages(translate);
  return (
    <div key="consent" className="flex flex-col gap-16">
      <SectionHeader title={translate(title)} className="mb-0" />
      <small className="micro">
        <ParagraphRenderer paragraphs={[translate(paragraph)]} />
      </small>
      {options && (
        <div className="micro">
          <RadioField
            key="kvkk_optin"
            name={NonApiFieldName.KvkkOptin}
            label={translate(TranslationKey.KVKKFIELD_LABEL)}
            control={control}
            options={options}
            messages={messages}
            showErrorText
            required
          />
        </div>
      )}
    </div>
  );
};
