'use client';

import * as React from 'react';
import { useState } from 'react';

import { TranslationKey } from '@vcc-package/leads-utils/api';

import { useLeadsContext } from '../context/leadsContext';

import { Message } from '.';

interface UseMyLocationButtonProps {
  onGeolocationRetrievalSuccess: (position: GeolocationCoordinates) => void;
  onGeolocationRetrievalError?: (error: GeolocationPositionError) => void;
}

const UseMyLocationButton = ({
  onGeolocationRetrievalSuccess,
  onGeolocationRetrievalError,
}: UseMyLocationButtonProps): JSX.Element => {
  const { translate } = useLeadsContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isGeoLocationDisabled, setIsGeoLocationDisabled] = useState(false);

  const onClick = () => {
    if (!('geolocation' in navigator)) {
      setIsGeoLocationDisabled(true);
      return;
    }
    setIsGeoLocationDisabled(false);
    setIsLoading(true);

    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => {
        onGeolocationRetrievalSuccess(position.coords);
        setIsLoading(false);
      },
      (error: GeolocationPositionError) => {
        setIsGeoLocationDisabled(true);
        onGeolocationRetrievalError?.(error);
        setIsLoading(false);
      },
    );
  };

  return (
    <>
      <button
        type="button"
        className="button-text text-accent-blue w-fit"
        data-arrow="none"
        data-color="blue"
        aria-disabled={isLoading}
        onClick={onClick}
      >
        {translate(TranslationKey.REDES_RETAILER_MAP_USE_MY_LOCATION)}
      </button>
      {isGeoLocationDisabled && (
        <Message
          intent="Caution"
          description={translate(TranslationKey.PLEASE_ENABLE_GEO_LOCATION)}
        />
      )}
    </>
  );
};

export default UseMyLocationButton;
