import React from 'react';
import { FinanceDetailsViewFinancialOptionDescriptions } from './finance-details.view.financial-option-descriptions';
import { FinanceDetailsViewHowItWorks } from './finance-details.view.how-it-works';
import { FinanceDetailsViewServiceDescriptions } from './finance-details.view.service-descriptions';
import { FinanceDetailsViewDisclaimers } from './finance-details.view.disclaimers';
import { FinanceDetailsProps } from '../finance-details.types';

export const FinanceDetailsView = ({
  salesModelImageUrl,
  financialOptionDescriptions,
  howItWorks,
  serviceDescriptions,
  disclaimers,
  loading,
  styles,
  testId,
}: FinanceDetailsProps) => {
  return (
    <div
      className={styles ?? 'container-xl flex-col gap-32 md:py-32 md:px-24'}
      data-bleed="until-md"
      data-testid={testId}
    >
      {loading && (
        <div className="flex items-center justify-center">
          <progress
            aria-label="Loading"
            className="spinner w-32 text-secondary"
          />
        </div>
      )}
      {!loading && (
        <>
          <img src={salesModelImageUrl} alt="" />
          <div className="container-xl flex-col gap-32">
            <FinanceDetailsViewFinancialOptionDescriptions
              testId={testId}
              {...financialOptionDescriptions}
            />
            <FinanceDetailsViewHowItWorks {...howItWorks} />
            {serviceDescriptions?.map((services) => (
              <FinanceDetailsViewServiceDescriptions
                key={`finance-details-service-descriptions-${services?.title}`}
                {...services}
              />
            ))}
            <FinanceDetailsViewDisclaimers {...disclaimers} />
          </div>
        </>
      )}
    </div>
  );
};
