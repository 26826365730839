'use client';
import React, { type ReactNode } from 'react';
import { useRetailAssistedSales } from 'src/hooks/useRetailAssistedSales';

export const RetailAssistanceProvider = ({
  children,
}: {
  children?: ReactNode;
}) => {
  useRetailAssistedSales();

  return <>{children}</>;
};
