/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DealerModel } from '../models/DealerModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DealersService {
  /**
   * @returns DealerModel OK
   * @throws ApiError
   */
  public static getAllDealers({
    siteSlug,
    clientId,
  }: {
    siteSlug: string;
    clientId?: string;
  }): CancelablePromise<Array<DealerModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/forms-backend/v1/{siteSlug}/dealers/all',
      path: {
        siteSlug: siteSlug,
      },
      query: {
        clientId: clientId,
      },
    });
  }

  /**
   * @returns DealerModel OK
   * @throws ApiError
   */
  public static getDealerByParmaPartnerCode({
    parmaPartnerCode,
    siteSlug,
    clientId,
  }: {
    parmaPartnerCode: string;
    siteSlug: string;
    clientId?: string;
  }): CancelablePromise<DealerModel> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/forms-backend/v1/{siteSlug}/dealers/{parmaPartnerCode}',
      path: {
        parmaPartnerCode: parmaPartnerCode,
        siteSlug: siteSlug,
      },
      query: {
        clientId: clientId,
      },
    });
  }

  /**
   * @returns DealerModel OK
   * @throws ApiError
   */
  public static getDealersByAddress({
    address,
    siteSlug,
    limit,
    offset,
    clientId,
  }: {
    address: string | null;
    siteSlug: string;
    limit?: number;
    offset?: number;
    clientId?: string;
  }): CancelablePromise<Array<DealerModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/forms-backend/v1/{siteSlug}/dealers/by-address',
      path: {
        siteSlug: siteSlug,
      },
      query: {
        address: address,
        limit: limit,
        offset: offset,
        clientId: clientId,
      },
    });
  }

  /**
   * @returns DealerModel OK
   * @throws ApiError
   */
  public static getDealersByZipCode({
    zipCode,
    siteSlug,
    clientId,
  }: {
    zipCode: string | null;
    siteSlug: string;
    clientId?: string;
  }): CancelablePromise<Array<DealerModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/forms-backend/v1/{siteSlug}/dealers/by-zip-code',
      path: {
        siteSlug: siteSlug,
      },
      query: {
        zipCode: zipCode,
        clientId: clientId,
      },
    });
  }

  /**
   * @returns DealerModel OK
   * @throws ApiError
   */
  public static getDealersByCoordinates({
    latitude,
    longitude,
    siteSlug,
    limit,
    offset,
    clientId,
  }: {
    latitude: number;
    longitude: number;
    siteSlug: string;
    limit?: number;
    offset?: number;
    clientId?: string;
  }): CancelablePromise<Array<DealerModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/forms-backend/v1/{siteSlug}/dealers/by-coordinates',
      path: {
        siteSlug: siteSlug,
      },
      query: {
        latitude: latitude,
        longitude: longitude,
        limit: limit,
        offset: offset,
        clientId: clientId,
      },
    });
  }
}
