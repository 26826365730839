import React from 'react';
import styles from './PromotionDisplay.module.css';
import { cssJoin } from '@volvo-cars/css/utils';
import { ResponsiveImage } from '@vcc-package/media';
import { Video_EXPERIMENTAL } from '@vcc-package/media/EXPERIMENTAL';
import {
  ResponsiveImagesContent,
  ResponsiveVideosContent,
} from '@vcc-package/media/content-management';
import type promotionContent from '../../content-management/content-types/PromotionContent.props';
import {
  Track,
  TrackingProvider,
  type GenericValue,
  type TrackProps,
} from '@volvo-cars/tracking';
import { Icon } from '@volvo-cars/react-icons';

interface Props extends promotionContent {
  className?: string;
  children?: React.ReactNode;
  trackingOptions?: {
    eventCategory?: GenericValue;
    cta: TrackProps; // Extend cta to match TrackProps type
    secondaryCta?: TrackProps; // Extend secondaryCta to match TrackProps type
  };
}

export const PromotionDisplay = ({
  label,
  title,
  body,
  cta,
  secondaryCta,
  media,
  theme = 'dark',
  flipped = false,
  className,
  children,
  trackingOptions,
}: Props) => {
  const eventCategory = trackingOptions?.eventCategory || 'Promotion Display';

  const ctaTrackingProps: TrackProps = {
    eventLabel: 'Promotion Display CTA',
    eventAction: 'button|click',
    ...trackingOptions?.cta,
  };

  const secondaryCtaTrackingProps: TrackProps = {
    eventLabel: 'Promotion Display Secondary CTA',
    eventAction: 'button|click',
    ...trackingOptions?.secondaryCta,
  };

  const isLightTheme = theme === 'light';
  const hasSecondaryCta = secondaryCta?.href && secondaryCta?.text;

  return (
    <TrackingProvider eventCategory={eventCategory}>
      <div
        className={cssJoin(
          'relative aspect-4/5 md:aspect-16/9 lg:aspect-21/9',
          className,
        )}
        data-color-mode={theme}
      >
        {/*
          Looks for keys present either in responsiveImages or responsiveVideos
          content types. Should work properly. Perhaps Media package should
          eventually provide a component that received a "media" of type
          `responsiveImages | responsiveVideos`?
        */}
        {'sm' in media && (
          <ResponsiveImage
            className="w-full aspect-4/5 md:aspect-16/9 lg:aspect-21/9"
            images={media as ResponsiveImagesContent}
            alt={(media as ResponsiveImagesContent).sm.alt}
          />
        )}
        {'smVideos' in media && !('sm' in media) && (
          <Video_EXPERIMENTAL
            className="w-full aspect-4/5 md:aspect-16/9 lg:aspect-21/9"
            videos={media as ResponsiveVideosContent}
            customControls={(togglePlaying, isPlaying) => {
              return (
                <button
                  className={cssJoin(
                    'absolute end-24 z-navigation',
                    styles['video-toggle-cta'],
                  )}
                  onClick={togglePlaying}
                  type="button"
                >
                  <Icon icon={isPlaying ? 'pause' : 'play'} size={24} />
                </button>
              );
            }}
          />
        )}
        <div
          className={cssJoin(
            'absolute top-0 start-0 h-full w-full',
            flipped && 'md:flex-row md:justify-end',
            styles['pointer-none'],
          )}
        >
          <div
            className={cssJoin(
              'h-full w-full flex-col justify-between md:justify-center md:max-w-1/2 lg:px-48',
              flipped ? 'md:pr-32' : 'md:pl-32',
              styles['pointer-auto'],
              isLightTheme
                ? styles['md:gradient-light']
                : styles['md:gradient-dark'],
              flipped
                ? styles['md:gradient-right']
                : styles['md:gradient-left'],
            )}
          >
            <div
              className={cssJoin(
                'lg:px-48',
                isLightTheme
                  ? styles['gradient-light']
                  : styles['gradient-dark'],
              )}
            >
              <div className="stack-text px-24 py-32 md:px-0 md:py-0">
                {label && <p className="micro">{label}</p>}
                <h2 className="heading-2 mt-16">{title}</h2>
                {body && <p>{body}</p>}
                {children}
              </div>
            </div>
            <div className="w-full md:w-fit flex-col gap-8 md:flex-row md:gap-16 pb-24 md:pb-0 px-24 md:px-0 lg:px-48 md:mt-24">
              <Track {...ctaTrackingProps}>
                <a className="button-filled" href={cta.href}>
                  {cta.text}
                </a>
              </Track>
              {hasSecondaryCta && (
                <Track {...secondaryCtaTrackingProps}>
                  <a className="button-outlined" href={secondaryCta.href}>
                    {secondaryCta.text}
                  </a>
                </Track>
              )}
            </div>
          </div>
        </div>
      </div>
    </TrackingProvider>
  );
};
