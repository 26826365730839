import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { PART_EXCHANGE_QUESTIONS } from '../queries/partExchangeQuestionsQuery';
import { PartExchangeQuestionsQuery } from '../queries/queries.types';

export const getPartExchangeQuestions = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
) => {
  try {
    const { data, loading, error } =
      await apolloClient.query<PartExchangeQuestionsQuery>({
        query: PART_EXCHANGE_QUESTIONS,
        fetchPolicy: 'no-cache',
      });

    return { data, loading, error };
  } catch (err) {
    console.error(err);
    return {
      data: {} as PartExchangeQuestionsQuery,
      loading: false,
      error: err,
    };
  }
};
