import Cookies from 'js-cookie';

/**
 * Groups are now prefixed with a `C` in the cookie
 */
export const ConsentGroups = {
  STRICTLY_NECESSARY: 'C1',
  PERFORMANCE: 'C2',
  FUNCTIONAL: 'C3',
  TARGETING_AND_ADVERTISING: 'C4',
  ADVANCED_ANALYTICS: 'C8',
  STATISTICS: 'C9',
} as const;

export type ConsentGroupName = keyof typeof ConsentGroups;

export type ConsentGroup = (typeof ConsentGroups)[ConsentGroupName];

/**
 * @param consentCookie optional cookie, eg. from request headers
 */
export function getOneTrustConsentGroups(consentCookie?: string) {
  const optanonConsentCookie =
    consentCookie || Cookies.get('OptanonConsent') || '';
  const optanonConsentGroups = new URLSearchParams(optanonConsentCookie).get(
    'groups',
  );

  if (!optanonConsentGroups) return {};

  return Object.fromEntries(
    optanonConsentGroups
      .split(',')
      .filter((pair) => pair.includes(':'))
      .map((pair) => {
        const [groupId, value] = pair.split(':');
        const groupIdWithC = groupId.startsWith('C') ? groupId : `C${groupId}`;
        return [groupIdWithC, value === '1'];
      }),
  );
}

/**
 * @param consentCookie optional cookie, eg. from request headers
 */
export function hasConsentForGroup(
  group: ConsentGroup,
  consentCookie?: string,
) {
  const groups = getOneTrustConsentGroups(consentCookie);
  return groups[group] || false;
}

/**
 * @param consentCookie optional cookie, eg. from request headers
 */
export function hasConsentForFunctionalCookies(consentCookie?: string) {
  const groups = getOneTrustConsentGroups(consentCookie);
  return groups[ConsentGroups.FUNCTIONAL] || false;
}
