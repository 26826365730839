'use client';
import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

interface HiddenInputProps {
  name: string;
  value: string;
}

export const HiddenInput = ({
  name,
  value,
}: HiddenInputProps): JSX.Element | null => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={value}
      render={() => <input type="hidden" />}
    />
  );
};
