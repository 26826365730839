import React from 'react';

const VideoLazyContainerContext = React.createContext(false);

export const VideoPosterReadyProvider: React.FC<
  React.PropsWithChildren<{
    value: boolean;
  }>
> = ({ value, children }) => {
  return (
    <VideoLazyContainerContext.Provider value={value}>
      {children}
    </VideoLazyContainerContext.Provider>
  );
};

export const usePosterReady = () => React.useContext(VideoLazyContainerContext);
