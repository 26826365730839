import React from 'react';
import DiscoveryCardPartContent from './discovery-card.part.content';
import DiscoveryCard from '.';
import {
  CardImageType,
  DiscoveryCardContentType,
  DiscoveryCardTemplateProps,
} from '../utils/types';

type Props = DiscoveryCardTemplateProps<
  CardImageType & {
    layout?: 'reversed' | 'default';
  }
>;

export const DiscoveryCardHorizontal = ({
  responsiveImages,
  layout,
  children,
  ...rest
}: Props) => {
  return (
    <DiscoveryCard
      variant="horizontal"
      layout={layout}
      responsiveImages={responsiveImages}
      className="md:gap-24"
    >
      {children ? (
        <div slot="main">{children}</div>
      ) : (
        <DiscoveryCardPartContent {...(rest as DiscoveryCardContentType)} />
      )}
    </DiscoveryCard>
  );
};
