import { useCallback } from 'react';

import type { FieldValidation } from '../src/flexible-forms/components/types';
import { useLeadsContext } from '../src/context';

export type ValidationCallback = (value: string) => Promise<boolean> | boolean;

export const useValidation = (validation?: FieldValidation) => {
  const { API } = useLeadsContext();

  if (!API) {
    throw new Error('Leads Context needs to be declared');
  }

  const validateEmail: ValidationCallback = useCallback(
    async (value: string) => await API.validateEmail(value),
    [API],
  );

  const validatePhone: ValidationCallback = useCallback(
    async (value: string) => await API.validatePhone(value),
    [API],
  );

  const regexValidate: ValidationCallback = useCallback(
    (value: string) => {
      return isRegexValidationEnabled(validation)
        ? new RegExp(validation.args[0]).test(value)
        : true;
    },
    [validation],
  );

  return { validateEmail, validatePhone, regexValidate };
};

const isRegexValidationEnabled = (
  val?: FieldValidation,
): val is FieldValidation & { args: string[] } =>
  !!val && val.type === 'regex' && !!val.args?.length;
