export const isNotEmpty = <T>(value: T | null | undefined): value is T => {
  return value !== null && value !== undefined;
};

/**
 * Convert T | T[] | undefined by forcing first non-falsy element of array, if array.
 */
export const firstTruthyElementIfArray = <T>(val: T | T[] | undefined) => {
  return Array.isArray(val) ? val.find((v) => !!v) : val;
};
