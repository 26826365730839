import React from 'react';
import { ExtendCSS, Flex, Text } from 'vcc-ui';
import { maxContentWidth10Columns } from '@vcc-www/constants/layout';
import {
  ColumnComponentProps,
  TableComponentType,
  BaseRowProps,
} from './ComparisonTable.types';
import { getData } from './utils';

export const TableDesktop: TableComponentType = ({ config, data, testId }) => (
  <Container testId={testId}>
    <ItemsContainer>
      {data.map(({ rowTitle }) => (
        <RowTitle testId={testId} key={`comparison-table-${rowTitle}`}>
          {rowTitle}
        </RowTitle>
      ))}
    </ItemsContainer>
    {config.map((column, ind) => (
      <Column
        testId={testId}
        data={data}
        {...column}
        key={`comparison-table-column-${ind}`}
      />
    ))}
  </Container>
);

const Container: React.FC<React.PropsWithChildren<{ testId?: string }>> = ({
  testId,
  ...props
}) => (
  <Flex
    data-testid={`${testId}__table__desktop`}
    extend={({ theme: { baselineGrid } }) => ({
      flexDirection: 'row',
      margin: `0 auto ${5 * baselineGrid}px`,
      maxWidth: maxContentWidth10Columns,
      width: '100%',
      display: 'none',

      fromL: {
        display: 'flex',
      },
    })}
    {...props}
  />
);

const ItemsContainer: React.FC<React.PropsWithChildren<unknown>> = (props) => (
  <Flex extend={{ margin: 'auto 0 0' }} {...props} />
);

const RowTitle: React.FC<React.PropsWithChildren<{ testId?: string }>> = ({
  testId,
  ...props
}) => (
  <Flex
    data-testid={`${testId}__row`}
    extend={[
      ({ theme: { baselineGrid } }) => ({ width: 34 * baselineGrid }),
      cellCSS,
    ]}
  >
    <Text
      extend={{
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      }}
      data-testid={`${testId}__row__title`}
      {...props}
    />
  </Flex>
);

export const Column = <TEntity extends BaseRowProps>({
  data,
  getValue,
  headerComponent,
  testId,
}: React.PropsWithChildren<ColumnComponentProps<TEntity>> & {
  testId?: string;
}) => (
  <ColumnContainer testId={testId}>
    <ColumnHeaderContainer testId={testId}>
      {headerComponent}
    </ColumnHeaderContainer>
    {data.map((item, ind) => (
      <ColumnItem testId={testId} data={getData(getValue, item)} key={ind} />
    ))}
  </ColumnContainer>
);

const ColumnContainer: React.FC<
  React.PropsWithChildren<{ testId?: string }>
> = ({ testId, ...props }) => (
  <Flex
    data-testid={`${testId}__column`}
    extend={({ theme: { baselineGrid } }) => ({
      width: 31 * baselineGrid,
    })}
    {...props}
  />
);

const ColumnHeaderContainer: React.FC<
  React.PropsWithChildren<{ testId?: string }>
> = ({ testId, ...props }) => (
  <Flex
    data-testid={`${testId}__column__header`}
    extend={({ theme: { baselineGrid } }) => ({
      paddingLeft: 3 * baselineGrid,
      flex: 1,
    })}
  >
    <Flex
      extend={({ theme: { color, baselineGrid } }) => ({
        background: color.background.secondary,
        alignItems: 'center',
        padding: 3 * baselineGrid,
        height: '100%',
      })}
      {...props}
    />
  </Flex>
);

const ColumnItem: React.FC<
  React.PropsWithChildren<{
    data: React.ReactNode | null;
    testId?: string;
  }>
> = ({ data, testId }) => (
  <Flex
    data-testid={`${testId}__column__item`}
    extend={[
      ({ theme: { baselineGrid } }) => ({
        paddingLeft: 3 * baselineGrid,
      }),
      cellCSS,
    ]}
  >
    <Flex
      extend={({ theme: { color } }) => ({
        background: color.background.secondary,
        color: color.foreground.primary,
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      })}
    >
      <Text
        extend={({ theme: { baselineGrid } }) => ({
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          width: '100%',
          textAlign: 'center',
          padding: `0 ${2 * baselineGrid}px`,
        })}
      >
        {data}
      </Text>
    </Flex>
  </Flex>
);

const cellCSS: ExtendCSS = ({ theme: { color, baselineGrid } }) => ({
  height: 6 * baselineGrid,
  borderBottom: `1px solid ${color.ornament.divider}`,
  justifyContent: 'center',

  ':last-child': { borderBottom: 'none' },
});
