import { type LeadsContextProperties } from '../../src/context';

import * as experiments from './experiments';

export const abTestingContextMapper = (
  context: LeadsContextProperties,
): LeadsContextProperties => {
  if (context.navigation.query.disableexperiments === 'true') {
    console.debug('Experiments disabled');
    return context;
  }

  Object.values(experiments).forEach((experiment) => {
    context = experiment?.(context);
  });

  return context;
};
