// TODO: Fix eslint issues the next time this file is edited.
/* eslint-disable react/button-has-type */
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Block, Flex, Icon, useTheme } from 'vcc-ui';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import {
  maxContentWidth10Columns,
  maxContentWidth12Columns,
} from '@vcc-www/constants/layout';
import { ImageField } from '@vcc-www/content-management-jss-client';
import { Disclaimer } from '@vcc-package/text';
import { Image_DEPRECATED } from '@vcc-package/media/DEPRECATED';
import {
  OfferComparisonProps,
  CarProps,
  SelectCarProps,
  ScrollDirection,
  CarouselScrollDisabled,
} from './OfferComparison.types';
import { Table } from './Table';
import { EngineType } from '@vcc-www/utils/engineTypes';
import { useBreakpoints } from '@vcc-www/hooks';
import { useCasImageWithBackground } from '@vcc-www/hooks/useCasImageWithBackground';
import tokens from '@volvo-cars/css/tokens';

const ITEM_WIDTH_IN_BASELINE_GRID = 11;

const OfferComparison_: React.FC<
  React.PropsWithChildren<OfferComparisonProps>
> = ({
  title,
  cars,
  detailsText,
  amountToShowCollapsedOnMobile = 4,
  makeCta,
  makeDisclaimer,
  electricLabel,
  pluginHybridLabel,
  mildHybridLabel,
  showMoreText,
  showLessText,
  financeOptions,
  overlayTitle,
  overlayOffersTitle,
  withPadding,
  onCarClick,
  onCtaClick,
  onOverlayClick,
  onExpandClick,
  onArrowClick,
  overlayCloseEventLabel,
  hidePrice,
}) => {
  const [selectedCar, setSelectedCar] = useState(cars[0]);
  const carouselRef = useRef<HTMLDivElement>(null);
  const [carouselScrollDisabled, setCarouselScrollDisabled] =
    useState<CarouselScrollDisabled>({
      [ScrollDirection.Left]: true,
      [ScrollDirection.Right]: false,
    });
  const { baselineGrid } = useTheme();

  const cta = makeCta?.(selectedCar);
  const disclaimer = makeDisclaimer?.(selectedCar);

  const supportsNativeSmoothScroll =
    typeof document !== 'undefined'
      ? 'scrollBehavior' in document.documentElement.style
      : false;

  const onNextPrevClick = (direction: ScrollDirection) => {
    const carousel = carouselRef.current;

    if (carousel) {
      const amountToScroll = ITEM_WIDTH_IN_BASELINE_GRID * baselineGrid;

      carousel.scrollBy({
        left:
          direction === ScrollDirection.Left ? -amountToScroll : amountToScroll,
        behavior: supportsNativeSmoothScroll ? 'smooth' : 'auto',
      });
    }
  };

  useEffect(() => {
    setSelectedCar(cars[0]);
  }, [cars]);

  useEffect(() => {
    const carousel = carouselRef.current;

    if (carousel) {
      const handleScroll = debounce(() => {
        setCarouselScrollDisabled({
          [ScrollDirection.Left]: carousel.scrollLeft === 0,
          [ScrollDirection.Right]:
            carousel.scrollLeft === carousel.scrollWidth - carousel.clientWidth,
        });
      }, 100);

      handleScroll();

      carousel.addEventListener('scroll', handleScroll);

      return () => {
        carousel.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    const onLoad = () => {
      if (carouselRef.current) {
        carouselRef.current.scrollTo({
          left: 0,
          behavior: supportsNativeSmoothScroll ? 'smooth' : 'auto',
        });
      }
    };
    window.addEventListener('load', onLoad);
    return () => {
      window.removeEventListener('load', onLoad);
    };
  }, [supportsNativeSmoothScroll]);

  return (
    <>
      <Wrapper>
        <Container withPadding={withPadding}>
          <Title>{title}</Title>
          {cars.length > 1 && (
            <CarouselContainer>
              <PrevNextButton
                direction={ScrollDirection.Left}
                onClick={() => {
                  onNextPrevClick(ScrollDirection.Left);
                  onArrowClick?.(ScrollDirection.Left);
                }}
                disabled={carouselScrollDisabled[ScrollDirection.Left]}
              />
              <CarTileContainer ref={carouselRef}>
                {cars?.map((car, i) => (
                  <CarTileWrapper
                    key={i}
                    withRightBorder={[EngineType.BEV, EngineType.PHEV].some(
                      (engineType) =>
                        car.engineType === engineType &&
                        cars[i + 1] &&
                        cars[i + 1]?.engineType !== engineType,
                    )}
                  >
                    {electricLabel &&
                      i === 0 &&
                      car.engineType === EngineType.BEV && (
                        <EngineTypeLabel>{electricLabel}</EngineTypeLabel>
                      )}
                    {pluginHybridLabel &&
                      car.engineType === EngineType.PHEV &&
                      (!i || cars[i - 1].engineType === EngineType.BEV) && (
                        <EngineTypeLabel>{pluginHybridLabel}</EngineTypeLabel>
                      )}
                    {mildHybridLabel &&
                      [EngineType.NHEV, EngineType.HEV].includes(
                        car.engineType as EngineType,
                      ) &&
                      (!i ||
                        [EngineType.PHEV, EngineType.BEV].includes(
                          cars[i - 1].engineType as EngineType,
                        )) && (
                        <EngineTypeLabel>{mildHybridLabel}</EngineTypeLabel>
                      )}

                    <CarTile
                      {...car}
                      key={i}
                      isSelected={selectedCar.modelId === car.modelId}
                      onClick={() => {
                        setSelectedCar(car);
                        onCarClick?.(car);
                      }}
                    />
                  </CarTileWrapper>
                ))}
              </CarTileContainer>
              <PrevNextButton
                direction={ScrollDirection.Right}
                onClick={() => {
                  onNextPrevClick(ScrollDirection.Right);
                  onArrowClick?.(ScrollDirection.Right);
                }}
                disabled={carouselScrollDisabled[ScrollDirection.Right]}
              />
            </CarouselContainer>
          )}
          <Table
            selectedCar={selectedCar}
            detailsText={detailsText}
            amountToShowCollapsedOnMobile={amountToShowCollapsedOnMobile}
            showMoreText={showMoreText}
            showLessText={showLessText}
            financeOptions={financeOptions}
            overlayTitle={overlayTitle}
            overlayOffersTitle={overlayOffersTitle}
            onOverlayClick={onOverlayClick}
            onExpandClick={onExpandClick}
            overlayCloseEventLabel={overlayCloseEventLabel}
            hidePrice={hidePrice}
          />
          {cta?.text && cta?.href && (
            <div className="my-0 mx-auto">
              <a
                data-testid="offer-comparison__cta"
                className="button-outlined"
                data-color="accent"
                href={cta.href}
                onClick={() => {
                  onCtaClick?.(selectedCar);
                }}
              >
                {cta.text}
              </a>
            </div>
          )}
        </Container>
      </Wrapper>
      {!!disclaimer && (
        <Flex data-testid="offer-comparison__disclaimer">
          <Disclaimer
            extend={({ theme: { baselineGrid } }) => ({
              margin: `${4 * baselineGrid}px auto 0`,
              whiteSpace: 'pre-wrap',
              maxWidth: maxContentWidth12Columns,
              width: `calc(100% - ${6 * baselineGrid}px)`,

              '& a': {
                textDecoration: 'underline',
              },
            })}
            text={disclaimer}
          />
        </Flex>
      )}
    </>
  );
};

const Wrapper: React.FC<React.PropsWithChildren<unknown>> = (props) => (
  <Flex
    data-testid="offer-comparison"
    extend={{
      maxWidth: maxContentWidth12Columns,
      margin: '0 auto',
      width: '100%',
    }}
    {...props}
  />
);

const Container: React.FC<
  React.PropsWithChildren<Pick<OfferComparisonProps, 'withPadding'>>
> = ({ withPadding, ...props }) => (
  <Flex
    extend={({ theme: { color, baselineGrid } }) => ({
      background: color.background.primary,
      width: '100%',
      alignItems: 'center',

      fromL: {
        padding: `0 ${3 * baselineGrid}px`,
      },

      extend: [
        {
          condition: withPadding,
          style: {
            paddingTop: 8 * baselineGrid,
            paddingBottom: 8 * baselineGrid,

            fromM: {
              paddingTop: 10 * baselineGrid,
              paddingBottom: 10 * baselineGrid,
            },

            fromL: {
              paddingTop: 12 * baselineGrid,
              paddingBottom: 12 * baselineGrid,
            },
          },
        },
      ],
    })}
    {...props}
  />
);

const Title: React.FC<React.PropsWithChildren<unknown>> = (props) => (
  <h2
    data-testid="offer-comparison__title"
    className="heading-2 mx-pagemargin"
    style={{ marginBottom: `calc(${tokens.space8} * 5)` }}
  >
    {props.children}
  </h2>
);

const CarouselContainer: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Flex
    extend={({ theme: { baselineGrid } }) => ({
      flexDirection: 'row',
      margin: `0 auto ${3 * baselineGrid}px`,
      padding: `0 ${3 * baselineGrid}px ${2 * baselineGrid}px`,
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',

      fromL: { padding: `0 0 ${2 * baselineGrid}px` },
    })}
    {...props}
  />
);

const CarTileContainer = forwardRef<
  HTMLDivElement,
  { isDragging?: boolean; children: React.ReactNode }
>(({ isDragging, ...props }, ref?) => (
  <Flex
    as="nav"
    ref={ref}
    extend={{
      flexDirection: 'row',
      maxWidth: maxContentWidth10Columns,
      width: '100%',
      overflowX: 'auto',
      overflowY: 'hidden',
      scrollSnapType: 'x mandatory',
      scrollbarWidth: 'none',

      '::-webkit-scrollbar': {
        width: 0,
        background: 'transparent',
      },

      fromL: { width: '63%' },

      extend: [
        {
          condition: isDragging,
          style: { cursor: 'grab', userSelect: 'none' },
        },
      ],
    }}
    {...props}
  />
));
CarTileContainer.displayName = 'CarTileContainer';

const EngineTypeLabel: React.FC<React.PropsWithChildren<unknown>> = (props) => {
  const style: React.CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 2,
    whiteSpace: 'nowrap',
  };

  return <small className="micro" {...props} style={style} />;
};

const CarTileWrapper: React.FC<
  React.PropsWithChildren<{ withRightBorder?: boolean }>
> = ({ withRightBorder, ...props }) => (
  <Flex
    data-testid="offer-comparison__car"
    extend={({ theme: { baselineGrid, color } }) => ({
      position: 'relative',
      margin: `0 ${baselineGrid}px 0 0`,
      scrollSnapAlign: 'start',

      ':last-child': { margin: '0 0' },

      ...(withRightBorder && {
        padding: `0 ${baselineGrid}px 0 0`,
        borderRight: `1px solid ${color.ornament.divider}`,
      }),
    })}
    {...props}
  />
);

const CarTile: React.FC<React.PropsWithChildren<CarProps & SelectCarProps>> = ({
  image,
  modelName,
  isSelected,
  onClick,
}) => (
  <CarContainer onClick={onClick}>
    {image?.src && <CarImage isSelected={isSelected} {...image} />}
    <CarTitle>{modelName}</CarTitle>
  </CarContainer>
);

const CarContainer: React.FC<React.PropsWithChildren<SelectCarProps>> = ({
  onClick,
  ...props
}) => (
  <button className="mt-24" onClick={onClick} type="button">
    <Flex
      extend={({ theme: { baselineGrid } }) => ({
        width: ITEM_WIDTH_IN_BASELINE_GRID * baselineGrid,
        alignItems: 'center',
      })}
      {...props}
    />
  </button>
);

const CarImage: React.FC<
  React.PropsWithChildren<
    ImageField & {
      isSelected?: boolean;
    }
  >
> = ({ src, alt, isSelected }) => {
  const { baselineGrid } = useTheme();

  const imageSize = ITEM_WIDTH_IN_BASELINE_GRID * baselineGrid;

  const scale = 1.1;

  const imageUrl = useCasImageWithBackground(src, 'secondary');
  imageUrl?.searchParams.set('w', `${imageSize * scale}`);

  return (
    <Flex
      extend={({ theme: { color, baselineGrid } }) => ({
        width: imageSize,
        height: imageSize,
        justifyContent: 'center',
        border: isSelected
          ? `2px solid ${color.ornament.highlight}`
          : '1px solid transparent',
        borderRadius: '4px',
        marginBottom: baselineGrid,
        transition: isSelected ? 'none' : 'border-color 0.3s ease-out',
        background: color.background.secondary,
        overflow: 'hidden',

        ':hover': {
          border: isSelected
            ? `2px solid ${color.ornament.highlight}`
            : `1px solid ${color.foreground.primary}`,
        },
      })}
    >
      <Block
        extend={{
          border: isSelected ? 'none' : '1px solid transparent',
        }}
      >
        <Image_DEPRECATED
          extend={{ transform: `scale(${scale})` }}
          data-testid="offer-comparison__car__image"
          src={imageUrl?.href || ''}
          alt={alt || ''}
          loading="native-lazy"
        />
      </Block>
    </Flex>
  );
};

const CarTitle: React.FC<React.PropsWithChildren<unknown>> = (props) => {
  const style: React.CSSProperties = {
    width: tokens.space48,
    lineHeight: '1.3',
  };

  return (
    <small
      data-testid="offer-comparison__car__title"
      className="micro text-center"
      style={style}
      {...props}
    />
  );
};

const areEqual = (
  prevProps: OfferComparisonProps,
  nextProps: OfferComparisonProps,
) => {
  const extractRelevantProps = (props: OfferComparisonProps) => ({
    cars: props.cars,
    showMoreText: props.showMoreText,
    showLessText: props.showLessText,
    title: props.title,
    makeCta: props.makeCta,
    makeDisclaimer: props.makeDisclaimer,
    electricLabel: props.electricLabel,
    pluginHybridLabel: props.pluginHybridLabel,
    mildHybridLabel: props.mildHybridLabel,
    detailsText: props.detailsText,
    overlayTitle: props.overlayTitle,
    overlayOffersTitle: props.overlayOffersTitle,
  });

  return isEqual(
    extractRelevantProps(prevProps),
    extractRelevantProps(nextProps),
  );
};

const PrevNextButton: React.FC<
  React.PropsWithChildren<{
    direction: ScrollDirection;
    onClick: () => void;
    disabled?: boolean;
  }>
> = ({ direction, onClick, disabled }) => {
  // eslint-disable-next-line vcc-www/use-breakpoints
  const { untilL } = useBreakpoints();
  const margin = direction === ScrollDirection.Left ? 'mr-8' : 'ml-8';
  const defaultStyle: React.CSSProperties = {
    display: untilL ? 'none' : 'block',
  };
  const disabledStyle: React.CSSProperties = {
    cursor: 'auto',
    opacity: 0.4,
  };

  const style = {
    ...defaultStyle,
    ...(disabled && disabledStyle),
  };

  return (
    <button
      className={`py-32 px-8 ${margin}`}
      data-testid={`offer-comparison__arrow__${direction}`}
      disabled={disabled}
      onClick={onClick}
      style={style}
      type="button"
    >
      <Icon
        type={
          direction === ScrollDirection.Left
            ? 'mediacircled-previous-40'
            : 'mediacircled-next-40'
        }
        color="primary"
      />
    </button>
  );
};

const OfferComparison = React.memo(OfferComparison_, areEqual);

export default OfferComparison;
