import { SelectableCardProps } from '@vcc-package/selectable-card/src/selectable-card.types';
import { useCallback, useMemo } from 'react';
import { YesNoQuestionHookProps } from './yes-no-question.types';

export const useYesNoQuestion = ({
  onChange,
  yesText,
  noText,
}: YesNoQuestionHookProps) => {
  const options: SelectableCardProps[] = useMemo(
    () => [
      {
        title: yesText,
      },
      {
        title: noText,
      },
    ],
    [yesText, noText],
  );

  const onChangeHandler = useCallback(
    (index: number) => {
      if (onChange) {
        onChange(!index);
      }
    },
    [onChange],
  );

  return useMemo(
    () => ({
      options,
      onChangeHandler,
    }),
    [options, onChangeHandler],
  );
};
