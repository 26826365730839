import React from 'react';

export const Spinner: React.FC = () => (
  <div
    className="flex w-full justify-center items-center"
    style={{ height: 340 }}
  >
    <progress aria-label="Loading" className="spinner" />
  </div>
);
