import React from 'react';

/**
 * Check if a child is of a certain type of component
 *
 * @param type component type
 * @returns function that can be used in `Array.prototype.filte` or `Array.prototype.find`
 */
export const isChildType =
  <T extends React.ElementType>(type: T) =>
  (
    child: React.ReactNode,
  ): child is React.ReactElement<
    React.ComponentProps<T>,
    React.JSXElementConstructor<React.ComponentProps<T>>
  > =>
    React.isValidElement(child) && child.type === type;
