import { useEffect } from 'react';

import type { LeadsContextProperties } from '../../src/context';

import * as experiments from './experiments';

export const useAbTesting = (
  context: LeadsContextProperties,
  disabled: boolean,
) => {
  useEffect(() => {
    if (disabled) return;

    Object.values(experiments).forEach((experiment) => {
      experiment?.(context);
    });
  }, [context, disabled]);
};
