import { CurrentTheme, ThemeBreakpointName } from 'vcc-ui';

export type Breakpoints = CurrentTheme['breakpoints'];

export type Responsive = 'responsive';

export type ResponsiveAspectRatio = {
  [P in ThemeBreakpointName | `@media (${string})`]?: number[] | Responsive;
} & { default: number[] | Responsive };

export type AspectRatio = Responsive | number[] | ResponsiveAspectRatio;

export function isResponsiveAspectRatio(
  aspectRatio?: AspectRatio,
): aspectRatio is ResponsiveAspectRatio {
  return (
    typeof aspectRatio === 'object' &&
    !Array.isArray(aspectRatio) &&
    aspectRatio !== null &&
    'default' in aspectRatio
  );
}

export const getAspectRatioCSS = (
  breakpoints: Breakpoints,
  aspectRatio?: AspectRatio,
) => {
  if (!aspectRatio || aspectRatio === 'responsive') {
    return {};
  }
  if (Array.isArray(aspectRatio) && aspectRatio[0] && aspectRatio[1]) {
    // Simple case of aspectRatio in number[] form
    return {
      padding: aspectRatio
        ? `0 0 ${(100 * aspectRatio[1]) / aspectRatio[0]}% 0`
        : 0,
    };
  }
  if (!Array.isArray(aspectRatio) && aspectRatio.default) {
    // Complex case of different aspectRatio for different breakpoints
    const { default: defaultAspectRatio, ...aspectRatiosFromBreakpoints } =
      aspectRatio;

    const getPadding = (ratio: number[] | Responsive) =>
      ratio === 'responsive'
        ? 'unset'
        : `0 0 ${(100 * ratio[1]) / ratio[0]}% 0`;

    const breakpointPaddings = Object.keys(aspectRatiosFromBreakpoints).reduce(
      (a, breakpointKey) => ({
        ...a,
        [breakpoints[breakpointKey as ThemeBreakpointName] || breakpointKey]: {
          padding: getPadding(
            aspectRatiosFromBreakpoints[
              breakpointKey as ThemeBreakpointName | `@media (${string})`
            ] as number[],
          ),
        },
      }),
      {},
    );

    return {
      padding: getPadding(defaultAspectRatio),
      ...breakpointPaddings,
    };
  }
  return {};
};
