'use client';
import React from 'react';

import type { Control, FieldErrors } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { ErrorMessage, Radio, RadioGroup } from '@volvo-cars/react-forms';

import type { Messages, SelectOption } from '../types';

type FieldProps = {
  name: string;
  label: string;
  defaultValue?: string;
  required?: boolean;
  control: Control;
  errors?: FieldErrors;
  options?: SelectOption[];
  messages?: Messages;
  showErrorText?: boolean;
};

export const RadioField = ({
  name,
  label,
  defaultValue = '',
  control,
  required = false,
  options,
  messages,
  showErrorText = false,
}: FieldProps) => (
  <Controller
    control={control}
    defaultValue={defaultValue}
    name={name}
    rules={{
      required: {
        value: required,
        message: messages?.required ? messages.required(label) : '',
      },
    }}
    render={({
      field: { onChange, onBlur, name, ref },
      fieldState: { error },
    }) => {
      return (
        <div ref={ref} className="flex flex-col gap-4">
          <RadioGroup
            name="group"
            legend={label + `${required && ' *'}`}
            defaultValue={defaultValue}
            className="text-secondary"
            onChange={onChange}
          >
            {options?.map(({ label = '', value }, i) => (
              <Radio
                onBlur={onBlur}
                label={label as string}
                value={value}
                className="text-primary"
                key={i}
              />
            ))}
          </RadioGroup>
          {showErrorText && error && (
            <ErrorMessage errorMessage={error.message} id={name + '-error'} />
          )}
        </div>
      );
    }}
  />
);
