import gql from 'graphql-tag';

export const PART_EXCHANGE_VALUATION_OFFER = gql`
  query GetPartExchangeValuationOffer(
    $input: PartExchangeValuationOfferInput!
    $answers: [PartExchangeQuestionAnswerInput!]!
  ) {
    getPartExchangeValuationOffer(input: $input, answers: $answers) {
      success
      messages {
        type
        message
      }
      payload {
        valuationOffer {
          valuation
          outstandingFinance
          expirationDate
          isExpired
          futureValues {
            daysFrom
            daysTo
            value
          }
        }
      }
    }
  }
`;
