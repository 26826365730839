import { FlexFormLegalFieldType } from '@vcc-package/leads-utils/api';

export const getLegalFieldName = (
  fieldType: FlexFormLegalFieldType,
): FlexFormLegalFieldType => {
  switch (fieldType) {
    case FlexFormLegalFieldType.VALIDATED_EMAIL_OPTIN:
      return FlexFormLegalFieldType.EMAIL_OPTIN;
    case FlexFormLegalFieldType.VALIDATED_PHONE_OPTIN:
      return FlexFormLegalFieldType.PHONE_OPTIN;
    case FlexFormLegalFieldType.VALIDATED_SMS_OPTIN:
      return FlexFormLegalFieldType.SMS_OPTIN;

    default:
      return fieldType;
  }
};
