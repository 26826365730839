'use client';
import React, { useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';
import { NonApiFieldName } from '@vcc-package/leads-utils/types';
import type { DealerModel } from '@vcc-package/leads-utils/api';
import { TranslationKey } from '@vcc-package/leads-utils/api';
import { getTranslationKeyForDealer } from '@vcc-package/leads-utils/util';
import { getInputErrorMessages } from '@vcc-package/leads-utils/hooks';

import type { SelectOption } from '../../flexible-forms/components/types';
import { SectionHeader } from '../../components';
import { useLeadsContext } from '../../context/leadsContext';
import { useRetailer } from '../../context/retailerContext';
import { SelectField } from '../../flexible-forms/components';
import { RetailerPicker } from '../components/inputs/retailer-picker';
import {
  useFlexFormContext,
  useFlexFormDealerPickerContext,
} from '../providers/FlexibleFormsProvider';

const name = NonApiFieldName.RetailerId;

/**
 * This section contains its own field in order to break it out of the normal form-flow.
 * The code has mostly been borrowed/stolen from 'shared\leads-utils\customer-form\inputs\dealerPicker.tsx'
 * Because of this, it contains pretty much the same functionality but slightly modified.
 */
export const DealerPickerSection = (): JSX.Element | null => {
  const dealerPickerProps = useFlexFormDealerPickerContext();
  const {
    API,
    translate,
    navigation: { query },
  } = useLeadsContext();
  const { onRetailerSelect, selectedRetailer } = useRetailer();
  const { hideRequired } = useFlexFormContext();

  if (!dealerPickerProps || !API || !translate) {
    throw new Error(
      "dealerPickerProps or rootLegacyProps hasn't been initialized",
    );
  }

  const { title, byAddress } = dealerPickerProps;
  const customTranslations = dealerPickerProps.customTranslation ?? [];
  const { clearErrors, control, setValue } = useFormContext();
  const messages = getInputErrorMessages(translate);
  const [optionsState, setOptionsState] = useState<SelectOption[]>([]);
  const [autoSelect, setAutoSelect] = useState(false);
  const [loadingDeps, setLoadingDeps] = useState(!byAddress);

  useEffect(() => {
    if (!byAddress) {
      setDealers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [dealerData, setDealerData] = useState<DealerModel[] | null>(null);

  const labels = {
    retailerSelection: getTranslationKeyForDealer(
      customTranslations,
      'retailerSelection',
    ),
    selectRetailer: getTranslationKeyForDealer(
      customTranslations,
      'selectRetailer',
    ),
  };

  const customByAddressLabels = {
    addressFieldPlaceholder: getTranslationKeyForDealer(
      customTranslations,
      'addressFieldPlaceholder',
    ),
    change: getTranslationKeyForDealer(customTranslations, 'change'),
    selectRetailerCloseToAddress: getTranslationKeyForDealer(
      customTranslations,
      'selectRetailerCloseToAddress',
    ),
    selectRetailerEnterAddress: getTranslationKeyForDealer(
      customTranslations,
      'selectRetailerEnterAddress',
    ),
    selectRetailerLoadMore: getTranslationKeyForDealer(
      customTranslations,
      'selectRetailerLoadMore',
    ),
    selectRetailerVerificationNotice: getTranslationKeyForDealer(
      customTranslations,
      'selectRetailerVerificationNotice',
    ),
    technicalIssuesMessage: getTranslationKeyForDealer(
      customTranslations,
      'technicalIssuesMessage',
    ),
  };
  const byAddressLabels = {
    addressFieldPlaceholder: customByAddressLabels.addressFieldPlaceholder
      ? translate(customByAddressLabels.addressFieldPlaceholder.label)
      : translate(TranslationKey.ADDRESS_FIELD_PLACEHOLDER),
    change: customByAddressLabels.change
      ? translate(customByAddressLabels.change.label)
      : translate(TranslationKey.CHANGE),
    selectRetailerCloseToAddress:
      customByAddressLabels.selectRetailerCloseToAddress
        ? translate(customByAddressLabels.selectRetailerCloseToAddress.label)
        : translate(TranslationKey.SELECT_RETAILER_CLOSE_TO_ADDRESS),
    selectRetailerEnterAddress: customByAddressLabels.selectRetailerEnterAddress
      ? translate(customByAddressLabels.selectRetailerEnterAddress.label)
      : translate(TranslationKey.SELECT_RETAILER_ENTER_ADDRESS),
    selectRetailerLoadMore: customByAddressLabels.selectRetailerLoadMore
      ? translate(customByAddressLabels.selectRetailerLoadMore.label)
      : translate(TranslationKey.SELECT_RETAILER_LOAD_MORE),
    selectRetailerVerificationNotice:
      customByAddressLabels.selectRetailerVerificationNotice
        ? translate(
            customByAddressLabels.selectRetailerVerificationNotice.label,
          )
        : translate(TranslationKey.SELECT_RETAILER_VERIFICATION_NOTICE),
    technicalIssuesMessage: customByAddressLabels.technicalIssuesMessage
      ? translate(customByAddressLabels.technicalIssuesMessage.label)
      : translate(TranslationKey.TECHNICAL_ISSUES_MESSAGE),
  };

  const dealerPickerCustomHeading = getTranslationKeyForDealer(
    customTranslations,
    'dealerPickerHeading',
  );

  // Dealers for drop-down
  const setDealers = async () => {
    const response = (await API.getDealers()) ?? [];
    const mappedDealers = mapDealersToOptions(response);
    setDealerData(response);
    setOptionsState(mappedDealers);
    if (response?.length === 1) {
      setValue(name, mappedDealers[0].value);
      setAutoSelect(true);
      onRetailerSelect(response[0]);
    }
    setLoadingDeps(false);
  };

  const mapDealersToOptions = (dealers: DealerModel[]): SelectOption[] => {
    return dealers.map((dealer) => {
      return dealer.dealerId
        ? {
            label: `${dealer.name}, ${dealer.address?.addressLine1 ?? ''} ${
              dealer.address?.city ?? ''
            } ${dealer.address?.postalCode ?? ''}`,
            value: dealer.dealerId,
          }
        : { label: '', value: '' };
    });
  };

  const hasRetailerInQuery = !!query.retailerid;
  const retailerIsFromQuery =
    hasRetailerInQuery && query.retailerid === selectedRetailer?.dealerId;
  if (retailerIsFromQuery) {
    return null;
  }

  // Field type selection (drop-down/byAddress)
  let field = !byAddress ? (
    <SelectField
      defaultValue={undefined}
      // placeholder={placeholder}
      name={name}
      label={
        labels.selectRetailer
          ? translate(labels.selectRetailer.label)
          : translate(TranslationKey.SELECT_RETAILER)
      }
      control={control}
      options={optionsState}
      required
      hideRequired={hideRequired}
      messages={messages}
      onSelect={({ target }) => {
        const selected = dealerData?.find((d) => d.dealerId === target.value);
        selected && onRetailerSelect(selected);
      }}
    />
  ) : (
    <RetailerPicker
      labels={byAddressLabels}
      control={control}
      onSelect={(dealer) => {
        if (dealer) {
          onRetailerSelect(dealer);
          dealer.dealerId && clearErrors(name);
        }
      }}
      messages={messages}
      required
      hideRequired={hideRequired}
      name={name}
    />
  );

  const heading = dealerPickerCustomHeading
    ? translate(dealerPickerCustomHeading.label)
    : title
      ? translate(title)
      : '';

  return autoSelect || loadingDeps ? null : (
    <div>
      {heading && <SectionHeader title={heading} />}
      {field}
    </div>
  );
};
