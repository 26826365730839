import React from 'react';
import { FinanceDetailsViewDisclaimersProps } from '../finance-details.types';

export const FinanceDetailsViewDisclaimers = ({
  title,
  disclaimers,
}: FinanceDetailsViewDisclaimersProps) => {
  if (!title || !disclaimers?.length) return null;

  return (
    <div className="flex-col gap-8">
      <h4 className="pb-4 micro font-medium">{title}</h4>
      {disclaimers.map((disclaimer, index) => (
        <p
          className="micro text-secondary"
          key={`finance-details-disclaimer-${index}`}
        >
          {disclaimer}
        </p>
      ))}
    </div>
  );
};
