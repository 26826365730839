import Container from './src/components/Container.view';
import Title from './src/components/Title.view';
import Links from './src/components/Links.view';
import CTAs from './src/components/CTAs.view';

export { useSubNavigationHeight } from './src/utils/useSubNavigationHeight';

const SubNavigation = {
  Container,
  Title,
  Links,
  CTAs,
};

export default SubNavigation;
