import React from 'react';

import type { Retailer } from '@vcc-package/leads-utils/types';

import { useLeadsContext } from '../context/leadsContext';

type RetailerAddressProps = {
  retailer: Retailer;
};
export function RetailerAddress({ retailer }: RetailerAddressProps) {
  const { features } = useLeadsContext();
  const { cityBeforeZipCode } = features;

  const { address } = retailer;
  if (!address) return null;

  const { addressLine1, addressLine2, addressLine3, city, postalCode } =
    address;

  const zipCodeAndCity = cityBeforeZipCode
    ? `${city} ${postalCode}`
    : `${postalCode} ${city}`;

  return (
    <div>
      {addressLine1 && <P>{addressLine1}</P>}
      {postalCode && !addressLine2?.includes(postalCode) && (
        <P>{addressLine2}</P>
      )}
      <P>{zipCodeAndCity}</P>
      {addressLine3 && <P>{addressLine3}</P>}
    </div>
  );
}

const P = ({ children }: { children: React.ReactNode }) => (
  <p className="text-secondary">{children}</p>
);
