export const MODEL_SLUGS = {
  HYBRID: 'hybrid',
  PHEV: 'phev',
  BEV: 'bev',
  ICE: 'ice',
  CC: 'cc',
  CROSS_COUNTRY: 'cross-country',
  RECHARGE: 'recharge',
  RECHARGE_PURE_ELECTRIC: 'recharge-pure-electric',
  ELECTRIC: 'electric',
  POLESTAR_ENGINEERED: 'polestar-engineered',
} as const;

export const MODEL_SLUGS_WITH_DASH = {
  HYBRID: `-${MODEL_SLUGS.HYBRID}`,
  PHEV: `-${MODEL_SLUGS.PHEV}`,
  BEV: `-${MODEL_SLUGS.BEV}`,
  ICE: `-${MODEL_SLUGS.ICE}`,
  CC: `-${MODEL_SLUGS.CC}`,
  CROSS_COUNTRY: `-${MODEL_SLUGS.CROSS_COUNTRY}`,
  RECHARGE: `-${MODEL_SLUGS.RECHARGE}`,
  RECHARGE_PURE_ELECTRIC: `-${MODEL_SLUGS.RECHARGE_PURE_ELECTRIC}`,
  ELECTRIC: `-${MODEL_SLUGS.ELECTRIC}`,
  POLESTAR_ENGINEERED: `-${MODEL_SLUGS.POLESTAR_ENGINEERED}`,
} as const;
