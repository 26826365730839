'use client';
import React from 'react';
import { Disclaimer } from '@vcc-package/text';
import { useTracker } from '@volvo-cars/tracking';
import { cssJoin } from '@volvo-cars/css/utils';
import { ResponsiveImage } from '@vcc-package/media';

import { type EngineType } from 'src/types/queries';
import { useQueries } from 'src/hooks/useQueries';
import { findCarAndFormatDisclaimer } from 'src/utils/queries';
import { MarkdownOrRichText } from 'src/components/organisms/MarkdownOrRichText';
import { Spacing } from '../../layout/Spacing';
import type {
  ImageEntry,
  LinkEntry,
} from '@volvo-cars/content-management-client';

export interface HighlightsProps {
  type: 'highlights';
  title?: string;
  disclaimer?: string;
  cards: CardProps[];
  withoutSpacing?: boolean;
  leftAlignContent?: boolean;
}

interface CardProps {
  heading: string;
  body: string;
  price?: string;
  cta?: LinkEntry;
  secondaryCta?: LinkEntry;
  landscapeImage?: ImageEntry;
  portraitImage?: ImageEntry;
  model?: string;
  engineType?: EngineType;
  disclaimer?: string;
  leftAlignContent?: boolean;
}

export const Highlights: React.FC<HighlightsProps> = ({
  title,
  disclaimer,
  cards,
  withoutSpacing,
  leftAlignContent = false,
}) => {
  const { graphCars } = useQueries();

  if (cards.length === 0) {
    return null;
  }

  cards = cards.map((card) => {
    let formattedDisclaimer = card.disclaimer;
    if (card.model && card.engineType && card.disclaimer) {
      formattedDisclaimer = findCarAndFormatDisclaimer({
        disclaimer: card.disclaimer,
        graphCars,
        model: card.model,
        engineType: card.engineType,
      });
    }

    return {
      ...card,
      disclaimer: formattedDisclaimer,
    };
  });

  // Group cards two and two, to be able to grid them out together with potential disclaimers.
  const groupedCards: CardProps[][] = [];
  for (let i = 0; i < cards.length; i += 2) {
    groupedCards.push(cards.slice(i, i + 2));
  }

  const SpacingWrapper = withoutSpacing ? React.Fragment : Spacing;

  return (
    <SpacingWrapper>
      <div data-testid="highlights" className="container">
        {title && (
          <h2
            data-testid="highlights__title"
            className="heading-2 text-center mb-s"
          >
            {title}
          </h2>
        )}

        {/* Each card group will generate a two by two grid, with two cards, and two disclaimers */}
        {groupedCards.map((cardGroup, i) => {
          return (
            <div className="grid lg:grid-cols-2 gap-24" key={`group-${i}`}>
              {cardGroup.map((card, i) => {
                return (
                  <Card
                    leftAlignContent={leftAlignContent}
                    {...card}
                    key={`card-${i}`}
                  />
                );
              })}
              {cardGroup.map((card, i) => {
                // Rather than moving around the grid, hide the disclaimer container on
                // small devices, where they will be added by the <Card> component.
                return (
                  <div className="until-lg:hidden" key={`disclaimer-${i}`}>
                    {card.disclaimer && <Disclaimer text={card.disclaimer} />}
                  </div>
                );
              })}
            </div>
          );
        })}

        {disclaimer && (
          <div className="mt-32">
            <Disclaimer text={disclaimer} />
          </div>
        )}
      </div>
    </SpacingWrapper>
  );
};

const Card: React.FC<CardProps> = ({
  heading,
  body,
  price,
  cta,
  secondaryCta,
  landscapeImage,
  portraitImage,
  disclaimer,
  leftAlignContent,
}) => {
  const ga4Tracker = useTracker({}, { mode: 'ga4' });
  const eventCategory = 'highlights';

  return (
    <div className="flex-col w-full">
      {portraitImage && (
        <ResponsiveImage
          loading="lazy"
          data-testid="highlights__image"
          images={{
            sm: portraitImage,
            smAspectRatio: '4:3',
            md: landscapeImage,
            mdAspectRatio: '21:9',
          }}
          sizes={{
            lg: 1 / 2,
          }}
          alt={portraitImage?.alt || ''}
        />
      )}

      <div
        className={cssJoin(
          'bg-secondary p-48 md:p-64 flex-col h-full',
          !leftAlignContent && 'text-center',
        )}
      >
        <h3 data-testid="highlights__car__title" className="heading-2 mb-16">
          {heading}
        </h3>
        <div className="mb-24 text-secondary">
          <MarkdownOrRichText data-testid="highlights__car__body" text={body} />
        </div>

        {price && (
          <p
            data-testid="highlights__car__price"
            className="text-secondary mb-24 font-medium"
          >
            {price}
          </p>
        )}

        {cta?.href && cta?.text && (
          <div
            className={cssJoin(
              'flex-col md:flex-row gap-8 md:gap-24 mt-auto flex-wrap',
              leftAlignContent ? 'justify-start' : 'justify-center',
            )}
          >
            <div>
              <a
                className="button-outlined"
                data-color="accent"
                data-testid="highlights__car__cta"
                aria-label={cta.text}
                href={cta.href}
                target={cta.target}
                onClick={() => {
                  ga4Tracker.customEvent({
                    eventCategory,
                    eventAction: 'button|click',
                    eventLabel: `${heading}|${cta.href}`,
                  });
                }}
              >
                {cta.text}
              </a>
            </div>
            {secondaryCta?.href && secondaryCta?.text && (
              <div>
                <a
                  data-testid="highlights__car__secondaryCta"
                  className="button-text"
                  data-color="accent"
                  href={secondaryCta.href}
                  target={secondaryCta.target}
                  onClick={() => {
                    ga4Tracker.customEvent({
                      eventCategory,
                      eventAction: 'link|click',
                      eventLabel: `${heading}|${secondaryCta.href}`,
                    });
                  }}
                >
                  {secondaryCta.text}
                </a>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Card disclaimer for small viewports. For larger viewports they are own columns in the grid. */}
      {disclaimer && (
        <div className="mt-24 lg:hidden">
          <Disclaimer text={disclaimer} />
        </div>
      )}
    </div>
  );
};
