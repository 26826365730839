'use client';
import React, { useEffect, useRef, useState } from 'react';
import { cssMerge } from '@volvo-cars/css/utils';
import { useBreakpoints } from '@vcc-www/hooks';
import { Icon } from '@volvo-cars/react-icons';
import { findRatioClass } from '../../utils';
import type { VideoProps } from './types';

const Video_EXPERIMENTAL: React.FC<VideoProps> = ({
  videos: videoEntries,
  className,
  customControls,
  includeSSR,
  ...props
}) => {
  // eslint-disable-next-line vcc-www/use-breakpoints
  const { fromM, fromL } = useBreakpoints();

  const [isVisible, setIsVisible] = useState(false);
  const [hasBeenReached, setHasBeenReached] = useState(includeSSR || false);
  const [videoSources, setVideoSource] = useState(videoEntries.smVideos || []);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const videoIsPlaying = () => {
    const ref = videoRef.current;
    if (ref) {
      const videoIsPlaying =
        ref.currentTime > 0 && !ref.paused && !ref.ended && ref.readyState > 2;
      return videoIsPlaying;
    }
    return false;
  };

  const playVideo = () => {
    videoRef.current?.play();
  };

  const pauseVideo = () => {
    videoRef.current?.pause();
  };

  const togglePlaying = () => {
    if (videoIsPlaying()) {
      pauseVideo();
    } else {
      playVideo();
    }
  };

  // Observing video node.
  useEffect(() => {
    // Put ref in temporary variable to properly clean it up. Recommended by React.
    const ref = videoRef.current;

    const observer = new IntersectionObserver((entries) => {
      setIsVisible(entries[0].isIntersecting);
      if (entries[0].isIntersecting && !hasBeenReached) {
        setHasBeenReached(true);
      }
    }, {});

    if (ref) {
      observer.observe(ref);
      ref.addEventListener('play', () => {
        setIsPlaying(true);
      });
      ref.addEventListener('pause', () => {
        setIsPlaying(false);
      });
    }

    return () => {
      if (ref) {
        observer.unobserve(ref);
      }
    };
  });

  // Updating video source on viewport change.
  useEffect(() => {
    if (fromL && videoEntries.lgVideos) {
      setVideoSource(videoEntries.lgVideos);
    } else if (fromM && videoEntries.mdVideos) {
      setVideoSource(videoEntries.mdVideos);
    } else {
      setVideoSource(videoEntries.smVideos || []);
    }
  }, [fromM, fromL, videoEntries]);

  // Managing playing and pausing.
  useEffect(() => {
    const playing = videoIsPlaying();

    // Be aware, browsers may block autoplay if video is not muted.
    if (isVisible && videoEntries.autoPlay) {
      playVideo();
    } else if (hasBeenReached && playing && !isVisible) {
      pauseVideo();
    }
  }, [isVisible, hasBeenReached, videoEntries]);

  return (
    <div className="relative">
      <video
        ref={videoRef}
        className={cssMerge(
          'object-cover w-full block',
          videoEntries.smAspectRatio &&
            findRatioClass(videoEntries.smAspectRatio).sm,
          videoEntries.mdAspectRatio &&
            findRatioClass(videoEntries.mdAspectRatio).md,
          videoEntries.lgAspectRatio &&
            findRatioClass(videoEntries.lgAspectRatio).lg,
          className,
        )}
        key={videoSources[0]?.src}
        autoPlay={videoEntries.autoPlay}
        muted={videoEntries.muted}
        loop={videoEntries.loop}
        preload="metadata"
        poster={
          !videoEntries.autoPlay ? videoEntries.posters?.sm.src : undefined
        }
        {...props}
      >
        {hasBeenReached &&
          videoSources.map((source) => {
            return (
              <source
                key={source.src}
                src={source.src}
                type={source.mimeType}
              />
            );
          })}

        {/* TODO: Find a better solution to support track */}
        <track kind="captions" srcLang="en" label="English captions" />
      </video>
      {customControls ? (
        customControls(togglePlaying, isPlaying)
      ) : (
        <button
          type="button"
          className="absolute bottom-16 end-16 border border-always-black p-4 rounded-full"
          onClick={() => togglePlaying()}
          aria-label={isPlaying ? 'Pause video' : 'Play video'} // Dictionaries?
        >
          <Icon icon={isPlaying ? 'pause' : 'play'} size={24} />
        </button>
      )}
    </div>
  );
};

export default Video_EXPERIMENTAL;
