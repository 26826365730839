'use client';

import React, { useState } from 'react';
import { ListItemViewProps } from './list-item.types';
import { cssJoin } from '@volvo-cars/css/utils';
import styles from './list-item.module.css';
import {
  AccordionDetails,
  AccordionSummary,
} from '@volvo-cars/react-accordion';
import { Card } from './list-item.card.view';
import { getLinkVariant } from './list-item.utils';

export const ListItemView: React.FC<
  React.PropsWithChildren<ListItemViewProps>
> = ({
  children,
  description,
  descriptionSources,
  descriptionVariant,
  interactive,
  isOpenDefault = false,
  hasChildren,
  href,
  linkText,
  linkTextSources,
  linkTextClosed,
  linkTextClosedSources,
  linkTextOpened,
  linkTextOpenedSources,
  linkVariant: linkVariantInput,
  onClick,
  onToggle,
  secondaryInfo,
  secondaryInfoSources,
  thumbnail,
  title,
  titleSources,
  renderSecondaryInfo,
  isPriceBreakDown,
  testId,
  testIds,
  verticalCenter,
}) => {
  const [isOpen, setIsOpen] = useState(isOpenDefault);
  const [onHover, setHover] = useState(false);
  const [onPress, setPress] = useState(false);
  const linkVariant = getLinkVariant({
    children,
    isOpen,
    linkText,
    linkTextOpened,
    linkTextClosed,
    linkVariant: linkVariantInput,
    nonInteractive: !interactive,
  });

  const onToggleHandler = (isOpen: boolean) => {
    setIsOpen(isOpen);
    if (typeof onToggle === 'function') {
      onToggle(isOpen);
    }
  };

  const commonProps = {
    onMouseDown: () => setPress(true),
    onMouseUp: () => setPress(false),
    onMouseEnter: () => setHover(true),
    onMouseLeave: () => setHover(false),
    onKeyDown: () => setHover(false),
    onKeyUp: () => setHover(true),
    onClick,
    'data-testid': `${testId}-${
      isOpen ? 'less-info-button' : 'more-info-button'
    }`,
    'aria-pressed': isOpen,
  };

  const renderCard = () => (
    <Card
      description={description}
      descriptionSources={descriptionSources}
      descriptionVariant={descriptionVariant}
      interactive={interactive}
      isOpen={isOpen}
      isPriceBreakDown={isPriceBreakDown}
      linkText={linkText}
      linkTextSources={linkTextSources}
      linkTextClosed={linkTextClosed}
      linkTextClosedSources={linkTextClosedSources}
      linkTextOpened={linkTextOpened}
      linkTextOpenedSources={linkTextOpenedSources}
      linkVariant={interactive ? linkVariant : 'non-interactive'}
      onHover={onHover}
      onPress={onPress}
      renderSecondaryInfo={renderSecondaryInfo}
      secondaryInfo={secondaryInfo}
      secondaryInfoSources={secondaryInfoSources}
      testId={testId}
      testIds={testIds}
      title={title}
      titleSources={titleSources}
      thumbnail={thumbnail}
      verticalCenter={verticalCenter}
    />
  );

  return hasChildren ? (
    <AccordionDetails
      data-testid={testId}
      className={cssJoin(
        'flex-col',
        // This will be added shortly. Remove this comment when it's added.
        // eslint-disable-next-line @volvo-cars/css/no-custom-class
        'border-b-0',
        isPriceBreakDown
          ? styles['list-item-price-breakdown']
          : styles['list-item'],
        styles['list-item-accordion'],
      )}
      defaultOpen={isOpenDefault}
      onToggle={(open) => onToggleHandler(open)}
    >
      <AccordionSummary
        className={cssJoin('p-0 flex-col', !isPriceBreakDown && 'pt-24')}
        icon=" "
        data-testid={`${testId}-${
          isOpen ? 'less-info-button' : 'more-info-button'
        }`}
      >
        <div
          {...commonProps}
          data-testid={null}
          className={cssJoin(
            'text-start',
            interactive
              ? styles['list-item-pointer']
              : styles['list-item-default'],
          )}
        >
          {renderCard()}
        </div>
      </AccordionSummary>
      <div
        className={cssJoin(
          'overflow-hidden flex-col',
          styles['list-item-content'],
          isPriceBreakDown
            ? styles['.list-item-price-breakdown-content-accordion']
            : styles['list-item-content-accordion'],
          isOpen && styles['list-item-content-height'],
        )}
      >
        {children}
      </div>
    </AccordionDetails>
  ) : interactive ? (
    <a
      {...commonProps}
      className={cssJoin(
        linkVariant !== 'chevron-title' && 'pt-24',
        styles['list-item-link-card'],
        styles['list-item-pointer'],
      )}
      href={href}
    >
      {renderCard()}
    </a>
  ) : (
    <div
      className={cssJoin(
        linkVariant !== 'chevron-title' && 'pt-24',
        styles['list-item-link-card'],
      )}
    >
      {renderCard()}
    </div>
  );
};
