'use client';
import React, { createContext, useContext } from 'react';
import { type GraphCarProps } from 'src/services/federated-graph/getGraphCars';
import { type FinanceOptions } from '@vcc-www/api/finance-options';

interface QueriesContextProps {
  graphCars: GraphCarProps[];
  financeOptions: FinanceOptions;
}

const QueriesContext = createContext<QueriesContextProps | null>(null);

export const QueriesProvider: React.FC<
  React.PropsWithChildren<QueriesContextProps>
> = ({ graphCars = [], financeOptions, children }) => {
  return (
    <QueriesContext.Provider value={{ graphCars, financeOptions }}>
      {children}
    </QueriesContext.Provider>
  );
};

export const useQueries = () => {
  const value = useContext(QueriesContext);

  if (value == null) {
    throw new Error('QueriesProvider is missing in the component tree');
  }

  return value;
};
