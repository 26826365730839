import type { CustomTranslations } from '@vcc-package/leads-utils/types';
import type { CustomTranslation } from '@vcc-package/leads-utils/api';

const isSpecificTranslationsKey = (
  t?: CustomTranslation | null,
): t is CustomTranslation => !!t?.label && !!t?.placeholder && !!t?.description;

export const getTranslationKey = (
  customTranslations: CustomTranslations,
  key: string,
  specificKey?: string,
): CustomTranslation | null => {
  const customTranslation = customTranslations ? customTranslations[key] : null;

  if (customTranslation) {
    if (specificKey) {
      const specificKeyTranslation =
        customTranslation.find(
          (translation) =>
            translation.key?.toLowerCase() === specificKey.toLowerCase(),
        ) ?? null;
      return isSpecificTranslationsKey(specificKeyTranslation)
        ? specificKeyTranslation
        : null;
    } else if (isSpecificTranslationsKey(customTranslation[0])) {
      return customTranslation[0];
    }
  }

  return null;
};

export const getTranslationKeyForDealer = (
  customTranslations: Array<CustomTranslation> | null,
  specificKey: string,
): CustomTranslation | null => {
  if (customTranslations) {
    const customTranslation = customTranslations.find(
      (translation) =>
        translation.key?.toLowerCase() === specificKey.toLowerCase(),
    );

    return isSpecificTranslationsKey(customTranslation)
      ? customTranslation
      : null;
  }
  return null;
};
