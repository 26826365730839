/*
  @generated
  This file was automatically generated and should not be edited.
*/
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AnnualPno12: any;
  /** Represents the base attributes of an offer */
  BaseOffer: any;
  BigDecimal: any;
  /** Represents a car */
  CarKey: any;
  ConfigurationMeta: any;
  /** Represents a configuration */
  ConfigurationState: any;
  Date: any;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** Type represents date as a ISO-8601 Date string. */
  LocalDate: any;
  /** String representation of local date-time following ISO 8601 format - "yyyy-MM-dd'T'HH:mm:ss[.SSS]'Z'" */
  LocalDateTime: any;
  Long: any;
  NonNegativeInt: any;
  /** Represents an offer configuration */
  OfferConfigurationState: any;
  /** Represents a offer */
  OfferKey: any;
  /** An arbitrary precision signed decimal */
  OrderTracker__BigDecimal: any;
  /** A 64-bit signed integer */
  OrderTracker__Long: any;
  PositiveInt: any;
  /** Represents a guid */
  UUID: any;
  /** Vehicle Identification Number (VIN) https://en.wikipedia.org/wiki/Vehicle_identification_number */
  VIN: any;
};

/** Mutable fields on the address object */
export type AccountProfileAddressInput = {
  country?: InputMaybe<Scalars['String']>;
  locality?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
};

/** Mutable fields on the account profile object */
export type AccountProfileInput = {
  address?: InputMaybe<AccountProfileAddressInput>;
  /** Birthdate in the format: yyyy-mm-dd */
  birthdate?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export enum Action {
  ADD = 'ADD',
  NONE = 'NONE',
  REMOVE = 'REMOVE',
}

export type AttributeInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export enum BaseColour {
  BLACK = 'BLACK',
  BLUE = 'BLUE',
  BROWN = 'BROWN',
  GRAY = 'GRAY',
  GREEN = 'GREEN',
  ORANGE = 'ORANGE',
  RED = 'RED',
  SILVER = 'SILVER',
  VIOLET = 'VIOLET',
  WHITE = 'WHITE',
  YELLOW = 'YELLOW',
}

/** Support business models */
export enum BusinessModel {
  /** Business model direct */
  DIRECT = 'DIRECT',
  /** Business model Unspecified is a mix of wholesale and direct */
  UNSPECIFIED = 'UNSPECIFIED',
  /** Business model wholesale */
  WHOLESALE = 'WHOLESALE',
}

/** The different feature types according to CAS */
export enum CASFeatureType {
  ASHTRAY_LID = 'ASHTRAY_LID',
  BACKGROUND = 'BACKGROUND',
  BODY = 'BODY',
  BODYKIT = 'BODYKIT',
  BUMPER = 'BUMPER',
  CARPET_KIT = 'CARPET_KIT',
  CEILING = 'CEILING',
  CEILING_MIRROR = 'CEILING_MIRROR',
  CENTER_DISPLAY = 'CENTER_DISPLAY',
  CENTER_TUNNEL_CONSOLE = 'CENTER_TUNNEL_CONSOLE',
  COLOR = 'COLOR',
  COMBI_INSTRUMENT = 'COMBI_INSTRUMENT',
  CONNECTIVITY_PANEL = 'CONNECTIVITY_PANEL',
  COUNTRY_CODE = 'COUNTRY_CODE',
  DASHBOARD = 'DASHBOARD',
  DECOR = 'DECOR',
  DECOR_SIDE_WINDOW = 'DECOR_SIDE_WINDOW',
  DOORMOULDING = 'DOORMOULDING',
  DRIVELINE = 'DRIVELINE',
  DRIVE_MODE = 'DRIVE_MODE',
  ENGINE = 'ENGINE',
  ENGINE_EMBLEM = 'ENGINE_EMBLEM',
  ENGINE_TYPE = 'ENGINE_TYPE',
  EXHAUST = 'EXHAUST',
  EXTERIOR_OPTIONS = 'EXTERIOR_OPTIONS',
  EXTERIOR_STYLING_OPTIONS = 'EXTERIOR_STYLING_OPTIONS',
  FILTER_MODEL_YEAR = 'FILTER_MODEL_YEAR',
  FOGLIGHT = 'FOGLIGHT',
  FORCE_SALES_VERSION_IN_URL = 'FORCE_SALES_VERSION_IN_URL',
  FOREGROUND = 'FOREGROUND',
  FRONT_MUD_FLAP = 'FRONT_MUD_FLAP',
  FRONT_STORAGE = 'FRONT_STORAGE',
  FUEL_PORT_CHARGING_PORT = 'FUEL_PORT_CHARGING_PORT',
  FUEL_TYPE = 'FUEL_TYPE',
  GCC_OPTIONS = 'GCC_OPTIONS',
  GEARBOX = 'GEARBOX',
  GEARLEVER = 'GEARLEVER',
  GRILLE = 'GRILLE',
  HANDLES = 'HANDLES',
  HEADLIGHTS = 'HEADLIGHTS',
  HEADLIGHT_CLEANER = 'HEADLIGHT_CLEANER',
  HEADLINING = 'HEADLINING',
  HEATED_STEERINGWHEEL = 'HEATED_STEERINGWHEEL',
  INTERIOR_ILLUMINATION = 'INTERIOR_ILLUMINATION',
  INTERIOR_STYLING_OPTIONS = 'INTERIOR_STYLING_OPTIONS',
  MARKET_CODE = 'MARKET_CODE',
  MIRROR = 'MIRROR',
  MISC = 'MISC',
  MODEL = 'MODEL',
  MODEL_YEAR = 'MODEL_YEAR',
  OPTIONS = 'OPTIONS',
  PACKAGES = 'PACKAGES',
  PADDLE = 'PADDLE',
  PNO12 = 'PNO12',
  PNO34 = 'PNO34',
  POLESTAR_ENGINEERED = 'POLESTAR_ENGINEERED',
  POWERSOCKET = 'POWERSOCKET',
  POWER_MEMORY_SEAT = 'POWER_MEMORY_SEAT',
  POWER_MEMORY_SEAT_EXTENDED = 'POWER_MEMORY_SEAT_EXTENDED',
  PRE_CONFIGURED = 'PRE_CONFIGURED',
  RAILS = 'RAILS',
  REAR_ARMREST = 'REAR_ARMREST',
  REAR_HEADREST = 'REAR_HEADREST',
  REAR_MUD_FLAP = 'REAR_MUD_FLAP',
  RIMS = 'RIMS',
  ROOF = 'ROOF',
  RUNNINGBOARD = 'RUNNINGBOARD',
  SALES_VERSION = 'SALES_VERSION',
  SEAT_ADJUSTMENT = 'SEAT_ADJUSTMENT',
  SEAT_COUNT = 'SEAT_COUNT',
  SEAT_CUSHION_EXTENSION = 'SEAT_CUSHION_EXTENSION',
  SEAT_LUMBAR_SUPPORT = 'SEAT_LUMBAR_SUPPORT',
  SENSORS = 'SENSORS',
  SKIDPLATE = 'SKIDPLATE',
  SKIDPLATE_ACCESSORY = 'SKIDPLATE_ACCESSORY',
  SOUNDSYSTEM = 'SOUNDSYSTEM',
  SPOILER = 'SPOILER',
  STANDARD_FEATURES = 'STANDARD_FEATURES',
  STARTING_POINT = 'STARTING_POINT',
  STEERINGWHEEL = 'STEERINGWHEEL',
  STEERING_TYPE = 'STEERING_TYPE',
  STRUCTURE_WEEK = 'STRUCTURE_WEEK',
  STYLE_CONFIGURATIONS = 'STYLE_CONFIGURATIONS',
  SUNROOF = 'SUNROOF',
  TOTAL_AND_DEFAULTS = 'TOTAL_AND_DEFAULTS',
  TOWBAR = 'TOWBAR',
  TRANSMISSION = 'TRANSMISSION',
  TREADPLATE = 'TREADPLATE',
  TRIMLEVEL = 'TRIMLEVEL',
  UNKNOWN = 'UNKNOWN',
  UPHOLSTERY = 'UPHOLSTERY',
  VERSION = 'VERSION',
  WASTEBIN = 'WASTEBIN',
  WHEEL_ARCHES = 'WHEEL_ARCHES',
  WINDOWS = 'WINDOWS',
}

export type CLEBoolFilter = {
  not?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Array<CLEBoolValue>>;
};

export type CLEBoolValue = {
  exists?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Scalars['Boolean']>;
};

export type CLEBoostBoolFilter = {
  boostScore?: InputMaybe<Scalars['Float']>;
  value?: InputMaybe<CLEBoostBoolValue>;
};

export type CLEBoostBoolValue = {
  exists?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Scalars['Boolean']>;
};

export type CLEBoostDateFilter = {
  boostScore?: InputMaybe<Scalars['Float']>;
  value?: InputMaybe<CLEBoostDateValue>;
};

export type CLEBoostDateRangeValue = {
  exactValue?: InputMaybe<Scalars['DateTime']>;
  max?: InputMaybe<Scalars['DateTime']>;
  min?: InputMaybe<Scalars['DateTime']>;
};

export type CLEBoostDateValue = {
  exists?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<CLEBoostDateRangeValue>;
};

export type CLEBoostNumberRangeFilter = {
  boostScore?: InputMaybe<Scalars['Float']>;
  value?: InputMaybe<CLEBoostNumberValue>;
};

export type CLEBoostNumberRangeValue = {
  exactValue?: InputMaybe<Scalars['Float']>;
  max?: InputMaybe<Scalars['Float']>;
  min?: InputMaybe<Scalars['Float']>;
};

export type CLEBoostNumberValue = {
  exists?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<CLEBoostNumberRangeValue>;
};

export type CLEBoostStringFilter = {
  boostScore?: InputMaybe<Scalars['Float']>;
  value?: InputMaybe<CLEBoostStringValue>;
};

export type CLEBoostStringValue = {
  exists?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Scalars['String']>;
};

export type CLEBoostValue = {
  accessories?: InputMaybe<Array<CLEBoostStringFilter>>;
  available?: InputMaybe<Array<CLEBoostBoolFilter>>;
  benefitInKindGrossAmount?: InputMaybe<Array<CLEBoostNumberRangeFilter>>;
  bodyCode?: InputMaybe<Array<CLEBoostStringFilter>>;
  cashPriceB2B?: InputMaybe<Array<CLEBoostNumberRangeFilter>>;
  cashPriceB2C?: InputMaybe<Array<CLEBoostNumberRangeFilter>>;
  colorCode?: InputMaybe<Array<CLEBoostStringFilter>>;
  colorHex?: InputMaybe<Array<CLEBoostStringFilter>>;
  commonOrderNumber?: InputMaybe<Array<CLEBoostStringFilter>>;
  commonOrderType?: InputMaybe<Array<CLEBoostNumberRangeFilter>>;
  commonSalesType?: InputMaybe<Array<CLEBoostStringFilter>>;
  dealerId?: InputMaybe<Array<CLEBoostStringFilter>>;
  demoCar?: InputMaybe<Array<CLEBoostBoolFilter>>;
  driveLine?: InputMaybe<Array<CLEBoostStringFilter>>;
  driveTypeCode?: InputMaybe<Array<CLEBoostStringFilter>>;
  duplicatesIdentifier?: InputMaybe<Array<CLEBoostStringFilter>>;
  energyEfficiencyClass?: InputMaybe<Array<CLEBoostStringFilter>>;
  engineCode?: InputMaybe<Array<CLEBoostStringFilter>>;
  engineType?: InputMaybe<Array<CLEBoostStringFilter>>;
  exteriorTheme?: InputMaybe<Array<CLEBoostStringFilter>>;
  features?: InputMaybe<Array<CLEBoostStringFilter>>;
  fleetManagementAvailable?: InputMaybe<Array<CLEBoostBoolFilter>>;
  fuelType?: InputMaybe<Array<CLEBoostStringFilter>>;
  gearboxCode?: InputMaybe<Array<CLEBoostStringFilter>>;
  gearboxType?: InputMaybe<Array<CLEBoostStringFilter>>;
  marketCode?: InputMaybe<Array<CLEBoostStringFilter>>;
  marketingCode?: InputMaybe<Array<CLEBoostStringFilter>>;
  minimumGrossSubscriptionPriceB2C?: InputMaybe<
    Array<CLEBoostNumberRangeFilter>
  >;
  minimumNetSubscriptionPriceB2B?: InputMaybe<Array<CLEBoostNumberRangeFilter>>;
  modelCategory?: InputMaybe<Array<CLEBoostStringFilter>>;
  modelCode?: InputMaybe<Array<CLEBoostStringFilter>>;
  modelFamily?: InputMaybe<Array<CLEBoostStringFilter>>;
  modelYear?: InputMaybe<Array<CLEBoostStringFilter>>;
  msrpPrice?: InputMaybe<Array<CLEBoostNumberRangeFilter>>;
  numberOfSeats?: InputMaybe<Array<CLEBoostNumberRangeFilter>>;
  numberOfSeatsCode?: InputMaybe<Array<CLEBoostStringFilter>>;
  options?: InputMaybe<Array<CLEBoostStringFilter>>;
  orderAvailableForUnconsentedTrade?: InputMaybe<Array<CLEBoostBoolFilter>>;
  orderBrandStatusPoint?: InputMaybe<Array<CLEBoostNumberRangeFilter>>;
  orderCommonStatusPoint?: InputMaybe<Array<CLEBoostNumberRangeFilter>>;
  orderCustomerReferenceNumber?: InputMaybe<Array<CLEBoostStringFilter>>;
  orderDealerOrderNumber?: InputMaybe<Array<CLEBoostStringFilter>>;
  orderDeliveryDate?: InputMaybe<Array<CLEBoostDateFilter>>;
  orderDestinationCode?: InputMaybe<Array<CLEBoostStringFilter>>;
  orderDistributorId?: InputMaybe<Array<CLEBoostStringFilter>>;
  orderFactoryCompleteDate?: InputMaybe<Array<CLEBoostDateFilter>>;
  orderFyon?: InputMaybe<Array<CLEBoostStringFilter>>;
  orderIntermediateDestination?: InputMaybe<Array<CLEBoostStringFilter>>;
  orderLastDateForSpecChange?: InputMaybe<Array<CLEBoostDateFilter>>;
  orderPendingTransfer?: InputMaybe<Array<CLEBoostBoolFilter>>;
  orderPreliminaryReservationDealerId?: InputMaybe<Array<CLEBoostStringFilter>>;
  orderVisibilityIndicator?: InputMaybe<Array<CLEBoostBoolFilter>>;
  packages?: InputMaybe<Array<CLEBoostStringFilter>>;
  pno12?: InputMaybe<Array<CLEBoostStringFilter>>;
  pno34PlusOptions?: InputMaybe<Array<CLEBoostStringFilter>>;
  preTaxAmount?: InputMaybe<Array<CLEBoostNumberRangeFilter>>;
  pricePerMarketCashPriceB2B?: InputMaybe<Array<CLEBoostNumberRangeFilter>>;
  pricePerMarketCashPriceB2C?: InputMaybe<Array<CLEBoostNumberRangeFilter>>;
  pricePerMarketMinimumGrossSubscriptionPriceB2C?: InputMaybe<
    Array<CLEBoostNumberRangeFilter>
  >;
  pricePerMarketMinimumNetSubscriptionPriceB2B?: InputMaybe<
    Array<CLEBoostNumberRangeFilter>
  >;
  pricePerMarketMsrpPrice?: InputMaybe<Array<CLEBoostNumberRangeFilter>>;
  reservationReservedThrough?: InputMaybe<Array<CLEBoostStringFilter>>;
  salesVersionCode?: InputMaybe<Array<CLEBoostStringFilter>>;
  salesVersionName?: InputMaybe<Array<CLEBoostStringFilter>>;
  salesVersionPnoCode?: InputMaybe<Array<CLEBoostStringFilter>>;
  specialMessageCode?: InputMaybe<Array<CLEBoostStringFilter>>;
  steeringCode?: InputMaybe<Array<CLEBoostStringFilter>>;
  structureWeek?: InputMaybe<Array<CLEBoostStringFilter>>;
  trimLevelCode?: InputMaybe<Array<CLEBoostStringFilter>>;
  upholsteryCode?: InputMaybe<Array<CLEBoostStringFilter>>;
  vin?: InputMaybe<Array<CLEBoostStringFilter>>;
  visible?: InputMaybe<Array<CLEBoostBoolFilter>>;
  wheelsCode?: InputMaybe<Array<CLEBoostStringFilter>>;
};

export type CLEBoostValueWithDistributionChannel = {
  accessories?: InputMaybe<Array<CLEBoostStringFilter>>;
  available?: InputMaybe<Array<CLEBoostBoolFilter>>;
  /** @deprecated Replaced by pricePerMarketBenefitInKindGrossAmount */
  benefitInKindGrossAmount?: InputMaybe<Array<CLEBoostNumberRangeFilter>>;
  bodyCode?: InputMaybe<Array<CLEBoostStringFilter>>;
  /** @deprecated Replaced by pricePerMarketCashPriceB2B */
  cashPriceB2B?: InputMaybe<Array<CLEBoostNumberRangeFilter>>;
  /** @deprecated Replaced by pricePerMarketCashPriceB2C */
  cashPriceB2C?: InputMaybe<Array<CLEBoostNumberRangeFilter>>;
  colorCode?: InputMaybe<Array<CLEBoostStringFilter>>;
  colorHex?: InputMaybe<Array<CLEBoostStringFilter>>;
  commonOrderNumber?: InputMaybe<Array<CLEBoostStringFilter>>;
  commonOrderType?: InputMaybe<Array<CLEBoostNumberRangeFilter>>;
  commonSalesType?: InputMaybe<Array<CLEBoostStringFilter>>;
  dealerId?: InputMaybe<Array<CLEBoostStringFilter>>;
  demoCar?: InputMaybe<Array<CLEBoostBoolFilter>>;
  distributionChannelType?: InputMaybe<Array<CLEBoostStringFilter>>;
  driveLine?: InputMaybe<Array<CLEBoostStringFilter>>;
  driveTypeCode?: InputMaybe<Array<CLEBoostStringFilter>>;
  duplicatesIdentifier?: InputMaybe<Array<CLEBoostStringFilter>>;
  energyEfficiencyClass?: InputMaybe<Array<CLEBoostStringFilter>>;
  engineCode?: InputMaybe<Array<CLEBoostStringFilter>>;
  engineType?: InputMaybe<Array<CLEBoostStringFilter>>;
  exteriorTheme?: InputMaybe<Array<CLEBoostStringFilter>>;
  features?: InputMaybe<Array<CLEBoostStringFilter>>;
  fleetManagementAvailable?: InputMaybe<Array<CLEBoostBoolFilter>>;
  fuelType?: InputMaybe<Array<CLEBoostStringFilter>>;
  gearboxCode?: InputMaybe<Array<CLEBoostStringFilter>>;
  gearboxType?: InputMaybe<Array<CLEBoostStringFilter>>;
  marketCode?: InputMaybe<Array<CLEBoostStringFilter>>;
  marketingCode?: InputMaybe<Array<CLEBoostStringFilter>>;
  /** @deprecated Replaced by pricePerMarketMinimumGrossSubscriptionPriceB2C */
  minimumGrossSubscriptionPriceB2C?: InputMaybe<
    Array<CLEBoostNumberRangeFilter>
  >;
  /** @deprecated Replaced by pricePerMarketMinimumNetSubscriptionPriceB2B */
  minimumNetSubscriptionPriceB2B?: InputMaybe<Array<CLEBoostNumberRangeFilter>>;
  modelCategory?: InputMaybe<Array<CLEBoostStringFilter>>;
  modelCode?: InputMaybe<Array<CLEBoostStringFilter>>;
  modelFamily?: InputMaybe<Array<CLEBoostStringFilter>>;
  modelYear?: InputMaybe<Array<CLEBoostStringFilter>>;
  /** @deprecated Replaced by pricePerMarketMsrpPrice */
  msrpPrice?: InputMaybe<Array<CLEBoostNumberRangeFilter>>;
  numberOfSeats?: InputMaybe<Array<CLEBoostNumberRangeFilter>>;
  numberOfSeatsCode?: InputMaybe<Array<CLEBoostStringFilter>>;
  options?: InputMaybe<Array<CLEBoostStringFilter>>;
  orderAvailableForUnconsentedTrade?: InputMaybe<Array<CLEBoostBoolFilter>>;
  orderBrandStatusPoint?: InputMaybe<Array<CLEBoostNumberRangeFilter>>;
  orderCommonStatusPoint?: InputMaybe<Array<CLEBoostNumberRangeFilter>>;
  orderCustomerReferenceNumber?: InputMaybe<Array<CLEBoostStringFilter>>;
  orderDealerOrderNumber?: InputMaybe<Array<CLEBoostStringFilter>>;
  orderDeliveryDate?: InputMaybe<Array<CLEBoostDateFilter>>;
  orderDestinationCode?: InputMaybe<Array<CLEBoostStringFilter>>;
  orderDistributorId?: InputMaybe<Array<CLEBoostStringFilter>>;
  orderFactoryCompleteDate?: InputMaybe<Array<CLEBoostDateFilter>>;
  orderFyon?: InputMaybe<Array<CLEBoostStringFilter>>;
  orderIntermediateDestination?: InputMaybe<Array<CLEBoostStringFilter>>;
  orderLastDateForSpecChange?: InputMaybe<Array<CLEBoostDateFilter>>;
  orderPendingTransfer?: InputMaybe<Array<CLEBoostBoolFilter>>;
  orderPreliminaryReservationDealerId?: InputMaybe<Array<CLEBoostStringFilter>>;
  orderVisibilityIndicator?: InputMaybe<Array<CLEBoostBoolFilter>>;
  packages?: InputMaybe<Array<CLEBoostStringFilter>>;
  pno12?: InputMaybe<Array<CLEBoostStringFilter>>;
  pno34PlusOptions?: InputMaybe<Array<CLEBoostStringFilter>>;
  /** @deprecated Replaced by pricePerMarketPreTaxAmount */
  preTaxAmount?: InputMaybe<Array<CLEBoostNumberRangeFilter>>;
  pricePerMarketBenefitInKindGrossAmount?: InputMaybe<
    Array<CLEBoostNumberRangeFilter>
  >;
  pricePerMarketCashPriceB2B?: InputMaybe<Array<CLEBoostNumberRangeFilter>>;
  pricePerMarketCashPriceB2C?: InputMaybe<Array<CLEBoostNumberRangeFilter>>;
  pricePerMarketMinimumGrossSubscriptionPriceB2C?: InputMaybe<
    Array<CLEBoostNumberRangeFilter>
  >;
  pricePerMarketMinimumNetSubscriptionPriceB2B?: InputMaybe<
    Array<CLEBoostNumberRangeFilter>
  >;
  pricePerMarketMsrpPrice?: InputMaybe<Array<CLEBoostNumberRangeFilter>>;
  pricePerMarketPreTaxAmount?: InputMaybe<Array<CLEBoostNumberRangeFilter>>;
  reservationReservedThrough?: InputMaybe<Array<CLEBoostStringFilter>>;
  salesVersionCode?: InputMaybe<Array<CLEBoostStringFilter>>;
  salesVersionName?: InputMaybe<Array<CLEBoostStringFilter>>;
  salesVersionPnoCode?: InputMaybe<Array<CLEBoostStringFilter>>;
  specialMessageCode?: InputMaybe<Array<CLEBoostStringFilter>>;
  steeringCode?: InputMaybe<Array<CLEBoostStringFilter>>;
  structureWeek?: InputMaybe<Array<CLEBoostStringFilter>>;
  trimLevelCode?: InputMaybe<Array<CLEBoostStringFilter>>;
  upholsteryCode?: InputMaybe<Array<CLEBoostStringFilter>>;
  vin?: InputMaybe<Array<CLEBoostStringFilter>>;
  visible?: InputMaybe<Array<CLEBoostBoolFilter>>;
  wheelsCode?: InputMaybe<Array<CLEBoostStringFilter>>;
};

export type CLECarFilter = {
  not?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<CLECarFilterValue>;
};

export type CLECarFilterValue = {
  accessories?: InputMaybe<CLEStringCollectionFilter>;
  available?: InputMaybe<CLEBoolFilter>;
  benefitInKindGrossAmount?: InputMaybe<CLENumberRangeFilter>;
  bodyCode?: InputMaybe<CLEStringFilter>;
  bodyStyle?: InputMaybe<CLEStringFilter>;
  cashPriceB2B?: InputMaybe<CLENumberRangeFilter>;
  cashPriceB2C?: InputMaybe<CLENumberRangeFilter>;
  colorCode?: InputMaybe<CLEStringFilter>;
  colorHex?: InputMaybe<CLEStringFilter>;
  commonOrderNumber?: InputMaybe<CLEStringFilter>;
  commonOrderType?: InputMaybe<CLENumberRangeFilter>;
  commonSalesType?: InputMaybe<CLEStringFilter>;
  dealerId?: InputMaybe<CLEStringFilter>;
  demoCar?: InputMaybe<CLEBoolFilter>;
  driveLine?: InputMaybe<CLEStringFilter>;
  driveTypeCode?: InputMaybe<CLEStringFilter>;
  duplicatesIdentifier?: InputMaybe<CLEStringFilter>;
  energyEfficiencyClass?: InputMaybe<CLEStringFilter>;
  engineCode?: InputMaybe<CLEStringFilter>;
  engineType?: InputMaybe<CLEStringFilter>;
  exteriorTheme?: InputMaybe<CLEStringFilter>;
  features?: InputMaybe<CLEStringCollectionFilter>;
  fleetManagementAvailable?: InputMaybe<CLEBoolFilter>;
  fuelType?: InputMaybe<CLEStringFilter>;
  gearboxCode?: InputMaybe<CLEStringFilter>;
  gearboxType?: InputMaybe<CLEStringFilter>;
  id?: InputMaybe<CLEStringFilter>;
  marketCode?: InputMaybe<CLEStringFilter>;
  marketingCode?: InputMaybe<CLEStringFilter>;
  minimumGrossSubscriptionPriceB2C?: InputMaybe<CLENumberRangeFilter>;
  minimumNetSubscriptionPriceB2B?: InputMaybe<CLENumberRangeFilter>;
  modelCategory?: InputMaybe<CLEStringFilter>;
  modelCode?: InputMaybe<CLEStringFilter>;
  modelFamily?: InputMaybe<CLEStringFilter>;
  modelYear?: InputMaybe<CLEStringFilter>;
  msrpPrice?: InputMaybe<CLENumberRangeFilter>;
  nedcCombinedCo2?: InputMaybe<CLENumberRangeFilter>;
  nedcElectricRange?: InputMaybe<CLENumberRangeFilter>;
  nedcEnergyConsumption?: InputMaybe<CLENumberRangeFilter>;
  nedcExurbanFuelConsumption?: InputMaybe<CLENumberRangeFilter>;
  nedcFuelConsumption?: InputMaybe<CLENumberRangeFilter>;
  nedcIsOfficial?: InputMaybe<CLEBoolFilter>;
  nedcUrbanFuelConsumption?: InputMaybe<CLENumberRangeFilter>;
  nedcWeightedCombinedCo2?: InputMaybe<CLENumberRangeFilter>;
  nedcWeightedCombinedFuelConsumption?: InputMaybe<CLENumberRangeFilter>;
  numberOfSeats?: InputMaybe<CLENumberRangeFilter>;
  numberOfSeatsCode?: InputMaybe<CLEStringFilter>;
  options?: InputMaybe<CLEStringCollectionFilter>;
  orderAvailableForUnconsentedTrade?: InputMaybe<CLEBoolFilter>;
  orderBrandStatusPoint?: InputMaybe<CLENumberRangeFilter>;
  orderCommonStatusPoint?: InputMaybe<CLENumberRangeFilter>;
  orderCustomerReferenceNumber?: InputMaybe<CLEStringFilter>;
  orderDealerOrderNumber?: InputMaybe<CLEStringFilter>;
  orderDeliveryDate?: InputMaybe<CLEDateFilter>;
  orderDestinationCode?: InputMaybe<CLEStringFilter>;
  orderDistributorId?: InputMaybe<CLEStringFilter>;
  orderFactoryCompleteDate?: InputMaybe<CLEDateFilter>;
  orderFyon?: InputMaybe<CLEStringFilter>;
  orderIntermediateDestination?: InputMaybe<CLEStringFilter>;
  orderLastDateForSpecChange?: InputMaybe<CLEDateFilter>;
  orderPendingTransfer?: InputMaybe<CLEBoolFilter>;
  orderPreliminaryReservationDealerId?: InputMaybe<CLEStringFilter>;
  orderVisibilityIndicator?: InputMaybe<CLEBoolFilter>;
  packages?: InputMaybe<CLEStringCollectionFilter>;
  pno12?: InputMaybe<CLEStringFilter>;
  pno34PlusOptions?: InputMaybe<CLEStringFilter>;
  preTaxAmount?: InputMaybe<CLENumberRangeFilter>;
  pricePerMarketCashPriceB2B?: InputMaybe<CLENumberRangeFilter>;
  pricePerMarketCashPriceB2C?: InputMaybe<CLENumberRangeFilter>;
  pricePerMarketMarketCode?: InputMaybe<Scalars['String']>;
  pricePerMarketMinimumGrossSubscriptionPriceB2C?: InputMaybe<CLENumberRangeFilter>;
  pricePerMarketMinimumNetSubscriptionPriceB2B?: InputMaybe<CLENumberRangeFilter>;
  pricePerMarketMsrpPrice?: InputMaybe<CLENumberRangeFilter>;
  reservationReservedThrough?: InputMaybe<CLEStringFilter>;
  salesVersionCode?: InputMaybe<CLEStringFilter>;
  salesVersionName?: InputMaybe<CLEStringFilter>;
  salesVersionPnoCode?: InputMaybe<CLEStringFilter>;
  specialMessageCode?: InputMaybe<CLEStringFilter>;
  steeringCode?: InputMaybe<CLEStringFilter>;
  structureWeek?: InputMaybe<CLEStringFilter>;
  subFilter?: InputMaybe<CLECarSubFilter>;
  trimLevelCode?: InputMaybe<CLEStringFilter>;
  upholsteryCode?: InputMaybe<CLEStringFilter>;
  vin?: InputMaybe<CLEStringFilter>;
  visible?: InputMaybe<CLEBoolFilter>;
  weightActualMass?: InputMaybe<CLENumberRangeFilter>;
  weightMassInRunningOrderTotal?: InputMaybe<CLENumberRangeFilter>;
  weightTestMassIndividual?: InputMaybe<CLENumberRangeFilter>;
  wheelsCode?: InputMaybe<CLEStringFilter>;
  wltpCombinedCo2?: InputMaybe<CLENumberRangeFilter>;
  wltpElectricEnergyConsumption?: InputMaybe<CLENumberRangeFilter>;
  wltpElectricRange?: InputMaybe<CLENumberRangeFilter>;
  wltpExtraHighFuelConsumption?: InputMaybe<CLENumberRangeFilter>;
  wltpFuelConsumption?: InputMaybe<CLENumberRangeFilter>;
  wltpHighFuelConsumption?: InputMaybe<CLENumberRangeFilter>;
  wltpIsOfficial?: InputMaybe<CLEBoolFilter>;
  wltpLowFuelConsumption?: InputMaybe<CLENumberRangeFilter>;
  wltpMediumFuelConsumption?: InputMaybe<CLENumberRangeFilter>;
  wltpWeightedCombinedCo2?: InputMaybe<CLENumberRangeFilter>;
  wltpWeightedCombinedFuelConsumption?: InputMaybe<CLENumberRangeFilter>;
};

export type CLECarFilterWithDistributionChannel = {
  not?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<CLECarFilterWithDistributionChannelValue>;
};

export type CLECarFilterWithDistributionChannelValue = {
  accessories?: InputMaybe<CLEStringCollectionFilter>;
  available?: InputMaybe<CLEBoolFilter>;
  /** @deprecated Replaced by pricePerMarketBenefitInKindGrossAmount */
  benefitInKindGrossAmount?: InputMaybe<CLENumberRangeFilter>;
  bodyCode?: InputMaybe<CLEStringFilter>;
  bodyStyle?: InputMaybe<CLEStringFilter>;
  /** @deprecated Replaced by pricePerMarketCashPriceB2B */
  cashPriceB2B?: InputMaybe<CLENumberRangeFilter>;
  /** @deprecated Replaced by pricePerMarketCashPriceB2C */
  cashPriceB2C?: InputMaybe<CLENumberRangeFilter>;
  colorCode?: InputMaybe<CLEStringFilter>;
  colorHex?: InputMaybe<CLEStringFilter>;
  commonOrderNumber?: InputMaybe<CLEStringFilter>;
  commonOrderType?: InputMaybe<CLENumberRangeFilter>;
  commonSalesType?: InputMaybe<CLEStringFilter>;
  dealerId?: InputMaybe<CLEStringFilter>;
  demoCar?: InputMaybe<CLEBoolFilter>;
  distributionChannelType?: InputMaybe<CLEDistributionChannelTypeFilter>;
  driveLine?: InputMaybe<CLEStringFilter>;
  driveTypeCode?: InputMaybe<CLEStringFilter>;
  duplicatesIdentifier?: InputMaybe<CLEStringFilter>;
  energyEfficiencyClass?: InputMaybe<CLEStringFilter>;
  engineCode?: InputMaybe<CLEStringFilter>;
  engineType?: InputMaybe<CLEStringFilter>;
  exteriorTheme?: InputMaybe<CLEStringFilter>;
  features?: InputMaybe<CLEStringCollectionFilter>;
  fleetManagementAvailable?: InputMaybe<CLEBoolFilter>;
  fuelType?: InputMaybe<CLEStringFilter>;
  gearboxCode?: InputMaybe<CLEStringFilter>;
  gearboxType?: InputMaybe<CLEStringFilter>;
  id?: InputMaybe<CLEStringFilter>;
  marketCode?: InputMaybe<CLEStringFilter>;
  marketingCode?: InputMaybe<CLEStringFilter>;
  /** @deprecated Replaced by pricePerMarketMinimumGrossSubscriptionPriceB2C */
  minimumGrossSubscriptionPriceB2C?: InputMaybe<CLENumberRangeFilter>;
  /** @deprecated Replaced by pricePerMarketMinimumGrossSubscriptionPriceB2B */
  minimumNetSubscriptionPriceB2B?: InputMaybe<CLENumberRangeFilter>;
  modelCategory?: InputMaybe<CLEStringFilter>;
  modelCode?: InputMaybe<CLEStringFilter>;
  modelFamily?: InputMaybe<CLEStringFilter>;
  modelYear?: InputMaybe<CLEStringFilter>;
  /** @deprecated Replaced by pricePerMarketMsrpPrice */
  msrpPrice?: InputMaybe<CLENumberRangeFilter>;
  nedcCombinedCo2?: InputMaybe<CLENumberRangeFilter>;
  nedcElectricRange?: InputMaybe<CLENumberRangeFilter>;
  nedcEnergyConsumption?: InputMaybe<CLENumberRangeFilter>;
  nedcExurbanFuelConsumption?: InputMaybe<CLENumberRangeFilter>;
  nedcFuelConsumption?: InputMaybe<CLENumberRangeFilter>;
  nedcIsOfficial?: InputMaybe<CLEBoolFilter>;
  nedcUrbanFuelConsumption?: InputMaybe<CLENumberRangeFilter>;
  nedcWeightedCombinedCo2?: InputMaybe<CLENumberRangeFilter>;
  nedcWeightedCombinedFuelConsumption?: InputMaybe<CLENumberRangeFilter>;
  numberOfSeats?: InputMaybe<CLENumberRangeFilter>;
  numberOfSeatsCode?: InputMaybe<CLEStringFilter>;
  options?: InputMaybe<CLEStringCollectionFilter>;
  orderAvailableForUnconsentedTrade?: InputMaybe<CLEBoolFilter>;
  orderBrandStatusPoint?: InputMaybe<CLENumberRangeFilter>;
  orderCommonStatusPoint?: InputMaybe<CLENumberRangeFilter>;
  orderCustomerReferenceNumber?: InputMaybe<CLEStringFilter>;
  orderDealerOrderNumber?: InputMaybe<CLEStringFilter>;
  orderDeliveryDate?: InputMaybe<CLEDateFilter>;
  orderDestinationCode?: InputMaybe<CLEStringFilter>;
  orderDistributorId?: InputMaybe<CLEStringFilter>;
  orderFactoryCompleteDate?: InputMaybe<CLEDateFilter>;
  orderFyon?: InputMaybe<CLEStringFilter>;
  orderIntermediateDestination?: InputMaybe<CLEStringFilter>;
  orderLastDateForSpecChange?: InputMaybe<CLEDateFilter>;
  orderPendingTransfer?: InputMaybe<CLEBoolFilter>;
  orderPreliminaryReservationDealerId?: InputMaybe<CLEStringFilter>;
  orderVisibilityIndicator?: InputMaybe<CLEBoolFilter>;
  packages?: InputMaybe<CLEStringCollectionFilter>;
  pno12?: InputMaybe<CLEStringFilter>;
  pno34PlusOptions?: InputMaybe<CLEStringFilter>;
  /** @deprecated Replaced by pricePerMarketPreTaxAmount */
  preTaxAmount?: InputMaybe<CLENumberRangeFilter>;
  pricePerMarketBenefitInKindGrossAmount?: InputMaybe<CLENumberRangeFilter>;
  pricePerMarketCashPriceB2B?: InputMaybe<CLENumberRangeFilter>;
  pricePerMarketCashPriceB2C?: InputMaybe<CLENumberRangeFilter>;
  pricePerMarketMarketCode?: InputMaybe<Scalars['String']>;
  pricePerMarketMinimumGrossSubscriptionPriceB2C?: InputMaybe<CLENumberRangeFilter>;
  pricePerMarketMinimumNetSubscriptionPriceB2B?: InputMaybe<CLENumberRangeFilter>;
  pricePerMarketMsrpPrice?: InputMaybe<CLENumberRangeFilter>;
  pricePerMarketPreTaxAmount?: InputMaybe<CLENumberRangeFilter>;
  reservationReservedThrough?: InputMaybe<CLEStringFilter>;
  salesVersionCode?: InputMaybe<CLEStringFilter>;
  salesVersionName?: InputMaybe<CLEStringFilter>;
  salesVersionPnoCode?: InputMaybe<CLEStringFilter>;
  specialMessageCode?: InputMaybe<CLEStringFilter>;
  steeringCode?: InputMaybe<CLEStringFilter>;
  structureWeek?: InputMaybe<CLEStringFilter>;
  subFilter?: InputMaybe<CLECarSubFilter>;
  trimLevelCode?: InputMaybe<CLEStringFilter>;
  upholsteryCode?: InputMaybe<CLEStringFilter>;
  vin?: InputMaybe<CLEStringFilter>;
  visible?: InputMaybe<CLEBoolFilter>;
  weightActualMass?: InputMaybe<CLENumberRangeFilter>;
  weightMassInRunningOrderTotal?: InputMaybe<CLENumberRangeFilter>;
  weightTestMassIndividual?: InputMaybe<CLENumberRangeFilter>;
  wheelsCode?: InputMaybe<CLEStringFilter>;
  wltpCombinedCo2?: InputMaybe<CLENumberRangeFilter>;
  wltpElectricEnergyConsumption?: InputMaybe<CLENumberRangeFilter>;
  wltpElectricRange?: InputMaybe<CLENumberRangeFilter>;
  wltpExtraHighFuelConsumption?: InputMaybe<CLENumberRangeFilter>;
  wltpFuelConsumption?: InputMaybe<CLENumberRangeFilter>;
  wltpHighFuelConsumption?: InputMaybe<CLENumberRangeFilter>;
  wltpIsOfficial?: InputMaybe<CLEBoolFilter>;
  wltpLowFuelConsumption?: InputMaybe<CLENumberRangeFilter>;
  wltpMediumFuelConsumption?: InputMaybe<CLENumberRangeFilter>;
  wltpWeightedCombinedCo2?: InputMaybe<CLENumberRangeFilter>;
  wltpWeightedCombinedFuelConsumption?: InputMaybe<CLENumberRangeFilter>;
};

export type CLECarSubFilter = {
  not?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Array<CLECarFilterWithDistributionChannel>>;
};

export type CLEDateFilter = {
  not?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Array<CLEDateValue>>;
};

export type CLEDateFilterValue = {
  exactValue?: InputMaybe<Scalars['DateTime']>;
  max?: InputMaybe<Scalars['DateTime']>;
  min?: InputMaybe<Scalars['DateTime']>;
};

export type CLEDateValue = {
  exists?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<CLEDateFilterValue>;
};

export type CLEDistributionChannelTypeFilter = {
  not?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Array<CLEDistributionChannelTypeInput>>;
};

export type CLEDistributionChannelTypeInput = {
  exists?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<CleDistributionChannelTypeValue>;
};

export type CLENumberFilterValue = {
  exactValue?: InputMaybe<Scalars['Float']>;
  max?: InputMaybe<Scalars['Float']>;
  min?: InputMaybe<Scalars['Float']>;
};

export type CLENumberRangeFilter = {
  not?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Array<CLENumberValue>>;
};

export type CLENumberValue = {
  exists?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<CLENumberFilterValue>;
};

export type CLESearchFilter = {
  boost?: InputMaybe<CLEBoostValue>;
  excludeDuplicates?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CLECarFilter>;
  /** @deprecated Will automatically be set to true if aggregations are included in the query */
  includeAggregations?: InputMaybe<Scalars['Boolean']>;
  includeUnavailable?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<CLESortValue>>;
  take?: InputMaybe<Scalars['Float']>;
};

export type CLESearchFilterWithDistributionChannel = {
  boost?: InputMaybe<CLEBoostValueWithDistributionChannel>;
  excludeDuplicates?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CLECarFilterWithDistributionChannel>;
  /** @deprecated Will automatically be set to true if aggregations are included in the query */
  includeAggregations?: InputMaybe<Scalars['Boolean']>;
  includeUnavailable?: InputMaybe<Scalars['Boolean']>;
  languages?: InputMaybe<Array<Scalars['String']>>;
  skip?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<CLESortValue>>;
  take?: InputMaybe<Scalars['Float']>;
};

/** CLE sortable field names */
export enum CLESortFieldName {
  available = 'available',
  /** @deprecated Replaced by pricePerMarketBenefitInKindGrossAmount */
  benefitInKindGrossAmount = 'benefitInKindGrossAmount',
  /** @deprecated Replaced by pricePerMarketCashPriceB2B */
  cashPriceB2B = 'cashPriceB2B',
  /** @deprecated Replaced by pricePerMarketCashPriceB2C */
  cashPriceB2C = 'cashPriceB2C',
  demoCar = 'demoCar',
  energyEfficiencyClass = 'energyEfficiencyClass',
  /** @deprecated Replaced by pricePerMarketMinimumGrossSubscriptionPriceB2C */
  minimumGrossSubscriptionPriceB2C = 'minimumGrossSubscriptionPriceB2C',
  /** @deprecated Replaced by pricePerMarketMinimumNetSubscriptionPriceB2B */
  minimumNetSubscriptionPriceB2B = 'minimumNetSubscriptionPriceB2B',
  modelYear = 'modelYear',
  /** @deprecated Replaced by pricePerMarketMsrpPrice */
  msrpPrice = 'msrpPrice',
  numberOfSeats = 'numberOfSeats',
  orderBrandStatusPoint = 'orderBrandStatusPoint',
  orderCommonStatusPoint = 'orderCommonStatusPoint',
  orderDeliveryDate = 'orderDeliveryDate',
  orderFactoryCompleteDate = 'orderFactoryCompleteDate',
  orderVisibilityIndicator = 'orderVisibilityIndicator',
  /** @deprecated Replaced by pricePerMarketPreTaxAmount */
  preTaxAmount = 'preTaxAmount',
  pricePerMarketBenefitInKindGrossAmount = 'pricePerMarketBenefitInKindGrossAmount',
  pricePerMarketCashPriceB2B = 'pricePerMarketCashPriceB2B',
  pricePerMarketCashPriceB2C = 'pricePerMarketCashPriceB2C',
  pricePerMarketMinimumGrossSubscriptionPriceB2C = 'pricePerMarketMinimumGrossSubscriptionPriceB2C',
  pricePerMarketMinimumNetSubscriptionPriceB2B = 'pricePerMarketMinimumNetSubscriptionPriceB2B',
  pricePerMarketMsrpPrice = 'pricePerMarketMsrpPrice',
  pricePerMarketPreTaxAmount = 'pricePerMarketPreTaxAmount',
  relevanceScore = 'relevanceScore',
  reservationReservedAt = 'reservationReservedAt',
  structureWeek = 'structureWeek',
  technicalDataElectricRange = 'technicalDataElectricRange',
  visible = 'visible',
  weightActualMass = 'weightActualMass',
  wltpElectricRange = 'wltpElectricRange',
  wltpFuelConsumption = 'wltpFuelConsumption',
}

export type CLESortValue = {
  desc?: InputMaybe<Scalars['Boolean']>;
  field?: InputMaybe<CLESortFieldName>;
};

export type CLEStringCollectionFilter = {
  not?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Array<CLEStringCollectionValue>>;
};

export type CLEStringCollectionValue = {
  exists?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<CLEStringCollectionValues>;
};

export type CLEStringCollectionValues = {
  values?: InputMaybe<Array<CLEStringValue>>;
};

export type CLEStringFilter = {
  not?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Array<CLEStringValue>>;
};

export type CLEStringValue = {
  exists?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Scalars['String']>;
};

export enum CLIENT_CONFIGURATION_CHECKOUT_ENUM {
  careByVolvo = 'careByVolvo',
  default = 'default',
  privateLeasing = 'privateLeasing',
}

export type CLIENT_CONFIGURATION_INPUT = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export enum COMPONENT_BEHAVIOR {
  hideInPackage = 'hideInPackage',
  hideIncluded = 'hideIncluded',
  showAll = 'showAll',
}

export enum CUSTOMER_TYPE {
  b2b = 'b2b',
  b2c = 'b2c',
}

export enum CacheControlScope {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export enum CamshaftType {
  DOHC = 'DOHC',
  SOHC = 'SOHC',
}

export enum CarComponentType {
  BODY = 'BODY',
  COLOR = 'COLOR',
  DRIVELINE = 'DRIVELINE',
  ENGINE = 'ENGINE',
  FEATURE = 'FEATURE',
  GEARBOX = 'GEARBOX',
  MARKETING_CODE = 'MARKETING_CODE',
  MODEL_YEAR = 'MODEL_YEAR',
  /** @deprecated Offer configuration on car not supported */
  OFFER = 'OFFER',
  OPTION = 'OPTION',
  PACKAGE = 'PACKAGE',
  PNO3 = 'PNO3',
  SALES_VERSION = 'SALES_VERSION',
  SPEC_MARKET = 'SPEC_MARKET',
  STEERING = 'STEERING',
  STRUCTURE_WEEK = 'STRUCTURE_WEEK',
  S_MESSAGE = 'S_MESSAGE',
  UPHOLSTERY = 'UPHOLSTERY',
}

export type CarConfigurationSpecification = {
  color?: InputMaybe<Scalars['String']>;
  fixComponents?: InputMaybe<Scalars['Boolean']>;
  modelYear: Scalars['Int'];
  options?: InputMaybe<Array<Scalars['String']>>;
  packages?: InputMaybe<Array<Scalars['String']>>;
  pno12: Scalars['String'];
  sMessage?: InputMaybe<Scalars['String']>;
  specMarket: Scalars['String'];
  structureWeek?: InputMaybe<Scalars['String']>;
  upholstery?: InputMaybe<Scalars['String']>;
};

export type CarKeyInput = {
  body: Scalars['String'];
  carType?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  engine: Scalars['String'];
  gearbox: Scalars['String'];
  marketingCode: Scalars['String'];
  modelYear: Scalars['Float'];
  options?: InputMaybe<Array<Scalars['String']>>;
  packages?: InputMaybe<Array<Scalars['String']>>;
  /** @deprecated Use carType instead of pno3 */
  pno3?: InputMaybe<Scalars['String']>;
  sMessage?: InputMaybe<Scalars['String']>;
  salesVersion: Scalars['String'];
  specMarket: Scalars['String'];
  steering: Scalars['String'];
  structureWeek: Scalars['Float'];
  upholstery?: InputMaybe<Scalars['String']>;
};

export enum CarListGroupingType {
  BODY = 'BODY',
  CAR_TYPE = 'CAR_TYPE',
  COLOR = 'COLOR',
  COMMERCIAL_MODEL_YEAR = 'COMMERCIAL_MODEL_YEAR',
  DRIVE_TRAIN = 'DRIVE_TRAIN',
  DRIVE_TYPE = 'DRIVE_TYPE',
  EDITION = 'EDITION',
  ENGINE = 'ENGINE',
  ENGINE_TYPE = 'ENGINE_TYPE',
  FUEL_TYPE = 'FUEL_TYPE',
  GEARBOX = 'GEARBOX',
  MARKETING_CODE = 'MARKETING_CODE',
  MODEL = 'MODEL',
  MODEL_CATEGORY = 'MODEL_CATEGORY',
  MODEL_FAMILY = 'MODEL_FAMILY',
  MODEL_YEAR = 'MODEL_YEAR',
  NUMBER_OF_SEATS = 'NUMBER_OF_SEATS',
  /** @deprecated Use CAR_TYPE instead */
  PNO3 = 'PNO3',
  /** PNO12 */
  PRODUCT_CODE = 'PRODUCT_CODE',
  PRODUCT_LINE = 'PRODUCT_LINE',
  SALES_VERSION = 'SALES_VERSION',
  SPEC_MARKET = 'SPEC_MARKET',
  STEERING = 'STEERING',
  STRUCTURE_WEEK = 'STRUCTURE_WEEK',
  S_MESSAGE = 'S_MESSAGE',
  THEME = 'THEME',
  TRANSMISSION_TYPE = 'TRANSMISSION_TYPE',
  TRIM = 'TRIM',
  TRIM_FAMILY = 'TRIM_FAMILY',
  UPHOLSTERY = 'UPHOLSTERY',
}

export enum CarTypeCategory {
  CROSSOVER = 'CROSSOVER',
  ESTATE = 'ESTATE',
  MPV = 'MPV',
  SEDAN = 'SEDAN',
  SUV = 'SUV',
}

export enum CartCountryCode {
  DE = 'DE',
  GB = 'GB',
  NL = 'NL',
  NO = 'NO',
  SE = 'SE',
}

export enum CartCustomerType {
  INDIVIDUAL = 'INDIVIDUAL',
  ORGANIZATION = 'ORGANIZATION',
}

export type CartInput = {
  attributes?: InputMaybe<Array<AttributeInput>>;
  countryCode: CartCountryCode;
};

export type CartLineInput = {
  attributes?: InputMaybe<Array<AttributeInput>>;
  merchandiseId: Scalars['String'];
  quantity: Scalars['Int'];
  sellingPlanId: Scalars['String'];
};

export type CartLinesInput = {
  cartLines: Array<CartLineInput>;
};

export enum CartPartnerType {
  DELIVERY = 'DELIVERY',
  SALES_PARTNER = 'SALES_PARTNER',
}

export enum CartProductType {
  ACCESSORY = 'ACCESSORY',
  /** On PCAT side accessories grouped under BUNDLE product. For storefront-cart service the separate type is extracted for clarity */
  ACCESSORY_PACKAGE = 'ACCESSORY_PACKAGE',
  /** A group of mandatory products and choices */
  BUNDLE = 'BUNDLE',
  CAR = 'CAR',
  COMMERCIALITEM = 'COMMERCIALITEM',
  COMPONENT = 'COMPONENT',
  COMPONENTCONFIGURATION = 'COMPONENTCONFIGURATION',
  CONFIGURABLEPRODUCT = 'CONFIGURABLEPRODUCT',
  DOWNPAYMENT = 'DOWNPAYMENT',
  /** The services in the mileage with car */
  MILEAGE = 'MILEAGE',
  PRE_ORDER = 'PRE_ORDER',
  /** The services in the offer with a car */
  SOFTOFFER = 'SOFTOFFER',
  SOFTWARE = 'SOFTWARE',
  /** Product type for any product that the storefront-cart-service is not aware of */
  UNKNOWN = 'UNKNOWN',
}

export enum CartSalesModel {
  CASH = 'CASH',
  LEASE = 'LEASE',
  LOAN = 'LOAN',
  NONE = 'NONE',
  PRE_ORDER = 'PRE_ORDER',
  SUB = 'SUB',
  SUB_FIXED = 'SUB_FIXED',
}

export enum CartSalesPartnerType {
  LEASING_COMPANY = 'LEASING_COMPANY',
  RETAIL_ASSISTED = 'RETAIL_ASSISTED',
}

export enum CartStatus {
  CHECKED_OUT = 'CHECKED_OUT',
  DRAFT = 'DRAFT',
  EXPIRED = 'EXPIRED',
  WAITING_CUSTOMER_INPUT = 'WAITING_CUSTOMER_INPUT',
}

/** To select which CAS endpoint cache needs to be purged. */
export enum CasEndPoints {
  ALL = 'ALL',
  FYON = 'FYON',
  SPECIFICATION = 'SPECIFICATION',
  VIN = 'VIN',
}

export type CheckoutBuyerIdentityInput = {
  emails?: InputMaybe<Array<EmailInput>>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  roles?: InputMaybe<Array<LegalEntityRole>>;
};

export type CheckoutBuyerInput = {
  buyerIdentities?: InputMaybe<Array<CheckoutBuyerIdentityInput>>;
  countryCode: CartCountryCode;
  customerType: CartCustomerType;
};

export type CheckoutInput = {
  buyer?: InputMaybe<CheckoutBuyerInput>;
  partners?: InputMaybe<CheckoutPartnersInput>;
};

export type CheckoutPartnersInput = {
  deliveryPartner: DeliveryPartnerInput;
  salesPartner?: InputMaybe<SalesPartnerInput>;
};

export enum CheckoutStatus {
  AWAITING_CUSTOMER_INPUT = 'AWAITING_CUSTOMER_INPUT',
  AWAITING_PAYMENT_CONFIRMATION = 'AWAITING_PAYMENT_CONFIRMATION',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  RETRY = 'RETRY',
  STARTED = 'STARTED',
}

/**
 * StockCar - also known as WholesaleCar
 *
 *
 * OnlineInstantCar - also known as ConsignmentCar
 */
export enum CleDistributionChannelTypeValue {
  OnlineCar = 'OnlineCar',
  OnlineInstantCar = 'OnlineInstantCar',
  StockCar = 'StockCar',
  SubscriptionCar = 'SubscriptionCar',
}

export enum ColourType {
  EXCLUSIVE = 'EXCLUSIVE',
  MATTE = 'MATTE',
  METALLIC = 'METALLIC',
  SOLID = 'SOLID',
}

export enum ComponentBehavior {
  HIDE_INCLUDED = 'HIDE_INCLUDED',
  HIDE_IN_PACKAGE = 'HIDE_IN_PACKAGE',
  SHOW_ALL = 'SHOW_ALL',
  /** @deprecated Replaced with HIDE_IN_PACKAGE */
  hideInPackage = 'hideInPackage',
  /** @deprecated Replaced with HIDE_INCLUDED */
  hideIncluded = 'hideIncluded',
  /** @deprecated Replaced with SHOW_ALL */
  showAll = 'showAll',
}

/** Tags for car components */
export enum ComponentTag {
  ACCESSORY = 'ACCESSORY',
  DEFAULT = 'DEFAULT',
  HUNDRED_PERCENT = 'HUNDRED_PERCENT',
  LOCAL_OPTION = 'LOCAL_OPTION',
  NO_CO2AFFECT = 'NO_CO2AFFECT',
  PACKAGE_ONLY = 'PACKAGE_ONLY',
  SPECIAL_VEHICLE_ONLY = 'SPECIAL_VEHICLE_ONLY',
  STANDARD_FEATURE_WHEEL = 'STANDARD_FEATURE_WHEEL',
  STRUCTURE_WEEK_DIFF = 'STRUCTURE_WEEK_DIFF',
}

export enum ConfigurationSelectionStrategy {
  AllSelected = 'AllSelected',
  FullPackageContent = 'FullPackageContent',
  KeepAll = 'KeepAll',
  NoPackageContent = 'NoPackageContent',
}

/** ConfigurationWindow represents the different time windows for which configuration can take place. */
export enum ConfigurationWindow {
  All = 'All',
  Current = 'Current',
  Future = 'Future',
  Past = 'Past',
}

export type ConnectedVehicleServiceInput = {
  /** Enable or disable the privacy settings for the service */
  enabled: Scalars['Boolean'];
  /** Id of service to update */
  serviceId: Scalars['String'];
  /** Vehicle of service to update */
  vin: Scalars['String'];
};

export enum ConsentCategory {
  feedback = 'feedback',
  marketing = 'marketing',
}

export enum ConsentChannel {
  app = 'app',
  direct_mail = 'direct_mail',
  email = 'email',
  phone = 'phone',
  sms = 'sms',
  social = 'social',
}

export type ConsentInput = {
  category: ConsentCategory;
  channel: ConsentChannel;
  consented: Scalars['Boolean'];
};

export type ConsentsInput = {
  consents: Array<ConsentInput>;
};

export type DeliveryPartnerInput = {
  partnerCode: Scalars['String'];
};

export enum DriveType {
  AWD = 'AWD',
  E_AWD = 'E_AWD',
  FWD = 'FWD',
  RWD = 'RWD',
}

export enum ElectricRangeType {
  EXTENDED_RANGE = 'EXTENDED_RANGE',
  PERFORMANCE = 'PERFORMANCE',
  STANDARD_RANGE = 'STANDARD_RANGE',
}

export type EmailInput = {
  email: Scalars['String'];
  emailRoles?: InputMaybe<Array<LegalEntityEmailRole>>;
};

export enum EngineType {
  BEV = 'BEV',
  HEV = 'HEV',
  NHEV = 'NHEV',
  PHEV = 'PHEV',
}

export enum FuelType {
  DIESEL = 'DIESEL',
  PETROL = 'PETROL',
  PETROL_ELECTRIC = 'PETROL_ELECTRIC',
  PURE_ELECTRIC = 'PURE_ELECTRIC',
}

export enum Group {
  COLOR = 'COLOR',
  EXTERIOR = 'EXTERIOR',
  INTERIOR = 'INTERIOR',
  OPTIONS = 'OPTIONS',
  PACKAGES = 'PACKAGES',
  SUMMER_TYRES = 'SUMMER_TYRES',
  WHEELS = 'WHEELS',
  WINTER_WHEELS = 'WINTER_WHEELS',
}

export type InputOrderListInputParameters = {
  createdFrom?: InputMaybe<Scalars['OrderTracker__Long']>;
  createdTo?: InputMaybe<Scalars['OrderTracker__Long']>;
  fleetIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  guestAccessToken?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderId?: InputMaybe<Scalars['String']>;
  orderIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  orderStatuses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  salesTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  statusDelays?: InputMaybe<Array<InputMaybe<OrderTracker__InputStatusDelay>>>;
  visibleTo?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum LegalEntityAddressRole {
  BILLING = 'BILLING',
  GARAGING = 'GARAGING',
  MAILING = 'MAILING',
  SHIPPING = 'SHIPPING',
  UNKNOWN = 'UNKNOWN',
}

export enum LegalEntityConsentType {
  NO = 'NO',
  NOT_PROVIDED = 'NOT_PROVIDED',
  YES = 'YES',
}

export enum LegalEntityEmailRole {
  CONTACT = 'CONTACT',
  INVOICE = 'INVOICE',
}

export enum LegalEntityExternalIdentifierIdType {
  BANK_ID = 'BANK_ID',
  CDB_ID = 'CDB_ID',
  CDS_ID = 'CDS_ID',
  FLEET_ID = 'FLEET_ID',
  UNKNOWN = 'UNKNOWN',
  VOLVO_ID = 'VOLVO_ID',
}

export enum LegalEntityOptIn {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  POSTAL = 'POSTAL',
  SMS = 'SMS',
  SOCIAL_MEDIA = 'SOCIAL_MEDIA',
  VOLVO_MOBILE_APP = 'VOLVO_MOBILE_APP',
}

export enum LegalEntityPhoneRole {
  CONTACT = 'CONTACT',
  FAX = 'FAX',
  HOME = 'HOME',
  MOBILE = 'MOBILE',
  WORK = 'WORK',
}

export enum LegalEntityRole {
  AGENT = 'AGENT',
  APPROVER = 'APPROVER',
  BILL_TO = 'BILL_TO',
  CONTACT = 'CONTACT',
  OPERATOR = 'OPERATOR',
  PAYER = 'PAYER',
  PICKUP = 'PICKUP',
  SHIP_TO = 'SHIP_TO',
  SIGNATORY = 'SIGNATORY',
  SOLD_TO = 'SOLD_TO',
}

export enum MediaType {
  DOCUMENT = 'DOCUMENT',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}

export type MsrpPriceConfigurationInput = {
  locale?: InputMaybe<Scalars['String']>;
  /** The requested price date, used only to get prices in specific date (future or past) and should'nt be used to get today prices */
  priceDate?: InputMaybe<Scalars['DateTime']>;
  roundingStrategy?: InputMaybe<RoundingStrategy>;
  /** Parameters for taxations passed to URAX */
  taxParameterInput?: InputMaybe<TaxParameterInput>;
};

/** How to sort null values */
export enum NullSortingStrategy {
  PlaceFirst = 'PlaceFirst',
  PlaceLast = 'PlaceLast',
  Remove = 'Remove',
}

export enum OfferGroupType {
  CUSTOMER_TYPE = 'CUSTOMER_TYPE',
  SALES_MODEL = 'SALES_MODEL',
}

export enum OfferTag {
  FollowsCar = 'FollowsCar',
  FollowsCustomer = 'FollowsCustomer',
  PlusVSA = 'PlusVSA',
  StandardVSA = 'StandardVSA',
  UltraVSA = 'UltraVSA',
  VSA = 'VSA',
  /** @deprecated will not be supported in upcoming releases */
  VSAFixed = 'VSAFixed',
  VSAFixedTerm = 'VSAFixedTerm',
  /** @deprecated will not be supported in upcoming releases */
  VSAFlexible = 'VSAFlexible',
  VSAFlexibleTerm = 'VSAFlexibleTerm',
  /** @deprecated will not be supported in upcoming releases */
  VSARolling = 'VSARolling',
  VSARollingTerm = 'VSARollingTerm',
}

/** The type of option, reflecting where in the pipeline it is added to the car */
export enum OptionType {
  ACCESSORY = 'ACCESSORY',
  LOCAL_OPTION = 'LOCAL_OPTION',
  OPTION = 'OPTION',
}

export type OrderAmendInput = {
  modelYear: Scalars['Int'];
  orderNumber: Scalars['String'];
  pno34PlusOptions: Scalars['String'];
};

export type OrderConstraintInput = {
  brand: Scalars['String'];
  country: Scalars['String'];
  modelCode?: InputMaybe<Scalars['String']>;
  modelYear?: InputMaybe<Scalars['Int']>;
  orderType: Scalars['String'];
};

export enum OrderStatus {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  CANCEL_IN_PROGRESS = 'CANCEL_IN_PROGRESS',
  CHECKOUT = 'CHECKOUT',
  COMPLETED = 'COMPLETED',
  NEW = 'NEW',
}

export enum OrderTracker__AddressRole {
  /** BILLING */
  BILLING = 'BILLING',
  /** GARAGING */
  GARAGING = 'GARAGING',
  /** MAILING */
  MAILING = 'MAILING',
  /** SHIPPING */
  SHIPPING = 'SHIPPING',
  /** UNKNOWN */
  UNKNOWN = 'UNKNOWN',
}

export enum OrderTracker__CustomerType {
  /** INDIVIDUAL */
  INDIVIDUAL = 'INDIVIDUAL',
  /** ORGANIZATION */
  ORGANIZATION = 'ORGANIZATION',
}

export enum OrderTracker__EmailRole {
  /** CONTACT */
  CONTACT = 'CONTACT',
  /** INVOICE */
  INVOICE = 'INVOICE',
}

export enum OrderTracker__ExternalReferenceSystem {
  /** FLEET_PORTAL */
  FLEET_PORTAL = 'FLEET_PORTAL',
}

export enum OrderTracker__ExternalUserIdType {
  /** BANKID */
  BANKID = 'BANKID',
  /** CDSID */
  CDSID = 'CDSID',
  /** NONE */
  NONE = 'NONE',
}

export type OrderTracker__InputStatusDelay = {
  brandStatus?: InputMaybe<Scalars['Int']>;
  statusDelayByMilliseconds?: InputMaybe<Scalars['OrderTracker__Long']>;
};

export enum OrderTracker__LegalEntityType {
  /** NONE */
  NONE = 'NONE',
  /** ORGANIZATION */
  ORGANIZATION = 'ORGANIZATION',
  /** ORGANIZATION_UNIT */
  ORGANIZATION_UNIT = 'ORGANIZATION_UNIT',
  /** SELFEMPLOYED */
  SELFEMPLOYED = 'SELFEMPLOYED',
}

export enum OrderTracker__PhoneRole {
  /** CONTACT */
  CONTACT = 'CONTACT',
  /** FAX */
  FAX = 'FAX',
  /** HOME */
  HOME = 'HOME',
  /** MOBILE */
  MOBILE = 'MOBILE',
  /** WORK */
  WORK = 'WORK',
}

export enum OrderTracker__Role {
  /** AGENT */
  AGENT = 'AGENT',
  /** APPROVER */
  APPROVER = 'APPROVER',
  /** BILL_TO */
  BILL_TO = 'BILL_TO',
  /** CONTACT */
  CONTACT = 'CONTACT',
  /** OPERATOR */
  OPERATOR = 'OPERATOR',
  /** PAYER */
  PAYER = 'PAYER',
  /** PICKUP */
  PICKUP = 'PICKUP',
  /** SHIP_TO */
  SHIP_TO = 'SHIP_TO',
  /** SIGNATORY */
  SIGNATORY = 'SIGNATORY',
  /** SOLD_TO */
  SOLD_TO = 'SOLD_TO',
}

export enum OrderTracker__StepStatus {
  /** ACTIVE */
  ACTIVE = 'ACTIVE',
  /** COMPLETED */
  COMPLETED = 'COMPLETED',
  /** FAILED */
  FAILED = 'FAILED',
  /** NOT_STARTED */
  NOT_STARTED = 'NOT_STARTED',
  /** PENDING_COMPLETE */
  PENDING_COMPLETE = 'PENDING_COMPLETE',
}

export enum OrderTracker__VatRegistrationStatus {
  /** NOT_APPLICABLE */
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  /** NOT_REGISTERED */
  NOT_REGISTERED = 'NOT_REGISTERED',
  /** REGISTERED */
  REGISTERED = 'REGISTERED',
}

/** The sales model specified by the user when placing the order. */
export enum OrderTrackingSalesModel {
  CASH = 'CASH',
  SUB = 'SUB',
  SUB_FIXED = 'SUB_FIXED',
}

export type PARTNER_CONFIGURATION_INPUT = {
  /** Contains key like programMarket, brand, countryCode and partner */
  key?: InputMaybe<Scalars['String']>;
  /** Contains value of respective key */
  value?: InputMaybe<Scalars['String']>;
};

export enum PRICE_STRATEGY_SETTING {
  absolutePrice = 'absolutePrice',
  listPretaxPrice = 'listPretaxPrice',
  listPrice = 'listPrice',
  price = 'price',
  relativeBasePrice = 'relativeBasePrice',
  relativeBasePriceSplitTax = 'relativeBasePriceSplitTax',
  relativePretaxBasePrice = 'relativePretaxBasePrice',
  relativePrice = 'relativePrice',
  requiredChangesPretaxPrice = 'requiredChangesPretaxPrice',
  requiredChangesPrice = 'requiredChangesPrice',
  requiredChangesPriceSplitTax = 'requiredChangesPriceSplitTax',
}

export type PriceConfigurationInput = {
  /** The chosen contract length for monthly SalesModels. */
  contractLength?: InputMaybe<Scalars['Float']>;
  /** The chosen customer type. Defaults to B2C if not specified. */
  customerType?: InputMaybe<Scalars['String']>;
  /** The id of the discount plan to be applied */
  discountPlanId?: InputMaybe<Scalars['String']>;
  /** The chosen downpayment-amount. */
  downpayment?: InputMaybe<Scalars['Float']>;
  locale: Scalars['String'];
  /** The chosen mileage for monthly SalesModels. */
  mileage?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  priceComponents?: InputMaybe<Array<Scalars['String']>>;
  /** The requested price date, used only to get prices in specific date (future or past) and should'nt be used to get today prices */
  priceDate?: InputMaybe<Scalars['DateTime']>;
  priceDates?: InputMaybe<Array<TaxationInput>>;
  priceSelectorOverrides?: InputMaybe<PriceSelectorOverrides>;
  roundingStrategy?: InputMaybe<RoundingStrategy>;
  taxation?: InputMaybe<Array<TaxationInput>>;
  /** The requested taxation date */
  taxationDate?: InputMaybe<Scalars['DateTime']>;
  /** Apply internal automatic downpayment adjustment rules. Set this flag to true and omit the downpayment input value in order to internally use default downpayment configured for a market */
  useAutomaticDownpaymentAdjustment?: InputMaybe<Scalars['Boolean']>;
  variableReferences?: InputMaybe<Array<VariableReference>>;
};

export type PriceConfigurationInputV2 = {
  /** The id of the discount plan to be applied */
  discountPlanId?: InputMaybe<Scalars['String']>;
  /** The chosen downpayment-amount. */
  downpayment?: InputMaybe<Scalars['Float']>;
  locale?: InputMaybe<Scalars['String']>;
  /** The requested price date, used only to get prices in specific date (future or past) and should'nt be used to get today prices */
  priceDate?: InputMaybe<Scalars['DateTime']>;
  priceDates?: InputMaybe<Array<TaxationInput>>;
  roundingStrategy?: InputMaybe<RoundingStrategy>;
  /** Parameters for taxations passed to URAX */
  taxParameterInput?: InputMaybe<TaxParameterInput>;
  /** Apply internal automatic downpayment adjustment rules. Set this flag to true and omit the downpayment input value in order to internally use default downpayment configured for a market */
  useAutomaticDownpaymentAdjustment?: InputMaybe<Scalars['Boolean']>;
};

export type PriceListConfigurationInput = {
  /** The chosen customer type. Defaults to B2C if not specified. */
  customerType?: InputMaybe<Scalars['String']>;
  /** Locale. Used to determine market internally. Also used for display price formatting. */
  locale?: InputMaybe<Scalars['String']>;
  /** The requested price date, used only to get prices in specific date (future or past) and should'nt be used to get today prices */
  priceDate?: InputMaybe<Scalars['DateTime']>;
  /** Rounding strategy used for formatting display prices. Note that this only takes effect on display string, prices and calculations themselves are not affected */
  roundingStrategy?: InputMaybe<RoundingStrategy>;
  /** The chosen sales model. */
  salesModel?: InputMaybe<Scalars['String']>;
  /** Parameters for taxations passed to URAX */
  taxParameterInput?: InputMaybe<TaxParameterInput>;
};

export type PriceSelectorOverrides = {
  /** Valid values: listPrice, listPretaxPrice, relativeBasePrice, relativeBasePriceSplitTax, requiredChangesPrice, requiredChangesPriceSplitTax, relativePretaxBasePrice, requiredChangesPretaxPrice */
  color?: InputMaybe<Scalars['String']>;
  /** Valid values: price, relativePrice, relativePretaxPrice */
  driveLine?: InputMaybe<Scalars['String']>;
  /** Valid values: listPrice, listPretaxPrice, relativeBasePrice, relativeBasePriceSplitTax, requiredChangesPrice, requiredChangesPriceSplitTax, relativePretaxBasePrice, requiredChangesPretaxPrice */
  option?: InputMaybe<Scalars['String']>;
  /** Valid values: listPrice, listPretaxPrice, relativeBasePrice, relativeBasePriceSplitTax, requiredChangesPrice, requiredChangesPriceSplitTax, relativePretaxBasePrice, requiredChangesPretaxPrice */
  package?: InputMaybe<Scalars['String']>;
  /** Valid values: price, relativePrice, relativePretaxPrice */
  salesVersion?: InputMaybe<Scalars['String']>;
  /** Valid values: price, relativePrice, relativePretaxPrice */
  seats?: InputMaybe<Scalars['String']>;
  /** Valid values: listPrice, listPretaxPrice, relativeBasePrice, relativeBasePriceSplitTax, requiredChangesPrice, requiredChangesPriceSplitTax, relativePretaxBasePrice, requiredChangesPretaxPrice */
  upholstery?: InputMaybe<Scalars['String']>;
};

export type ProductFilterInput = {
  /** The product types to filter on. */
  productTypes?: InputMaybe<Array<Scalars['String']>>;
};

export type ProductPaginationInput = {
  /** How many products to fetch */
  limit: Scalars['Int'];
  /** The offset to start from */
  offset: Scalars['Int'];
};

export enum ProductSource {
  ASSORTMENT = 'ASSORTMENT',
  PRODUCT_CATALOG = 'PRODUCT_CATALOG',
}

export enum ProductType {
  Accessory = 'Accessory',
  Package = 'Package',
  Service = 'Service',
  SoftwareOption = 'SoftwareOption',
  WinterWheels = 'WinterWheels',
}

export enum ROUNDING_STRATEGY {
  alwaysDecimals = 'alwaysDecimals',
  alwaysRound = 'alwaysRound',
  decimalsIfNeeded = 'decimalsIfNeeded',
  roundToNearestTen = 'roundToNearestTen',
}

/** What rounding strategy to use whn rounding prices */
export enum RoundingStrategy {
  alwaysDecimals = 'alwaysDecimals',
  alwaysRound = 'alwaysRound',
  decimalsIfNeeded = 'decimalsIfNeeded',
  noRounding = 'noRounding',
  roundToNearestTen = 'roundToNearestTen',
}

export type SafetyRatingInput = {
  locale?: InputMaybe<Scalars['String']>;
  modelYear: Scalars['Int'];
  pno12: Scalars['String'];
  published: Scalars['Boolean'];
  specificationMarket: Scalars['String'];
};

export type SalesPartnerInput = {
  assistingSalesConsultantId?: InputMaybe<Scalars['String']>;
  opportunityId?: InputMaybe<Scalars['String']>;
  partnerCode: Scalars['String'];
};

export type SelectedPaymentOption = {
  /** The price amount to be checked against the policy for a chosen sales model at the moment when the customer ordered the car */
  price: Scalars['Float'];
  /** The sales model (cash, subscription, fixed subscription) chosen by the customer when ordering the car */
  salesModel: OrderTrackingSalesModel;
};

export type StockCarOptionsInput = {
  /** Fetch only demo cars, i.e. cars available for test drive (using this flag ignores the orderCommonStatusPoint option) */
  demoCar?: InputMaybe<Scalars['Boolean']>;
  excludeDuplicates?: Scalars['Boolean'];
  /** The market, e.g. `'SE'` */
  marketCode: Scalars['String'];
  orderCommonStatusPoint?: StockCarOptionsOrderCommonStatusPointInput;
};

export type StockCarOptionsOrderCommonStatusPointInput = {
  max: Scalars['Int'];
  min: Scalars['Int'];
};

export enum SubscriptionStatus {
  CANCELED = 'CANCELED',
  INITIATED = 'INITIATED',
  PAUSED = 'PAUSED',
  RUNNING = 'RUNNING',
  SUSPENDED = 'SUSPENDED',
  TERMINATED = 'TERMINATED',
}

export enum SubscriptionType {
  FIXED = 'FIXED',
  FLEX = 'FLEX',
}

export type TaxParameterInput = {
  city?: InputMaybe<Scalars['String']>;
  companyCarMileage?: InputMaybe<Scalars['Float']>;
  county?: InputMaybe<Scalars['String']>;
  personalisedNumberPlates?: InputMaybe<Scalars['Boolean']>;
  postcode?: InputMaybe<Scalars['String']>;
  salary?: InputMaybe<Scalars['Float']>;
  state?: InputMaybe<Scalars['String']>;
  taxRate?: InputMaybe<Scalars['Float']>;
  taxTerritory?: InputMaybe<Scalars['String']>;
  taxationDate?: InputMaybe<Scalars['DateTime']>;
  taxationDates?: InputMaybe<Array<TaxationInput>>;
};

export type TaxationInput = {
  carType?: InputMaybe<Scalars['String']>;
  date: Scalars['String'];
  engine?: InputMaybe<Scalars['String']>;
  modelYear?: InputMaybe<Scalars['String']>;
};

/** Decides the structure week to use when configuring based on a token */
export enum TokenStructureWeekStrategy {
  /** Use the current structure week */
  CURRENT = 'CURRENT',
  /** Use the structure week on the passed in token */
  FROM_TOKEN = 'FROM_TOKEN',
}

export enum TransmissionType {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL',
}

export enum UnitSystem {
  IMPERIAL = 'IMPERIAL',
  METRIC = 'METRIC',
}

export type UpdateCartLineInput = {
  attributes?: InputMaybe<Array<AttributeInput>>;
  quantity: Scalars['Int'];
};

export enum UpholsteryBaseColor {
  AMBER = 'AMBER',
  BLOND = 'BLOND',
  BLUE = 'BLUE',
  CARDAMOM = 'CARDAMOM',
  CHARCOAL = 'CHARCOAL',
  DAWN = 'DAWN',
  FOG = 'FOG',
  GREY_MELANGE = 'GREY_MELANGE',
  INDIGO = 'INDIGO',
  MAROON = 'MAROON',
  MIST = 'MIST',
  MULBERRY = 'MULBERRY',
  PINE = 'PINE',
  RED = 'RED',
  SLATE = 'SLATE',
  ZINC = 'ZINC',
}

export enum UpholsteryMaterial {
  CONNECT_TEXTILE_MICROTECH = 'CONNECT_TEXTILE_MICROTECH',
  FUSION_MICROTECH_TEXTILE = 'FUSION_MICROTECH_TEXTILE',
  LEATHER = 'LEATHER',
  LEATHERETTE = 'LEATHERETTE',
  MICROTECH_TEXTILE = 'MICROTECH_TEXTILE',
  NAPPA_LEATHER = 'NAPPA_LEATHER',
  NAPPA_LEATHER_LEATHERETTE_TEXTILE = 'NAPPA_LEATHER_LEATHERETTE_TEXTILE',
  NAPPA_LEATHER_PERFORATED_NAPPA_LEATHER = 'NAPPA_LEATHER_PERFORATED_NAPPA_LEATHER',
  NAPPA_LEATHER_TEXTILE = 'NAPPA_LEATHER_TEXTILE',
  NORDICO = 'NORDICO',
  NUBUCK_TEXTILE_NAPPA_LEATHER = 'NUBUCK_TEXTILE_NAPPA_LEATHER',
  NUBUCK_TEXTILE_NAPPA_LEATHER_LEATHERETTE = 'NUBUCK_TEXTILE_NAPPA_LEATHER_LEATHERETTE',
  PIXEL_KNIT_NORDICO = 'PIXEL_KNIT_NORDICO',
  PREMIUM_TEXTILE = 'PREMIUM_TEXTILE',
  TEXTILE = 'TEXTILE',
  TEXTILE_BLEND = 'TEXTILE_BLEND',
  TEXTILE_NORDICO = 'TEXTILE_NORDICO',
  TEXTILE_VINYL = 'TEXTILE_VINYL',
  VENTILATED_NAPPA_LEATHER = 'VENTILATED_NAPPA_LEATHER',
  VENTILATED_NORDICO = 'VENTILATED_NORDICO',
  WOOL_BLEND = 'WOOL_BLEND',
  WOOL_BLEND_NORDICO = 'WOOL_BLEND_NORDICO',
}

export enum UpstreamEntityType {
  ACCESSORY = 'ACCESSORY',
  BODY = 'BODY',
  COLOUR = 'COLOUR',
  ENGINE = 'ENGINE',
  FEATURE = 'FEATURE',
  GEARBOX = 'GEARBOX',
  LOCAL_OPTION = 'LOCAL_OPTION',
  MARKETING_CODE = 'MARKETING_CODE',
  MODEL = 'MODEL',
  OPTION = 'OPTION',
  PACKAGE = 'PACKAGE',
  PNO12 = 'PNO12',
  SALES_VERSION = 'SALES_VERSION',
  SMESSAGE = 'SMESSAGE',
  STEERING = 'STEERING',
  UPHOLSTERY = 'UPHOLSTERY',
}

export type VariableReference = {
  name: Scalars['String'];
  value: Scalars['String'];
};

/** Mutable fields on the configuration object */
export type WishlistConfigurationInput = {
  cbv: Scalars['Boolean'];
  downPayment?: InputMaybe<Scalars['Int']>;
  meta?: InputMaybe<Scalars['ConfigurationMeta']>;
  modelSlug: Scalars['String'];
  modelYear: Scalars['String'];
  siteSlug: Scalars['String'];
  token: Scalars['String'];
};

export type CarsByPolicyQueryVariables = Exact<{
  subPolicy: Scalars['String'];
  cashPolicy: Scalars['String'];
  locale: Scalars['String'];
  customerType: Scalars['String'];
  priceDates?: InputMaybe<Array<TaxationInput> | TaxationInput>;
  taxation?: InputMaybe<Array<TaxationInput> | TaxationInput>;
  skip: Scalars['Boolean'];
}>;

export type CarsByPolicyQuery = {
  __typename?: 'Query';
  subCarsByPolicy: {
    __typename?: 'CarList';
    modelFamilies: Array<{
      __typename?: 'GroupedCarList';
      id: string;
      models: Array<{
        __typename?: 'GroupedCarList';
        car?: {
          __typename?: 'Car';
          configuration?: {
            __typename?: 'CarConfiguration';
            colors: Array<{
              __typename?: 'CarComponent';
              code: string;
              configurations?: Array<{
                __typename?: 'CarConfiguration';
                car?: {
                  __typename?: 'Car';
                  visualizations?: Array<{
                    __typename?: 'Visualization';
                    views: {
                      __typename?: 'Views';
                      exterior: {
                        __typename?: 'ExteriorViewGroup';
                        studio?: {
                          __typename?: 'ExteriorStudioViewGroup';
                          left?: {
                            __typename?: 'ImageGroup';
                            sizes?: {
                              __typename?: 'ImageSizes';
                              small?: {
                                __typename?: 'Image';
                                url?: string | null;
                              } | null;
                            } | null;
                          } | null;
                          threeQuartersFrontLeft?: {
                            __typename?: 'ImageGroup';
                            sizes?: {
                              __typename?: 'ImageSizes';
                              medium?: {
                                __typename?: 'Image';
                                url?: string | null;
                              } | null;
                            } | null;
                          } | null;
                        } | null;
                      };
                    };
                  }> | null;
                } | null;
              }> | null;
            }>;
          } | null;
        } | null;
        sub: {
          __typename?: 'CarList';
          car?: {
            __typename?: 'Car';
            priceSummary?: {
              __typename?: 'CarPriceSummary';
              price?: {
                __typename?: 'PriceAmount';
                amount?: number | null;
                display?: string | null;
              } | null;
            } | null;
            model?: {
              __typename?: 'SalesLevelContent';
              key: string;
              displayName?: {
                __typename?: 'StringProperty';
                value?: string | null;
              } | null;
            } | null;
            modelFamily?: {
              __typename?: 'SalesLevelContent';
              key: string;
              displayName?: {
                __typename?: 'StringProperty';
                value?: string | null;
              } | null;
            } | null;
            engine?: {
              __typename?: 'Engine';
              content?: {
                __typename?: 'EngineContent';
                engineType?: {
                  __typename?: 'EngineTypeProperty';
                  value?: EngineType | null;
                } | null;
              } | null;
            } | null;
            driveline?: {
              __typename?: 'Driveline';
              content?: {
                __typename?: 'DrivelineContent';
                displayName?: {
                  __typename?: 'StringProperty';
                  value?: string | null;
                } | null;
              } | null;
            } | null;
            visualizations?: Array<{
              __typename?: 'Visualization';
              views: {
                __typename?: 'Views';
                exterior: {
                  __typename?: 'ExteriorViewGroup';
                  studio?: {
                    __typename?: 'ExteriorStudioViewGroup';
                    left?: {
                      __typename?: 'ImageGroup';
                      sizes?: {
                        __typename?: 'ImageSizes';
                        small?: {
                          __typename?: 'Image';
                          url?: string | null;
                        } | null;
                      } | null;
                    } | null;
                    threeQuartersFrontLeft?: {
                      __typename?: 'ImageGroup';
                      sizes?: {
                        __typename?: 'ImageSizes';
                        medium?: {
                          __typename?: 'Image';
                          url?: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                };
              };
            }> | null;
            technicalData?: {
              __typename?: 'TechnicalData';
              cargoCapacity?: {
                __typename?: 'TechnicalDataComponentV2';
                formatted: string;
                label?: string | null;
                key: string;
              } | null;
              fuelConsumptionWltpTotal?: {
                __typename?: 'TechnicalDataComponentV2';
                formatted: string;
                label?: string | null;
                key: string;
              } | null;
              co2EmissionsWltpTotal?: {
                __typename?: 'TechnicalDataComponentV2';
                formatted: string;
                key: string;
                label?: string | null;
              } | null;
              electricEnergyConsumptionWltpTotal?: {
                __typename?: 'TechnicalDataComponentV2';
                formatted: string;
                key: string;
                label?: string | null;
              } | null;
              electricRangeWltpTotal?: {
                __typename?: 'TechnicalDataComponentV2';
                formatted: string;
                key: string;
                label?: string | null;
              } | null;
              wltpStatus?: {
                __typename?: 'TechnicalDataComponentV2';
                formatted: string;
                key: string;
                label?: string | null;
              } | null;
            } | null;
            carType?: {
              __typename?: 'CarType';
              content?: {
                __typename?: 'CarTypeContent';
                seatCapacity?: {
                  __typename?: 'IntProperty';
                  formatted?: string | null;
                } | null;
                carTypeCategory?: {
                  __typename?: 'CarTypeCategoryProperty';
                  formatted?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        };
        subFixed: {
          __typename?: 'CarList';
          car?: {
            __typename?: 'Car';
            priceSummary?: {
              __typename?: 'CarPriceSummary';
              subscriptionPrices?: Array<{
                __typename?: 'CarSubscriptionPrice';
                contractLength?: number | null;
                active: boolean;
              }> | null;
              price?: {
                __typename?: 'PriceAmount';
                amount?: number | null;
                display?: string | null;
              } | null;
            } | null;
          } | null;
        };
      }>;
    }>;
  };
  cashCarsByPolicy: {
    __typename?: 'CarList';
    modelFamilies: Array<{
      __typename?: 'GroupedCarList';
      id: string;
      models: Array<{
        __typename?: 'GroupedCarList';
        cash: {
          __typename?: 'CarList';
          car?: {
            __typename?: 'Car';
            model?: { __typename?: 'SalesLevelContent'; key: string } | null;
            priceSummary?: {
              __typename?: 'CarPriceSummary';
              price?: {
                __typename?: 'PriceAmount';
                amount?: number | null;
                display?: string | null;
              } | null;
            } | null;
          } | null;
        };
      }>;
    }>;
  };
};

export type ConfigurationQueryVariables = Exact<{
  url?: InputMaybe<Scalars['String']>;
}>;

export type ConfigurationQuery = {
  __typename?: 'Query';
  clientConfigurationQuery?: {
    __typename?: 'CLIENT_CONFIGURATION_QUERY';
    configuration?: {
      __typename?: 'CLIENT_CONFIGURATION';
      locale: string;
      salesModelSettings?: Array<{
        __typename?: 'SALES_MODEL_SETTING';
        policy?: string | null;
        salesModel?: string | null;
        clients?: Array<string | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type PriceSummaryFieldsFragment = {
  __typename?: 'CarPriceSummary';
  price?: {
    __typename?: 'PriceAmount';
    amount?: number | null;
    display?: string | null;
  } | null;
};

export type VisualizationsFragment = {
  __typename?: 'Visualization';
  views: {
    __typename?: 'Views';
    exterior: {
      __typename?: 'ExteriorViewGroup';
      studio?: {
        __typename?: 'ExteriorStudioViewGroup';
        left?: {
          __typename?: 'ImageGroup';
          sizes?: {
            __typename?: 'ImageSizes';
            small?: { __typename?: 'Image'; url?: string | null } | null;
          } | null;
        } | null;
        threeQuartersFrontLeft?: {
          __typename?: 'ImageGroup';
          sizes?: {
            __typename?: 'ImageSizes';
            medium?: { __typename?: 'Image'; url?: string | null } | null;
          } | null;
        } | null;
      } | null;
    };
  };
};

export type SharedCarFieldsFragment = {
  __typename?: 'Car';
  model?: {
    __typename?: 'SalesLevelContent';
    key: string;
    displayName?: {
      __typename?: 'StringProperty';
      value?: string | null;
    } | null;
  } | null;
  modelFamily?: {
    __typename?: 'SalesLevelContent';
    key: string;
    displayName?: {
      __typename?: 'StringProperty';
      value?: string | null;
    } | null;
  } | null;
  engine?: {
    __typename?: 'Engine';
    content?: {
      __typename?: 'EngineContent';
      engineType?: {
        __typename?: 'EngineTypeProperty';
        value?: EngineType | null;
      } | null;
    } | null;
  } | null;
  driveline?: {
    __typename?: 'Driveline';
    content?: {
      __typename?: 'DrivelineContent';
      displayName?: {
        __typename?: 'StringProperty';
        value?: string | null;
      } | null;
    } | null;
  } | null;
  visualizations?: Array<{
    __typename?: 'Visualization';
    views: {
      __typename?: 'Views';
      exterior: {
        __typename?: 'ExteriorViewGroup';
        studio?: {
          __typename?: 'ExteriorStudioViewGroup';
          left?: {
            __typename?: 'ImageGroup';
            sizes?: {
              __typename?: 'ImageSizes';
              small?: { __typename?: 'Image'; url?: string | null } | null;
            } | null;
          } | null;
          threeQuartersFrontLeft?: {
            __typename?: 'ImageGroup';
            sizes?: {
              __typename?: 'ImageSizes';
              medium?: { __typename?: 'Image'; url?: string | null } | null;
            } | null;
          } | null;
        } | null;
      };
    };
  }> | null;
  technicalData?: {
    __typename?: 'TechnicalData';
    cargoCapacity?: {
      __typename?: 'TechnicalDataComponentV2';
      formatted: string;
      label?: string | null;
      key: string;
    } | null;
    fuelConsumptionWltpTotal?: {
      __typename?: 'TechnicalDataComponentV2';
      formatted: string;
      label?: string | null;
      key: string;
    } | null;
    co2EmissionsWltpTotal?: {
      __typename?: 'TechnicalDataComponentV2';
      formatted: string;
      key: string;
      label?: string | null;
    } | null;
    electricEnergyConsumptionWltpTotal?: {
      __typename?: 'TechnicalDataComponentV2';
      formatted: string;
      key: string;
      label?: string | null;
    } | null;
    electricRangeWltpTotal?: {
      __typename?: 'TechnicalDataComponentV2';
      formatted: string;
      key: string;
      label?: string | null;
    } | null;
    wltpStatus?: {
      __typename?: 'TechnicalDataComponentV2';
      formatted: string;
      key: string;
      label?: string | null;
    } | null;
  } | null;
  carType?: {
    __typename?: 'CarType';
    content?: {
      __typename?: 'CarTypeContent';
      seatCapacity?: {
        __typename?: 'IntProperty';
        formatted?: string | null;
      } | null;
      carTypeCategory?: {
        __typename?: 'CarTypeCategoryProperty';
        formatted?: string | null;
      } | null;
    } | null;
  } | null;
};

export type StockSubscriptionCarsQueryVariables = Exact<{
  locale: Scalars['String'];
  marketCode: Scalars['String'];
  customerType: Scalars['String'];
  priceDates?: InputMaybe<Array<TaxationInput> | TaxationInput>;
  taxation?: InputMaybe<Array<TaxationInput> | TaxationInput>;
  skipSubFixed: Scalars['Boolean'];
  orderCommonStatusPointMinValue?: InputMaybe<Scalars['Float']>;
}>;

export type StockSubscriptionCarsQuery = {
  __typename?: 'Query';
  stockCars: {
    __typename?: 'CLEResponse';
    hits: Array<{
      __typename?: 'StockVehicleHit';
      vehicle: {
        __typename?: 'StockVehicle';
        specification?: {
          __typename?: 'Car';
          sub?: {
            __typename?: 'CarPriceSummary';
            price?: {
              __typename?: 'PriceAmount';
              amount?: number | null;
              display?: string | null;
            } | null;
          } | null;
          subFixed?: {
            __typename?: 'CarPriceSummary';
            subscriptionPrices?: Array<{
              __typename?: 'CarSubscriptionPrice';
              contractLength?: number | null;
              active: boolean;
            }> | null;
            price?: {
              __typename?: 'PriceAmount';
              amount?: number | null;
              display?: string | null;
            } | null;
          } | null;
          cash?: {
            __typename?: 'CarPriceSummary';
            price?: {
              __typename?: 'PriceAmount';
              amount?: number | null;
              display?: string | null;
            } | null;
          } | null;
          model?: {
            __typename?: 'SalesLevelContent';
            key: string;
            displayName?: {
              __typename?: 'StringProperty';
              value?: string | null;
            } | null;
          } | null;
          modelFamily?: {
            __typename?: 'SalesLevelContent';
            key: string;
            displayName?: {
              __typename?: 'StringProperty';
              value?: string | null;
            } | null;
          } | null;
          engine?: {
            __typename?: 'Engine';
            content?: {
              __typename?: 'EngineContent';
              engineType?: {
                __typename?: 'EngineTypeProperty';
                value?: EngineType | null;
              } | null;
            } | null;
          } | null;
          driveline?: {
            __typename?: 'Driveline';
            content?: {
              __typename?: 'DrivelineContent';
              displayName?: {
                __typename?: 'StringProperty';
                value?: string | null;
              } | null;
            } | null;
          } | null;
          visualizations?: Array<{
            __typename?: 'Visualization';
            views: {
              __typename?: 'Views';
              exterior: {
                __typename?: 'ExteriorViewGroup';
                studio?: {
                  __typename?: 'ExteriorStudioViewGroup';
                  left?: {
                    __typename?: 'ImageGroup';
                    sizes?: {
                      __typename?: 'ImageSizes';
                      small?: {
                        __typename?: 'Image';
                        url?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  threeQuartersFrontLeft?: {
                    __typename?: 'ImageGroup';
                    sizes?: {
                      __typename?: 'ImageSizes';
                      medium?: {
                        __typename?: 'Image';
                        url?: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              };
            };
          }> | null;
          technicalData?: {
            __typename?: 'TechnicalData';
            cargoCapacity?: {
              __typename?: 'TechnicalDataComponentV2';
              formatted: string;
              label?: string | null;
              key: string;
            } | null;
            fuelConsumptionWltpTotal?: {
              __typename?: 'TechnicalDataComponentV2';
              formatted: string;
              label?: string | null;
              key: string;
            } | null;
            co2EmissionsWltpTotal?: {
              __typename?: 'TechnicalDataComponentV2';
              formatted: string;
              key: string;
              label?: string | null;
            } | null;
            electricEnergyConsumptionWltpTotal?: {
              __typename?: 'TechnicalDataComponentV2';
              formatted: string;
              key: string;
              label?: string | null;
            } | null;
            electricRangeWltpTotal?: {
              __typename?: 'TechnicalDataComponentV2';
              formatted: string;
              key: string;
              label?: string | null;
            } | null;
            wltpStatus?: {
              __typename?: 'TechnicalDataComponentV2';
              formatted: string;
              key: string;
              label?: string | null;
            } | null;
          } | null;
          carType?: {
            __typename?: 'CarType';
            content?: {
              __typename?: 'CarTypeContent';
              seatCapacity?: {
                __typename?: 'IntProperty';
                formatted?: string | null;
              } | null;
              carTypeCategory?: {
                __typename?: 'CarTypeCategoryProperty';
                formatted?: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      };
    }>;
  };
};
