'use client';
import React from 'react';

import {
  FlexFormFieldType,
  TranslationKey,
} from '@vcc-package/leads-utils/api';
import { getInputErrorMessages } from '@vcc-package/leads-utils/hooks';
import type { TitleFieldProps } from '@vcc-package/leads-utils/types';
import { getTranslationKey } from '@vcc-package/leads-utils/util';
import { useFormContext } from 'react-hook-form';

import { RadioField } from '../components';

export const ConditionOfCar = ({
  translate,
  validation,
  options,
  hideRequired,
  customTranslation,
}: TitleFieldProps): JSX.Element | null => {
  const { control } = useFormContext();
  const messages = getInputErrorMessages(translate);
  const customTranslationKeys = getTranslationKey(
    customTranslation,
    FlexFormFieldType.CONDITION_OF_CAR,
  );
  const validationConditionOfCar =
    validation[FlexFormFieldType.CONDITION_OF_CAR.toLowerCase()];

  const translatedOptions = options.ConditionOfCar.map((option) => ({
    label: translate(option.label),
    value: option.value,
  }));

  return (
    <RadioField
      required={hideRequired}
      key={FlexFormFieldType.CONDITION_OF_CAR}
      name={FlexFormFieldType.CONDITION_OF_CAR}
      label={
        customTranslationKeys
          ? translate(customTranslationKeys.label)
          : translate(TranslationKey.CONDITION_OF_CAR)
      }
      messages={messages}
      control={control}
      options={translatedOptions}
      showErrorText
      {...validationConditionOfCar}
    />
  );
};
