import React, { useState, useEffect } from 'react';
import { Transition } from 'react-transition-group';
import { AspectRatio } from '@vcc-www/utils/getAspectRatioCSS';
import VideoBlock from '../VideoBlock';
import { VideoPosterReadyProvider } from './VideoPosterReadyProvider';

type Props = {
  inView: boolean;
  inViewRef: React.MutableRefObject<HTMLDivElement | null>;
  lazyLoadChildren?: boolean;
  responsivePoster: string;
  aspectRatio?: AspectRatio;
  posterReadyInit?: boolean;
  children: React.ReactNode;
};

const transitionStyles: { [id: string]: React.CSSProperties } = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

const VideoLazyContainer: React.FC<React.PropsWithChildren<Props>> = ({
  responsivePoster,
  lazyLoadChildren = false,
  inView,
  posterReadyInit = false,
  inViewRef,
  aspectRatio,
  children,
}) => {
  const [posterReady, setPosterReady] = useState(posterReadyInit);

  useEffect(() => {
    if (!posterReady && inView && responsivePoster) {
      const poster: HTMLImageElement = new Image();
      poster.onload = function () {
        setPosterReady(true);
      };
      poster.src = responsivePoster;
    }
  }, [inView, responsivePoster, posterReady]);

  return (
    <VideoBlock inViewRef={inViewRef} aspectRatio={aspectRatio}>
      <Transition in={posterReady} timeout={350}>
        {(state) => (
          <div
            style={{
              transition: 'opacity 350ms',
              willChange: 'opacity',
              ...transitionStyles[state],
            }}
          >
            <VideoPosterReadyProvider value={posterReady}>
              {lazyLoadChildren ? posterReady && children : children}
            </VideoPosterReadyProvider>
          </div>
        )}
      </Transition>
    </VideoBlock>
  );
};

export default VideoLazyContainer;
