export const getPredictionDescriptionAsHTML = (
  predictionItem: google.maps.places.AutocompletePrediction,
) => {
  const originalText = predictionItem.description;
  const matchedSubstrings = predictionItem.matched_substrings;

  let textAsHTMLString = predictionItem.description;

  matchedSubstrings.forEach((substringItem) => {
    const matchedSubstringText = originalText.substring(
      substringItem.offset,
      substringItem.length + substringItem.offset,
    );
    textAsHTMLString = textAsHTMLString.replace(
      matchedSubstringText,
      '<strong>' + matchedSubstringText + '</strong>',
    );
  });
  return textAsHTMLString;
};
