'use client';
import React, {
  type Dispatch,
  type SetStateAction,
  createContext,
  useState,
} from 'react';

export const DialogNavigationContext = createContext<{
  stack: string[];
  navigate: (path: string) => void;
  goBack: () => void;
  goHome: () => void;
  setTitle: Dispatch<SetStateAction<string | undefined>>;
  currentPage: string;
  previousPage?: string;
  dirty: boolean;
  title?: string;
}>({
  stack: [],
  navigate: () => {},
  goBack: () => {},
  goHome: () => {},
  setTitle: () => {},
  currentPage: '/',
  previousPage: undefined,
  dirty: false,
  title: undefined,
});

/**
 * Provides navigation functionality for dialog components.
 *
 * @component
 * @example
 * ```tsx
 * <DialogNavigationProvider elementRef={dialogRef}>
 *   {/* Dialog content goes here * /}
 * </DialogNavigationProvider>
 * ```
 *
 * @param elementRef - A React ref object for the dialog element.
 * @returns A React component that wraps the provided children with navigation context.
 */
export const DialogNavigationProvider: React.FC<
  React.PropsWithChildren<{ elementRef: React.RefObject<HTMLDialogElement> }>
> = ({ elementRef, children }) => {
  const [stack, setStack] = useState<string[]>(['/']);
  const [currentPage, setCurrentPage] = useState<string>('/');
  const [previousPage, setPreviousPage] = useState<string | undefined>();
  const [dirty, setDirty] = useState(false);
  const [title, setTitle] = useState<string | undefined>();

  const scrollToTop = () => {
    if (elementRef?.current) {
      elementRef.current.scrollTop = 0;
    }
  };
  const navigate = (path: string) => {
    scrollToTop();
    setDirty(true);
    setStack((prevStack) => {
      if (path === prevStack[prevStack.length - 1]) {
        return prevStack;
      }
      setCurrentPage(path);
      setPreviousPage(prevStack[prevStack.length - 1]);
      if (prevStack.includes(path)) {
        return prevStack;
      }
      return [...prevStack, path];
    });
  };

  const goBack = () => {
    scrollToTop();
    setStack((prevStack) => {
      if (prevStack.length === 1) {
        return prevStack;
      }
      setPreviousPage(prevStack[prevStack.length - 1]);
      const newStack = prevStack.slice(0, -1);
      setCurrentPage(newStack[newStack.length - 1]);

      return newStack;
    });
  };

  const goHome = () => {
    scrollToTop();
    setStack(['/']);
    setCurrentPage('/');
    setPreviousPage(undefined);
    setDirty(false);
  };

  return (
    <DialogNavigationContext.Provider
      value={{
        stack,
        currentPage,
        previousPage,
        dirty,
        title,
        navigate,
        goBack,
        goHome,
        setTitle,
      }}
    >
      {children}
    </DialogNavigationContext.Provider>
  );
};
