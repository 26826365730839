import { LeadsApp } from '@vcc-package/embeddable-forms';

import type { LeadsContextProperties } from '../../../src/context';

export const conversationalForm = (context: LeadsContextProperties) => {
  if (
    !context.features.ConversationalForm ||
    context.appId !== LeadsApp.QuoteRequest
  )
    return;

  const wrapper = document.getElementById('layout-root');
  const initiated = 'data-abtest-conversationalform-initiated';

  if (!wrapper || wrapper?.getAttribute(initiated)) {
    return;
  }

  const firstNameTitle = createEl(t.getToKnow);
  getParent(wrapper, 'Firstname')?.prepend(firstNameTitle);

  const contactMeTitle = createEl(t.contactMe);
  getParent(wrapper, 'MobilePhone')?.prepend(contactMeTitle);

  const infoTitle = createEl(t.moreInfo);
  getParent(wrapper, 'description')?.prepend(infoTitle);

  wrapper.setAttribute(initiated, 'true');
  console.log('Running experiment: conversationalForm');
};

const createEl = (title: string) => {
  const el = document.createElement('h2');
  el.className = 'font-20 mt-16 w-full font-medium';
  el.innerHTML = title;

  return el;
};

const t = {
  getToKnow: 'Dina uppgifter',
  contactMe: 'Så når vi dig',
  moreInfo: 'Något att tillägga?',
};

const getParent = (wrapper: HTMLElement, name: string) => {
  const el = wrapper.querySelector(`[name="${name}"]`)?.parentElement
    ?.parentElement;
  el?.classList.remove('lg:flex-row');
  return el;
};
