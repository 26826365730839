import React, { ReactNode } from 'react';

export const StepTitle = ({
  children,
  'data-testid': testID,
}: {
  children: ReactNode;
  'data-testid'?: string;
}) => {
  if (!children) return null;

  return (
    <header>
      <h3 className="font-16 font-medium" data-testid={testID}>
        {children}
      </h3>
    </header>
  );
};
