'use client';
import type { PropsWithChildren } from 'react';
import React, { useEffect, useState } from 'react';

import {
  getGa3EventAction,
  getGa3EventCategory,
  readFromSession,
  StorageKey,
} from '@vcc-package/leads-utils/util';
import { getMarketSite } from '@volvo-cars/market-sites';
import { PageTrackingProvider, TrackingProvider } from '@volvo-cars/tracking';

import { useLeadsContext } from '../context/leadsContext';
import { useLeadsTracking } from '../context/leadsTrackingContext';
import { useModel } from '../context/modelContext';

// Need to split the Volvo trackers to its own to access useLeadsTracking()
export const VolvoTrackingHelper = ({
  trackPageViewOnLoad = true,
  children,
}: PropsWithChildren<{ trackPageViewOnLoad?: boolean }>) => {
  const {
    appId,
    pageName,
    purposeConfig,
    navigation: { pathname, query },
  } = useLeadsContext();

  const leadsTracking = useLeadsTracking();
  const { languageName, marketName, regionCode } = getMarketSite(pathname);
  const { selectedModel } = useModel();

  if (!leadsTracking) {
    throw Error('Volvo Tracker Helper requires leadsTrackerHelper');
  }
  const pageViewData = leadsTracking.pageView?.() ?? {};
  const redirected = !!readFromSession(appId, StorageKey.redirected);
  const eventAction = getGa3EventAction(pathname, query);
  const eventCategory = getGa3EventCategory(appId);
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <TrackingProvider
      forceLowerCase={false}
      ga3={{
        countryName: regionCode,
        market: marketName,
        marketLanguage: languageName,
        featureList: pageViewData?.featureList,
        featureFlags: pageViewData.featureList,
      }}
      mode="both"
      pageName={pageName}
      pageType="form"
    >
      {trackPageViewOnLoad && mounted && purposeConfig?.events?.pageView ? (
        <PageTrackingProvider
          forceLowerCase={false}
          ga3={{
            carDriveLineType: selectedModel?.gtm?.powerTrain,
            carModelName: selectedModel?.gtm?.carModel,
            deeplink: redirected ? 'true' : undefined,
            eventAction: eventAction,
            eventCategory: eventCategory,
            eventLabel: 'load',
            featureFlags: pageViewData.featureList,
            featureList: pageViewData.featureList,
          }}
          mode="both"
          {...pageViewData}
        >
          {children}
        </PageTrackingProvider>
      ) : (
        children
      )}
    </TrackingProvider>
  );
};
