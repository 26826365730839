'use client';

import { useContext } from 'react';
import { DialogNavigationContext } from './DialogNavigationContext';

/**
 * Custom hook that provides access to the dialog navigation context.
 * Throws an error if used outside of a DialogNavigationProvider.
 *
 * @returns The dialog navigation object.
 */
export const useDialogNavigation = () => {
  const navigation = useContext(DialogNavigationContext);
  if (!navigation) {
    throw new Error(
      'useDialogNavigation must be used within a DialogNavigationProvider',
    );
  }
  return navigation;
};
