import {
  FieldDefinition,
  FieldType,
} from '@vcc-www/content-management-jss-client';

export const DEFAULT_DESKTOP_RATIO = '16:9';
export const DEFAULT_MOBILE_RATIO = '1:1';

export const defaultAspectRatioOptions = ['1:1', '4:3', '4:5', '16:9'];

export function aspectRatioField({
  id = 'aspectRatio',
  displayName = 'Aspect ratio',
  options = defaultAspectRatioOptions,
  default: defaultValue = DEFAULT_MOBILE_RATIO,
  helpText = '',
}) {
  return {
    id,
    displayName,
    type: FieldType.Enum as const,
    options,
    default: defaultValue,
    helpText,
  };
}

export const commonFields: ReadonlyArray<FieldDefinition> = [
  {
    id: 'desktopPoster',
    displayName: 'Desktop video poster',
    type: FieldType.Image,
  },
  {
    id: 'mobilePoster',
    displayName: 'Mobile video poster',
    helpText: 'Desktop poster is used by default for all screen sizes.',
    type: FieldType.Image,
  },
  {
    id: 'videoDesktopMp4',
    displayName: 'Desktop video MP4',
    type: FieldType.Reference,
    referenceType: 'Video',
  },
  {
    id: 'videoMobileMp4',
    displayName: 'Mobile video MP4',
    helpText:
      'Desktop MP4 video source is used by default for all screen sizes.',
    type: FieldType.Reference,
    referenceType: 'Video',
  },
  {
    id: 'videoDesktopWebM',
    displayName: 'Desktop video WebM',
    type: FieldType.Reference,
    referenceType: 'Video',
  },
  {
    id: 'videoMobileWebM',
    displayName: 'Mobile video WebM',
    helpText:
      'Desktop WebM video source is used by default for all screen sizes.',
    type: FieldType.Reference,
    referenceType: 'Video',
  },
  {
    id: 'subtitleTrack',
    displayName: 'Subtitle file (.vtt)',
    type: FieldType.Reference,
    referenceType: 'File',
  },
];
