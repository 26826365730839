'use client';
import React from 'react';
import { useSelectableCard } from './selectable-card.hook';
import { SelectableCardProps } from './selectable-card.types';
import { SelectableCardView } from './selectable-card.view';

export const SelectableCard: React.FC<
  React.PropsWithChildren<SelectableCardProps>
> = ({
  alwaysShowChildren,
  annotation,
  children,
  disabled,
  disclaimer,
  centerTitle,
  href,
  isActive,
  linkText,
  linkTextSources,
  onClick,
  onSelect,
  readOnly,
  secondaryInfo,
  secondaryInfoSources,
  title,
  titleSources,
  titleTag,
  titleTagSources,
  vatLabel,
  vatLabelSources,
  name,
  value,
  testId,
}) => {
  const { activeHeight, contentRef, linkRef, onClickHandler } =
    useSelectableCard({ href, onClick, children, disabled });

  if (typeof title === 'string' && title.length === 0) return null;

  return (
    <SelectableCardView
      activeHeight={activeHeight}
      alwaysShowChildren={alwaysShowChildren}
      annotation={annotation}
      contentRef={contentRef}
      disabled={disabled}
      disclaimer={disclaimer}
      centerTitle={centerTitle}
      href={href}
      isActive={isActive}
      linkRef={linkRef}
      linkText={linkText}
      linkTextSources={linkTextSources}
      onClick={onClickHandler}
      onSelect={onSelect}
      readOnly={readOnly}
      secondaryInfo={secondaryInfo}
      secondaryInfoSources={secondaryInfoSources}
      title={title}
      titleSources={titleSources}
      titleTag={titleTag}
      titleTagSources={titleTagSources}
      vatLabel={vatLabel}
      vatLabelSources={vatLabelSources}
      name={name}
      value={value}
      testId={testId}
    >
      {children}
    </SelectableCardView>
  );
};
