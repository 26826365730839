import {
  GetOnChangeHandlerProps,
  GetOptionsWithTitlesProps,
} from './single-select.types';

export const getOnChangeHandler =
  ({
    disabled,
    onChange,
    readOnly,
    setCurrentIndex,
  }: GetOnChangeHandlerProps) =>
  (index: number, option?: any) => {
    if (disabled || readOnly) return;
    setCurrentIndex(index);
    onChange?.(index, option);
  };

export const getOptionsWithTitles = ({ options }: GetOptionsWithTitlesProps) =>
  options?.filter((option) =>
    typeof option?.title === 'string' ? option?.title?.length : option,
  );
