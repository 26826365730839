'use client';
import React, { useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';
import { useBreakpoints } from '@vcc-www/hooks';
import { Track, TrackingProvider } from '@volvo-cars/tracking';
import {
  InfoBanner as SharedInfoBanner,
  InfoBannerWithCTA,
  useDismiss,
} from '@volvo-cars/react-info-banner';
import { hasConsentForFunctionalCookies } from '@vcc-package/storage/consent';
import type { LinkEntry } from '@volvo-cars/content-management-client';

export interface InfoBannerProps {
  type?: 'infoBanner';
  shortText?: string;
  longText?: string;
  cta?: LinkEntry;
}

export const InfoBanner: React.FC<InfoBannerProps> = ({
  shortText,
  longText,
  cta,
}) => {
  const [isClient, setIsClient] = useState(false);
  const pathname = usePathname();
  const { dismiss, visible } = useDismiss({
    key: pathname ?? '',
    persistToCookie: hasConsentForFunctionalCookies(),
  });
  // eslint-disable-next-line vcc-www/use-breakpoints
  const { fromL } = useBreakpoints();
  let infoText = shortText || longText;
  if (fromL && longText) {
    infoText = longText;
  }

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) {
    return null;
  }

  if (cta) {
    return (
      visible && (
        <TrackingProvider eventCategory="info banner">
          <Track eventAction="link|click" eventLabel={cta.text ?? ''}>
            <div data-testid="info-banner">
              <InfoBannerWithCTA
                onDismiss={dismiss}
                href={cta.href}
                linkText={cta.text ?? ''}
              >
                <span
                  style={{
                    whiteSpace: 'break-spaces',
                  }}
                >
                  {infoText}
                </span>
              </InfoBannerWithCTA>
            </div>
          </Track>
        </TrackingProvider>
      )
    );
  }

  return (
    visible && (
      <SharedInfoBanner onDismiss={dismiss}>{infoText}</SharedInfoBanner>
    )
  );
};
