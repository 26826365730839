/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConfigurationHistorySummary } from '../models/ConfigurationHistorySummary';
import type { FlexFormConfiguration } from '../models/FlexFormConfiguration';
import type { PurposeType } from '../models/PurposeType';
import type { StoredFlexFormConfiguration } from '../models/StoredFlexFormConfiguration';
import type { StoredFlexFormConfigurationHistory } from '../models/StoredFlexFormConfigurationHistory';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FlexFormService {
  /**
   * @returns FlexFormConfiguration OK
   * @throws ApiError
   */
  public static getFlexFormByPurpose({
    purpose,
    siteSlug,
    clientId,
  }: {
    purpose: PurposeType;
    siteSlug: string;
    clientId?: string;
  }): CancelablePromise<FlexFormConfiguration> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/forms-backend/v1/{siteSlug}/flexform/by-purpose',
      path: {
        siteSlug: siteSlug,
      },
      query: {
        purpose: purpose,
        clientId: clientId,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns ConfigurationHistorySummary OK
   * @throws ApiError
   */
  public static getFlexFormHistory({
    siteSlug,
  }: {
    siteSlug: string;
  }): CancelablePromise<Array<ConfigurationHistorySummary>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/forms-backend/v1/{siteSlug}/flexform/history/all',
      path: {
        siteSlug: siteSlug,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns StoredFlexFormConfigurationHistory OK
   * @throws ApiError
   */
  public static getFlexFormHistoryById({
    siteSlug,
    id,
  }: {
    siteSlug: string;
    id?: string;
  }): CancelablePromise<StoredFlexFormConfigurationHistory> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/forms-backend/v1/{siteSlug}/flexform/history/by-id',
      path: {
        siteSlug: siteSlug,
      },
      query: {
        id: id,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns StoredFlexFormConfiguration OK
   * @throws ApiError
   */
  public static getFallback({
    purpose,
    siteSlug,
  }: {
    purpose: PurposeType;
    siteSlug: string;
  }): CancelablePromise<StoredFlexFormConfiguration> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/forms-backend/v1/{siteSlug}/flexform/get-fallback',
      path: {
        siteSlug: siteSlug,
      },
      query: {
        purpose: purpose,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }
}
