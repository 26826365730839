import { useEffect, useState } from 'react';

import type { DealerModel } from '@vcc-package/leads-utils/api';

import { useLeadsContext } from '../../../../../context/leadsContext';

export type RetailerListType = 'Address' | 'Coordinates';

export type UseGetRetailersProps = {
  offset: number;
  limit: number;
  retailerListType: RetailerListType;
  address: string;
  latitude?: number;
  longitude?: number;
};

export const useGetRetailers = (props: UseGetRetailersProps) => {
  const {
    limit,
    offset,
    address = '',
    retailerListType = 'Address',
    latitude,
    longitude,
  } = props;
  const { API } = useLeadsContext();
  const [retailers, setRetailers] = useState<DealerModel[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [canLoadMore, setCanLoadMore] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      if (retailerListType === 'Coordinates') {
        if (!latitude || !longitude) return;
      } else if (address.length < 4) return;

      if (offset === 0) {
        setCanLoadMore(false);
        setRetailers([]);
      }

      try {
        setIsLoading(true);

        const response =
          retailerListType === 'Coordinates' && latitude && longitude
            ? await API.getDealersByCoordinates({
                latitude,
                longitude,
                limit,
                offset,
              })
            : await API.getDealersByAddress({
                address,
                limit,
                offset,
              });

        setRetailers((d) => (offset === 0 ? response : [...d, ...response]));
        // Note: Due to the way the API is built, we don't know the total number of entries.
        // Therefore, we do an optimistic assumption here if we can load more.
        setCanLoadMore(response.length >= limit);
        setError(null);
      } catch (error: any) {
        console.error('getRetailersByCoordinates|error', error);
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [API, offset, limit, retailerListType, address, latitude, longitude]);

  return {
    retailers,
    error,
    isLoading,
    canLoadMore,
  };
};
