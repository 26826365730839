'use client';
import React, { type PropsWithChildren } from 'react';
import {
  PartExchangeProvider,
  usePartExchangeTool,
  pxDictionaryMock,
  contentMock,
} from '@vcc-www/part-exchange-tool';
import { cssJoin } from '@volvo-cars/css/utils';

import { Spacing } from 'src/components/layout/Spacing';
import styles from './styles.module.css';
import { Track } from '@volvo-cars/tracking';
import type { LinkEntry } from '@volvo-cars/content-management-client';

export interface SingleHighlightProps {
  type: 'singleHighlight';
  heading: string;
  body: string;
  cta: LinkEntry;
  opensPartExchange?: boolean;
  onClick?: () => void;
}

export const SingleHighlight: React.FC<SingleHighlightProps> = ({
  heading,
  body,
  cta,
  opensPartExchange,
}) => {
  const { showPartExchangeTool } = usePartExchangeTool();

  return (
    <Spacing>
      <div className="px-24">
        <div
          className={cssJoin(
            styles.container,
            'container flex-col py-48 px-24 bg-secondary md:items-center gap-16',
            'md:flex-row md:justify-between',
          )}
        >
          <div>
            <h2
              data-testid="singleHighlight:heading"
              className="heading-1 mb-16"
            >
              {heading}
            </h2>
            <p data-testid="singleHighlight:body" className={styles.body}>
              {body}
            </p>
          </div>
          <CallToAction
            cta={cta}
            onClick={
              opensPartExchange
                ? () => showPartExchangeTool({ title: 'Part Exchange' })
                : undefined
            }
          />
        </div>
      </div>
    </Spacing>
  );
};

const CallToAction: React.FC<{
  cta: LinkEntry;
  onClick?: () => void;
}> = ({ cta, onClick }) => {
  const buttonProps = {
    onClick: onClick,
    href: onClick === undefined ? cta.href : undefined,
  };

  const eventAction = onClick === undefined ? 'link|click' : 'button|click';

  const buttonOrLink = React.createElement(
    onClick === undefined ? 'a' : 'button',
    {
      className: 'button-outlined',
      ['data-color']: 'accent',
      type: 'button',
      ['data-testid']: 'singleHighlight:cta',
      ...buttonProps,
    },
    cta.text,
  );

  return (
    <div className="flex">
      <Track eventAction={eventAction} eventLabel={cta.text ?? ''}>
        {buttonOrLink}
      </Track>
    </div>
  );
};

// For Storybook only
export const SingleHighlightWithProvider: React.FC<
  PropsWithChildren<SingleHighlightProps>
> = (props) => (
  <PartExchangeProvider
    loginRoute="/api/care-by-volvo/part-exchange/login-route"
    authTokenRoute="/api/care-by-volvo/part-exchange/auth-token"
    content={contentMock}
    dictionaries={pxDictionaryMock}
  >
    <SingleHighlight {...props} />
  </PartExchangeProvider>
);
