'use client';
import React from 'react';
import { animated, Interpolation, SpringValue } from 'react-spring';
import { ExtendCSS, Flex } from 'vcc-ui';
import { activeIndicator } from './SpringCarousel.helpers';

type SpringCarouselDotProps = {
  total: number;
  index: number;
  progress: SpringValue<number>;
  template?: React.ReactNode;
  extendDotIcon?: ExtendCSS;
};
type animatedActiveDotCSSType = {
  progress: SpringValue<number>;
  total: number;
  index: number;
};

export const SpringCarouselDot: React.FC<
  React.PropsWithChildren<SpringCarouselDotProps>
> = ({ total, index, progress, template, extendDotIcon }) => {
  return (
    <React.Fragment>
      {template ?? (
        <DefaultDot
          total={total}
          index={index}
          progress={progress}
          extendDotIcon={extendDotIcon}
        />
      )}
    </React.Fragment>
  );
};

const DefaultDot: React.FC<
  React.PropsWithChildren<{
    total: number;
    index: number;
    progress: SpringValue<number>;
    extendDotIcon?: ExtendCSS;
  }>
> = ({ total, index, progress, extendDotIcon }) => {
  return (
    <Flex extend={[dotBackgroundCSS, extendDotIcon]}>
      <Flex
        as={animated.div}
        extend={animatedActiveDotExtend}
        style={animatedActiveDotCSS({
          progress,
          total,
          index,
        })}
      />
    </Flex>
  );
};

const dotBackgroundCSS: ExtendCSS = ({
  theme: { baselineSubGrid, color },
}) => ({
  margin: `0 ${baselineSubGrid}px`,
  borderRadius: '100%',
  background: color.ornament.divider,
  overflow: 'hidden',
});

const animatedActiveDotExtend: ExtendCSS = ({
  theme: { baselineGrid, color },
}) => ({
  background: color.foreground.primary,
  width: baselineGrid,
  height: baselineGrid,
  overflow: 'hidden',
  transition: 'background 300ms',
});

const animatedActiveDotCSS = ({
  progress,
  total,
  index,
}: animatedActiveDotCSSType) => {
  return {
    opacity: progress.to((progress) =>
      activeIndicator({ index, total, progress }),
    ),
  } as React.CSSProperties | { opacity: Interpolation<number> };
};
