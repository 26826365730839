import { ContentType } from './ContentType';

export enum FieldType {
  /**
   * A single line of short text.
   */
  ShortText = 'ShortText',

  /**
   * Multiple paragraphs of text.
   */
  LongText = 'LongText',

  /**
   * Text that may include HTML tags.
   */
  RichText = 'RichText',

  /**
   * Boolean toggle.
   */
  Boolean = 'Boolean',

  /**
   * A numeric field.
   */
  Number = 'Number',

  /**
   * An image.
   */
  Image = 'Image',

  /**
   * A simple link.
   */
  Link = 'Link',

  /**
   * A Modal object with nested fields
   */
  Modal = 'Modal',

  /**
   * An Overlay object with nested fields
   */
  Overlay = 'Overlay',

  /**
   * A content item from a specified datasource.
   */
  Reference = 'Reference',

  /**
   * Multiple content items from a specified datasource.
   */
  ReferenceArray = 'ReferenceArray',

  /**
   * Item in a list of fixed values.
   */
  Enum = 'Enum',
}

interface BaseFieldDefinition {
  /**
   * Unique id of the field. Will be used as the property name.
   */
  id: string;

  /**
   * Friendly name of the field to show in the CMS.
   */
  displayName?: string;

  /**
   * Whether the field is required to enter by an editor in the CMS.
   *
   * @default false
   */
  required?: boolean;

  /**
   * Whether the field value can be different in different languages.
   *
   * @default true
   */
  localized?: boolean;

  /**
   * Sets the editor friendly help text on the field definition.
   */
  helpText?: string;
}

export type ShortTextField = string;
export interface ShortTextFieldDefinition extends BaseFieldDefinition {
  type: FieldType.ShortText;
}

export type LongTextField = string;
export interface LongTextFieldDefinition extends BaseFieldDefinition {
  type: FieldType.LongText;
}

export type RichTextField = string;
export interface RichTextFieldDefinition extends BaseFieldDefinition {
  type: FieldType.RichText;
  /**
   * Type of rich text, `html` will show a richText box in the CMS that can contain html, `markdown` will render a LongText field. Defaults to `html`
   */
  richType?: 'markdown' | 'html';
}

export type BooleanField = boolean;
export interface BooleanFieldDefinition extends BaseFieldDefinition {
  type: FieldType.Boolean;
  /**
   * The default value.
   */
  default?: boolean;
}

export type NumberField = number;
export interface NumberFieldDefinition extends BaseFieldDefinition {
  type: FieldType.Number;
  /**
   * The default value.
   */
  default?: number;
}

export type ImageField = {
  src: string;
  alt: string;
  title?: string;
  width?: number;
  height?: number;
};

export interface ImageFieldDefinition extends BaseFieldDefinition {
  type: FieldType.Image;
}

export type VideoField = {
  src: string;
  mimeType: string;
};

export interface VideoFieldDefinition extends BaseFieldDefinition {
  type: FieldType.Reference;
  referenceType: 'Video';
}

export type ModalField = {
  title: string;
  subheading: string;
  contactLink: string;
};

export interface ModalFieldDefinition extends BaseFieldDefinition {
  type: FieldType.Reference;
  referenceType: 'Modal';
}

export type OverlayField = {
  title: string;
  subheading: string;
};

export interface OverlayFieldDefinition extends BaseFieldDefinition {
  type: FieldType.Reference;
  referenceType: 'Overlay';
}

export type FileField = {
  src: string;
  mimeType: string;
};

export interface FileFieldDefinition extends BaseFieldDefinition {
  type: FieldType.Reference;
  referenceType: 'File';
}

export type LinkField = {
  href: string;
  text?: string;
  target?: string;
  title?: string;
};
export interface LinkFieldDefinition extends BaseFieldDefinition {
  type: FieldType.Link;
}

export type ReferenceField<T = any> = T;
export interface ReferenceFieldDefinition extends BaseFieldDefinition {
  type: FieldType.Reference;

  /**
   * Explicit path to content in Sitecore. Defaults to descendants of the content item.
   */
  sitecoreSource?: string;

  /**
   * Content type of referenced item.
   */
  referenceContentType: ContentType;
}

export type ReferenceArrayField<T = any> = ReadonlyArray<T>;
export interface ReferenceArrayFieldDefinition extends BaseFieldDefinition {
  type: FieldType.ReferenceArray;

  /**
   * Explicit path to content in Sitecore. Defaults to descendants of the content item.
   */
  sitecoreSource?: string;

  /**
   * Content type(s) of array item.
   */
  referenceContentType: ContentType | ContentType[];

  /**
   * How the selector appears in the CMS, either a tree list
   * (TreelistEx in Sitecore) or a multi select (Multilist in Sitecore).
   *
   * @default select
   */
  selectorView?: 'tree' | 'select';
}

export type EnumField<T extends string | number = string | number> = T;
export interface EnumFieldDefinition<
  T extends string | number = string | number,
> extends BaseFieldDefinition {
  type: FieldType.Enum;

  /**
   * The possible options an editor can select from.
   */
  options: ReadonlyArray<T>;

  /**
   * The option that will be selected by default.
   */
  default?: T;
}

export type FieldValue =
  | ShortTextField
  | LongTextField
  | RichTextField
  | BooleanField
  | NumberField
  | EnumField
  | ImageField
  | VideoField
  | ModalField
  | OverlayField
  | FileField
  | LinkField
  | ReferenceField
  | ReferenceArrayField;

/**
 * Definition of a component configuration parameter field.
 */
export type ParamsDefinition =
  | BooleanFieldDefinition
  | NumberFieldDefinition
  | EnumFieldDefinition;

/**
 * Definition of a content type field.
 */
export type FieldDefinition =
  | ShortTextFieldDefinition
  | LongTextFieldDefinition
  | RichTextFieldDefinition
  | BooleanFieldDefinition
  | NumberFieldDefinition
  | ImageFieldDefinition
  | VideoFieldDefinition
  | ModalFieldDefinition
  | OverlayFieldDefinition
  | FileFieldDefinition
  | LinkFieldDefinition
  | ReferenceFieldDefinition
  | ReferenceArrayFieldDefinition
  | EnumFieldDefinition;
