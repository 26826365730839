/* eslint-disable react-hooks/exhaustive-deps */
// Modified fork of https://github.com/thebuilder/react-intersection-observer
import * as React from 'react';
import { useEffect } from 'react';
import { observe, unobserve } from './intersection';
import { InViewHookResponse, IntersectionOptions } from './props';
type State = {
  inView: boolean;
  entry?: IntersectionObserverEntry;
};

const initialState: State = {
  inView: false,
  entry: undefined,
};

export function useInView<T extends HTMLElement = HTMLDivElement>(
  options: IntersectionOptions & { disabled?: boolean } = {},
): InViewHookResponse<T> {
  const { disabled = false, ...observerOptions } = options;
  const { triggerOnce = false } = observerOptions;
  const ref = React.useRef<T | null>(null);
  const [state, setState] = React.useState<State>(initialState);

  useEffect(() => {
    if (!disabled && ref.current) {
      if (!supportsIntersectionObserver()) return;
      unobserve(ref.current);
      observe(
        ref.current,
        (inView, intersection) => {
          setState({ inView, entry: intersection });

          if (inView && triggerOnce) {
            // If it should only trigger once, unobserve the element after it's inView
            unobserve(ref.current);
          }
        },
        observerOptions,
      );
    }
    return () => {
      if (!disabled) {
        unobserve(ref.current);
      }
    };
  }, [
    observerOptions.threshold,
    observerOptions.root,
    observerOptions.rootMargin,
    observerOptions.triggerOnce,
  ]);

  useEffect(() => {
    if (!disabled && !ref.current && state !== initialState && !triggerOnce) {
      // If we don't have a ref, then reset the state (unless the hook is set to only `triggerOnce`)
      // This ensures we correctly reflect the current state - If you aren't observing anything, then nothing is inView
      setState(initialState);
    }
  });

  if (disabled || !supportsIntersectionObserver()) {
    ref.current = null;
    return [ref, true, undefined];
  }

  return [ref, state.inView, state.entry];
}

function supportsIntersectionObserver() {
  if (typeof window === 'undefined') return true;
  if (
    !('IntersectionObserver' in window) ||
    !('IntersectionObserverEntry' in window) ||
    !('intersectionRatio' in window.IntersectionObserverEntry.prototype)
  ) {
    return false;
  }
  return true;
}
