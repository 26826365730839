import { useTheme } from 'vcc-ui';

export const useCasImageWithBackground = (
  url: string | undefined,
  backgroundColor: 'primary' | 'secondary',
) => {
  const { color } = useTheme();

  if (!url) return null;

  const imageUrl = new URL(url);
  imageUrl.searchParams.set(
    'bg',
    `${color.background[backgroundColor].replace('#', '')}`,
  );

  return imageUrl;
};
