'use client';

import type { PropsWithChildren } from 'react';
import React from 'react';

import { globalStyling } from '@vcc-package/leads-utils/constants';
import { FlexibleFormSections } from '@vcc-package/leads-utils/types';

import { Header, Page, RetailerAddress, VehicleTitle } from '../components';
import type { LeadsImageSize } from '../components/imageComponents/LeadsImages';
import { ModelImage } from '../components/imageComponents/LeadsImages';
import { useLeadsContext } from '../context/leadsContext';
import { useModel } from '../context/modelContext';
import { useRetailer } from '../context/retailerContext';

import InfoBox from './InfoBox';
import styles from './LeadsDefaultLayout.module.css';

type LayoutProps = {
  hideCTA?: boolean;
  hideModel?: boolean;
  subHeading?: string;
  heading?: string;
};

const sizes: LeadsImageSize[] = [
  { screenWidth: 480, imageWidth: 480, type: 'max' },
  { screenWidth: 1025, imageWidth: 464, type: 'max' },
  { screenWidth: 1025, imageWidth: 592, type: 'min' },
];

export const LeadsDefaultLayout = (props: PropsWithChildren<LayoutProps>) => {
  const { hideCTA = false, subHeading, heading, children, hideModel } = props;

  const {
    formConfig,
    navigation: { query },
  } = useLeadsContext();

  const { selectedModel } = useModel();
  const { selectedRetailer, isInsideSales } = useRetailer();

  const hasDealerPicker = formConfig?.order.includes(
    FlexibleFormSections.DealerPicker,
  );

  const showRetailer = !isInsideSales && selectedRetailer && !hasDealerPicker;

  if (hideModel) {
    return children;
  }

  return (
    <Page>
      {heading && (
        <div className="md:hidden">
          <Header heading={heading} subheading={subHeading} />
        </div>
      )}
      <div className={`flex flex-col md:flex-row ${globalStyling.sectionGap}`}>
        <div className={styles.vehicleModelContainer}>
          {selectedModel && (
            <ModelImage
              model={selectedModel}
              sizes={sizes}
              className="until-md:hidden"
            />
          )}
          <div className="p-24 flex flex-col w-full gap-24 bg-secondary">
            {selectedModel && (
              <InfoBox type="model" hideCTA={hideCTA || !!query.cceid}>
                <VehicleTitle vehicle={selectedModel} />
              </InfoBox>
            )}

            {showRetailer && (
              <>
                <hr className="w-full border-t m-0 p-0" />

                <InfoBox type="retailer" hideCTA={hideCTA}>
                  <p>
                    <strong>{selectedRetailer.name}</strong>
                  </p>
                  <RetailerAddress retailer={selectedRetailer} />
                </InfoBox>
              </>
            )}
          </div>
        </div>

        <div
          className={`flex flex-col md:mx-auto ${globalStyling.sectionGap}`}
          style={{ maxWidth: globalStyling.columnMaxWidth }}
        >
          {heading && (
            <div className="hidden md:flex">
              <Header heading={heading} subheading={subHeading} />
            </div>
          )}

          {children}
        </div>
      </div>
    </Page>
  );
};
