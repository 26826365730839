import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { PART_EXCHANGE_VALUATION_OFFER } from '../queries/partExchangeValuationOfferQuery';
import {
  PartExchangeValuationOfferQuery,
  PartExchangeValuationOfferQueryVariables,
} from '../queries/queries.types';

export const getPartExchangeValuationOffer = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  carDetails: PartExchangeValuationOfferQueryVariables['input'],
  answers: PartExchangeValuationOfferQueryVariables['answers'],
) => {
  try {
    const { data, loading, error } = await apolloClient.query<
      PartExchangeValuationOfferQuery,
      PartExchangeValuationOfferQueryVariables
    >({
      query: PART_EXCHANGE_VALUATION_OFFER,
      fetchPolicy: 'no-cache',
      variables: {
        input: carDetails,
        answers: answers,
      },
    });

    return { data, loading, error };
  } catch (err) {
    console.error(err);
    return {
      data: {} as PartExchangeValuationOfferQuery,
      loading: false,
      error: err,
    };
  }
};
