'use client';
import React from 'react';
import { Spacing } from '../../layout/Spacing';
import { useQueries } from 'src/hooks/useQueries';
import { type EngineType } from 'src/types/queries';
import { findCarAndFormatDisclaimer } from 'src/utils/queries';
import { DiscoveryCardHorizontal } from '@vcc-package/discovery';
import { Markdown } from '@vcc-package/text';
import type {
  ImageEntry,
  LinkEntry,
} from '@volvo-cars/content-management-client';

export interface PromotionTextImageProps {
  type: 'promotionTextImage';
  title: string;
  body: string;
  cta?: LinkEntry;
  imageAlign?: 'left' | 'right';
  image: ImageEntry;
  disclaimer?: string;
  model?: string;
  engineType?: EngineType;
}

export const PromotionTextImage: React.FC<PromotionTextImageProps> = ({
  title,
  body,
  cta,
  imageAlign = 'right',
  image,
  disclaimer,
  model,
  engineType,
}) => {
  const { graphCars } = useQueries();

  let formattedDisclaimer = disclaimer;
  if (model && engineType && disclaimer) {
    formattedDisclaimer = findCarAndFormatDisclaimer({
      disclaimer,
      graphCars,
      model,
      engineType,
    });
  }

  return (
    <Spacing>
      <div className="container">
        <DiscoveryCardHorizontal
          responsiveImages={{ sm: image }}
          heading={title}
          bodyText={body}
          cta={cta}
          layout={imageAlign === 'right' ? 'reversed' : 'default'}
        />
        {formattedDisclaimer && (
          <Markdown
            className="micro text-secondary mt-32 md:text-center"
            markdown={formattedDisclaimer}
          />
        )}
      </div>
    </Spacing>
  );
};
