import { useEffect } from 'react';

export const useSetOverlayTitle = (
  setOverlayTitle: ((title: string) => void) | undefined,
  title: string,
  inOverlay: boolean,
) => {
  useEffect(() => {
    if (!inOverlay || !title || !setOverlayTitle) {
      return;
    }

    setOverlayTitle(title);
  }, [inOverlay, setOverlayTitle, title]);
};
