import { FlattenedDictionaries } from '@volvo-cars/content-delivery-client/dictionaries/types';

export const pxDictionaryMock: FlattenedDictionaries = {
  'financeOptions.partExchangeTool.ctaText': 'Continue',
  'financeOptions.partExchangeTool.carInputTitle': 'Tell us about your car.',
  'financeOptions.partExchangeTool.carInputSubTitle': '',
  'financeOptions.partExchangeTool.carInputBody':
    'We will use this to fetch your car details from the registration systems.',
  'financeOptions.partExchangeTool.carInputRegNr': 'Registration Number',
  'financeOptions.partExchangeTool.carInputRegNrLabel':
    'Enter your registration number',
  'financeOptions.partExchangeTool.carInputRegNrErrorMsg':
    'Could not find car. Please make sure to enter correct registration number.',
  'financeOptions.partExchangeTool.carInputMileage': 'Mileage',
  'financeOptions.partExchangeTool.carInputMileageLabel':
    'Enter your current mileage',
  'financeOptions.partExchangeTool.carInputMileageErrorMsg':
    'Please enter a valid mileage.',
  'financeOptions.partExchangeTool.carDetailsTitle': 'Is this the correct car?',
  'financeOptions.partExchangeTool.carDetailsSubTitle': '',
  'financeOptions.partExchangeTool.carDetailsBody':
    'This is the information we found. Please make sure this is your car, and then click continue to move on with the Part Exchange estimation.',
  'financeOptions.partExchangeTool.conditionTitle':
    'How would you describe the current condition of the car?',
  'financeOptions.partExchangeTool.conditionSubTitle': '',
  'financeOptions.partExchangeTool.aboutCarTitle':
    'Tell us about the details of your car.',
  'financeOptions.partExchangeTool.aboutCarSubTitle': '',
  'financeOptions.partExchangeTool.financeTitle':
    'Is there any outstanding finance on the car?',
  'financeOptions.partExchangeTool.financeSubTitle': '',
  'financeOptions.partExchangeTool.financeInputErrorMsg':
    'Please enter a valid number.',
  'financeOptions.partExchangeTool.financeInputTitle': 'Outstanding finance',
  'financeOptions.partExchangeTool.financeYesOption':
    'Yes, I have an outstanding amount due',
  'financeOptions.partExchangeTool.financeNoOption':
    'No, I have nothing left to pay',
  'financeOptions.partExchangeTool.summaryTitle': 'Your part exchange estimate',
  'financeOptions.partExchangeTool.summaryCarValue': 'Car Value',
  'financeOptions.partExchangeTool.summaryOutstandingFinance':
    'Outstanding Finance',
  'financeOptions.partExchangeTool.summaryEquity': 'Equity',
  'financeOptions.partExchangeTool.stepsTitle': 'What happens next?',
  'financeOptions.partExchangeTool.stepOneBody':
    'When placing the order for your new car, confirm your interest to part exchange your current vehicle. Use the valuation sum as a whole, or in part, to define the deposit.',
  'financeOptions.partExchangeTool.stepTwoBody':
    'Your selected retailer will contact you a couple of weeks before your new car is ready to schedule a confirmed valuation of your current car.',
  'financeOptions.partExchangeTool.stepThreeBody':
    'Once the confirmed value is set and agreed, the deposit is updated. When you pick up your new car, bring the vehicle you are trading in. Leave it with us, and drive away in your new Volvo.',
  'financeOptions.partExchangeTool.closeButton': 'Close valuation',
  'financeOptions.partExchangeTool.errorViewTitle':
    'Oops, something went wrong.',
  'financeOptions.partExchangeTool.errorViewBody':
    'We could not provide a valuation of your car at the moment. Please try again later.',
  'financeOptions.partExchangeTool.valuationErrorTitle':
    'Oops, something went wrong.',
  'financeOptions.partExchangeTool.valuationErrorBody':
    'Based on the information you have provided us with, we are unable to provide a valuation for your vehicle. Please check your details again and make sure you have provided us with the correct mileage for your car',
  'financeOptions.partExchangeTool.errorViewButton': 'Close',
};
