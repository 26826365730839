'use client';
import React, { useEffect } from 'react';
import { useSpring } from 'react-spring';
import { Flex, ExtendCSS } from 'vcc-ui';
import { useSpringCarousel } from '.';
import { SpringCarouselDot } from './SpringCarouselDot';

type SpringCarouselDotsProps = {
  overlay?: boolean;
  extend?: ExtendCSS;
  extendDotIcon?: ExtendCSS;
  dotIcon?: React.ReactNode;
  activeDotIcon?: React.ReactNode;
};

export const SpringCarouselDots: React.FC<
  React.PropsWithChildren<SpringCarouselDotsProps>
> = ({ overlay, extend, extendDotIcon, dotIcon, activeDotIcon }) => {
  const { total, onProgress, current } = useSpringCarousel();
  const [{ progress }, setSpring] = useSpring<{ progress: number }>(() => ({
    progress: 0,
  }));

  useEffect(() => {
    const unsubProgress = onProgress(({ value }) =>
      setSpring.start({ progress: value._progress, immediate: true }),
    );
    return () => unsubProgress();
  }, [total, setSpring, onProgress]);

  return (
    <Flex extend={[containerCSS({ overlay, total }), extend]}>
      {total > 1 &&
        new Array(total).fill(null).map((_n, index) => {
          const isCurrentCustomItem =
            current - 1 === index && activeDotIcon !== null && dotIcon !== null;
          return (
            <SpringCarouselDot
              index={index}
              progress={progress}
              total={total}
              template={isCurrentCustomItem ? activeDotIcon : dotIcon}
              extendDotIcon={extendDotIcon}
              key={index}
            />
          );
        })}
    </Flex>
  );
};

const containerCSS: ({
  overlay,
  total,
}: {
  overlay?: boolean;
  total: number;
}) => ExtendCSS =
  ({ overlay }) =>
  ({ theme: { baselineGrid } }) => ({
    margin: `${baselineGrid}px 0`,
    flexDirection: 'row',
    justifyContent: 'center',
    ...(overlay && {
      position: 'absolute',
      bottom: 0,
      width: '100%',
    }),
  });
