// TODO: Fix eslint issues the next time this file is edited.
/* eslint-disable @volvo-cars/css/no-custom-class */
import React from 'react';
import { cssJoin } from '@volvo-cars/css/utils';
import { Icon } from '@volvo-cars/react-icons';

import {
  type DEPRECATED_IconType,
  getIconPropsFromDeprecatedIconType,
} from '@volvo-cars/react-icons/compat';

import styles from '../finopt-comparison.module.css';

export type GridListItemProps = {
  largeOnMobile?: boolean;
  isElectricOnly?: boolean;
  value: string;
  iconName: DEPRECATED_IconType | '';
  column: number;
};

export type IconProps = {
  icon: DEPRECATED_IconType | '';
  largeOnMobile?: boolean;
};

const GridListItem = ({
  largeOnMobile = true,
  isElectricOnly = false,
  value,
  iconName,
  column,
}: GridListItemProps) => (
  <div
    style={{ gridColumn: column }}
    className={cssJoin(
      'flex mb-16 lg:flex-row lg:items-center',
      styles['grid-list-item-wrapper'],
      largeOnMobile ? 'flex-col items-start' : 'items-center',
    )}
  >
    <div>
      <div
        className={cssJoin(
          'flex mr-8 lg:mr-24 items-center',
          styles['grid-icon'],
        )}
        data-testid="finance-options-comparison:grid-item:icon"
      >
        {iconName !== '' && (
          <Icon {...getIconPropsFromDeprecatedIconType(iconName)} />
        )}
      </div>
    </div>
    <div>
      <div className="flex space-between items-center">
        <p data-testid="finance-options-comparison:grid-item:label">{value}</p>
        {isElectricOnly && <Icon icon="lightning-bolt" size={32} />}
      </div>
    </div>
  </div>
);

const GridList = ({ listItems }: { listItems: GridListItemProps[] }) => {
  return (
    <>
      {listItems.map((item, i) => (
        <GridListItem key={item.value + i} {...item} />
      ))}
    </>
  );
};

export default GridList;
