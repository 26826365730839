import React from 'react';
import VideoBlock from './VideoBlock';
import VideoCore, { Props as VideoCoreProps } from './VideoCore';
import { VideoPosterReadyProvider } from './VideoLazyContainer';

type Props = {
  inViewRef: React.MutableRefObject<HTMLDivElement | null>;
};

const VideoRegular: React.FC<
  React.PropsWithChildren<Props & VideoCoreProps>
> = ({ inView, inViewRef, aspectRatio, background, ...props }) => {
  return (
    <VideoBlock
      inViewRef={inViewRef}
      aspectRatio={aspectRatio}
      background={background}
    >
      <VideoPosterReadyProvider value>
        <VideoCore
          data-testid="video:core"
          {...props}
          inView={inView}
          aspectRatio={aspectRatio}
        />
      </VideoPosterReadyProvider>
    </VideoBlock>
  );
};

export default VideoRegular;
