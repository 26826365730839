'use client';

import { useEffect, MutableRefObject, RefObject } from 'react';

export const useOnClickOutside = (
  myRef: MutableRefObject<HTMLElement> | RefObject<HTMLElement>,
  handler: (ev: MouseEvent | TouchEvent) => any,
) => {
  useEffect(() => {
    const listener = (ev: MouseEvent | TouchEvent): any => {
      if (!myRef.current || myRef.current.contains(ev.target as Node)) {
        return;
      }
      handler(ev);
    };

    window.addEventListener('mousedown', listener);
    window.addEventListener('touchstart', listener);

    return () => {
      window.removeEventListener('mousedown', listener);
      window.removeEventListener('touchstart', listener);
    };
  }, [myRef, handler]);
};
