import { getDictionariesProvider } from '@vcc-www/react-translate';
import mappedItems from './mapItemsToParameters.json';

const providers = getDictionariesProvider<typeof mappedItems>();

export const useTranslate: ReturnType<
  typeof getDictionariesProvider
>['useTranslate'] = providers.useTranslate;

export const DictionariesProvider = providers.DictionariesProvider;
