import React from 'react';
import { useSingleSelect } from './single-select.hook';
import { SingleSelectInputProps } from './single-select.types';
import { SingleSelectView } from './single-select.view';

export const SingleSelect: React.FC<
  React.PropsWithChildren<SingleSelectInputProps>
> = ({
  alwaysShowChildren,
  centerTitle,
  children,
  defaultSelectedIndex,
  disabled,
  onChange,
  options,
  readOnly,
  columnCount,
  testId,
}) => {
  const singleSelectProps = useSingleSelect({
    defaultSelectedIndex,
    disabled,
    onChange,
    options,
    readOnly,
  });

  return (
    <SingleSelectView
      alwaysShowChildren={alwaysShowChildren}
      disabled={disabled}
      columnCount={columnCount}
      centerTitle={centerTitle}
      readOnly={readOnly}
      testId={testId}
      {...singleSelectProps}
    >
      {children}
    </SingleSelectView>
  );
};
