export enum Background {
  Warm = 'warm',
  Cold = 'cold',
  Green = 'green',
  Neutral = 'neutral',
  White = 'white',
}

export const backgroundMap = {
  [Background.Warm]: '#FAF7F5',
  [Background.Cold]: '#F5F7FA',
  [Background.Green]: '#F3F5F2',
  [Background.Neutral]: '#F5F5F5',
  [Background.White]: '#FFFFFF',
};
