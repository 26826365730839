import { rockarEnvs } from './getEnv';

const getRockarEndpoints = (env: rockarEnvs) => {
  const fusionAuthUrl =
    env === 'prod'
      ? 'https://fusionauth.rockarplatform.com'
      : 'https://fusionauth.uat.rockarplatform.com';

  const apolloUrl =
    env === 'prod'
      ? 'https://apollo.rockarplatform.com/'
      : 'https://apollo.uat.rockarplatform.com/';

  return { fusionAuthUrl, apolloUrl };
};

export default getRockarEndpoints;
