import React from 'react';
import { cssJoin } from '@volvo-cars/css/utils';
import styles from '../SubNavigation.module.css';
import type { CTAsProps } from './CTAs.types';
import { MobileCTAs, Track } from './CTAs.client';

const CTAs: React.FC<CTAsProps> = (props) => {
  if (
    !props.primaryCta &&
    (!props.additionalCtas || props.additionalCtas?.length === 0)
  ) {
    return null;
  }

  return (
    <>
      <DesktopCTAs {...props} />
      <MobileCTAs {...props} />
    </>
  );
};

const DesktopCTAs: React.FC<CTAsProps> = ({ primaryCta, additionalCtas }) => (
  <div
    className={cssJoin('flex-row-reverse gap-8 until-lg:hidden', styles.ctas)}
    data-autoid="sub-navigation:desktop-ctas"
    data-testid="sub-navigation:desktop-ctas"
    slot="back"
  >
    {primaryCta && (
      <Track eventLabel={primaryCta.eventLabel}>
        <a
          href={primaryCta.href}
          target={primaryCta.target}
          aria-label={primaryCta.accessibilityLabel}
          className={cssJoin('button-filled', styles.cta)}
          data-size="small"
          data-sources={primaryCta.source}
        >
          {primaryCta.text}
        </a>
      </Track>
    )}
    {additionalCtas?.map((cta, index) => (
      <Track key={index} eventLabel={cta.eventLabel}>
        <a
          href={cta.href}
          target={cta.target}
          aria-label={cta.accessibilityLabel}
          className="button-outlined"
          data-size="small"
          data-sources={cta.source}
        >
          {cta.text}
        </a>
      </Track>
    ))}
  </div>
);

export default CTAs;
