import React from 'react';

import { globalStyling } from '@vcc-package/leads-utils/constants';

type HeadingProps = {
  heading: string;
  subheading?: string;
  description?: string;
};

export function Header(props: HeadingProps) {
  const { heading, subheading, description } = props;

  return (
    <header
      className="flex justify-between flex-col gap-16 lg:flex-row lg:items-center w-full mx-auto"
      style={{ maxWidth: globalStyling.maxWidth }}
    >
      <div className="flex flex-col">
        {subheading && <p className="micro text-secondary">{subheading}</p>}
        <h1 data-testid="leads-form-header" className="heading-1 font-medium">
          {heading}
        </h1>
      </div>
      {description && (
        <p
          className="text-secondary font-20 lg:text-end"
          style={{ maxWidth: globalStyling.columnMaxWidth }}
        >
          {description}
        </p>
      )}
    </header>
  );
}
