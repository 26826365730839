import React, { HTMLAttributes } from 'react';
import styles from '../discovery-card.module.css';
import { cssMerge } from '@volvo-cars/css/utils';
import { ResponsiveImageProps } from '@vcc-package/media';
import Card from '../parts';
import { ResponsiveImagesContent } from '@vcc-package/media/content-management';

type CardProps = React.PropsWithChildren<
  HTMLAttributes<HTMLDivElement> & {
    responsiveImages?: ResponsiveImageProps['images'];
    layout?: 'reversed' | 'default';
    variant?: 'horizontal' | 'stacked' | undefined;
  }
>;

const DiscoveryCard = ({
  children,
  className,
  layout,
  variant,
  responsiveImages,
  ...rest
}: CardProps) => {
  const horizontalAspectRatios: Partial<ResponsiveImagesContent> = {
    smAspectRatio: '4:3',
    mdAspectRatio: '1:1',
    lgAspectRatio: '4:3',
  };

  return (
    <div
      {...rest}
      className={cssMerge(
        'w-full bg-secondary',
        styles.discovery_card,
        variant ? styles[variant] : styles.no_media,
        className,
        variant === 'horizontal' && layout === 'reversed' && styles.reversed,
      )}
    >
      {variant === 'stacked' && responsiveImages && (
        <Card.Image
          responsiveImages={{ smAspectRatio: '16:9', ...responsiveImages }}
        />
      )}
      {variant === 'horizontal' && responsiveImages && (
        <Card.Image
          responsiveImages={{ ...horizontalAspectRatios, ...responsiveImages }}
        />
      )}
      {children}
    </div>
  );
};

export default DiscoveryCard;
