import type { PropsWithChildren } from 'react';
import React from 'react';
import { LeadsApp } from '../constants';

import type { JSONObject } from '@vcc-package/leads-utils/types';
import type {
  RequestApiModel,
  RequestResponseApiModel,
} from '@vcc-package/leads-utils/api';

import { FormPage } from './GenericFormComponent';
import { QuoteRequestForm } from './QuoteRequestForm';
import { useLeadsContext } from '@vcc-package/leads-utils';
import { EmbeddedFormErrorType } from './types';
import { Spinner } from './Spinner';

export type EmbeddedFormProps = {
  OnSubmit(
    submitData: RequestApiModel,
    apiResponse: RequestResponseApiModel,
  ): void;
  OnError(type: EmbeddedFormErrorType, e: Error): void;
  hideModelImage?: boolean;
  additionalInfo?: JSONObject;
  inOverlay?: boolean;
};

export const EmbeddedForm = (props: PropsWithChildren<EmbeddedFormProps>) => {
  const { appId, features } = useLeadsContext();

  if (!features) {
    return <Spinner />;
  }

  const useQuoteRequestForm = appId === LeadsApp.QuoteRequest;

  return useQuoteRequestForm ? (
    <QuoteRequestForm {...props} />
  ) : (
    <FormPage {...props} />
  );
};
