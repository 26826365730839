import { useRef, useMemo } from 'react';
import isEqual from 'lodash/isEqual';

export const useMemoDeep = (
  factory: () => any,
  deps?: React.DependencyList,
) => {
  const depsRef = useRef() as React.MutableRefObject<any>;
  if (!isEqual(deps, depsRef.current)) depsRef.current = deps;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memo = useMemo(factory, depsRef.current);
  return memo;
};
