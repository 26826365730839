import { ExtendCSS, ThemeBreakpointName } from 'vcc-ui';
import {
  getMediaQueryValues,
  ResponsiveValue,
} from '@vcc-www/utils/getMediaQueryValues';
import { SIZES_BREAKPOINT_MEDIA_QUERIES, SizesBreakpoints } from './constants';

export const getSizes = <T>(sizes: ResponsiveValue<T>) => {
  const queryValues = getMediaQueryValues(sizes, (size) => size as ExtendCSS);

  return queryValues
    .reverse()
    .map((item) => {
      if (typeof item === 'object') {
        const breakpoint = Object.keys(item)[0] as ThemeBreakpointName;
        const value = (item as SizesBreakpoints)[breakpoint];

        return `${SIZES_BREAKPOINT_MEDIA_QUERIES[breakpoint]} ${value}`;
      }

      return item;
    })
    .join(', ');
};
