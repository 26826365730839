export const maxContentWidth12Columns = 1232;
export const maxContentWidth10Columns = 1022;
export const maxContentWidth8Columns = 814;
export const maxContentWidth5Columns = 534;
export const maxContentWidth4Columns = 408;

export const maxBackgroundWidth12Columns = 1440;

export const maxBackgroundWidth12ColumnsRem = `${
  maxBackgroundWidth12Columns / 16
}rem`;
export const maxContentWidth10ColumnsRem = `${
  maxContentWidth10Columns / 16
}rem`;
export const maxContentWidth12ColumnsRem = `${
  maxContentWidth12Columns / 16
}rem`;
export const maxContentWidth4ColumnsRem = `${maxContentWidth4Columns / 16}rem`;
export const maxContentWidth5ColumnsRem = `${maxContentWidth5Columns / 16}rem`;
export const maxContentWidth8ColumnsRem = `${maxContentWidth8Columns / 16}rem`;
