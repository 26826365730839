'use client';
import React from 'react';
import { Icon } from '@volvo-cars/react-icons';
import { useTracker } from '@volvo-cars/tracking';

import { Spacing } from 'src/components/layout/Spacing';
import { Markdown } from '@vcc-package/text';
import styles from './comparison-table-v2.module.css';
import { cssJoin } from '@volvo-cars/css/utils';
import type { LinkEntry } from '@volvo-cars/content-management-client';

export interface ComparisonTableV2Props {
  type: 'comparisonTableV2';
  title: string;
  titleSize?: 'small' | 'large';
  body: string;
  comparisonHeaderLeft: string;
  comparisonHeaderRight: string;
  cta?: LinkEntry;
  items: ComparisonTableItem[];
  disclaimer?: string;
}

interface ComparisonTableItem {
  leftCheck?: boolean;
  text: string;
  rightCheck?: boolean;
  firstRow?: boolean;
  lastRow?: boolean;
}

export const ComparisonTableV2: React.FC<
  React.PropsWithChildren<ComparisonTableV2Props>
> = ({
  title,
  body,
  comparisonHeaderLeft,
  comparisonHeaderRight,
  cta,
  items,
  disclaimer,
}) => {
  const ga4Tracker = useTracker({}, { mode: 'ga4' });

  return (
    <Spacing>
      <div
        data-testid="comparison-table-v2"
        className="container flex-col text-center items-center"
      >
        <h2
          data-testid="comparison-table-v2__title"
          className="heading-1 mb-s"
          style={{ whiteSpace: 'pre-wrap' }}
        >
          {title}
        </h2>
        <div
          data-testid="comparison-table-v2__body"
          className={cssJoin(
            'font-16 mb-s text-secondary',
            styles['body-wrapper'],
          )}
        >
          {body}
        </div>
        <div
          className={cssJoin(
            'flex-row justify-end w-full pb-8',
            styles['headers-wrapper'],
          )}
        >
          <p
            className={cssJoin('font-20 text-secondary', styles['header-left'])}
          >
            {comparisonHeaderLeft}
          </p>
          <p
            className={cssJoin(
              'font-20 text-primary font-medium',
              styles['header-right'],
            )}
          >
            {comparisonHeaderRight}
          </p>
        </div>
        <div className={cssJoin('flex-col items-center', styles['stretch'])}>
          <div className={cssJoin('w-full', styles['max-width-716'])}>
            <div
              className={cssJoin(
                'ml-auto bg-always-black rounded-t',
                styles['column-edge'],
              )}
            />
          </div>
          {items.map((item, i) => (
            <TableRow
              {...item}
              key={i}
              firstRow={i === 0}
              lastRow={i === items.length - 1}
            />
          ))}
          <div className={cssJoin('w-full', styles['max-width-716'])}>
            <div
              className={cssJoin(
                'ml-auto bg-always-black rounded-b',
                styles['column-edge'],
              )}
            />
          </div>
        </div>
        {cta?.href && cta?.text && (
          <a
            data-testid="comparison-table-v2__deals"
            className="button-filled mt-48"
            data-color="accent"
            onClick={() =>
              ga4Tracker.customEvent({
                eventAction: 'button|click',
                eventLabel: cta?.text?.replace(/ /g, '_') || 'shop',
                eventCategory: 'comparison_table_v2',
              })
            }
            href={cta.href}
          >
            {cta.text}
          </a>
        )}
        {disclaimer && (
          <div className="container-lg mt-32 text-start md:text-center">
            <Markdown markdown={disclaimer} className="text-secondary micro" />
          </div>
        )}
      </div>
    </Spacing>
  );
};

const Checked: React.FC<React.PropsWithChildren<{ isChecked?: boolean }>> = ({
  isChecked,
}) => {
  if (isChecked) {
    return <Icon icon="checkmark" size={32} color="inherit" />;
  }
  return <Icon icon="x" size={32} color="inherit" />;
};

const TableRow: React.FC<React.PropsWithChildren<ComparisonTableItem>> = ({
  leftCheck,
  text,
  rightCheck,
  firstRow,
  lastRow,
}) => (
  <div
    className={cssJoin(
      'flex-row mx-auto w-full bg-secondary',
      styles['max-width-716'],
      firstRow && styles['first-row'],
      lastRow && styles['last-row'],
    )}
  >
    <div
      className={cssJoin(
        'pr-16 pl-48 py-16 text-start',
        styles['table-text-wrapper'],
      )}
    >
      <p className="text-secondary font-16">{text}</p>
    </div>
    <div
      className={cssJoin(
        'flex items-center justify-center',
        styles['left-check-mark'],
      )}
    >
      <Checked isChecked={leftCheck} />
    </div>
    <div
      className={cssJoin(
        'flex items-center justify-center bg-always-black text-always-white',
        styles['right-check-mark'],
      )}
    >
      <Checked isChecked={rightCheck} />
    </div>
  </div>
);
