'use client';
import React from 'react';

import {
  FlexFormFieldType,
  TranslationKey,
} from '@vcc-package/leads-utils/api';
import { useVisibleVehicles } from '@vcc-package/leads-utils/hooks';
import type { Options, Translator } from '@vcc-package/leads-utils/types';
import { Checkbox } from '@volvo-cars/react-forms';
import { Controller, useFormContext } from 'react-hook-form';

import type { FieldValidations } from '../components/types';

type ProductsOfInterestFieldProps = {
  translate: Translator;
  options: Options;
  validation: FieldValidations;
};

export const ProductsOfInterestField = ({
  translate,
  options,
}: ProductsOfInterestFieldProps): JSX.Element | null => {
  const { control } = useFormContext();
  const vehicles = useVisibleVehicles();

  if (!options.ProductsOfInterest && vehicles) {
    options.ProductsOfInterest = vehicles.map((vehicle) => ({
      label: vehicle.presentation.title,
      value: vehicle.pno34,
      productGroup: vehicle.presentation.seriesType,
    }));
  }

  const productGroups: string[] = React.useMemo(() => {
    let group: string[] = [];

    options.ProductsOfInterest?.forEach((option) => {
      if (option.productGroup && group.indexOf(option.productGroup) === -1) {
        group.push(option.productGroup);
      }
    });

    return group;
  }, [options.ProductsOfInterest]);

  return (
    <div className="w-full pb-16">
      <p className="text-secondary mb-8">
        {translate(TranslationKey.PRODUCT_OF_INTEREST_FIELD_TITLE)}
      </p>
      <Controller
        control={control}
        defaultValue={[]}
        name={FlexFormFieldType.PRODUCTS_OF_INTEREST}
        render={({ field: { onChange, value } }) => (
          <div className="flex w-full flex-wrap gap-16">
            {productGroups.map((group, index) => (
              <div
                key={`view_${index}`}
                className="flex flex-col flex-grow gap-4"
                style={{ minWidth: 200 }}
              >
                <small className="micro font-medium">{group}</small>
                {options.ProductsOfInterest.filter(
                  (product) => product.productGroup === group,
                ).map((option, index) => (
                  <Checkbox
                    key={index}
                    name={option.label}
                    value={option.value}
                    checked={value?.includes(option.value)}
                    label={option.label}
                    data-testid={`flexforms-poi-${index}`}
                    onChange={({ target }) => {
                      if (target.checked) {
                        onChange([...value, option.value]);
                      } else {
                        onChange(
                          value.filter((v: string) => v !== option.value),
                        );
                      }
                    }}
                  />
                ))}
              </div>
            ))}
          </div>
        )}
      />
    </div>
  );
};
