/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RequestApiModel } from '../models/RequestApiModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ConsumerDataService {
  /**
   * @returns RequestApiModel OK
   * @throws ApiError
   */
  public static getApiFormsBackendV1Consumerdata({
    id,
  }: {
    id?: string;
  }): CancelablePromise<RequestApiModel> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/forms-backend/v1/consumerdata',
      query: {
        id: id,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }
}
