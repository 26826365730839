'use client';
import React from 'react';
import { Steps as SharedSteps } from '@vcc-package/steps';
import { Spacing } from '../../layout/Spacing';
import { TrackingProvider, useTracker } from '@volvo-cars/tracking';
import type { LinkEntry } from '@volvo-cars/content-management-client';

export interface StepsProps {
  type: 'steps';
  title: string;
  body?: string;
  steps: StepProps[];
  cta?: LinkEntry;
}

interface StepProps {
  title: string;
  body: string;
  subBody?: string;
}

export const Steps: React.FC<React.PropsWithChildren<StepsProps>> = ({
  title,
  body,
  steps,
  cta,
}) => {
  const ga4Tracker = useTracker({}, { mode: 'ga4' });
  const eventCategory = 'steps';

  return (
    <TrackingProvider eventCategory={eventCategory}>
      <Spacing>
        <SharedSteps
          title={title}
          body={body}
          steps={steps}
          cta={
            cta?.href && cta?.text
              ? { href: cta.href, text: cta.text }
              : undefined
          }
          setTestId={(name?: string | string[]) =>
            `steps${
              name
                ? `__${(Array.isArray(name) ? name : [name]).join('__')}`
                : ''
            }`
          }
          onClickCta={() => {
            ga4Tracker.customEvent({
              eventCategory,
              eventAction: 'button|click',
              eventLabel: `${cta?.text}|${cta?.href}`,
            });
          }}
        />
      </Spacing>
    </TrackingProvider>
  );
};
