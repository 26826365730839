'use client';
import React from 'react';
import { SalesModelId } from '@vcc-www/api/finance-options';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { Spacing } from 'src/components/layout/Spacing';
import { Markdown } from '@vcc-package/text';
import { useQueries } from 'src/hooks/useQueries';

const salesModelDisclaimerMap: Record<string, SalesModelId> = {
  subscription: SalesModelId.Sub,
  subscribe: SalesModelId.Sub,
  'financial-lease': SalesModelId.Sub,
  'subscription-fixed': SalesModelId.SubFixed,
  lease: SalesModelId.SubFixed,
  'operating-lease': SalesModelId.SubFixed,
  purchase: SalesModelId.Cash,
  'personal-contract-purchase': SalesModelId.Lease,
  'loan-balloon': SalesModelId.Lease,
  'volvo-loan': SalesModelId.Loan,
  loan: SalesModelId.Loan,
};

const FinanceOptionDisclaimer = ({ pageName }: { pageName: string }) => {
  const translate = useSharedComponentsTranslate();
  const { financeOptions } = useQueries();

  const financeOption = financeOptions[salesModelDisclaimerMap[pageName]];

  if (!financeOption || !financeOption?.disclaimer) return null;

  return (
    <Spacing>
      <div className="container stack-4">
        <p className="font-medium">
          {translate('FinanceDetails.disclaimers.title')}
        </p>
        <Markdown
          className="text-secondary"
          markdown={financeOption.disclaimer}
        />
      </div>
    </Spacing>
  );
};

export default FinanceOptionDisclaimer;
