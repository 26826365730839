import React, { PropsWithChildren } from 'react';
import { Expand } from '@volvo-cars/react-accordion';
import { useTracker } from '@volvo-cars/tracking';
import { useValuationSummary } from '../part-exchange.hook';
import {
  QuestionWithAnswer,
  ValuationSummaryProps,
} from '../part-exchange.types';
import { useTranslate } from '../part-exchange.dictionaries-provider';
import { gtmCategory } from '../part-exchange.utils';
import { cssJoin } from '@volvo-cars/css/utils';

export const ValuationSummary: React.FC<ValuationSummaryProps> = ({
  isLoading,
  overrideIsExpanded = false,
}) => {
  const [isExpanded, setIsExpanded] = React.useState(overrideIsExpanded);
  const {
    outstandingFinance,
    formattedEquity,
    questionsWithAnswers,
    carValuationFromLS,
    regNumberFromLS,
    mileageFromLS,
  } = useValuationSummary();

  const translate = useTranslate();
  const ga4Tracker = useTracker({}, { mode: 'ga4' });

  const flattenedQuestions = questionsWithAnswers
    ? questionsWithAnswers.flatMap((item) => item.flatMap((obj) => obj))
    : ([] as QuestionWithAnswer[]);

  const moreInfoLabel =
    !isExpanded && !overrideIsExpanded ? 'More info' : 'Less info';

  return !isLoading ? (
    <>
      {carValuationFromLS !== undefined &&
        flattenedQuestions.length !== 0 &&
        regNumberFromLS !== undefined &&
        mileageFromLS !== undefined && (
          <div className="p-16 bg-secondary">
            <div className="flex justify-between items-center">
              <div>
                <p>
                  {translate('financeOptions.partExchangeTool.summaryEquity')}
                </p>
                <p className="font-20 font-medium">{formattedEquity}</p>
              </div>
              <button
                type="button"
                className="link-inline"
                onClick={() => {
                  setIsExpanded(!isExpanded);
                  ga4Tracker.customEvent({
                    eventCategory: gtmCategory,
                    eventAction: 'link|click',
                    eventLabel: moreInfoLabel,
                  });
                }}
              >
                <small className="micro">{moreInfoLabel}</small>
              </button>
            </div>
            <Expand
              extend={{ paddingTop: 24 }}
              isExpanded={isExpanded || overrideIsExpanded}
            >
              <TextItemsWrapper withBorder>
                <TextItemWithValue
                  text={translate(
                    'financeOptions.partExchangeTool.summaryCarValue',
                  )}
                  value={`£${carValuationFromLS}`}
                />
                <TextItemWithValue
                  text={translate(
                    'financeOptions.partExchangeTool.summaryOutstandingFinance',
                  )}
                  value={`£${outstandingFinance || 0}`}
                />
                <TextItemWithValue
                  text={translate(
                    'financeOptions.partExchangeTool.summaryEquity',
                  )}
                  value={formattedEquity}
                  fontMedium
                />
              </TextItemsWrapper>
              <TextItemsWrapper>
                <TextItemWithValue
                  text={translate(
                    'financeOptions.partExchangeTool.carInputRegNr',
                  )}
                  value={regNumberFromLS}
                />
                <TextItemWithValue
                  text={translate(
                    'financeOptions.partExchangeTool.carInputMileage',
                  )}
                  value={mileageFromLS}
                />
                {flattenedQuestions.map(
                  (question: QuestionWithAnswer, index: number) => {
                    const selectedOption = question.question.options.find(
                      (option) => option.slug === question.answer.answer,
                    );
                    return (
                      selectedOption && (
                        <TextItemWithValue
                          key={index}
                          text={question.question.title}
                          value={selectedOption.text}
                        />
                      )
                    );
                  },
                )}
              </TextItemsWrapper>
            </Expand>
          </div>
        )}
    </>
  ) : (
    <div className="flex items-center">
      <progress className="spinner w-24 text-secondary" />
    </div>
  );
};

const TextItemsWrapper: React.FC<
  React.PropsWithChildren<{
    withBorder?: boolean;
  }>
> = ({ withBorder, ...props }) => (
  <div
    className={cssJoin(
      `flex-col py-16 gap-16`,
      withBorder && 'border-b selected:border-primary',
    )}
    {...props}
  />
);

const TextItemWithValue: React.FC<
  PropsWithChildren<{ text: string; value: string; fontMedium?: boolean }>
> = ({ text, value, fontMedium }) => (
  <div className="flex-row justify-between items-center">
    <p className={cssJoin('font-16', fontMedium && 'font-medium')}>{text}</p>
    <p className={cssJoin('font-16', fontMedium && 'font-medium')}>{value}</p>
  </div>
);
