// TODO: Fix eslint issues the next time this file is edited.
/* eslint-disable @volvo-cars/css/no-custom-class */
import React from 'react';
import { cssJoin } from '@volvo-cars/css/utils';
import type {
  ProductsOverlayViewAboutServicesLinkProps,
  ProductsOverlayViewDisclaimersProps,
  ProductsOverlayViewIncludedServiceDescriptionProps,
  ProductsOverlayViewIncludedServicesProps,
  ProductsOverlayViewProductDescriptionProps,
  ProductsOverlayViewProps,
  ProductsOverlayViewSellingPlanSelectorProps,
  ProductsOverlayViewSellingPointsProps,
  ProductsOverlayViewServiceOptionsProps,
  ServiceIconProps,
} from './products-overlay.types';
import { SingleSelect } from '@vcc-package/single-select';
import { ListItem } from '@vcc-package/list-item';
import { Icon } from '@volvo-cars/react-icons';
import { DialogFooter, DialogMain } from '@vcc-package/overlays';

export const ProductsOverlayView = ({
  aboutServicesSectionRef,
  ctaSources,
  ctaTitle,
  defaultSelectedPackageIndex,
  description,
  disclaimers,
  displayName,
  imageUrl,
  includedServices,
  includedServiceSectionId,
  labels,
  onChangeVariant,
  onClick,
  onClickAboutServices,
  options,
  price,
  showChooseVariantSection,
}: ProductsOverlayViewProps) => {
  return (
    <>
      <DialogMain>
        <div className="container-xl flex-col gap-32">
          <img className="w-full" src={imageUrl} alt={displayName ?? ''} />
          <div className="flex-col gap-48">
            <ProductsOverlayViewProductDescription
              displayName={displayName}
              price={price}
              description={description}
            />
            {showChooseVariantSection && (
              <div className={cssJoin('flex-col gap-32')}>
                {showChooseVariantSection && onChangeVariant && (
                  <div className="flex-col gap-16">
                    <ProductsOverlayViewSellingPlanSelector
                      labels={labels}
                      options={options}
                      onChangeVariant={onChangeVariant}
                      defaultSelectedPackageIndex={defaultSelectedPackageIndex}
                    />
                    <ProductsOverlayViewAboutServicesLink
                      onClickAboutServices={onClickAboutServices}
                      labels={labels}
                      includedServiceSectionId={includedServiceSectionId}
                    />
                  </div>
                )}
              </div>
            )}
            <div>
              <ProductsOverlayViewIncludedServices
                title={labels?.includedServices?.title}
                titleSources={labels?.includedServices?.sources}
                includedServices={includedServices}
                aboutServicesSectionRef={aboutServicesSectionRef}
                includedServiceSectionId={includedServiceSectionId}
              />
            </div>
            <ProductsOverlayViewDisclaimers
              labels={labels}
              disclaimers={disclaimers}
            />
          </div>
        </div>
      </DialogMain>
      {ctaTitle && typeof onClick === 'function' && (
        <DialogFooter>
          <button
            type="button"
            className="button-filled w-full"
            data-color="accent"
            onClick={onClick}
            data-sources={ctaSources}
          >
            {ctaTitle}
          </button>
        </DialogFooter>
      )}
    </>
  );
};

export const ProductsOverlayViewSellingPlanSelector = ({
  labels,
  options,
  onChangeVariant,
  defaultSelectedPackageIndex,
}: ProductsOverlayViewSellingPlanSelectorProps) => {
  return (
    <div className="flex-col gap-24">
      {labels?.chooseVariant?.title && (
        <h2
          data-sources={labels?.chooseVariant?.sources}
          className="font-20 font-medium text-balance"
        >
          {labels?.chooseVariant?.title}
        </h2>
      )}
      {!!options?.length && (
        <SingleSelect
          options={options}
          onChange={(selectedIndex) => onChangeVariant?.(selectedIndex)}
          defaultSelectedIndex={defaultSelectedPackageIndex}
        />
      )}
    </div>
  );
};

export const ProductsOverlayViewProductDescription = ({
  displayName,
  price,
  description,
}: ProductsOverlayViewProductDescriptionProps) => {
  return (
    <div className="flex-col gap-16">
      <div className="flex-col gap-8">
        {displayName && <h1 className="font-medium font-24">{displayName}</h1>}
        {price && <h2 className="font-20 text-balance">{price}</h2>}
      </div>
      {description && <p className="font-16">{description}</p>}
    </div>
  );
};

export const ProductsOverlayViewIncludedServices = ({
  title,
  titleSources,
  includedServices,
  aboutServicesSectionRef,
  includedServiceSectionId,
}: ProductsOverlayViewIncludedServicesProps) => {
  return (
    <div
      ref={aboutServicesSectionRef}
      id={includedServiceSectionId}
      className="flex-col gap-32"
    >
      {title && (
        <h2
          className="font-20 font-medium text-balance"
          data-sources={titleSources}
        >
          {title}
        </h2>
      )}
      {!!includedServices?.length && (
        <ul className="flex-col gap-32">
          {includedServices.map((service, index) => {
            return (
              <li key={`service-${index}`}>
                <ListItem
                  thumbnail={
                    <ServiceIcon
                      displayName={
                        service?.thumbnailAltText ?? service?.displayName
                      }
                      thumbnail={service?.thumbnail}
                    />
                  }
                  title={service?.displayName}
                  titleSources={[
                    `meta:services[${service.displayName}].displayName`,
                  ]}
                  description={
                    <ProductsOverlayViewIncludedServiceDescription
                      service={service}
                      ariaLabel={service?.ariaLabel}
                    />
                  }
                  linkVariant="chevron-title"
                  descriptionVariant="font-16"
                />
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

const ProductsOverlayViewIncludedServiceDescription = ({
  service,
}: ProductsOverlayViewIncludedServiceDescriptionProps) => {
  return (
    <div className="flex-col gap-8">
      <p>{service?.description}</p>
      {!!service?.sellingPoints?.length && (
        <ProductsOverlayViewSellingPoints
          sellingPoints={service?.sellingPoints}
        />
      )}
    </div>
  );
};

const ProductsOverlayViewSellingPoints = ({
  sellingPoints,
}: ProductsOverlayViewSellingPointsProps) => {
  return (
    <ul className="list-none flex-col gap-8">
      {sellingPoints?.map((title) => (
        <li key={`title-${title}`} className="flex-row gap-8 text-secondary">
          <div className="flex justify-center items-center">
            <Icon icon="checkmark" size={12} alt="checkmark" />
          </div>
          <p>{title}</p>
        </li>
      ))}
    </ul>
  );
};

export const ServiceIcon = ({ thumbnail, displayName }: ServiceIconProps) => (
  <div className="flex-row items-center justify-center">
    <img
      className="object-cover aspect-1/1 w-32 h-32"
      src={thumbnail ?? ''}
      alt={displayName}
    />
  </div>
);

export const ProductsOverlayViewAboutServicesLink = ({
  labels,
  onClickAboutServices,
  includedServiceSectionId,
}: ProductsOverlayViewAboutServicesLinkProps) => {
  return (
    <>
      {labels?.aboutServices?.title && (
        <a
          className="button-text text-accent-blue justify-start"
          data-arrow="down"
          href={`#${includedServiceSectionId}`}
          onClick={onClickAboutServices}
          data-sources={labels?.aboutServices?.sources}
        >
          {labels?.aboutServices?.title}
        </a>
      )}
    </>
  );
};

export const ProductsOverlayViewDisclaimers = ({
  disclaimers,
  labels,
}: ProductsOverlayViewDisclaimersProps) => {
  return (
    <>
      {!!disclaimers?.length && (
        <div className="flex flex-col gap-12">
          {labels?.disclaimers?.title && (
            <h4
              className="micro font-medium"
              data-sources={labels?.disclaimers?.sources}
            >
              {labels?.disclaimers?.title}
            </h4>
          )}
          <ul className="list-none flex flex-col gap-12">
            {disclaimers.map((disclaimer, index) => (
              <li className="text-secondary" key={`${disclaimer}-${index}`}>
                <small className="micro">{disclaimer}</small>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export const ProductsOverlayViewServiceOptions = ({
  labels,
  items,
}: ProductsOverlayViewServiceOptionsProps) => {
  return (
    <div className="text-secondary">
      {labels && (
        <span data-sources={labels?.included?.sources}>
          {labels?.included?.title}
        </span>
      )}
      {items && (
        <ul>
          {items?.map((service: any) => (
            <li key={`service-${service?.id}`}>
              {service?.content?.displayName?.value}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
