import { type SiteSlug, sites } from '@volvo-cars/market-sites';

const toValidSiteSlug = (s: string) => {
  return (sites[s as SiteSlug] ? s : 'uk') as SiteSlug;
};

export const getSiteSlugByWindowLocation = () => {
  if (typeof window === 'undefined') {
    return 'uk';
  }

  const location = window.location;
  const potentialSiteSlugFromLocation = location.pathname.split('/')[1];

  if (potentialSiteSlugFromLocation === 'master') {
    return 'uk';
  }

  return toValidSiteSlug(potentialSiteSlugFromLocation);
};
