import React from 'react';

interface ErrorViewProps {
  title?: string;
  body?: string;
}

export const ErrorView: React.FC<ErrorViewProps> = ({ title, body }) => {
  return (
    <div>
      <div>
        {title && <h2 className="heading-2 mb-16">{title}</h2>}
        {body && <p>{body}</p>}
      </div>
    </div>
  );
};
