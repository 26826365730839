import { useMemo } from 'react';
import { CarPriceHookReturnType, CarPriceProps } from './car-price.types';
import {
  getDefaultVariant,
  getSupportedModelSlugs,
} from './car-price.utils/car-price.utils';
import { getTranslatedPrices } from './car-price.utils/car-price.utils.get-translated-prices';
import { useCarPriceContext } from './car-price.provider';

export const useCarPrice = ({
  edition,
  modelYear,
  variant: variantInput,
  forceVariant,
  monthlySalesModelOverride,
  purchaseSalesModelOverride,
  layout,
  hideDiscountPricingSaveLabel = false,
  hideDiscountPricingStrikethroughPrice = false,
  noPriceFallback,
  ...props
}: CarPriceProps): CarPriceHookReturnType => {
  const {
    content = {},
    prices = {},
    settings = {},
    isLoading: isPriceDataLoading,
    refetchPrices,
  } = useCarPriceContext();
  const variant = getDefaultVariant(variantInput, forceVariant);
  const translatedPrices = useMemo(
    () =>
      getTranslatedPrices({
        settings,
        prices,
        edition,
        modelYear,
        variant,
        monthlySalesModelOverride,
        purchaseSalesModelOverride,
        content,
        hideDiscountPricingSaveLabel,
        hideDiscountPricingStrikethroughPrice,
        ...props,
      }),
    [
      prices,
      settings,
      edition,
      modelYear,
      variant,
      monthlySalesModelOverride,
      purchaseSalesModelOverride,
      content,
      hideDiscountPricingSaveLabel,
      hideDiscountPricingStrikethroughPrice,
      props,
    ],
  );

  const supportedModels = useMemo(
    () =>
      getSupportedModelSlugs({
        variant,
        prices,
        content,
        settings,
        monthlyPriceSalesModel: translatedPrices?.monthlyPriceSalesModel,
        purchasePriceSalesModel: translatedPrices?.purchasePriceSalesModel,
        ...props,
      }),
    [content, prices, settings, translatedPrices, variant, props],
  );

  return useMemo(
    () => ({
      ...translatedPrices,
      layout,
      noPriceFallback,
      refetchPrices,
      loading: isPriceDataLoading ?? false,
      supportedModels,
    }),
    [
      translatedPrices,
      layout,
      noPriceFallback,
      refetchPrices,
      isPriceDataLoading,
      supportedModels,
    ],
  );
};
