import React from 'react';
import type { FinanceDetailsViewFinancialOptionDescriptionsProps } from '../finance-details.types';

export const FinanceDetailsViewFinancialOptionDescriptions = ({
  displayName,
  description,
  testId,
}: FinanceDetailsViewFinancialOptionDescriptionsProps) => {
  if (!displayName) return null;

  return (
    <div className="flex-col gap-24">
      <h1 data-testid={`${testId}-title`} className="heading-2">
        {displayName}
      </h1>
      <p className="font-16">{description}</p>
    </div>
  );
};
