'use client';
import React from 'react';
import {
  CampaignBanner as BaseCampaignBanner,
  type CampaignBannerProps as BaseCampaignBannerProps,
} from 'src/components/organisms/CampaignBanner';
import { Spacing } from '../../layout/Spacing';
import { useQueries } from 'src/hooks/useQueries';
import { type EngineType } from 'src/types/queries';
import { findCarAndFormatDisclaimer } from 'src/utils/queries';

export interface CampaignBannerProps extends BaseCampaignBannerProps {
  type: 'campaignBanner';
  model?: string;
  engineType?: EngineType;
}

export const CampaignBanner: React.FC<
  React.PropsWithChildren<CampaignBannerProps>
> = ({ disclaimer, model, engineType, ...props }) => {
  const { graphCars } = useQueries();

  let formattedDisclaimer = disclaimer;
  if (model && engineType && disclaimer) {
    formattedDisclaimer = findCarAndFormatDisclaimer({
      disclaimer,
      graphCars,
      model,
      engineType,
    });
  }

  return (
    <Spacing>
      <BaseCampaignBanner {...props} disclaimer={formattedDisclaimer} />
    </Spacing>
  );
};
