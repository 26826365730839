'use client';

import React, { ForwardedRef } from 'react';
import { Props, LoadingStrategy, DefaultLoadingStrategy } from './Image.types';
import ImageNative from './ImageNative';
import ImageWithJsLazy from './ImageWithJsLazy';
import { EAGER, NATIVE_LAZY, JS_LAZY } from './constants';

export type ImageRefProp = {
  imageRef?: ForwardedRef<HTMLImageElement>;
};

/**
 *  @deprecated
 *  Use ResponsiveImage or BaseImage components from '@vcc-package/media' instead.
 **/
const Image: React.FC<React.PropsWithChildren<Props & ImageRefProp>> = ({
  src,
  loading,
  objectFit,
  aspectRatio,
  disableAutoOptimize,
  sources,
  imageRef,
  fetchPriority,
  imageDensity,
  ...props
}) => {
  const loadingStrategy = getLoadingStrategy(loading);
  const { default: defaultSource } = sources || {};
  const source = src || defaultSource || '';

  const commonProps = {
    src,
    objectFit,
    aspectRatio,
    source,
    sources,
    loadingStrategy,
    disableAutoOptimize,
    fetchPriority,
  };

  if (loadingStrategy === NATIVE_LAZY || loadingStrategy === EAGER) {
    return (
      <ImageNative
        imageRef={imageRef}
        {...props}
        {...commonProps}
        imageDensity={imageDensity}
      />
    );
  }

  return <ImageWithJsLazy loading={loading} {...props} {...commonProps} />;
};

function getLoadingStrategy(loading?: DefaultLoadingStrategy): LoadingStrategy {
  if (loading === EAGER) return EAGER;
  if (loading === 'native-lazy') return NATIVE_LAZY;

  return JS_LAZY;
}

export default Image;
