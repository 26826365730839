import type {
  FlexFormConfiguration,
  Validation,
} from '@vcc-package/leads-utils/api';
import {
  FlexFormLegalFieldType,
  TranslationKey,
} from '@vcc-package/leads-utils/api';

export const EXTRA_BREAKPOINT_MEDIA_QUERY = '@media (max-width: 767px)';

enum FieldNames {
  EMAIL = 'email',
  MOBILE = 'mobilephone',
}

interface IOptin {
  fieldName: string;
  translationKey: string;
  requiredField: FieldNames | undefined;
  requiredFieldDescriptionKey: string | undefined;
}

interface IValidatedOptin extends IOptin {
  requiredField: FieldNames;
  requiredFieldDescriptionKey: string;
}

const optinsWithValidationMap: { [x: string]: IOptin } = {
  [FlexFormLegalFieldType.EMAIL_OPTIN]: {
    fieldName: FlexFormLegalFieldType.EMAIL_OPTIN,
    translationKey: TranslationKey.EMAIL_OPTIN_FIELD_LABEL,
    requiredField: undefined,
    requiredFieldDescriptionKey: undefined,
  },
  [FlexFormLegalFieldType.VALIDATED_EMAIL_OPTIN]: {
    fieldName: FlexFormLegalFieldType.EMAIL_OPTIN,
    translationKey: TranslationKey.EMAIL_OPTIN_FIELD_LABEL,
    requiredField: FieldNames.EMAIL,
    requiredFieldDescriptionKey: 'EmailOptinRequiredDescription',
  },
  [FlexFormLegalFieldType.PHONE_OPTIN]: {
    fieldName: FlexFormLegalFieldType.PHONE_OPTIN,
    translationKey: TranslationKey.PHONE_OPTION_FIELD_LABEL,
    requiredField: undefined,
    requiredFieldDescriptionKey: undefined,
  },
  [FlexFormLegalFieldType.VALIDATED_PHONE_OPTIN]: {
    fieldName: FlexFormLegalFieldType.PHONE_OPTIN,
    translationKey: TranslationKey.PHONE_OPTION_FIELD_LABEL,
    requiredField: FieldNames.MOBILE,
    requiredFieldDescriptionKey:
      TranslationKey.PHONE_OPTIN_REQUIRED_DESCRIPTION,
  },
  [FlexFormLegalFieldType.SMS_OPTIN]: {
    fieldName: FlexFormLegalFieldType.SMS_OPTIN,
    translationKey: TranslationKey.SMS_OPTIN_FIELD_LABEL,
    requiredField: undefined,
    requiredFieldDescriptionKey: undefined,
  },
  [FlexFormLegalFieldType.VALIDATED_SMS_OPTIN]: {
    fieldName: FlexFormLegalFieldType.SMS_OPTIN,
    translationKey: TranslationKey.SMS_OPTIN_FIELD_LABEL,
    requiredField: FieldNames.MOBILE,
    requiredFieldDescriptionKey: TranslationKey.SMS_OPTIN_REQUIRED_DESCRIPTION,
  },
  [FlexFormLegalFieldType.MAIL_OPTIN]: {
    fieldName: FlexFormLegalFieldType.MAIL_OPTIN,
    translationKey: TranslationKey.MAIL_OPTIN_FIELD_LABEL,
    requiredField: undefined,
    requiredFieldDescriptionKey: undefined,
  },
  [FlexFormLegalFieldType.SOCIAL_OPTIN]: {
    fieldName: FlexFormLegalFieldType.SOCIAL_OPTIN,
    translationKey: TranslationKey.SOCIAL_OPTIN_FIELD_LABEL,
    requiredField: undefined,
    requiredFieldDescriptionKey: undefined,
  },
  [FlexFormLegalFieldType.ONE_MARKETING_CONSENT_OPTIN]: {
    fieldName: FlexFormLegalFieldType.ONE_MARKETING_CONSENT_OPTIN,
    translationKey: TranslationKey.ONE_MARKETING_CONSENT_FIELD_LABEL,
    requiredField: undefined,
    requiredFieldDescriptionKey: undefined,
  },
};

export const applyOptinValidation = (
  validationRules: { [k: string]: Validation },
  validatedOptins: IValidatedOptin[],
  watchValues: { [x: string]: any },
): { [k: string]: Validation } => {
  for (const vo of validatedOptins) {
    // If the required field exists in the validation rules.
    if (validationRules[vo.requiredField]) {
      //Set required if optin is set to true (or the field was already required)
      validationRules[vo.requiredField] = {
        ...validationRules[vo.requiredField],
        required:
          validationRules[vo.requiredField].required ||
          watchValues[vo.fieldName],
      };
    } else if (validationRules[vo.requiredField + 'async']) {
      //If the async version of the field exists, set required to true if optin is true (or the field was already required).
      validationRules[vo.requiredField + 'async'] = {
        ...validationRules[vo.requiredField + 'async'],
        required:
          validationRules[vo.requiredField + 'async'].required ||
          watchValues[vo.fieldName],
      };
    }
  }
  return validationRules;
};

export const getOptin = (shortName: FlexFormLegalFieldType) =>
  optinsWithValidationMap[shortName] ?? {
    fieldName: '',
    translationKey: '',
    requiredField: undefined,
    requiredFieldDescriptionKey: undefined,
  };

export const getValidatedOptins = (
  formConfig: FlexFormConfiguration | null,
): IValidatedOptin[] =>
  formConfig?.legal?.fields
    ?.map(getOptin)
    .filter((o: IOptin): o is IValidatedOptin => !!o.requiredField) ?? [];
