'use client';
import React from 'react';

import { useFormContext } from 'react-hook-form';
import type { FieldProps } from '@vcc-package/leads-utils/types';
import { FlexFormTestID } from '@vcc-package/leads-utils/types';
import {
  FlexFormFieldType,
  TranslationKey,
} from '@vcc-package/leads-utils/api';
import { getTranslationKey } from '@vcc-package/leads-utils/util';
import {
  getInputErrorMessages,
  useValidation,
} from '@vcc-package/leads-utils/hooks';

import { ValidatedField } from '../../flexible-forms/components';

export const EmailField = ({
  translate,
  validation,
  customTranslation,
  hideRequired,
}: FieldProps): JSX.Element | null => {
  const { control } = useFormContext();
  const messages = getInputErrorMessages(translate);
  const customTranslationKeys = getTranslationKey(
    customTranslation,
    FlexFormFieldType.EMAIL,
  );
  const { regexValidate } = useValidation(validation?.email);

  return (
    <ValidatedField
      hideRequired={hideRequired}
      key="email"
      name={FlexFormFieldType.EMAIL}
      testid={FlexFormTestID.Email}
      label={
        customTranslationKeys
          ? translate(customTranslationKeys.label)
          : translate(TranslationKey.EMAIL_FIELD_LABEL)
      }
      inputType="Email"
      autoComplete="email"
      control={control}
      validate={regexValidate}
      messages={messages}
      {...validation.email}
    />
  );
};
