import React from 'react';
import {
  GetIsInteractiveProps,
  GetLinkVariantProps,
  GetOnClickProps,
  RenderSecondaryInfoDefaultProps,
} from './list-item.types';

export const getIsInteractive = ({
  href,
  nonInteractive,
  onClick,
  hasChildren,
}: GetIsInteractiveProps) =>
  !nonInteractive && (!!href || !!onClick || hasChildren);

export const getTitleColor = ({
  interactive,
  onPress,
  onHover,
  isPriceBreakDown = false,
}: {
  interactive: boolean;
  onPress: boolean;
  onHover: boolean;
  isPriceBreakDown?: boolean;
}): string => {
  if (!interactive) return 'text-primary';

  return onPress
    ? 'text-secondary'
    : !isPriceBreakDown && onHover
      ? 'text-accent-blue'
      : 'text-primary';
};

export const getOnClick = ({ href, onClick, event }: GetOnClickProps) => {
  if (typeof onClick === 'function') {
    !href && event?.preventDefault();
    onClick(event);
  }
};

export const getLinkVariant = ({
  children,
  isOpen,
  linkText,
  linkTextClosed,
  linkTextOpened,
  linkVariant,
  nonInteractive,
}: GetLinkVariantProps) => {
  if (linkVariant && linkVariant !== 'default') return linkVariant;

  const hasChildren = React.Children.count(children) > 0;
  const linkTextValue = (isOpen ? linkTextOpened : linkTextClosed) || linkText;

  return nonInteractive
    ? 'non-interactive'
    : linkTextValue
      ? 'text'
      : hasChildren
        ? 'chevron-expandable'
        : 'chevron-link';
};

export const renderSecondaryInfoDefault = ({
  secondaryInfo,
  secondaryInfoSources,
  testId,
}: RenderSecondaryInfoDefaultProps) => (
  <small
    className="micro pb-4"
    data-sources={secondaryInfoSources}
    aria-label={secondaryInfo}
    data-testid={`${testId}-secondary-info`}
  >
    {secondaryInfo}
  </small>
);
