import { type GraphCarProps } from 'src/services/federated-graph/getGraphCars';
import {
  EngineType,
  type PriceSummaryFieldsFragment,
  type SharedCarFieldsFragment,
} from 'src/types/queries';
import { detailsMap, detailsRegex, detailsWithOnlyValue } from './constants';
import type {
  Details,
  Price,
} from '@vcc-www/offer-comparison/OfferComparison.types';

export const extractPrice = (
  price: PriceSummaryFieldsFragment['price'],
): Price => ({
  amount: price?.amount ?? null,
  value: price?.display ?? null,
});

export const compareEngineType = (
  carOneEngineType?: EngineType | null,
  carTwoEngineType?: EngineType | null,
): boolean =>
  (carOneEngineType &&
    carTwoEngineType &&
    carOneEngineType === carTwoEngineType) ||
  [carOneEngineType, carTwoEngineType].every((cat) =>
    [EngineType.NHEV, EngineType.HEV].includes(cat as EngineType),
  );

export const getLowestPrice = (
  carOnePrice?: Price,
  carTwoPrice?: Price,
): Price => {
  if (carOnePrice?.amount && carTwoPrice?.amount) {
    return carOnePrice?.amount < carTwoPrice?.amount
      ? carOnePrice
      : carTwoPrice;
  } else if (carOnePrice?.value && !carTwoPrice?.value) {
    return carOnePrice;
  } else if (!carOnePrice?.value && carTwoPrice?.value) {
    return carTwoPrice;
  } else {
    return { value: null, amount: null };
  }
};

export const isCarOnePriceLower = (
  carOnePrice?: Price,
  carTwoPrice?: Price,
) => {
  if (!carOnePrice?.value && carTwoPrice?.value) {
    return false;
  } else if (
    carOnePrice?.value &&
    carTwoPrice?.value &&
    carOnePrice?.amount &&
    carTwoPrice?.amount
  ) {
    return carOnePrice.amount < carTwoPrice.amount;
  }
  // Returns 'true' if neither carOnePrice and carTwoPrice exists or if only carOnePrice exists.
  return true;
};

export const getDetails = (
  technicalData?: SharedCarFieldsFragment['technicalData'],
): Details | undefined => {
  if (technicalData) {
    return Object.keys(technicalData).reduce((acc: Details, curr) => {
      const item = technicalData[curr as keyof typeof technicalData];

      if (
        !item ||
        typeof item === 'string' ||
        !Object.values(detailsMap).includes(item.key)
      )
        return acc;

      acc[item.key] = {
        key: item.key,
        label: item.label,
        value: item.formatted,
      };

      return acc;
    }, {});
  }
};

export const formatTextWithCarDetails = (text: string, details: Details) =>
  text?.replace(detailsRegex, (_, key) => {
    const field = details?.[detailsMap[key as keyof typeof detailsMap]];

    if (detailsWithOnlyValue.includes(key)) {
      return field?.value;
    }

    return `${field?.label} ${field?.value}`;
  }) ?? '';

export const findCarAndFormatDisclaimer = ({
  disclaimer,
  graphCars,
  model,
  engineType,
}: {
  disclaimer: string;
  graphCars: GraphCarProps[];
  model: string;
  engineType: EngineType;
}) => {
  const {
    details,
    modelName = '',
    driveline = '',
  } = graphCars.find(
    ({ familyId, engineType: engType }) =>
      familyId === model && engType === engineType,
  ) || {};

  return details
    ? formatTextWithCarDetails(disclaimer, {
        ...details,
        modelName: { key: 'modelName', label: '', value: modelName as string },
        driveline: { key: 'driveline', label: '', value: driveline as string },
      })
    : disclaimer;
};
