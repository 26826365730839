export const getUrlParamsAsString = ({
  searchParams,
  excludedParams = [],
}: {
  searchParams: Readonly<URLSearchParams | null>;
  excludedParams?: string[];
}) => {
  if (!searchParams) {
    return '';
  }
  const entries = Object.fromEntries(searchParams.entries());
  excludedParams.forEach((param) => {
    delete entries[param];
  });

  return new URLSearchParams(entries).toString();
};
