'use client';
import React from 'react';

import { useFormContext } from 'react-hook-form';
import type { FieldProps } from '@vcc-package/leads-utils/types';
import { FlexFormTestID } from '@vcc-package/leads-utils/types';
import {
  FlexFormFieldType,
  TranslationKey,
} from '@vcc-package/leads-utils/api';
import { getTranslationKey } from '@vcc-package/leads-utils/util';
import {
  getInputErrorMessages,
  useValidation,
} from '@vcc-package/leads-utils/hooks';

import { ValidatedField } from '../../flexible-forms/components';

export const PostalCodeField = ({
  translate,
  validation,
  customTranslation,
  hideRequired,
}: FieldProps): JSX.Element | null => {
  const { control } = useFormContext();
  const messages = getInputErrorMessages(translate);
  const customTranslationKeys = getTranslationKey(
    customTranslation,
    FlexFormFieldType.POSTAL_CODE,
  );
  const { regexValidate } = useValidation(validation?.postalcode);

  return (
    <ValidatedField
      hideRequired={hideRequired}
      key="postal-code"
      name={FlexFormFieldType.POSTAL_CODE}
      testid={FlexFormTestID.PostalCode}
      label={
        customTranslationKeys
          ? translate(customTranslationKeys.label)
          : translate(TranslationKey.POSTAL_CODE_FIELD_LABEL)
      }
      messages={messages}
      control={control}
      inputType="Text"
      validate={regexValidate}
      {...validation.postalcode}
    />
  );
};
