import React from 'react';
import { unescapeHTML } from '@vcc-www/utils/unescapeHtml';
import { LinkEntry } from '@volvo-cars/content-management-client';
import { Track } from '@volvo-cars/tracking';

const DiscoveryCardPartContent = ({
  label,
  heading,
  bodyText,
  cta,
}: {
  heading: string;
  label?: string;
  bodyText?: string;
  cta?: LinkEntry;
}) => (
  <div slot="main">
    {label && <p className="font-16 text-secondary">{label}</p>}
    <h2 className="heading-2 mt-16">{heading}</h2>
    {bodyText && (
      <p className="font-16 text-secondary mt-16">{unescapeHTML(bodyText)}</p>
    )}
    {cta?.href && (
      <Track eventLabel={`${cta.text}|${cta.href}`} eventAction="button|click">
        <a
          className="button-outlined mt-24 w-fit"
          href={cta.href}
          target={cta.target}
          aria-label={cta.accessibilityLabel}
        >
          {cta.text}
        </a>
      </Track>
    )}
  </div>
);

export default DiscoveryCardPartContent;
