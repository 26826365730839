import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { PART_EXCHANGE_VRM_LOOKUP } from '../queries/partExchangeVRMLookupQuery';
import {
  PartExchangeVRMLookupQuery,
  PartExchangeVRMLookupQueryVariables,
} from '../queries/queries.types';

export const getPartExchangeCarDetails = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  registrationNumber: string,
  mileage: string,
) => {
  try {
    const { data, loading, error } = await apolloClient.query<
      PartExchangeVRMLookupQuery,
      PartExchangeVRMLookupQueryVariables
    >({
      query: PART_EXCHANGE_VRM_LOOKUP,
      fetchPolicy: 'no-cache',
      variables: {
        input: {
          vrm: registrationNumber,
          mileage: mileage,
        },
      },
    });

    return { data, loading, error };
  } catch (err) {
    console.error(err);
    return {
      data: {} as PartExchangeVRMLookupQuery,
      loading: false,
      error: err,
    };
  }
};
