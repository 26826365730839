import React from 'react';
import { Step } from './step/Step';
import type { IStep, TStep } from '../types';
import { StepsProps } from './Steps';

type Props = {
  children?: TStep[] | TStep;
  'data-testid'?: string;
  items?: IStep[];
  setTestId?: (name?: string | string[], index?: number | undefined) => string;
  stepStyle?: StepsProps['stepStyle'];
};

export const StepsList = ({
  children,
  'data-testid': testID,
  items,
  setTestId,
  stepStyle,
}: Props) => {
  if (React.Children.count(children))
    return <ol data-testid={testID}>{children}</ol>;

  if (!items || items?.length === 0) return null;

  return (
    <ol data-testid={testID} className="p-0">
      {items?.map((step, i) => (
        <Step
          body={step.body}
          title={step.title}
          ticked={step.ticked}
          subBody={step.subBody}
          key={i}
          orderNumber={i + 1}
          isLast={i === items.length - 1}
          stepStyle={stepStyle}
          data-testid={`step-${i}`}
          setTestId={setTestId}
        />
      ))}
    </ol>
  );
};
