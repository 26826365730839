import { LeadsApp } from '@vcc-package/leads-utils/constants';

import { FlexFormFieldType } from '../../../api';
import type { LeadsContextProperties } from '../../../src/context';
import type { ABTest } from '../types';

export const conversationalForm: ABTest = (context: LeadsContextProperties) => {
  if (
    !context.features.ConversationalForm ||
    context.appId !== LeadsApp.QuoteRequest ||
    !context?.formConfig
  )
    return context;

  console.log('Running experiment: conversationalForm');

  const transformedMainRows = [
    [FlexFormFieldType.FIRSTNAME, FlexFormFieldType.SURNAME],
    [FlexFormFieldType.LEGAL_ADDRESS_ASYNC],
    [FlexFormFieldType.MOBILE_PHONE_ASYNC, FlexFormFieldType.EMAIL_ASYNC],
    [FlexFormFieldType.NOTES],
  ];

  context.formConfig.main.rows = transformedMainRows;

  context.formConfig.header = {
    heading: 'RequestQuote',
    subHeading: null,
  };
  return context;
};
