'use client';

import { useEffect, useState } from 'react';

import type { UrlData } from '@vcc-package/leads-utils/types';
import {
  readFromSession,
  saveToSession,
  StorageKey,
} from '@vcc-package/leads-utils/util';
import type { LeadsApp } from '@vcc-package/leads-utils/constants';

type Props = {
  appId: LeadsApp;
  /** Server side rendered UrlData */
  ssrUrlData: UrlData;
};

/**
 * Hook for handling Url data (originalUrl & referralUrl)
 */
export const useUrlData = ({ appId, ssrUrlData }: Props): UrlData => {
  const session = readFromSession<UrlData>(appId, StorageKey.urlData);
  const [urlData, setUrlData] = useState<UrlData | null>(session);

  useEffect(() => {
    const newOriginalUrl = ssrUrlData.originalUrl && !session?.originalUrl;
    const newReferralUrl = ssrUrlData.referralUrl && !session?.referralUrl;

    if (newOriginalUrl || newReferralUrl) {
      const data: UrlData = {
        originalUrl: newOriginalUrl
          ? ssrUrlData.originalUrl
          : session?.originalUrl ?? null,
        referralUrl: newReferralUrl
          ? ssrUrlData.referralUrl
          : session?.referralUrl ?? null,
      };

      setUrlData(data);
      saveToSession(appId, StorageKey.urlData, data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    urlData ?? {
      originalUrl: '',
      referralUrl: '',
    }
  );
};
