import React from 'react';
import { useYesNoQuestion } from './yes-no-question.hook';
import { YesNoQuestionInputProps } from './yes-no-question.types';
import { YesNoQuestionView } from './yes-no-question.view';

export const YesNoQuestion: React.FC<
  React.PropsWithChildren<YesNoQuestionInputProps>
> = ({
  defaultValue,
  title,
  titleVariant,
  description,
  descriptionVariant,
  titleWeight,
  yesText,
  noText,
  onChange,
}) => {
  const { options, onChangeHandler } = useYesNoQuestion({
    yesText,
    noText,
    onChange,
  });

  if (title.length === 0 || yesText.length === 0 || noText.length === 0) {
    return null;
  }

  return (
    <YesNoQuestionView
      defaultValue={defaultValue}
      title={title}
      titleVariant={titleVariant}
      options={options}
      description={description}
      descriptionVariant={descriptionVariant}
      titleWeight={titleWeight}
      onChange={onChangeHandler}
    />
  );
};
