/**
 * Custom image loader to use Akamai for image optimization instead of Next.
 */
export default function loader({ src, width }: { src: string; width: number }) {
  try {
    const url = new URL(src);

    // Images served from Akamai will have both the h and w query params set.
    // These will be added based on the size of the orignal uploaded image (right?)
    url.searchParams.delete('h');
    url.searchParams.delete('w');

    url.searchParams.set('w', width.toString());

    // If we can get Akamai to disable the feature where it's making judgement calls
    // based on client pixel density, we won't need to add this query param.
    url.searchParams.set('imdensity', '1');

    return url.toString();
  } catch {
    // Either this is a relative URL or something else that can't be parsed by URL.
    return `${src}?w=${width}`;
  }
}
