import React, { useMemo } from 'react';
import { UseListItemProps } from './list-item.types';
import { getIsInteractive } from './list-item.utils';

export const useListItem = ({
  children,
  href,
  nonInteractive,
  onClick,
}: UseListItemProps) => {
  const hasChildren = useMemo(
    () => React.Children.count(children) > 0,
    [children],
  );

  const interactive = useMemo(
    () => getIsInteractive({ href, nonInteractive, onClick, hasChildren }),
    [hasChildren, href, nonInteractive, onClick],
  );
  return {
    interactive,
    hasChildren,
  };
};
