'use client';
import type { Dispatch, SetStateAction } from 'react';
import React, { useEffect } from 'react';

import type { MarketVehicleConfiguration } from '@vcc-package/leads-utils/api';
import { cssJoin } from '@volvo-cars/css/utils';

import { ModelImage } from '../imageComponents/LeadsImages';

import { VehicleTitle } from './VehicleTitle';

type VehicleCardProps = {
  vehicle: MarketVehicleConfiguration;
  onClick: () => void;
  selected: boolean;
  setLeftStartpos: Dispatch<SetStateAction<number | null>>;
};

export const VehicleCard = ({
  vehicle,
  onClick,
  selected = false,
  setLeftStartpos,
}: VehicleCardProps) => {
  const ref = React.createRef<HTMLDivElement>();

  useEffect(() => {
    if (selected && ref?.current?.offsetLeft) {
      setLeftStartpos(ref.current.offsetLeft);
    }
  }, [selected, setLeftStartpos, ref]);

  return (
    <div className="w-full text-center" ref={ref}>
      <button
        type="button"
        role="tab"
        onClick={onClick}
        className="tap-area w-full border-2 border-transparent rounded-sm w-full bg-always-white"
        style={{ width: 90 }}
      >
        <div className="mx-auto">
          <ModelImage
            model={vehicle}
            sizes={[{ screenWidth: 0, imageWidth: 90, type: 'none' }]}
            className="aspect-1/1 m-0 mb-4"
            imageClassName={cssJoin(
              'rounded-sm hover:border-primary border transition-colors',
              selected && 'border-2 border-accent-blue',
            )}
          />
        </div>
        <VehicleTitle vehicle={vehicle} variant="small" />
      </button>
    </div>
  );
};
