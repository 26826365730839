import { ThemeBreakpointName } from 'vcc-ui';
import { LoadingStrategy } from './Image.types';

export const NATIVE_LAZY = 'nativeLazy';
export const LAZY = 'lazy';
export const EAGER = 'eager';
export const JS_LAZY = 'jsLazy';

type ComponentState = {
  hasLoaded: boolean;
  shouldListenToViewport: boolean;
  shouldLoad: boolean;
};

type ReadyState = 'initial' | 'loading' | 'loaded';

export const STATES: Record<
  LoadingStrategy,
  Record<ReadyState, ComponentState>
> = {
  [JS_LAZY]: {
    initial: {
      hasLoaded: false,
      shouldListenToViewport: true,
      shouldLoad: false,
    },
    loading: {
      hasLoaded: false,
      shouldListenToViewport: false,
      shouldLoad: true,
    },
    loaded: {
      hasLoaded: true,
      shouldListenToViewport: false,
      shouldLoad: true,
    },
  },
  [NATIVE_LAZY]: {
    initial: {
      hasLoaded: true,
      shouldListenToViewport: false,
      shouldLoad: true,
    },
    loading: {
      hasLoaded: true,
      shouldListenToViewport: false,
      shouldLoad: true,
    },
    loaded: {
      hasLoaded: true,
      shouldListenToViewport: false,
      shouldLoad: true,
    },
  },
  [EAGER]: {
    initial: {
      hasLoaded: true,
      shouldListenToViewport: false,
      shouldLoad: true,
    },
    loading: {
      hasLoaded: true,
      shouldListenToViewport: false,
      shouldLoad: true,
    },
    loaded: {
      hasLoaded: true,
      shouldListenToViewport: false,
      shouldLoad: true,
    },
  },
};

export type SizesBreakpoints = Partial<{
  [key in ThemeBreakpointName]: string;
}>;

export const SIZES_BREAKPOINT_MEDIA_QUERIES: SizesBreakpoints = {
  /** \< 480 (identical to untilM) */
  onlyS: '(max-width: 479px)',
  /** \< 480 */
  untilM: '(max-width: 479px)',
  /** \> 480 */
  fromM: '(min-width: 480px)',
  /** \>= 480 && < 1024 */
  onlyM: '((min-width: 480px) and (max-width: 1023px))',
  /** \< 1024 */
  untilL: '(max-width: 1023px)',
  /** \>= 1024 */
  fromL: '(min-width: 1024px)',
  /** \>= 1024 && < 1600 */
  onlyL: '((min-width: 1024px) and (max-width: 1599px))',
  /** \< 1600 */
  untilXL: '(max-width: 1599px)',
  /** \>= 1600 */
  fromXL: '(min-width: 1600px)',
  /** \>= 1600 (identical to fromXL) */
  onlyXL: '(min-width: 1600px)',
};
