/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConfigurationHistorySummary } from '../models/ConfigurationHistorySummary';
import type { FullConfiguration } from '../models/FullConfiguration';
import type { MarketConfiguration } from '../models/MarketConfiguration';
import type { PurposeType } from '../models/PurposeType';
import type { StoredMarketConfiguration } from '../models/StoredMarketConfiguration';
import type { StoredMarketConfigurationHistory } from '../models/StoredMarketConfigurationHistory';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ConfigurationService {
  /**
   * @returns MarketConfiguration OK
   * @throws ApiError
   */
  public static getConfiguration({
    siteSlug,
    clientId,
    purpose,
  }: {
    siteSlug: string;
    clientId?: string;
    purpose?: PurposeType;
  }): CancelablePromise<MarketConfiguration> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/forms-backend/v1/{siteSlug}/configuration/marketconfiguration',
      path: {
        siteSlug: siteSlug,
      },
      query: {
        clientId: clientId,
        purpose: purpose,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static getConfigurationextract({
    settings,
    siteSlug,
    clientId,
  }: {
    settings: Array<string> | null;
    siteSlug: string;
    clientId?: string;
  }): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/forms-backend/v1/{siteSlug}/configuration/configurationextract',
      path: {
        siteSlug: siteSlug,
      },
      query: {
        settings: settings,
        clientId: clientId,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns FullConfiguration OK
   * @throws ApiError
   */
  public static getFull({
    siteSlug,
    clientId,
    purpose,
  }: {
    siteSlug: string;
    clientId?: string;
    purpose?: PurposeType;
  }): CancelablePromise<FullConfiguration> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/forms-backend/v1/{siteSlug}/configuration',
      path: {
        siteSlug: siteSlug,
      },
      query: {
        clientId: clientId,
        purpose: purpose,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns boolean OK
   * @throws ApiError
   */
  public static updateFallback({
    siteSlug,
  }: {
    siteSlug: string;
  }): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/forms-backend/v1/{siteSlug}/configuration/update-fallback',
      path: {
        siteSlug: siteSlug,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns StoredMarketConfiguration OK
   * @throws ApiError
   */
  public static getFallback({
    siteSlug,
  }: {
    siteSlug: string;
  }): CancelablePromise<StoredMarketConfiguration> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/forms-backend/v1/{siteSlug}/configuration/get-fallback',
      path: {
        siteSlug: siteSlug,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns ConfigurationHistorySummary OK
   * @throws ApiError
   */
  public static getMarketHistory({
    siteSlug,
  }: {
    siteSlug: string;
  }): CancelablePromise<Array<ConfigurationHistorySummary>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/forms-backend/v1/{siteSlug}/configuration/marketconfiguration/history/all',
      path: {
        siteSlug: siteSlug,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns StoredMarketConfigurationHistory OK
   * @throws ApiError
   */
  public static getMarketHistoryById({
    siteSlug,
    id,
  }: {
    siteSlug: string;
    id?: string;
  }): CancelablePromise<StoredMarketConfigurationHistory> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/forms-backend/v1/{siteSlug}/configuration/marketconfiguration/history/by-id',
      path: {
        siteSlug: siteSlug,
      },
      query: {
        id: id,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }
}
