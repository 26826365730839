'use client';

import type { PropsWithChildren } from 'react';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import type { DealerModel } from '@vcc-package/leads-utils/api';
import {
  getInsideSalesRetailer,
  isInsideSalesRetailer,
  readFromSession,
  removeSessionItem,
  saveToSession,
  StorageKey,
} from '@vcc-package/leads-utils/util';

import { useLeadsContext } from './leadsContext';

export interface RetailerContextValues {
  /** Callback for clearing the selected retailer. Clears state & session */
  clearSelectedRetailer: () => void;
  /** Tracks if the selected retailer is the Inside Sales Retailer */
  isInsideSales: boolean;
  /** Callback when a new retailer has been selected. Sets state & saves to session */
  onRetailerSelect: (r: DealerModel) => void;
  /** Retailer saved in session */
  savedRetailer: DealerModel | undefined;
  /** Currently selected retailer, uses query parameters "retailerid" & "customretailerid" */
  selectedRetailer: DealerModel | undefined;
}

export const useRetailer = () => useContext(RetailerContext);
const RetailerContext = createContext<RetailerContextValues>({
  clearSelectedRetailer: () => {},
  isInsideSales: false,
  onRetailerSelect: () => {},
  savedRetailer: undefined,
  selectedRetailer: undefined,
});

export const RetailerContextProvider = ({
  children,
}: PropsWithChildren<unknown>) => {
  const {
    API,
    appId,
    marketConfig,
    translate,
    isEmbeddableForms,
    navigation: { clearSelectedRetailerQuery, query },
  } = useLeadsContext();
  const retailerId = query.retailerid ?? query.customretailerid;
  const session =
    readFromSession<DealerModel>(appId, StorageKey.selectedRetailer) ??
    undefined;

  const [savedRetailer, setSavedRetailer] = useState(session);
  const [selectedRetailer, setSelectedRetailer] = useState<
    DealerModel | undefined
  >();

  useEffect(
    () => {
      const fetchRetailer = async (retailerId: string) => {
        // 1. Check if we have the specific retailer already saved (skipping unnecessary fetch)
        if (retailerId === savedRetailer?.dealerId) {
          setSelectedRetailer(savedRetailer);
          return;
        }

        // 2. Else fetch the retailer data. Take into account if the inside sales retailer is selected
        try {
          const insideSalesRetailer = marketConfig.insideSalesRetailer;
          const isInsideSales = isInsideSalesRetailer({
            retailerId,
            insideSalesRetailer,
          });
          const retailer =
            isInsideSales && insideSalesRetailer
              ? getInsideSalesRetailer({ insideSalesRetailer, translate })
              : await API.getDealer(retailerId);
          if (retailer) onRetailerSelect(retailer);
        } catch (e: any) {
          console.error("Couldn't find retailer with ID:", retailerId);
        }
      };
      // If a retailerId query-parameter is supplied, fetch and set the retailer data
      if (retailerId) {
        fetchRetailer(retailerId);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    isEmbeddableForms ? [retailerId, savedRetailer, marketConfig] : [],
  );

  const clearSelectedRetailer = useCallback(() => {
    clearSelectedRetailerQuery();
    setSavedRetailer(undefined);
    setSelectedRetailer(undefined);
    removeSessionItem(appId, StorageKey.selectedRetailer);
  }, [clearSelectedRetailerQuery, appId]);

  const isInsideSales = useMemo(() => {
    return isInsideSalesRetailer({
      retailerId: selectedRetailer?.dealerId,
      insideSalesRetailer: marketConfig.insideSalesRetailer,
    });
  }, [marketConfig.insideSalesRetailer, selectedRetailer?.dealerId]);

  const onRetailerSelect = useCallback(
    (retailer: DealerModel) => {
      setSavedRetailer(retailer);
      setSelectedRetailer(retailer);
      saveToSession(appId, StorageKey.selectedRetailer, retailer);
    },
    [appId],
  );

  return (
    <RetailerContext.Provider
      value={{
        clearSelectedRetailer,
        isInsideSales,
        onRetailerSelect,
        savedRetailer,
        selectedRetailer,
      }}
    >
      {children}
    </RetailerContext.Provider>
  );
};
