import React, { useMemo } from 'react';
import Head from 'next/head';
import { richTextRegex } from 'src/components/organisms/MarkdownOrRichText';
import { parseMarkdown } from '@vcc-package/text';

export const FAQSchema: React.FC<
  React.PropsWithChildren<{
    questions: { question: string; answer: string }[];
  }>
> = ({ questions }) => {
  /**
   * https://schema.org/FAQPage
   */
  const jsonLd = useMemo(
    () =>
      JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: questions.map(({ question, answer }) => {
          const answerIsMarkdown = richTextRegex.test(answer);

          return {
            '@type': 'Question',
            name: question,
            acceptedAnswer: {
              '@type': 'Answer',
              text: answerIsMarkdown ? parseMarkdown(answer) : answer,
            },
          };
        }),
      }),
    [questions],
  );

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: jsonLd }}
      />
    </Head>
  );
};
