import React from 'react';

export default function IconMagnifyingGlass() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1471 7.07353C12.1471 9.87556 9.87556 12.1471 7.07353 12.1471C4.2715 12.1471 2 9.87556 2 7.07353C2 4.2715 4.2715 2 7.07353 2C9.87556 2 12.1471 4.2715 12.1471 7.07353ZM10.9991 11.7081C9.94041 12.6057 8.57019 13.1471 7.07353 13.1471C3.71921 13.1471 1 10.4278 1 7.07353C1 3.71921 3.71921 1 7.07353 1C10.4278 1 13.1471 3.71921 13.1471 7.07353C13.1471 8.57119 12.605 9.94224 11.7063 11.0012L15.3524 14.6473L14.6453 15.3544L10.9991 11.7081Z"
        fill="black"
        fillOpacity="0.92"
      />
    </svg>
  );
}
