import { getModelSlugByKey } from '@vcc-package/offers-utils';
import { MapPricesProps, MapPricesReturnType } from '../car-price.types';

export const mapPrices = ({
  prices,
  edition,
  modelYear,
  monthlySalesModelOverride,
  purchaseSalesModelOverride,
  defaultMonthlySalesModel,
  ...props
}: MapPricesProps): MapPricesReturnType => {
  let modelSlug;

  if ('modelSlug' in props) {
    modelSlug = props?.modelSlug;
  } else {
    modelSlug = getModelSlugByKey(props?.model ?? '') ?? '';
  }
  const selectedPriceModel = edition
    ? prices?.[modelSlug ?? '']?.editions?.[edition]
    : modelYear
      ? prices?.[modelSlug ?? '']?.modelYears?.[modelYear]
      : prices?.[modelSlug ?? ''];

  const monthlyPriceSalesModel =
    monthlySalesModelOverride ?? defaultMonthlySalesModel;
  const monthlyPrice = selectedPriceModel?.[monthlyPriceSalesModel];
  const monthlyPriceSource = [
    `data:${selectedPriceModel?.sourcePrefix ?? ''}.${monthlyPriceSalesModel}`,
  ];
  const monthlyWasPrice =
    selectedPriceModel?.discount?.was?.[monthlyPriceSalesModel];
  const monthlyWasPriceSource = [
    `data:${
      selectedPriceModel?.sourcePrefix ?? ''
    }.was.${monthlyPriceSalesModel}`,
  ];
  const monthlySavingAmount =
    selectedPriceModel?.discount?.saving?.[monthlyPriceSalesModel];

  const monthlySavingAmountSource = `data:${
    selectedPriceModel?.sourcePrefix ?? ''
  }.saving.${monthlyPriceSalesModel}`;

  const purchasePriceSalesModel =
    purchaseSalesModelOverride ??
    (selectedPriceModel?.default ? 'default' : 'cash');
  const purchasePrice = selectedPriceModel?.[purchasePriceSalesModel];
  const purchasePriceSource = [
    `data:${selectedPriceModel?.sourcePrefix ?? ''}.${purchasePriceSalesModel}`,
  ];
  const purchaseWasPrice =
    selectedPriceModel?.discount?.was?.[purchasePriceSalesModel];
  const purchaseWasPriceSource = [
    `data:${
      selectedPriceModel?.sourcePrefix ?? ''
    }.was.${purchasePriceSalesModel}`,
  ];
  const purchaseSavingAmount =
    selectedPriceModel?.discount?.saving?.[purchasePriceSalesModel];
  const purchaseSavingAmountSource = `data:${
    selectedPriceModel?.sourcePrefix ?? ''
  }.saving.${purchasePriceSalesModel}`;

  return {
    monthlyPrice,
    monthlyPriceSource,
    monthlyWasPrice,
    monthlyWasPriceSource,
    monthlySavingAmount,
    monthlySavingAmountSource,
    purchasePrice,
    purchasePriceSource,
    purchaseWasPrice,
    purchaseWasPriceSource,
    purchaseSavingAmount,
    purchaseSavingAmountSource,
    monthlyPriceSalesModel,
    purchasePriceSalesModel,
    includesTax: selectedPriceModel?.includesTax ?? false,
  };
};
