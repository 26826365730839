import React from 'react';
import { Block, Flex } from 'vcc-ui';
import { useTracker } from '@volvo-cars/tracking';
import { Disclaimer as SharedDisclaimer } from '@vcc-package/text';
import { MarkdownOrRichText } from './MarkdownOrRichText';
import { ResponsiveImage } from '@vcc-package/media';
import type {
  ImageEntry,
  LinkEntry,
} from '@volvo-cars/content-management-client';

export interface CampaignBannerProps {
  anchorId?: string;
  heading: string;
  body: string;
  cta: LinkEntry;
  link?: LinkEntry;
  landscapeImage?: ImageEntry;
  portraitImage?: ImageEntry;
  disclaimer?: string;
  contentPosition: 'left' | 'right';
  textAlignOnMobile: 'left' | 'center';
  hideImageOverlay?: boolean;
  hideCbvLabel?: boolean;
}

export const CampaignBanner: React.FC<
  React.PropsWithChildren<CampaignBannerProps>
> = ({
  anchorId,
  heading,
  body,
  cta,
  link,
  landscapeImage,
  portraitImage,
  disclaimer,
  contentPosition = 'left',
  textAlignOnMobile = 'left',
  hideImageOverlay,
  hideCbvLabel,
}) => {
  const alignContentRight = contentPosition === 'right';
  console.log('CampaignBannerProps', landscapeImage);
  return (
    <Wrapper id={anchorId}>
      <Container hasDisclaimer={!!disclaimer}>
        <InnerContainer alignContentRight={alignContentRight}>
          <Content alignContentRight={alignContentRight}>
            <TextAndLinkContainer alignContentRight={alignContentRight}>
              <TextContainer
                alignCenterOnMobile={textAlignOnMobile === 'center'}
              >
                {!hideCbvLabel && (
                  <p
                    className="uppercase font-medium text-always-white"
                    data-testid="campaign-banner__cbv-label"
                  >
                    Care by Volvo
                  </p>
                )}
                <Title>{heading}</Title>
                <MarkdownOrRichText
                  data-testid="campaign-banner__body"
                  className="text-always-white"
                  text={body}
                />
              </TextContainer>
              <LinkSection
                alignCenterOnMobile={textAlignOnMobile === 'center'}
                cta={cta}
                link={link}
                heading={heading}
              />
            </TextAndLinkContainer>
          </Content>
          <BackgroundImage
            landscapeImage={landscapeImage}
            portraitImage={portraitImage}
            alignContentRight={alignContentRight}
            hideImageOverlay={hideImageOverlay}
          />
        </InnerContainer>
      </Container>
      {disclaimer && (
        <DisclaimerContainer>
          <SharedDisclaimer text={disclaimer} />
        </DisclaimerContainer>
      )}
    </Wrapper>
  );
};

const Wrapper: React.FC<React.PropsWithChildren<{ id?: string }>> = ({
  id,
  ...props
}) => <Flex id={id} data-testid="campaign-banner" {...props} />;

const Container: React.FC<
  React.PropsWithChildren<{ hasDisclaimer?: boolean }>
> = ({ hasDisclaimer, ...props }) => (
  <Flex
    extend={{
      width: '100%',
      maxWidth: '1280px',
      margin: '0 auto',
    }}
    {...props}
  />
);

const InnerContainer: React.FC<
  React.PropsWithChildren<{
    alignContentRight?: boolean;
  }>
> = ({ alignContentRight, ...props }) => (
  <Flex
    extend={({ theme: { color } }) => ({
      position: 'relative',
      width: '100%',
      margin: '0 auto',
      minHeight: '496px',
      overflow: 'hidden',
      background: color.background.primary,

      fromM: {
        padding: 0,
        flexDirection: alignContentRight ? 'row-reverse' : 'row',
      },
    })}
    {...props}
  />
);

const Content: React.FC<
  React.PropsWithChildren<{
    alignContentRight?: boolean;
  }>
> = ({ alignContentRight, ...props }) => (
  <Flex
    extend={({ theme: { baselineGrid } }) => ({
      zIndex: 2,
      height: '100%',
      flex: '1 1 60%',
      justifyContent: 'space-between',
      width: `calc(100% - ${6 * baselineGrid}px)`,
      margin: `${3 * baselineGrid}px auto`,

      fromM: {
        width: '100%',
        margin: 'auto 0',
        padding: alignContentRight
          ? `${5 * baselineGrid}px ${6 * baselineGrid}px ${
              6 * baselineGrid
            }px 0`
          : `${5 * baselineGrid}px 0 ${6 * baselineGrid}px ${
              6 * baselineGrid
            }px`,
      },

      fromL: {
        padding: `${10 * baselineGrid}px ${12 * baselineGrid}px ${
          12 * baselineGrid
        }px`,
      },
    })}
    {...props}
  />
);

const TextContainer: React.FC<
  React.PropsWithChildren<{
    alignCenterOnMobile?: boolean;
  }>
> = ({ alignCenterOnMobile, ...props }) => (
  <Flex
    extend={({ theme: { baselineGrid } }) => ({
      margin: `0 0 ${2 * baselineGrid}px`,

      ...(alignCenterOnMobile && {
        textAlign: 'center',
      }),

      fromM: {
        margin: `0 0 ${2 * baselineGrid}px`,
        width: 'auto',
        maxWidth: '368px',
        textAlign: 'left',
      },
    })}
    {...props}
  />
);

const TextAndLinkContainer: React.FC<
  React.PropsWithChildren<{
    alignContentRight?: boolean;
  }>
> = ({ alignContentRight, ...props }) => (
  <Flex
    extend={{
      height: '100%',
      flex: '1 1 auto',
      margin: alignContentRight ? '0 0 0 auto' : '0 auto 0 0',
      justifyContent: 'center',
    }}
    {...props}
  />
);

const Overlay: React.FC<
  React.PropsWithChildren<{ alignContentRight?: boolean }>
> = ({ alignContentRight }) => (
  <Block
    extend={{
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      zIndex: 1,
      background: `linear-gradient(${
        alignContentRight ? '-90deg' : '90deg'
      }, #000000 0%, rgba(0, 0, 0, 0) 100%)`,
      opacity: 0.5,
    }}
  />
);

const Title: React.FC<React.PropsWithChildren<unknown>> = (props) => (
  <h2
    data-testid="campaign-banner__title"
    className="heading-2 text-always-white py-8"
    style={{ whiteSpace: 'pre-wrap' }}
  >
    {props.children}
  </h2>
);

const LinkContainer: React.FC<
  React.PropsWithChildren<{
    alignCenterOnMobile?: boolean;
  }>
> = ({ alignCenterOnMobile, ...props }) => (
  <Flex
    extend={{
      margin: `auto auto ${alignCenterOnMobile ? '0 auto' : '0 0'}`,
      alignItems: alignCenterOnMobile ? 'center' : 'flex-start',

      fromM: {
        alignItems: 'flex-start',
        margin: '0 auto 0 0',
        padding: 0,
      },
    }}
    {...props}
  />
);

const ButtonContainer: React.FC<React.PropsWithChildren<unknown>> = (props) => (
  <Block
    extend={({ theme: { baselineGrid } }) => ({
      margin: `0 0 ${baselineGrid}px`,
    })}
    {...props}
  />
);

const BackgroundImage: React.FC<
  React.PropsWithChildren<{
    landscapeImage?: ImageEntry;
    portraitImage?: ImageEntry;
    alignContentRight?: boolean;
    hideImageOverlay?: boolean;
  }>
> = ({
  landscapeImage,
  portraitImage,
  alignContentRight,
  hideImageOverlay,
}) => {
  if (!portraitImage || (!landscapeImage?.src && !portraitImage?.src)) {
    return null;
  }

  return (
    <div className="absolute w-full h-full">
      {!hideImageOverlay && <Overlay alignContentRight={alignContentRight} />}
      <ResponsiveImage
        className="w-full h-full"
        images={{
          sm: portraitImage,
          md: landscapeImage,
        }}
        alt={portraitImage.alt}
      />
    </div>
  );
};

const DisclaimerContainer: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Flex
    data-testid="campaign-banner__disclaimer"
    extend={({ theme: { baselineGrid } }) => ({
      width: `calc(100% - ${6 * baselineGrid}px)`,
      margin: `${4 * baselineGrid}px auto 0`,
      fromL: { maxWidth: '1280px' },
    })}
    {...props}
  />
);

const LinkSection: React.FC<
  React.PropsWithChildren<{
    alignCenterOnMobile?: boolean;
    cta?: LinkEntry;
    link?: LinkEntry;
    heading: string;
  }>
> = ({ alignCenterOnMobile, cta, link, heading }) => {
  const ga4Tracker = useTracker({}, { mode: 'ga4' });
  const eventCategory = 'campaign banner';

  return (
    <LinkContainer alignCenterOnMobile={alignCenterOnMobile}>
      <ButtonContainer>
        {cta?.href && cta?.text && (
          <a
            data-testid="campaign-banner__cta"
            className="button-filled bg-always-white text-primary"
            href={cta.href}
            onClick={() => {
              ga4Tracker.customEvent({
                eventCategory,
                eventAction: 'button|click',
                eventLabel: `${heading}-${cta.text}`,
              });
            }}
          >
            {cta?.text}
          </a>
        )}
      </ButtonContainer>
      {link?.href && link?.text && (
        <a
          className="button-text text-always-white"
          data-testid="campaign-banner__link"
          onClick={() => {
            ga4Tracker.customEvent({
              eventCategory,
              eventAction: 'link|click',
              eventLabel: `${heading}-${link.text}`,
            });
          }}
          href={link?.href}
        >
          {link?.text}
        </a>
      )}
    </LinkContainer>
  );
};
