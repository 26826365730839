import React from 'react';
import { TitleWithBodyProps } from '../part-exchange.types';

export const TitleWithBody = ({ title, body }: TitleWithBodyProps) => {
  return (
    <div className="mb-32">
      <h1
        data-testid="part-exchange-tool:title-with-body-title"
        className="heading-1 font-20"
      >
        {title}
      </h1>
      {!!body && (
        <p
          data-testid="part-exchange-tool:title-with-body-body"
          className="font-16 text-secondary mt-8"
        >
          {body}
        </p>
      )}
    </div>
  );
};
