import type { MutableRefObject } from 'react';
import { useEffect, useState } from 'react';
import { debounce } from './debounce';

export const useSubNavigationScroll = (
  navRef: MutableRefObject<HTMLElement | null>,
) => {
  const [scrollPosition, setScrollPosition] = useState<
    'start' | 'middle' | 'end'
  >('middle');

  useEffect(() => {
    const handleScroll = () => {
      if (navRef.current) {
        const currentScrollEl = navRef.current;
        const children = Array.from(currentScrollEl.querySelectorAll('a'));
        const direction = getComputedStyle(currentScrollEl).direction;
        const [firstLink, lastLink] =
          direction === 'rtl'
            ? [children[children.length - 1], children[0]]
            : [children[0], children[children.length - 1]];

        const firstLinkRect = firstLink.getBoundingClientRect();
        const lastLinkRect = lastLink.getBoundingClientRect();
        const navRect = navRef.current.getBoundingClientRect();

        if (Math.round(lastLinkRect.right) <= Math.round(navRect.right)) {
          setScrollPosition(direction === 'rtl' ? 'start' : 'end');
        } else if (Math.round(firstLinkRect.left) >= Math.round(navRect.left)) {
          setScrollPosition(direction === 'rtl' ? 'end' : 'start');
        } else {
          setScrollPosition('middle');
        }
      }
    };

    const debouncedHandleScroll = debounce(handleScroll, 10);
    handleScroll();
    if (navRef.current) {
      const currentEl = navRef.current;
      currentEl.addEventListener('scroll', debouncedHandleScroll);

      return () => {
        if (currentEl) {
          currentEl.removeEventListener('scroll', debouncedHandleScroll);
        }
      };
    }
  }, [navRef]);

  return { scrollPosition };
};
