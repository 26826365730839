import type { PropsWithChildren } from 'react';
import React, { useCallback, useState } from 'react';
import { LeadsApp } from '../constants';

import type {
  RequestApiModel,
  RequestResponseApiModel,
} from '@vcc-package/leads-utils/api';
import { TranslationKey } from '@vcc-package/leads-utils/api';

import { useSetOverlayTitle } from '../hooks/useSetOverlayTitle';
import type { EmbeddableFormsWrapperProps } from './EmbeddableFormsWrapper';
import { EmbeddedForm } from './EmbeddedForm';
import { EmbeddedThankYouComponent } from './EmbeddedThankYouComponent';
import { QuotePurposeSelector } from './QuotePurposeSelector';
import { LeadsContextProperties } from '@vcc-package/leads-utils';

type EmbeddableFormsPartialProps = PropsWithChildren<{
  value: LeadsContextProperties | undefined;
  parentProps: PropsWithChildren<EmbeddableFormsWrapperProps>;
}>;
const EmbeddableFormsPartial = ({
  value,
  parentProps,
  children,
}: EmbeddableFormsPartialProps) => {
  const isQR = value?.appId === LeadsApp.QuoteRequest;
  const [submitData, setSubmitData] = useState<RequestApiModel | null>(null);
  const inOverlay = parentProps?.setOverlayTitle !== undefined;

  const title = value?.translate(
    value?.formConfig?.header.subHeading ||
      TranslationKey.REDES_RETAILER_MAP_SUB_HEADING,
  );

  useSetOverlayTitle(parentProps?.setOverlayTitle, title ?? '', inOverlay);

  const OnError =
    parentProps.OnError ??
    ((type, e) => {
      console.error(type, e);
    });

  const OnSubmitWrapped = useCallback(
    (submitData: RequestApiModel, apiResponse: RequestResponseApiModel) => {
      setSubmitData(submitData);

      if (parentProps.OnSubmit) {
        parentProps.OnSubmit(submitData, apiResponse);
      }
    },
    [parentProps],
  );

  if (submitData) {
    return <EmbeddedThankYouComponent />;
  }

  if (isQR) {
    return (
      <div className="flex flex-col mx-auto w-full">
        {parentProps.childrenPosition === 'top' ? children : <></>}
        <QuotePurposeSelector inOverlay={inOverlay}>
          <EmbeddedForm
            OnError={OnError}
            OnSubmit={OnSubmitWrapped}
            hideModelImage
            additionalInfo={parentProps.additionalInfo}
            inOverlay={inOverlay}
          />
        </QuotePurposeSelector>
        {parentProps.childrenPosition === 'bottom' ? children : <></>}
      </div>
    );
  }

  return (
    <EmbeddedForm
      OnError={OnError}
      OnSubmit={OnSubmitWrapped}
      additionalInfo={parentProps.additionalInfo}
      hideModelImage
      inOverlay={inOverlay}
    />
  );
};

export default EmbeddableFormsPartial;
