import React from 'react';
import { CarDetailsProps } from './part-exchange.types';
import { Icon } from '@volvo-cars/react-icons';
import { usePartExchange } from './part-exchange.hook';
import { TitleWithBody } from './components/TitleWithBody';
import { useTranslate } from './part-exchange.dictionaries-provider';
import { ErrorView } from './components/ErrorView';

interface CarDetailsViewProps {
  carDetails: CarDetailsProps;
  loading?: boolean;
}

const CarDetailsView: React.FC<CarDetailsViewProps> = ({
  carDetails,
  loading,
}) => {
  const { registrationNumber, mileage, error } = usePartExchange();
  const translate = useTranslate();

  if (error) {
    return <ErrorView title={error.title} body={error.body} />;
  }

  return (
    <div data-testid="part-exchange-tool:car-info">
      <TitleWithBody
        title={translate('financeOptions.partExchangeTool.carDetailsTitle')}
        body={translate('financeOptions.partExchangeTool.carDetailsBody')}
      />
      {loading ? (
        <div className="flex justify-center items-center pb-32">
          <progress className="spinner w-24 text-secondary" />
        </div>
      ) : (
        <CarInfoContainer
          registrationNumber={registrationNumber}
          mileage={mileage}
          carInfo={carDetails}
        />
      )}
    </div>
  );
};

const CarInfoContainer: React.FC<{
  registrationNumber: string;
  mileage: string;
  carInfo: CarDetailsProps;
}> = ({ registrationNumber, mileage, carInfo }) => (
  <div className="p-24 bg-secondary">
    <div className="mb-16">
      <p className="font-16 font-medium">{`${carInfo.make} ${carInfo.model}`}</p>
      <p className="font-16 font-medium">{registrationNumber}</p>
    </div>
    <div className="flex-row flex-wrap">
      <CarInfoItem text={carInfo.transmission} />
      <CarInfoItem text={`${mileage} miles`} />
      <CarInfoItem text={`${carInfo.doors} doors`} />
      <CarInfoItem text={`${carInfo.registrationYear}`} />
      <CarInfoItem text={`${carInfo.engineSize} litre engine`} />
    </div>
  </div>
);

const CarInfoItem: React.FC<{ text: string }> = ({ text }) => (
  <div className="flex-row gap-8 mb-16" style={{ flex: '50%' }}>
    <div className="justify-center">
      <Icon icon="checkmark" size={12} />
    </div>
    <p>{text}</p>
  </div>
);

export default CarDetailsView;
