'use client';
import { useEffect, useState } from 'react';

export const useVolvoCarsChat = () => {
  const chat = usePollChat();
  const [openChat, setOpenChat] = useState(() => chat?.open);
  const [availability, setAvailability] = useState<ChatAvailability>(
    chat ? chat.availability : 'pending',
  );
  const [state, setState] = useState<ChatState>(chat ? chat.state : 'closed');
  const [inactiveEnabled, setInactiveEnabled] = useState<boolean | undefined>(
    chat?.inactiveEnabled,
  );

  useEffect(() => {
    if (!chat) return;
    const handleOpen = () => setState('open');
    const handleOpening = () => setState('opening');
    const handleClose = () => setState('closed');
    const handleOnline = () => setAvailability('online');
    const handleOffline = () => setAvailability('offline');

    const handleListeners = (type: 'add' | 'remove') => {
      const action =
        type === 'add' ? chat.addEventListener : chat.removeEventListener;
      action('open', handleOpen);
      action('opening', handleOpening);
      action('close', handleClose);
      action('online', handleOnline);
      action('offline', handleOffline);
    };
    handleListeners('add');

    return () => handleListeners('remove');
  }, [chat]);

  useEffect(() => {
    if (!chat) return;
    if (!openChat) setOpenChat(() => chat.open);
    if (!availability) setAvailability(chat.availability);
    if (!state) setState(chat.state);
    if (!inactiveEnabled) setInactiveEnabled(chat.inactiveEnabled);
  }, [chat, openChat, availability, state, inactiveEnabled]);

  return {
    state,
    availability,
    openChat,
    config: chat?.activeChatRouteConfig,
    inactiveEnabled,
  };
};

const usePollChat = () => {
  const [chat, setChat] = useState(
    typeof window !== 'undefined' ? window.VolvoCarsChat : undefined,
  );
  // If chat is already exposed to window, do nothing.
  // Otherwise listen for an event that the navigation service dispatches when chat is exposed
  useEffect(() => {
    if (chat) {
      return;
    } else if (window.VolvoCarsChat) {
      setChat(window.VolvoCarsChat);
    } else {
      const handleChatExposed = () => setChat(window.VolvoCarsChat);
      window.addEventListener('__VolvoCarsChatExposed__', handleChatExposed);
      return () => {
        window.removeEventListener(
          '__VolvoCarsChatExposed__',
          handleChatExposed,
        );
      };
    }
  }, [chat]);

  return chat;
};

// TODO: Consider using actual types from apps/site-navigation/src/salesforce
export type ChatAvailability = 'disabled' | 'pending' | 'online' | 'offline';
export type ChatState =
  | 'opening'
  | 'open'
  | 'closed'
  | 'minimized'
  | 'containerclosed';
type EventType =
  | 'online'
  | 'offline'
  | 'open'
  | 'openinactive'
  | 'close'
  | 'opening'
  | 'minimized'
  | 'containerclosed';
type Listener = (event: CustomEvent | Event) => any;

declare global {
  interface Window {
    VolvoCarsChat: {
      addEventListener: (eventType: EventType, listener: Listener) => void;
      removeEventListener: (eventType: EventType, listener: Listener) => void;
      availability: ChatAvailability;
      state: ChatState;
      inactiveEnabled: boolean;
      buttonLabel: string;
      open: () => void;
      activeChatRouteConfig: {
        pathPattern: string;
        disableChat?: boolean;
        config?: Record<any, any>;
        whatsAppPhoneNumber?: string;
        fbMessengerPageName?: string;
      } | null;
    };
  }
}
