'use client';
import React from 'react';

import { useFormContext } from 'react-hook-form';
import type {
  CustomTranslations,
  Translator,
} from '@vcc-package/leads-utils/types';
import {
  FlexFormLegalFieldType,
  TranslationKey,
} from '@vcc-package/leads-utils/api';
import { getTranslationKey } from '@vcc-package/leads-utils/util';

import { ParagraphRenderer } from '../../components';

export type OneMarketingConsentAutoOptinProps = {
  translate: Translator;
  customTranslation: CustomTranslations;
};

const OneMarketingConsentAutoOptin = ({
  translate,
  customTranslation,
}: OneMarketingConsentAutoOptinProps): JSX.Element => {
  const { register } = useFormContext();

  const customTranslationKeys = getTranslationKey(
    customTranslation,
    FlexFormLegalFieldType.ONE_MARKETING_CONSENT_OPTIN,
  );
  const label = customTranslationKeys
    ? translate(customTranslationKeys.label)
    : translate(TranslationKey.ONE_MARKETING_CONSENT_FIELD_LABEL);

  return (
    <div className="micro">
      <input
        hidden
        value="true"
        {...register(FlexFormLegalFieldType.ONE_MARKETING_CONSENT_OPTIN)}
      />
      <ParagraphRenderer paragraphs={[label]} />
    </div>
  );
};

export default OneMarketingConsentAutoOptin;
