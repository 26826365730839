export type rockarEnvs = 'qa' | 'prod';

const getRockarEnv = (url: string) => {
  const rockarEnv: rockarEnvs =
    url.includes('testwww.volvocars.com') ||
    url.includes('qawww.volvocars.com') ||
    url.includes('authoring.volvocars.com') ||
    url.includes('localhost')
      ? 'qa'
      : 'prod';
  return rockarEnv;
};

export default getRockarEnv;
