import {
  CAR_PRICE_VARIANT,
  VAT_DISPLAY_TEMPLATE,
} from '../car-price.constants';
import {
  CarPriceVariantType,
  GetVATLabelAndSourcesProps,
  GetSavingLabelAndSourcesProps,
  GetLabelAndSourcesByCarPriceVariantProps,
  GetSupportedModelSlugsProps,
  GetContentByVariantProps,
  GetContentByVariantReturnType,
} from '../car-price.types';

export const isEmptyObject = (obj: Record<string, unknown> | undefined) =>
  Object.keys(obj ?? {}).length === 0;

/**
 * If passed a variant, returns an alternative variant if the data we have doesn't support it
 *
 * @param variant
 * @param monthlyPrice
 * @param purchasePrice
 * @returns a variant
 */
export const normaliseVariant = (
  variant: CarPriceVariantType,
  monthlyPrice: string | undefined,
  purchasePrice: string | undefined,
) => {
  if (
    variant === CAR_PRICE_VARIANT.FROM_MONTHLY_OR_PURCHASE_PRICE ||
    variant === CAR_PRICE_VARIANT.MONTHLY_FROM_PURCHASE_FROM
  ) {
    if (!monthlyPrice) {
      return CAR_PRICE_VARIANT.PURCHASE_FROM;
    } else if (!purchasePrice) {
      return CAR_PRICE_VARIANT.MONTHLY_FROM;
    }
  }

  return variant;
};

/**
 * Returns the default variant based on the settings and the preferred variant
 *
 * @param preferredVariant
 * @param forceVariant
 * @returns a variant
 */
export const getDefaultVariant = (
  preferredVariant: CarPriceVariantType | undefined,
  forceVariant: boolean | undefined,
) => {
  const hasPreferredVariant = typeof preferredVariant !== 'undefined';
  if (forceVariant && hasPreferredVariant) {
    return preferredVariant;
  }

  // If we need to force a variant in a certain condition again, this is the place to do it
  //

  if (hasPreferredVariant) {
    return preferredVariant;
  }

  return CAR_PRICE_VARIANT.MONTHLY_FROM_PURCHASE_FROM;
};

export const getVATLabelAndSources = ({
  showVATLabel,
  includesTax,
  content,
}: GetVATLabelAndSourcesProps) => {
  if (!showVATLabel) return { label: '', sources: '' };
  if (
    !content?.includingVAT &&
    !content?.excludingVAT &&
    !content?.sourcePrefix
  )
    return { label: '', sources: '' };

  const label = VAT_DISPLAY_TEMPLATE?.replace(
    '{vat}',
    includesTax ? `${content?.includingVAT}` : `${content?.excludingVAT}`,
  );
  const sources = `${content?.sourcePrefix}.${
    includesTax ? 'includingVAT' : 'excludingVAT'
  }`;

  return { label, sources };
};

export const getSavingLabelAndSources = ({
  shouldShowSavingsElement,
  content,
  hasMonthlySavingAmount,
  hasPurchaseSavingAmount,
  monthlySavingAmountSource,
  purchaseSavingAmountSource,
}: GetSavingLabelAndSourcesProps) => {
  if (!shouldShowSavingsElement) return { label: '', sources: [] };
  const label = content?.save ?? '';
  const sources = [`${content?.sourcePrefix ?? ''}.save`];
  if (hasMonthlySavingAmount) {
    sources.push(monthlySavingAmountSource);
  }
  if (hasPurchaseSavingAmount) {
    sources.push(purchaseSavingAmountSource);
  }
  return { label, sources };
};

export const getLabelAndSourcesByCarPriceVariant = ({
  content,
  settings,
  monthlyPriceSalesModel,
}: GetLabelAndSourcesByCarPriceVariantProps) => {
  return {
    [String(CAR_PRICE_VARIANT.FROM_MONTHLY_OR_PURCHASE_PRICE)]: {
      label: content?.fromOr ?? '',
      labelSources: `${content?.sourcePrefix ?? ''}.fromOr`,
      hasMonthly: true,
      hasPurchase: true,
    },
    [String(CAR_PRICE_VARIANT.MONTHLY_FROM_PURCHASE_FROM)]: {
      label: content?.subscribeFromPurchaseFrom ?? '',
      labelSources: `${content?.sourcePrefix ?? ''}.subscribeFromPurchaseFrom`,
      hasMonthly: true,
      hasPurchase: true,
    },
    [String(CAR_PRICE_VARIANT.FROM_PURCHASE_PRICE)]: {
      label: content?.from ?? '',
      labelSources: `${content?.sourcePrefix ?? ''}.from`,
      hasMonthly: false,
      hasPurchase: true,
    },
    [String(CAR_PRICE_VARIANT.FROM_MONTHLY_PRICE)]: {
      label: content?.from ?? '',
      labelSources: `${content?.sourcePrefix ?? ''}.from`,
      hasMonthly: true,
      hasPurchase: false,
    },
    [String(CAR_PRICE_VARIANT.PURCHASE_FROM)]: {
      label: content?.purchaseFrom ?? '',
      labelSources: `${content?.sourcePrefix ?? ''}.purchaseFrom`,
      hasMonthly: false,
      hasPurchase: true,
    },
    [String(CAR_PRICE_VARIANT.MONTHLY_FROM)]:
      settings?.leaseFromLabelSalesModels?.includes(monthlyPriceSalesModel)
        ? {
            label: content?.leaseFrom ?? '',
            labelSources: `${content?.sourcePrefix ?? ''}.leaseFrom`,
            hasMonthly: true,
            hasPurchase: false,
          }
        : {
            label: content?.subscribeFrom ?? '',
            labelSources: `${content?.sourcePrefix ?? ''}.subscribeFrom`,
            hasMonthly: true,
            hasPurchase: false,
          },
  };
};

export const getContentByVariant = ({
  priceAndSourcesByCarPriceVariant,
  monthlyPrice,
  purchasePrice,
  monthlyPriceSource,
  monthlyWasPrice,
  monthlyWasPriceSource,
  purchasePriceSource,
  purchaseWasPrice,
  purchaseWasPriceSource,
  vatLabelSources,
  savingsLabelSources,
}: GetContentByVariantProps): GetContentByVariantReturnType =>
  Object.keys(priceAndSourcesByCarPriceVariant).reduce((prev, curr) => {
    const { hasMonthly, hasPurchase, label, labelSources } =
      priceAndSourcesByCarPriceVariant[curr];
    const hasPriceToShow = !!(
      (hasMonthly && monthlyPrice) ||
      (hasPurchase && purchasePrice)
    );

    return {
      ...prev,
      [curr]: {
        label,
        labelSources,
        translation: hasPriceToShow ? label : '',
        translationSources: hasPriceToShow
          ? [
              ...(labelSources ? [labelSources] : []),
              ...(hasMonthly && monthlyPriceSource ? [monthlyPriceSource] : []),
              ...(hasMonthly && monthlyWasPrice && monthlyWasPriceSource
                ? [monthlyWasPriceSource]
                : []),
              ...(hasPurchase && purchasePriceSource
                ? [purchasePriceSource]
                : []),
              ...(hasPurchase && purchaseWasPrice && purchaseWasPriceSource
                ? [purchaseWasPriceSource]
                : []),

              ...(vatLabelSources ? [vatLabelSources] : []),
              ...(savingsLabelSources ? [...savingsLabelSources] : []),
            ]
          : [],
      },
    };
  }, {});

/**
 * Returns an array of model slugs that Car Price would render for the current
 * variant/sales model if given the chance.
 * Essentially it filters out all model slugs from the returned price data that
 * wouldn't return a price for display.
 *
 * @returns string[]
 */
export const getSupportedModelSlugs = ({
  variant,
  prices,
  content,
  settings,
  monthlyPriceSalesModel,
  purchasePriceSalesModel,
}: GetSupportedModelSlugsProps) => {
  const { hasMonthly, hasPurchase } =
    getLabelAndSourcesByCarPriceVariant({
      content,
      settings,
      monthlyPriceSalesModel,
    })[variant ?? ''] ?? {};

  return Object.keys(prices ?? {}).filter(
    (key) =>
      (hasMonthly && prices?.[key]?.[monthlyPriceSalesModel]) ||
      (hasPurchase && prices?.[key]?.[purchasePriceSalesModel]),
  );
};
