import type { PropsWithChildren } from 'react';
import React from 'react';

import {
  AdditionalProps,
  FormLoadEventTracker,
  Header,
  LeadsForm,
  LeadsFormSubmitHandler,
  Page,
  useLeadsContext,
  useLeadsTracking,
  useModel,
  useRetailer,
} from '@vcc-package/leads-utils';
import {
  FlexFormFieldType,
  FlexFormLegalFieldType,
  PurposeType,
} from '@vcc-package/leads-utils/api';
import type { CreateRequestInput } from '@vcc-package/leads-utils/util';
import {
  StorageKey,
  convertStringToBoolean,
  createRequestFromFlexForm,
  getCampaignCode,
  getCarInfoFromFormValues,
  getGa3EventAction,
  getGa3EventCategory,
  getGtmFormTags,
  readFromSession,
  saveToSession,
  sendGASuccessEvent,
} from '@vcc-package/leads-utils/util';
import { useTracker } from '@volvo-cars/tracking';
import { LeadsApp } from '../constants';
import { useEmbeddableFormsTracking } from '../hooks/useEmbeddableFormsTracking';
import { EmbeddedFormProps } from './EmbeddedForm';
import { Spinner } from './Spinner';

export const FormPage = (props: PropsWithChildren<EmbeddedFormProps>) => {
  const leadsTracking = useLeadsTracking();
  const track = useTracker();
  const { selectedModel } = useModel();
  const { isInsideSales, selectedRetailer } = useRetailer();
  const {
    API,
    analytics,
    appId,
    formConfig,
    marketConfig,
    navigation: { pathname },
    purposeConfig,
    siteSlug,
    translate,
    translationKeys,
    urlData,
    purpose,
    consumerApp,
    isEmbeddableForms,
    navigation: { goTo, query },
    features,
    publicRuntimeConfig,
  } = useLeadsContext();

  useEmbeddableFormsTracking({
    inDialog: props?.inOverlay ?? false,
    appId,
    purpose,
    isEmbeddableForms,
    consumerApp,
  });

  const header = formConfig?.header;

  const b2bQuery = convertStringToBoolean(query.b2b);
  const retailerid = query.retailerid ?? query.customretailerid;
  const hideDealerPicker = !!retailerid && !!selectedRetailer;
  const redirected = !!readFromSession(
    LeadsApp.QuoteRequest,
    StorageKey.redirected,
  );
  const isVbs = !!query.cceurl || !!query.vbscardatapath;
  const salesChannel =
    isInsideSales || purpose === PurposeType.CBV_CALLBACK
      ? 'online'
      : undefined;

  const b2bOptions = {
    shouldRender: b2bQuery || !!features.showB2bSection,
    isB2B: b2bQuery,
  };

  // Callback: Hide products of interest if model query exists
  const hideFields: FlexFormFieldType[] = [];
  if (selectedModel?.pno34) {
    hideFields.push(FlexFormFieldType.PRODUCTS_OF_INTEREST);
  }

  const additionalParameters: AdditionalProps = {
    b2b: b2bOptions,
    hideDealerPicker: hideDealerPicker,
    hideFields: hideFields,
    useSingleColumn: props.inOverlay,
    inOverlay: props.inOverlay,
  };

  const sendRequest: LeadsFormSubmitHandler = async (
    formValues,
    recaptchaToken,
    setLoading,
    loading,
  ) => {
    if (loading) {
      return false;
    }
    if (purpose === null) {
      console.error('No purpose set for form');
      return false;
    }
    setLoading(true);

    const carInformation = getCarInfoFromFormValues(formValues);
    const description = formValues.description ?? '';

    formValues.description = carInformation
      ? `${carInformation} \nAdditional information: ${description}`
      : description;

    const isB2B = Boolean(formValues.business) || b2bOptions.isB2B;
    const campaignCode = getCampaignCode({ isB2B, purposeConfig, query });
    const brand = purpose === PurposeType.CBV_CALLBACK ? 'cbv' : query.brand;

    if (selectedModel?.pno34) {
      formValues[FlexFormFieldType.PRODUCTS_OF_INTEREST] = [
        selectedModel.pno34,
      ];
    }

    if (purpose === PurposeType.KEEP_ME_UPDATED) {
      formValues[FlexFormLegalFieldType.EMAIL_OPTIN] =
        formValues[FlexFormLegalFieldType.EMAIL_OPTIN] ?? true;
    }

    const requestInput: CreateRequestInput = {
      additionalInformation: {
        analytics,
        brand,
        business: isB2B,
        campaignCode: campaignCode ?? undefined,
        recaptchaToken: recaptchaToken,
        urlData,
      },
      formValues,
      languageId: marketConfig.lang ?? 'en',
      marketId: siteSlug,
      appId,
      query,
      retailerInformation: {
        retailerId: selectedRetailer?.dealerId || query.retailerid,
        customRetailerId: query.customretailerid,
      },
      consumerApp: consumerApp ?? pathname ?? 'unkown',
      embeddableFormsAdditionalInfo: props.additionalInfo,
      isEmbeddableForms,
      publicRuntimeConfig,
    };

    const request = createRequestFromFlexForm(requestInput);

    saveToSession(appId, StorageKey.submitData, request);

    const trackingData = leadsTracking?.formSubmit
      ? await leadsTracking.formSubmit({ b2b: isB2B })
      : undefined;
    const response = await API.sendRequest(request);
    const success = response !== undefined;

    if (success) {
      const { formId, formType } = getGtmFormTags({
        appId,
        isB2b: !!request.business,
        isVbs: isVbs,
        marketId: request.marketId,
      });
      sendGASuccessEvent(formId, formType, salesChannel);
      track.interaction({
        carDriveLineType: selectedModel?.gtm?.powerTrain,
        carModelName: selectedModel?.gtm?.carModel,
        deeplink: purpose === PurposeType.OFFER_REQUEST && redirected,
        eventAction: getGa3EventAction(pathname, query),
        eventCategory: getGa3EventCategory(appId),
        eventLabel: 'success',
        salesChannel: salesChannel,
      });

      if (trackingData) {
        track.formSubmit(trackingData);
      }

      const redirectUrl: string | undefined =
        response?.redirectUrl || query.redirecturl;
      if (redirectUrl && !isEmbeddableForms) {
        goTo(redirectUrl, { fullNavigation: true, external: true });
      } else {
        props.OnSubmit(request, response);
      }
    } else {
      console.error('Something went wrong with the form submit');
      setLoading(false);
    }
  };

  const heading = header?.heading
    ? translate(header.heading)
    : header?.heading ?? translate(translationKeys.formHeading);

  const subHeading = props.inOverlay
    ? undefined
    : header?.subHeading
      ? translate(header?.subHeading)
      : header?.subHeading ?? translate(translationKeys.formSubHeading);

  if (!formConfig) {
    return <Spinner />;
  }

  return (
    <>
      {!props.inOverlay && (
        <FormLoadEventTracker
          isB2b={b2bOptions.isB2B}
          isVbs={isVbs}
          salesChannel={salesChannel}
        />
      )}
      <Page>
        <Header heading={heading} subheading={subHeading} />
        <LeadsForm
          additionalProps={additionalParameters}
          onSubmit={sendRequest}
          onError={(errors, setLoading) => {
            setLoading(false);
            props.OnError('General', errors);
          }}
        >
          {props.children}
        </LeadsForm>
      </Page>
    </>
  );
};

export default FormPage;
