import React from 'react';

import { cssMerge } from '@volvo-cars/css/utils';

type SectionHeaderProps = {
  title: string;
  className?: string;
  testId?: string;
};

export function SectionHeader({
  title,
  className,
  testId = 'section-header',
}: SectionHeaderProps) {
  return (
    <h2
      className={cssMerge('font-20 font-medium mb-24', className)}
      data-testid={testId}
    >
      {title}
    </h2>
  );
}
