import type { Translator } from '@vcc-package/leads-utils/types';
import type {
  DealerModel,
  InsideSalesRetailer,
} from '@vcc-package/leads-utils/api';

/**
 * Check if a certain retailerId (parmaPartnerCode) is the Inside Sales Retailer's one.
 * Also checks if the Inside Sales Retailer is available AND IF it should be used.
 */
export const isInsideSalesRetailer = ({
  retailerId,
  insideSalesRetailer,
}: {
  retailerId?: string | null;
  insideSalesRetailer: InsideSalesRetailer | null;
}): boolean => {
  return (
    !!retailerId &&
    !!insideSalesRetailer &&
    !!insideSalesRetailer.useInsideSalesTransform &&
    insideSalesRetailer.retailerId === retailerId
  );
};

/**
 * Returns the market's configured Inside Sales Retailer in the form of an ordinary retailer
 * @returns DealerModel
 */
export const getInsideSalesRetailer = ({
  insideSalesRetailer,
  translate,
}: {
  insideSalesRetailer: InsideSalesRetailer;
  translate: Translator;
}): DealerModel => {
  const {
    retailerId,
    retailerNameTranslationKey,
    retailerAddressTranslationKey,
  } = insideSalesRetailer;

  return {
    dealerId: retailerId ?? '',
    name: translate(retailerNameTranslationKey ?? 'InsideSalesRetailerName'),
    phone: null,
    vccDealerId: null,
    distanceToAddress: null,
    generalContactEmail: null,
    address: {
      country: null,
      city: null,
      district: null,
      postalCode: null,
      addressLine1: translate(
        retailerAddressTranslationKey ?? 'InsideSalesRetailerAddress',
      ),
      addressLine2: null,
      addressLine3: null,
      addressLine4: null,
      addressLine5: null,
    },
    geoCode: null,
    timezone: null,
  };
};
