import React, { useMemo } from 'react';
import { Block, Text } from 'vcc-ui';
import DisplayPrice from '@vcc-www/display-price';
import Overlay from '@vcc-www/offers-overlay-with-link';
import ComparisonTable from '@vcc-www/comparison-table';
import {
  CarProps,
  OfferComparisonFinanceOption,
  OfferComparisonFinanceOptions,
  OverlayProps,
  Price,
  TableOfferEntity,
  TableProps,
} from './OfferComparison.types';
import { TrackingProvider } from '@volvo-cars/tracking';
import { SalesModelId } from '@vcc-www/api/finance-options';
import { financeOptionToLocalization, servicesToOffers } from '.';
import { CarPrice } from '@vcc-package/car-price';
import { SalesModels } from '@vcc-package/offers-utils';
import { CarPriceVariantType } from '@vcc-package/car-price/src/car-price.types';
import { useFeatureFlags } from '@vcc-www/feature-flags';

export const Table: React.FC<React.PropsWithChildren<TableProps>> = ({
  selectedCar,
  showMoreText,
  showLessText,
  financeOptions,
  detailsText,
  overlayTitle,
  overlayOffersTitle,
  onOverlayClick,
  onExpandClick,
  overlayCloseEventLabel,
  hidePrice,
}) => {
  const selectedCarWithSalesModelId = {
    ...selectedCar,
    price: {
      SUB: selectedCar.price.sub,
      SUB_FIXED: selectedCar.price.subFixed,
      CASH: selectedCar.price.cash,
      LOAN: selectedCar.price.loan,
      LEASE: selectedCar.price.lease,
    },
  };

  const salesModels = (
    Object.keys(selectedCarWithSalesModelId.price) as []
  ).filter(
    (key: SalesModelId) =>
      selectedCarWithSalesModelId.price[key]?.value &&
      Object.values(SalesModelId).includes(key),
  );

  const { data, config } = useTableProps(
    financeOptions,
    {
      detailsText,
      overlayTitle,
      overlayOffersTitle,
    },
    salesModels,
    selectedCarWithSalesModelId,
    onOverlayClick,
    overlayCloseEventLabel,
    hidePrice,
  );

  return (
    <ComparisonTable
      config={config}
      data={data}
      showMoreText={showMoreText}
      showLessText={showLessText}
      onExpandClick={onExpandClick}
      testId="offer-comparison"
    />
  );
};

const useTableProps = (
  financeOptions: OfferComparisonFinanceOptions,
  overlayProps: OverlayProps,
  salesModels: SalesModelId[],
  selectedCarWithSalesModelId: Omit<CarProps, 'price'> & {
    price: {
      [key in SalesModelId]?: Price;
    };
  },
  onOverlayClick?: TableProps['onOverlayClick'],
  overlayCloseEventLabel?: TableProps['overlayCloseEventLabel'],
  hidePrice?: boolean,
) => {
  const data = useMemo(() => {
    const obj = Object.entries(financeOptions).reduce<{
      [key: string]: TableOfferEntity;
    }>((acc, [salesModel, { services }]) => {
      services.forEach((service) => {
        if (!acc[service.displayName]) {
          acc[service.displayName] = {
            rowTitle: service.displayName,
            id: service.displayName,
          };
        }

        if (
          service.applicableCarModels?.includes(
            selectedCarWithSalesModelId?.modelId as string,
          )
        ) {
          acc[service.displayName][salesModel as SalesModelId] = true;
        }
      });
      return acc;
    }, {});

    return Object.values(obj);
  }, [financeOptions, selectedCarWithSalesModelId.modelId]);

  const { globalFlags } = useFeatureFlags();
  const useNewCarPrice = globalFlags.showCarPrice;

  const config = useMemo(
    () =>
      salesModels.map((salesModel, i) => {
        const price = selectedCarWithSalesModelId.price[salesModel]?.value;
        const modelId = selectedCarWithSalesModelId.modelId;
        const carPriceSalesModel = salesModel.toLowerCase() as SalesModels;
        const monthlySalesModel =
          carPriceSalesModel === 'sub_fixed' || carPriceSalesModel === 'sub'
            ? carPriceSalesModel
            : 'sub';
        let variant: CarPriceVariantType = 'FROM_MONTHLY_PRICE';
        if (carPriceSalesModel === 'cash') {
          variant = 'FROM_PURCHASE_PRICE';
        }
        return {
          mobileHeaderComponent: (
            <MobileHeader
              price={price ? price : undefined}
              modelId={modelId}
              monthlySalesModel={monthlySalesModel}
              variant={variant}
              overlayProps={overlayProps}
              financeOption={financeOptions[salesModel]}
              onOverlayClick={onOverlayClick}
              index={i}
              overlayCloseEventLabel={overlayCloseEventLabel}
              hidePrice={hidePrice}
              useNewCarPrice={useNewCarPrice}
            />
          ),
          headerComponent: (
            <DesktopHeader
              price={price ? price : undefined}
              modelId={modelId}
              monthlySalesModel={monthlySalesModel}
              variant={variant}
              overlayProps={overlayProps}
              financeOption={financeOptions[salesModel]}
              onOverlayClick={onOverlayClick}
              index={i}
              overlayCloseEventLabel={overlayCloseEventLabel}
              hidePrice={hidePrice}
              useNewCarPrice={useNewCarPrice}
            />
          ),
          getValue: (item: TableOfferEntity) => item[salesModel] || false,
        };
      }),
    [
      financeOptions,
      overlayProps,
      salesModels,
      selectedCarWithSalesModelId,
      onOverlayClick,
      overlayCloseEventLabel,
      hidePrice,
      useNewCarPrice,
    ],
  );

  // Making sure only the SUB services are rendered in the comparison list
  const filteredDataBySub = data.filter((item) => item.SUB);

  return { data: filteredDataBySub, config };
};

interface HeaderProps {
  modelId: string | undefined;
  price: string | undefined;
  monthlySalesModel: 'sub' | 'sub_fixed';
  variant: CarPriceVariantType;
  useNewCarPrice: boolean;
  overlayProps: OverlayProps;
  financeOption: OfferComparisonFinanceOption | undefined;
  onOverlayClick?: TableProps['onOverlayClick'];
  index?: number;
  overlayCloseEventLabel?: TableProps['overlayCloseEventLabel'];
  hidePrice?: boolean;
}

const MobileHeader: React.FC<React.PropsWithChildren<HeaderProps>> = ({
  price,
  modelId,
  variant,
  monthlySalesModel,
  useNewCarPrice,
  overlayProps,
  financeOption,
  onOverlayClick,
  index,
  overlayCloseEventLabel,
  hidePrice,
}) => {
  const overlay = (
    <Overlay
      onOverlayClick={onOverlayClick}
      offers={servicesToOffers(financeOption?.services || [])}
      localization={financeOption && financeOptionToLocalization(financeOption)}
      {...overlayProps}
      index={index}
    />
  );

  return (
    <>
      <MobileHeaderTitle>{financeOption?.displayName}</MobileHeaderTitle>
      {!hidePrice && (
        <>
          {useNewCarPrice ? (
            <div className="mt-auto">
              <CarPrice
                model={modelId}
                variant={variant}
                forceVariant
                monthlySalesModelOverride={monthlySalesModel}
                layout="COMPACT"
              />
            </div>
          ) : (
            <>
              <MobilePrice isMobile>
                <DisplayPrice
                  type="short-from"
                  foreground="foreground.primary"
                  subStyle="emphasis"
                  price={price}
                  fallback={
                    <Text variant="bates" subStyle="emphasis">
                      {price}
                    </Text>
                  }
                />
              </MobilePrice>
              <MobilePrice>
                <DisplayPrice
                  type="from"
                  variant="kelly"
                  foreground="foreground.primary"
                  priceSubStyle="emphasis"
                  price={price}
                  fallback={
                    <Text variant="kelly" subStyle="emphasis">
                      {price}
                    </Text>
                  }
                />
              </MobilePrice>
            </>
          )}
        </>
      )}
      {overlayCloseEventLabel ? (
        <TrackingProvider eventLabel={overlayCloseEventLabel}>
          {overlay}
        </TrackingProvider>
      ) : (
        overlay
      )}
    </>
  );
};

const DesktopHeader: React.FC<React.PropsWithChildren<HeaderProps>> = ({
  modelId,
  price,
  monthlySalesModel,
  variant,
  useNewCarPrice,
  overlayProps,
  financeOption,
  onOverlayClick,
  index,
  overlayCloseEventLabel,
  hidePrice,
}) => {
  const overlay = (
    <Overlay
      onOverlayClick={onOverlayClick}
      offers={servicesToOffers(financeOption?.services || [])}
      localization={financeOption && financeOptionToLocalization(financeOption)}
      {...overlayProps}
      index={index}
    />
  );

  return (
    <>
      <ColumnTitle>{financeOption?.displayName}</ColumnTitle>
      {!hidePrice && (
        <ColumnPrice>
          {useNewCarPrice ? (
            <CarPrice
              model={modelId}
              variant={variant}
              forceVariant
              monthlySalesModelOverride={monthlySalesModel}
            />
          ) : (
            <DisplayPrice
              type="from"
              variant="kelly"
              foreground="foreground.primary"
              priceSubStyle="emphasis"
              price={price}
              fallback={
                <Text variant="kelly" subStyle="emphasis">
                  {price}
                </Text>
              }
            />
          )}
        </ColumnPrice>
      )}
      {overlayCloseEventLabel ? (
        <TrackingProvider eventLabel={overlayCloseEventLabel}>
          {overlay}
        </TrackingProvider>
      ) : (
        overlay
      )}
    </>
  );
};

const ColumnTitle: React.FC<React.PropsWithChildren<unknown>> = (props) => (
  <Text
    extend={({ theme: { baselineGrid } }) => ({
      marginBottom: baselineGrid,
      textAlign: 'center',
    })}
    {...props}
  />
);

const ColumnPrice: React.FC<React.PropsWithChildren<unknown>> = (props) => (
  <Block
    extend={{
      margin: `auto 0 8px`,
    }}
    {...props}
  />
);

const MobileHeaderTitle: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Text
    variant={{ default: 'kelly', fromM: 'columbus' }}
    extend={({ theme: { baselineSubGrid } }) => ({
      marginBottom: baselineSubGrid,
    })}
    {...props}
  />
);

const MobilePrice: React.FC<
  React.PropsWithChildren<{ isMobile?: boolean }>
> = ({ isMobile, ...props }) => (
  <Text
    extend={{
      marginTop: 'auto',

      extend: [
        { condition: isMobile, style: { fromM: { display: 'none' } } },
        {
          condition: !isMobile,
          style: { display: 'none', fromM: { display: 'block' } },
        },
      ],
    }}
    {...props}
  />
);
