import React from 'react';

import { Translator } from '../../types';
import { TranslationKey } from '../../api';

type PrefillDataBannerProps = {
  translate: Translator;
};

export function PrefillDataBanner({ translate }: PrefillDataBannerProps) {
  const title = translate(TranslationKey.PREFILLED_DATA_TITLE);
  const text = translate(TranslationKey.PREFILLED_DATA_TEXT);
  return (
    <>
      <h2
        className="font-20 font-medium mb-16"
        data-testid="main-section-header-prefill-data-banner-title"
      >
        {title}
      </h2>
      <p
        className="mb-24"
        data-testid="main-section-header-prefill-data-banner-text"
      >
        {text}
      </p>
    </>
  );
}
