'use client';
import React from 'react';
import { TrackingProvider, useTracker } from '@volvo-cars/tracking';
import {
  SpringCarouselDots,
  SpringCarouselPane,
  SpringCarouselProvider,
} from '@vcc-www/spring-carousel';

import { Spacing } from 'src/components/layout/Spacing';
import type testimonialBlocksProps from 'src/content-management/editorial-components/TestimonialBlocks/TestimonialBlocks.props';
import type testimonialBlocksItem from 'src/content-management/content-types/TestimonialBlocksItem/TestimonialBlocksItem.props';

export type TestimonialBlocksProps = testimonialBlocksProps['content'] & {
  type: 'testimonialBlocks';
};

export const TestimonialBlocks: React.FC<TestimonialBlocksProps> = ({
  title,
  subTitle,
  testimonials,
}) => {
  const ga4Tracker = useTracker({}, { mode: 'ga4' });
  const eventCategory = 'testimonial blocks';

  return (
    <Spacing>
      <TrackingProvider eventCategory={eventCategory}>
        <SpringCarouselProvider
          onChange={(action) => {
            if (action.type === 'swipe-pane') {
              ga4Tracker.customEvent({
                eventCategory,
                eventAction: 'carousel|swipe',
                eventLabel: action.previous < action.current ? 'right' : 'left',
              });
            }
          }}
        >
          <div
            data-bleed="until-xl"
            data-testid="testimonial-blocks"
            className="container bg-secondary py-m md:px-64"
          >
            <h2
              data-testid="testimonial-blocks__title"
              className="heading-2 w-sm mx-auto md:text-center mb-32"
            >
              {title}
              {!!subTitle && (
                <span
                  data-testid="testimonial-blocks__subtitle"
                  className="text-secondary"
                >
                  {subTitle}
                </span>
              )}
            </h2>
            <div className="mx-auto overflow-hidden">
              <SpringCarouselPane
                alignment="left-cover"
                itemWidth={{
                  default: 1,
                  fromL: 1 / 2,
                }}
                itemSpacing={24}
              >
                {testimonials.map((testimonial, i) => (
                  <Testimonial key={`testimonial_${i}`} {...testimonial} />
                ))}
              </SpringCarouselPane>
            </div>
            <SpringCarouselDots
              extend={({ theme: { baselineGrid } }) => ({
                marginTop: 5 * baselineGrid,
              })}
            />
          </div>
        </SpringCarouselProvider>
      </TrackingProvider>
    </Spacing>
  );
};

const Testimonial: React.FC<testimonialBlocksItem> = ({
  quote,
  avatar,
  name,
  role,
}) => {
  return (
    <div
      data-testid="testimonial-blocks__card"
      className="bg-primary px-48 py-24 h-full flex-col"
    >
      <QuotationMark />
      <p data-testid="testimonial-blocks__quote" className="mt-8 mb-24">
        {quote}
      </p>
      <div className="flex items-center mt-auto">
        {avatar && (
          <div className="mr-16">
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              src={avatar.src}
              alt={avatar.alt}
              width="48"
              height="48"
              data-testid="testimonial-blocks__avatar-image"
              className="rounded-full"
            />
          </div>
        )}
        <div>
          <p data-testid="testimonial-blocks__name" className="font-medium">
            {name}
            {!!role && (
              <span
                data-testid="testimonial-blocks__role"
                className="font-light"
              >
                {', ' + role}
              </span>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

// There is no icon for a quotation mark in @volvo-cars/react-icons yet. Adding
// this inline as it's only used in this single component. Could be requested to
// be added to the icon library, and then used from there.
const QuotationMark: React.FC = () => (
  <svg
    fill="none"
    height="21"
    width="23"
    viewBox="0 0 46 43"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m42.9828 42.04v-19.08h-6.24c0-7.44 1.68-9.96 8.4-14.28l-3.48-8.160007c-11.88 5.039997-15.24 11.640007-15.24 25.680007v15.84zm-26.52 0v-19.08h-6.24c0-7.44 1.68-9.96 8.52-14.28l-3.6-8.160007c-11.75999 5.039997-15.119987 11.640007-15.119987 25.680007v15.84z"
      fill="currentColor"
    />
  </svg>
);
