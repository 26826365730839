/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum FlexFormLegalFieldType {
  OPTIN_CONTROL_FIELD = 'Optin_control_field',
  SELECT_ALL_OPTIN = 'Select_all_optin',
  EMAIL_OPTIN = 'Email_optin',
  PHONE_OPTIN = 'Phone_optin',
  SMS_OPTIN = 'Sms_optin',
  VALIDATED_EMAIL_OPTIN = 'Validated_email_optin',
  VALIDATED_PHONE_OPTIN = 'Validated_phone_optin',
  VALIDATED_SMS_OPTIN = 'Validated_sms_optin',
  SOCIAL_OPTIN = 'Social_optin',
  MAIL_OPTIN = 'Mail_optin',
  CBV_OPTIN = 'Cbv_optin',
  GROUPING_LABEL = 'Grouping_Label',
  ONE_MARKETING_CONSENT_OPTIN = 'OneMarketingConsent_optin',
  ONE_MARKETING_CONSENT_AUTO_OPTIN = 'OneMarketingConsent_auto_optin',
}
