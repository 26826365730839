'use client';
import React from 'react';

import { useFormContext } from 'react-hook-form';
import type {
  CustomTranslations,
  Translator,
} from '@vcc-package/leads-utils/types';
import { FlexFormLegalTestID } from '@vcc-package/leads-utils/types';
import {
  FlexFormLegalFieldType,
  TranslationKey,
} from '@vcc-package/leads-utils/api';
import { getTranslationKey } from '@vcc-package/leads-utils/util';

import { CheckboxField } from '../../components';

type OptinControlFieldProps = {
  translate: Translator;
  defaultValue: boolean;
  onChange: (e: any) => boolean;
  customTranslation: CustomTranslations;
};

export const OptinControlField = ({
  translate,
  defaultValue,
  onChange,
  customTranslation,
}: OptinControlFieldProps): JSX.Element | null => {
  const { control } = useFormContext();
  const customTranslationKeys = getTranslationKey(
    customTranslation,
    FlexFormLegalFieldType.OPTIN_CONTROL_FIELD,
  );
  return (
    <CheckboxField
      name={FlexFormLegalFieldType.OPTIN_CONTROL_FIELD}
      testid={FlexFormLegalTestID.OptinControl}
      control={control}
      label={
        customTranslationKeys
          ? translate(customTranslationKeys.label)
          : translate(TranslationKey.OPTIN_CONTROL_FIELD_LABEL)
      }
      required={false}
      onChange={onChange}
      key={FlexFormLegalFieldType.OPTIN_CONTROL_FIELD}
      defaultValue={defaultValue}
    />
  );
};
