// TODO: Fix eslint issues the next time this file is edited.
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Block } from 'vcc-ui';

const checksum = (input: string) => {
  let checkSum = 0x12345678;
  for (let i = 0; i < input.length; i++) {
    checkSum += input.charCodeAt(i) * (i + 1);
  }

  return (checkSum & 0xffffffff).toString(16);
};

export const Icon = ({ type, id }: { type: IconType; id: string }) => {
  const Element = _icons[type];
  return <Element id={`${type}-icon-${checksum(id)}`} />;
};

const IconWrapper: React.FC<React.PropsWithChildren<{ viewBox: string }>> = ({
  viewBox,
  ...props
}) => (
  <Block
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox={viewBox}
    extend={{
      display: 'block',
      maxHeight: '100%',
      maxWidth: '100%',
      height: '100%',
      backfaceVisibility: 'hidden',
      transform: 'translateZ(0)',
      color: '#737373',
    }}
    {...props}
  />
);

export type IconType = keyof typeof _icons;

const _icons = {
  play: function PlayIcon({ id = '' }: { id?: string }) {
    return (
      <IconWrapper viewBox="0 0 40 40" data-testid="icon:play">
        <circle cx="20" cy="20" r="19" fill="white" />
        <mask id={id} fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.7481 27.5725C15.4147 27.763 15 27.5223 15 27.1384V12.8616C15 12.4777 15.4147 12.237 15.7481 12.4275L28.2403 19.5659C28.5762 19.7578 28.5762 20.2422 28.2403 20.4341L15.7481 27.5725ZM27 20L16 26.2674V13.8047L27 20Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.7481 27.5725C15.4147 27.763 15 27.5223 15 27.1384V12.8616C15 12.4777 15.4147 12.237 15.7481 12.4275L28.2403 19.5659C28.5762 19.7578 28.5762 20.2422 28.2403 20.4341L15.7481 27.5725ZM27 20L16 26.2674V13.8047L27 20Z"
          stroke="#141414"
          strokeWidth="2"
          mask={`url(#${id})`}
        />
      </IconWrapper>
    );
  },

  play_black: function PlayIconBlack({ id = '' }: { id?: string }) {
    return (
      <IconWrapper viewBox="0 0 40 40" data-testid="icon:play_black" key={id}>
        <g opacity="0.7">
          <rect width="40" height="40" rx="20" fill="#141414" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27 20L15 13V27L27 20ZM25.0154 20L16 14.741V25.259L25.0154 20Z"
            fill="white"
          />
        </g>
      </IconWrapper>
    );
  },

  pause: function PauseIcon({ id = '' }: { id?: string }) {
    return (
      <IconWrapper viewBox="0 0 40 40" data-testid="icon:pause" key={id}>
        <circle cx="20" cy="20" r="19" fill="white" />
        <rect
          x="17.25"
          y="27.75"
          width="15.5"
          height="0.5"
          rx="0.25"
          transform="rotate(-90 17.25 27.75)"
          stroke="#141414"
          strokeWidth="0.5"
        />
        <rect
          x="22.25"
          y="27.75"
          width="15.5"
          height="0.5"
          rx="0.25"
          transform="rotate(-90 22.25 27.75)"
          stroke="#141414"
          strokeWidth="0.5"
        />
      </IconWrapper>
    );
  },

  pause_black: function PauseIconBlack({ id = '' }: { id?: string }) {
    return (
      <IconWrapper viewBox="0 0 40 40" data-testid="icon:pause_black" key={id}>
        <g opacity="0.7">
          <rect width="40" height="40" rx="20" fill="#141414" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 13V27H17V13H16ZM23 13V27H24V13H23Z"
            fill="white"
          />
        </g>
      </IconWrapper>
    );
  },
};
