'use client';

import type React from 'react';
import { useEffect } from 'react';

export type AddressAutocompleteKeyHandlerProps = {
  inputRef: React.MutableRefObject<HTMLInputElement | undefined>;
  suggestionsWrapper: React.MutableRefObject<HTMLDivElement | null>;
  setShowSuggestions: (show: boolean) => void;
  selectAddress: (address: string) => void;
};

const useAddressAutocompleteInputKeyEventHandler = ({
  inputRef,
  suggestionsWrapper,
  setShowSuggestions,
  selectAddress,
}: AddressAutocompleteKeyHandlerProps) => {
  useEffect(() => {
    const inputElement = inputRef.current;

    if (!inputElement) return;

    const handleKeyDown = (event: KeyboardEvent) => {
      if (!suggestionsWrapper.current) return;
      if (event.ctrlKey || event.shiftKey || event.altKey || event.metaKey)
        return;

      const suggestions = Array.from(
        suggestionsWrapper.current.querySelectorAll('button'),
      );

      const selectedIndex = suggestions.findIndex(
        (s) => s.getAttribute('aria-selected') === 'true',
      );

      switch (event.key) {
        case 'Enter':
          event.preventDefault();
          if (suggestions.length > 0) {
            selectAddress(suggestions[Math.max(0, selectedIndex)]?.value ?? '');
          }

          break;

        case 'Tab':
          if (selectedIndex >= 0) {
            selectAddress(suggestions[selectedIndex]?.value ?? '');
          } else {
            selectAddress(inputElement.value);
          }

          return;

        case 'Escape':
          event.preventDefault();
          setShowSuggestions(false);
          inputElement.focus();

          break;

        case 'Home':
          event.preventDefault();
          inputElement.setSelectionRange(0, 0);
          inputElement.focus();

          break;

        case 'End': {
          event.preventDefault();
          const endPos = inputElement.value?.length || 0;

          inputElement.setSelectionRange(endPos, endPos);
          inputElement.focus();

          break;
        }

        case 'ArrowDown':
        case 'ArrowUp': {
          event.preventDefault();

          if (selectedIndex >= 0) {
            suggestions[selectedIndex]?.setAttribute('aria-selected', 'false');
          }

          const isUp = event.key === 'ArrowUp';
          let nextIndex =
            isUp && selectedIndex < 0
              ? suggestions.length - 1
              : selectedIndex + (isUp ? -1 : 1);

          if (nextIndex < 0 || nextIndex >= suggestions.length) {
            inputElement.focus();
          } else {
            suggestions[nextIndex]?.setAttribute('aria-selected', 'true');
            suggestions[nextIndex]?.scrollIntoView({
              block: 'nearest',
              inline: 'nearest',
              behavior: 'instant',
            });
          }

          break;
        }

        default:
          return;
      }
    };

    inputElement.addEventListener('keydown', handleKeyDown);
    return () => {
      inputElement.removeEventListener('keydown', handleKeyDown);
    };
  }, [inputRef, suggestionsWrapper, setShowSuggestions, selectAddress]);
};

export default useAddressAutocompleteInputKeyEventHandler;
