import { ExtendCSS, ResponsiveProp, ThemeBreakpointName } from 'vcc-ui';
import isPlainObject from 'lodash/isPlainObject';

export type MediaQueryObjectBreakpoints<T> = {
  [key in ThemeBreakpointName]: T;
};
type MediaQueryObjectDefault<T> = { default: T };
type MediaQueryObjectValue<T> = Partial<MediaQueryObjectBreakpoints<T>> &
  MediaQueryObjectDefault<T>;

type StyleGeneratorCallback<T> = (value: T) => ExtendCSS;

type AllBreakpointsWithDefault = 'default' | ThemeBreakpointName;
type FromBreakpointsWithDefault = 'default' | 'fromM' | 'fromL' | 'fromXL';

export type ResponsiveValue<T> = ResponsiveProp<T>;

const allBreakpointsWithDefault: AllBreakpointsWithDefault[] = [
  'default',
  'onlyS',
  'untilM',
  'fromM',
  'onlyM',
  'untilL',
  'fromL',
  'onlyL',
  'untilXL',
  'fromXL',
  'onlyXL',
];

const fromBreakpointsWithDefault: FromBreakpointsWithDefault[] = [
  'default',
  'fromM',
  'fromL',
  'fromXL',
];

/**
 * A function that will create a ready-to-use style object, with media query
 * wrapped values. This can be used for when a component should have a flexible
 * and responsive prop, such as the `size` prop for the `GridHelper` component,
 * `aspectRatio` for the `Image` component or all the setting props for the
 * `SpringCarousel` component.
 *
 * @param value A responsive value, array of values or a media query object.
 * @param styleGeneratorCallback A callback function that will be used
 *
 * ---
 *
 * ### Examples
 *
 * Single value example - mostly to handle when it's not an array or object
 * ```
 * getMediaQueryValues(8, value => ({ width: `${value}px` }))
 * ```
 * returns `{ width: '8px' }`
 *
 * ---
 *
 * An array of 1 - 4 values, where the index represents breakpoints:
 * `[default, fromM, fromL, fromXL]`.
 * The `default` value is required.
 * ```
 * getMediaQueryValues([6, 8, 10, 12], value => ({ width: `${value}px` }))
 * ```
 * returns `{
 *   width: '6px',
 *   fromM: { width: '8px' },
 *   fromL: { width: '10px' },
 *   fromXL: { width: '12px' }
 * }`
 *
 * ---
 *
 * An object where we can add whatever breakpoints from VCC-UI we want to.
 * The `default` value is required.
 * ```
 * getMediaQueryValues(
 *   { default: 6, untilM: 4, fromL: 12 },
 *   value => ({ width: `${value}px` })
 * )
 * ```
 * returns `{
 *   width: '6px',
 *   untilM: { width: '4px' },
 *   fromL: { width: '12px' }
 * }`
 *
 */
export const getMediaQueryValues = <T,>(
  value: ResponsiveValue<T>,
  styleGeneratorCallback: StyleGeneratorCallback<T>,
): ExtendCSS[] => {
  const styleObjectArray: ExtendCSS[] = [];

  if (Array.isArray(value)) {
    fromBreakpointsWithDefault.forEach((breakpoint, i) => {
      if (typeof value[i] !== 'undefined') {
        styleObjectArray.push(
          breakpoint === 'default'
            ? styleGeneratorCallback(value[i] as T)
            : {
                [breakpoint]: styleGeneratorCallback(value[i] as T),
              },
        );
      }
    });

    return styleObjectArray;
  }

  if (isPlainObject(value)) {
    allBreakpointsWithDefault.forEach((breakpoint) => {
      if (
        typeof (value as MediaQueryObjectValue<T>)[breakpoint] !== 'undefined'
      ) {
        styleObjectArray.push(
          breakpoint === 'default'
            ? styleGeneratorCallback(
                (value as MediaQueryObjectDefault<T>)[breakpoint],
              )
            : {
                [breakpoint]: styleGeneratorCallback(
                  (value as MediaQueryObjectBreakpoints<T>)[breakpoint],
                ),
              },
        );
      }
    });

    return styleObjectArray;
  }

  styleObjectArray.push(styleGeneratorCallback(value as T));

  return styleObjectArray;
};
