import { ModelSlugs } from '@vcc-www/content-types/carModelSlugs';
import { EngineType } from '@vcc-www/utils/engineTypes';
import { ImageField, LinkField } from '@vcc-www/content-management-jss-client';
import { BaseRowProps } from '@vcc-www/comparison-table/ComparisonTable.types';
import {
  FinanceOption,
  SalesModelId,
  Service,
} from '@vcc-www/api/finance-options/types';

export type { Offer } from '@vcc-www/offers-overlay-with-link/OffersOverlayWithLink.types';

export interface OfferComparisonProps {
  title: string;
  cars: CarProps[];
  detailsText: string;
  amountToShowCollapsedOnMobile?: number;
  electricLabel?: string;
  pluginHybridLabel?: string;
  mildHybridLabel?: string;
  showMoreText: string;
  showLessText: string;
  financeOptions: OfferComparisonFinanceOptions;
  overlayTitle: string;
  overlayOffersTitle: string;
  makeCta?: (car: CarProps) => LinkField;
  makeDisclaimer?: (car: CarProps) => string;
  withPadding?: boolean;
  onCarClick?: (car: CarProps) => void;
  onCtaClick?: (car: CarProps) => void;
  onOverlayClick?: (isClosing?: boolean) => void;
  onExpandClick?: (isExpanding?: boolean) => void;
  onArrowClick?: (direction: ScrollDirection) => void;
  overlayCloseEventLabel?: string;
  hidePrice?: boolean;
}

export type OfferComparisonFinanceOptions = Partial<
  Record<SalesModelId, OfferComparisonFinanceOption>
>;
export type OfferComparisonFinanceOption = Pick<
  FinanceOption,
  'displayName' | 'description' | 'shortDescription'
> & {
  services: Pick<
    Service,
    | 'id'
    | 'displayName'
    | 'description'
    | 'shortDescription'
    | 'disclaimer'
    | 'applicableCarModels'
  >[];
};

export interface TableOfferEntity extends BaseRowProps {
  [SalesModelId.Sub]?: boolean | string;
  [SalesModelId.Cash]?: boolean | string;
  [SalesModelId.SubFixed]?: boolean | string;
  [SalesModelId.Lease]?: boolean | string;
  [SalesModelId.Loan]?: boolean | string;
  id: string;
}

export interface OverlayProps {
  overlayTitle: string;
  overlayOffersTitle: string;
  detailsText: string;
}
export interface CarProps {
  familyId?: ModelSlugs;
  modelId?: string;
  modelName?: string | null;
  engineType?: EngineType | null;
  details?: Details;
  image?: ImageField;
  price: {
    sub?: Price;
    subFixed?: Price;
    cash?: Price;
    loan?: Price;
    lease?: Price;
  };
}

export interface Price {
  amount?: number | null;
  value?: string | null;
}

export interface Details {
  [key: string]: DetailsItem;
}

interface DetailsItem {
  key: string;
  label?: string | null;
  value: string;
}

export interface SelectCarProps {
  isSelected?: boolean;
  onClick: () => void;
}

export type TableProps = {
  selectedCar: CarProps;
} & Pick<
  OfferComparisonProps,
  | 'detailsText'
  | 'amountToShowCollapsedOnMobile'
  | 'showMoreText'
  | 'showLessText'
  | 'financeOptions'
  | 'overlayTitle'
  | 'overlayOffersTitle'
  | 'onOverlayClick'
  | 'onExpandClick'
  | 'overlayCloseEventLabel'
  | 'hidePrice'
>;

export enum ScrollDirection {
  Left = 'left',
  Right = 'right',
}

export interface CarouselScrollDisabled {
  [ScrollDirection.Left]: boolean;
  [ScrollDirection.Right]: boolean;
}
