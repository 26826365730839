import { getSiteSlugByWindowLocation } from '@vcc-package/leads-utils/util';
import type { LeadsApp } from '../constants';
import { urls } from '@vcc-package/leads-utils/constants';

export const defaultLocaldevBaseUrl = 'https://qawww.volvocars.com';

export function getWindowBasedConfig(appId: LeadsApp) {
  if (typeof window === 'undefined') {
    throw new Error(
      'getWindowBasedConfig should only be called on the client side',
    );
  }
  const location = window.location;
  const siteSlug = getSiteSlugByWindowLocation();
  const referrer = window.document.referrer;
  const pathname = location.pathname;
  const route = urls[appId];

  const ssrUrlData = {
    originalUrl: location.href,
    referralUrl: referrer,
  };
  return {
    siteSlug,
    ssrUrlData,
    pathname,
    route,
  };
}
