const allowedHTMLEntities = {
  nbsp: '\u00A0',
  amp: '\u0026',
  apos: '\u0027',
  shy: '\u00AD',
  quot: '\u0022',
  copy: '\u00A9',
  reg: '\u00AE',
  trade: '\u2122',
  hellip: '\u2026',
  mdash: '\u2014',
  bull: '\u2022',
  ldquo: '\u201C',
  rdquo: '\u201D',
  lsquo: '\u2018',
  rsquo: '\u2019',
  '#10': '\u000A',
};

/**
 * @param  {string} htmlEntityString - Text string containing HTML entities such as `"hippo&shy;potamus"`
 * @returns {string} - escaped HTML string to JS string such as `"hippo\u00ADpotamus"`
 *
 * The purpose is to allow editors use HTML entities and at the same time avoid relying on solutions like Reacts [dangerouslySetInnerHTML](https://reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml).
 *
 * To add more allowed HTML entities, look them up over at https://unicode-table.com/ and add your wanted entitiy.
 * In time of writing < and > has been deliberately left out to avoid malign string building to create HTML tags.
 */
const unescapeHTML = (htmlEntityString: string) => {
  return htmlEntityString.replace(
    /&([^;]+);/g,
    (entity, entityCode: keyof typeof allowedHTMLEntities) => {
      if (entityCode in allowedHTMLEntities) {
        return allowedHTMLEntities[entityCode] as string;
      } else {
        return entity;
      }
    },
  );
};

export default unescapeHTML;
