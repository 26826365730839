import gql from 'graphql-tag';

export const PART_EXCHANGE_VRM_LOOKUP = gql`
  query PartExchangeVrmLookup($input: PartExchangeVrmLookupInput!) {
    partExchangeVrmLookup(input: $input) {
      success
      messages {
        type
        message
      }
      payload {
        vehicles {
          vehicleServiceId
          name
          make
          model
          derivative
          bodyType
          engineSize
          doors
          transmission
          registrationYear
          registrationMonth
          mileage
          plateLetter
          vrm
          vehicleDescription
        }
      }
    }
  }
`;
