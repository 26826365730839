import type { RefObject } from 'react';
import { useEffect } from 'react';

type Dimensions = {
  width: number;
  height: number;
};

type Props = {
  ref: RefObject<HTMLElement>;
  callback: (size: Dimensions) => void;
};

export const useResizeObserver = ({ ref, callback }: Props) => {
  useEffect(() => {
    if (!window.ResizeObserver) {
      return;
    }

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        callback({
          width: entry.target.clientWidth,
          height: entry.target.clientHeight,
        });
      }
    });

    if (ref.current) {
      resizeObserver.observe(ref?.current);
    }
    return () => resizeObserver.disconnect();
  }, [callback, ref]);

  // backup for iOS 13.3 and earlier
  useEffect(() => {
    const { current } = ref;
    if (window.ResizeObserver || !current) {
      return;
    }

    const boundingRect = current.getBoundingClientRect();
    const { width, height } = boundingRect;
    callback({ width: Math.round(width), height: Math.round(height) });
  }, [callback, ref]);
};
