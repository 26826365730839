import type { UtmParameterDataApiModel } from '@vcc-package/leads-utils/api';

const getUtmCampaignCookie = () => {
  if (typeof document === 'undefined') return;
  return document.cookie
    ?.split('; ')
    ?.find((cookie) => cookie?.startsWith('utm_campaign_details='))
    ?.split('=')[1];
};

export const utmCookieReader = () => {
  const cookie = getUtmCampaignCookie();
  const utmParameterData: UtmParameterDataApiModel = {
    utmMedium: null,
    utmSource: null,
    utmCampaign: null,
    utmContent: null,
    utmTerm: null,
  };

  if (cookie) {
    const data = new URLSearchParams(decodeURIComponent(cookie));
    utmParameterData.utmMedium = data?.get('utm_medium');
    utmParameterData.utmSource = data?.get('utm_source');
    utmParameterData.utmCampaign = data?.get('utm_campaign');
    utmParameterData.utmContent = data?.get('utm_content');
    utmParameterData.utmTerm = data?.get('utm_term');
  }
  return utmParameterData;
};
