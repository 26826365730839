/**
 * Flex Form field components
 */
export enum FlexFormTestID {
  Accompanied = 'ff-accompanied',
  AddressOne = 'ff-address-one',
  AddressTwo = 'ff-address-two',
  B2B = 'ff-b2b',
  Business = 'ff-business',
  City = 'ff-city',
  DealerPickerByAddress = 'ff-dealer-picker-by-address',
  DealerPicker = 'ff-dealer-picker',
  District = 'ff-district',
  EmailAsync = 'ff-email-async',
  Email = 'ff-email',
  FirstName = 'ff-first-name',
  Gender = 'ff-gender',
  Landline = 'ff-landline',
  LegalAddress = 'ff-legal-address',
  LegalAddressAsync = 'ff-legal-address-async',
  LegalName = 'ff-legal-name',
  MobilePhone = 'ff-mobile-phone',
  MobilePhoneAsync = 'ff-mobile-phone-async',
  Notes = 'ff-notes',
  PostalCode = 'ff-postal-code',
  SecondSurname = 'ff-second-surname',
  Surname = 'ff-surname',
  Title = 'ff-title',
}

/**
 * Flex Form legal/optin components
 */
export enum FlexFormLegalTestID {
  SelectAll = 'ffl-select-all',
  Email = 'ffl-email',
  EmailValidated = 'ffl-email-validated',
  Mail = 'ffl-mail',
  OptinControl = 'ffl-optin-control',
  Phone = 'ffl-telephone',
  PhoneValidated = 'ffl-phone-validated',
  Sms = 'ffl-sms',
  SmsValidated = 'ffl-sms-validated',
  Social = 'ffl-social',
  Cbv = 'ffl-cbv',
  OneMarketingConsent = 'ffl-one-marketing-consent',
}
