'use client';
import { useEffect } from 'react';
import {
  createStyleSheet,
  getStyleSheet,
  removeStylesheet,
} from './createStyleSheet';

type TopbarPosition = 'relative' | 'fixed' | 'sticky';

const styleSheetName = 'sn-topbar-position';

/**
 * Toggles the `--hideonscroll` CSS custom property on the Site Navigation.
 */
export const useTopbarPosition = (
  position: TopbarPosition,
  disable = false,
) => {
  useEffect(() => {
    if (disable) return;

    const styleSheet =
      getStyleSheet(styleSheetName) || createStyleSheet(styleSheetName);

    if (position === 'relative') {
      styleSheet.innerHTML = `
        :root {
          --sitenav-hideonscroll: var(--sitenav-ON) !important;
        }
        #site-navigation {
          --hideonscroll: var(--ON) !important;
        }
      `;
    }
    if (position === 'fixed') {
      styleSheet.innerHTML = `
        :root {
          --sitenav-hideonscroll: var(--sitenav-OFF) !important;
        }
        #site-navigation {
          --hideonscroll: var(--OFF) !important;
        }
      `;
    }
    if (position === 'sticky') {
      styleSheet.innerHTML = `
        :root {
          --sitenav-stickyonscroll: var(--sitenav-ON) !important;
        }
        #site-navigation {
          --stickyonscroll: var(--ON) !important;
        }
      `;
    }

    document.head.appendChild(styleSheet);

    return () => {
      removeStylesheet(styleSheet);
    };
  }, [position, disable]);
};
