/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConfigurationHistorySummary } from '../models/ConfigurationHistorySummary';
import type { MarketVehicleConfiguration } from '../models/MarketVehicleConfiguration';
import type { PurposeType } from '../models/PurposeType';
import type { StoredVehicle } from '../models/StoredVehicle';
import type { StoredVehiclesHistory } from '../models/StoredVehiclesHistory';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VehiclesService {
  /**
   * @returns MarketVehicleConfiguration OK
   * @throws ApiError
   */
  public static getVehicles({
    siteSlug,
    purpose,
    clientId,
  }: {
    siteSlug: string;
    purpose?: PurposeType;
    clientId?: string;
  }): CancelablePromise<Array<MarketVehicleConfiguration>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/forms-backend/v1/{siteSlug}/vehicles',
      path: {
        siteSlug: siteSlug,
      },
      query: {
        purpose: purpose,
        clientId: clientId,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns binary OK
   * @throws ApiError
   */
  public static image({
    imageId,
  }: {
    imageId: string;
  }): CancelablePromise<Blob> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/forms-backend/v1/vehicles/image',
      query: {
        imageId: imageId,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns ConfigurationHistorySummary OK
   * @throws ApiError
   */
  public static getVehiclesHistory(): CancelablePromise<
    Array<ConfigurationHistorySummary>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/forms-backend/v1/vehicles/history/all',
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns StoredVehiclesHistory OK
   * @throws ApiError
   */
  public static getVehiclesHistoryById({
    id,
  }: {
    id?: string;
  }): CancelablePromise<StoredVehiclesHistory> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/forms-backend/v1/vehicles/history/by-id',
      query: {
        id: id,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns StoredVehicle OK
   * @throws ApiError
   */
  public static getFallback({
    pno,
  }: {
    pno?: string;
  }): CancelablePromise<StoredVehicle> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/forms-backend/v1/vehicles/get-fallback',
      query: {
        pno: pno,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }
}
