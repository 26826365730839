import React from 'react';

import { SubmitButton } from '@volvo-cars/react-forms';
import { TranslationKey } from '@vcc-package/leads-utils/api';
import { globalStyling } from '@vcc-package/leads-utils/constants';

import { useFlexFormContext } from '../providers/FlexibleFormsProvider';

export const SubmitSection = (): JSX.Element | null => {
  const rootLegacyProps = useFlexFormContext();
  if (!rootLegacyProps) {
    throw new Error('No rootLegacyProps');
  }

  const {
    customSubmitLabel,
    translate,
    loading,
    setLoadingDebounced,
    hideRequired,
  } = rootLegacyProps;

  if (!translate) {
    throw new Error('No translate provided');
  }

  return (
    <div className={`flex flex-col items-start ${globalStyling.sectionGap}`}>
      {!hideRequired && (
        <p className="micro text-secondary" data-testid="required">
          * {translate(TranslationKey.REQUIRED)}
        </p>
      )}
      <SubmitButton
        data-testid="customer-information-submit"
        type="submit"
        color="accent"
        onClick={(e) => setLoadingDebounced && setLoadingDebounced(true, e)}
        loading={loading}
      >
        {customSubmitLabel ?? translate(TranslationKey.SUBMIT)}
      </SubmitButton>
    </div>
  );
};
