'use client';
import React, { useRef, useState } from 'react';
import { cssJoin } from '@volvo-cars/css/utils';
import type { TrackProps } from '@volvo-cars/tracking';
import { Track as BaseTrack } from '@volvo-cars/tracking';
import styles from '../SubNavigation.module.css';
import { Icon } from '@volvo-cars/react-icons';
import type { CTAsProps } from './CTAs.types';
import { useOnClickOutside } from '@vcc-www/hooks';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';

interface CSSPropertiesWithVariable extends React.CSSProperties {
  '--amount-of-ctas': number;
}

export const MobileCTAs: React.FC<CTAsProps> = ({
  primaryCta,
  additionalCtas,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const sharedTranslate = useSharedComponentsTranslate();

  useOnClickOutside(containerRef, () => {
    setIsExpanded(false);
  });
  const amountOfCtas = [primaryCta, ...(additionalCtas || [])].filter(
    Boolean,
  ).length;

  return (
    <div
      ref={containerRef}
      className={cssJoin(
        'lg:hidden w-full flex gap-8 justify-end px-pagemargin md:px-0 items-end fixed',
        styles.mobileCtaContainer,
      )}
      data-autoid="sub-navigation:mobile-ctas"
      data-testid="sub-navigation:mobile-ctas"
    >
      <div
        className={cssJoin(
          'w-full overflow-hidden gap-8',
          styles.mobileCtaInnerContainer,
        )}
        style={
          {
            '--amount-of-ctas': amountOfCtas,
          } as CSSPropertiesWithVariable
        }
        aria-expanded={isExpanded}
      >
        {primaryCta && (
          <Track eventLabel={primaryCta.eventLabel}>
            <a
              href={primaryCta.href}
              target={primaryCta.target}
              className={cssJoin('button-filled w-full', styles.cta)}
              data-sources={primaryCta.source}
            >
              {primaryCta.text}
            </a>
          </Track>
        )}
        {additionalCtas?.map((cta, index) => (
          <Track key={index} eventLabel={cta.eventLabel}>
            <a
              href={cta.href}
              target={cta.target}
              className="button-filled w-full mt-8"
              aria-hidden={!isExpanded}
              data-sources={cta.source}
            >
              {cta.text}
            </a>
          </Track>
        ))}
      </div>
      {amountOfCtas > 1 && (
        <Track eventLabel={isExpanded ? 'collapse' : 'expand'}>
          <button
            type="button"
            className={cssJoin(
              'button-filled px-0 w-full',
              styles.cta,
              styles.expandButton,
            )}
            onClick={() => setIsExpanded(!isExpanded)}
            aria-expanded={isExpanded}
            aria-label={
              isExpanded
                ? sharedTranslate(
                    'SubNavigation.subNavigation.expandButtonAriaLabelCollapse',
                  )
                : sharedTranslate(
                    'SubNavigation.subNavigation.expandButtonAriaLabelExpand',
                  )
            }
          >
            <Icon
              icon="chevron-up"
              size={16}
              className="button-expanded:rotate-180 transition-transform"
              color="currentColor"
            />
          </button>
        </Track>
      )}
    </div>
  );
};

// TODO: Remove this when the tracking component is fixed to support RSC
export const Track: React.FC<TrackProps> = (props) => <BaseTrack {...props} />;
