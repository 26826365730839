'use client';
import React from 'react';

import type { Control } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { Checkbox } from '@volvo-cars/react-forms';

import { TextWithLink } from '../elements/textWithLink';

interface FieldProps {
  name: string;
  testid?: string;
  defaultValue?: boolean;
  label: string;
  hint?: React.ReactNode;
  required?: boolean;
  control: Control;
  description?: string;
  /**
   * Let's you execute custom logic when checkbox onChange event fires.
   *
   * @return boolean specifying whether default onChange event should be executed after custom logic.
   */
  onChange?: (e: any) => boolean;
  disabled?: boolean;
  hideRequired?: boolean;
  useLabelElement?: boolean;
  className?: string;
  errorMessage?: string;
}

export const CheckboxField = ({
  name,
  testid,
  defaultValue = false,
  label,
  hint,
  control,
  required = false,
  onChange: customOnChange,
  disabled,
  hideRequired,
  useLabelElement,
  className = 'micro',
  errorMessage,
}: FieldProps) => (
  <Controller
    control={control}
    defaultValue={defaultValue}
    name={name}
    rules={{ required: required }}
    render={({
      field: { onChange, onBlur, value, name, ref },
      fieldState: { error },
    }) => (
      <Checkbox
        label={
          useLabelElement
            ? ((
                <label htmlFor={name}>
                  {hideRequired ? label : required ? `${label} *` : label}
                </label>
              ) as any as string)
            : ((
                <p>
                  <TextWithLink>
                    {hideRequired ? label : required ? `${label} *` : label}
                  </TextWithLink>
                </p>
              ) as any as string)
        }
        hint={hint}
        name={name}
        ref={ref}
        id={name}
        data-testid={testid}
        checked={value}
        onChange={(e: any) => {
          if (customOnChange) {
            const runDefaultOnChange = customOnChange(e);
            if (runDefaultOnChange) onChange(e.target.checked);
          } else {
            onChange(e.target.checked);
          }
        }}
        onBlur={onBlur}
        aria-invalid={!!error}
        disabled={disabled}
        className={className}
        errorMessage={error ? errorMessage : undefined}
      />
    )}
  />
);
