import { createPublicEnv } from '@vcc-www/public-env';
import { getElasticVariablesFromEnv } from '@vcc-package/opentelemetry';

export const { PublicEnv, getPublicEnv } = createPublicEnv({
  deployEnv: process.env.DEPLOY_ENV || 'qa',
  rootPath: process.env.ROOT_PATH || '/[market]/',
  appEnv: process.env.APP_ENVIRONMENT || 'LOCAL',
  apiHost: process.env.API_URL || 'https://qawww.volvocars.com',
  commitId: process.env.COMMIT_ID || 'unknown',
  setBy: 'server',
  assetPath: process.env.ASSET_PATH || '/static/leads-forms/',
  currentAssetPrefix: process.env.CURRENT_ASSET_PREFIX || '',
  authHeaderName: process.env.AUTH_HEADER_NAME || '',
  authHeaderValue: process.env.AUTH_HEADER_VALUE || '',
  useCors: process.env.USE_CORS === 'true',
  port: process.env.PORT || '3000',
  version: process.env.VERSION || '1.0.0',
  hideMissingTranslationMessage:
    process.env.HIDE_MISSING_TRANSLATION_MESSAGE || 'false',
  ...getElasticVariablesFromEnv(),
});
