import { BusinessModel } from '@vcc-www/federated-graph-types';
import { MODEL_SLUGS_WITH_DASH } from './model-slug.utils.types';

export const getModelSlugByKey = (key: string) => {
  if (!key) {
    return;
  }

  if (key.includes(MODEL_SLUGS_WITH_DASH.PHEV)) {
    return key.replace(
      MODEL_SLUGS_WITH_DASH.PHEV,
      MODEL_SLUGS_WITH_DASH.HYBRID,
    );
  }
  if (key.includes(MODEL_SLUGS_WITH_DASH.BEV)) {
    return key.replace(
      MODEL_SLUGS_WITH_DASH.BEV,
      MODEL_SLUGS_WITH_DASH.ELECTRIC,
    );
  }
  if (key.includes(MODEL_SLUGS_WITH_DASH.ICE)) {
    return key.replace(MODEL_SLUGS_WITH_DASH.ICE, '');
  }
  if (key.includes(MODEL_SLUGS_WITH_DASH.CC)) {
    return key.replace(
      MODEL_SLUGS_WITH_DASH.CC,
      MODEL_SLUGS_WITH_DASH.CROSS_COUNTRY,
    );
  }
  if (key.includes(MODEL_SLUGS_WITH_DASH.RECHARGE_PURE_ELECTRIC)) {
    return key.replace(
      MODEL_SLUGS_WITH_DASH.RECHARGE_PURE_ELECTRIC,
      MODEL_SLUGS_WITH_DASH.ELECTRIC,
    );
  }
  if (key.includes(MODEL_SLUGS_WITH_DASH.RECHARGE)) {
    return key.replace(
      MODEL_SLUGS_WITH_DASH.RECHARGE,
      MODEL_SLUGS_WITH_DASH.HYBRID,
    );
  }
  return key;
};

export const getKeyByModelSlug = (modelSlug: string | undefined) => {
  if (!modelSlug) {
    return;
  }

  if (modelSlug.includes(MODEL_SLUGS_WITH_DASH.HYBRID)) {
    return modelSlug.replace(
      MODEL_SLUGS_WITH_DASH.HYBRID,
      MODEL_SLUGS_WITH_DASH.PHEV,
    );
  }
  if (modelSlug.includes(MODEL_SLUGS_WITH_DASH.ELECTRIC)) {
    return modelSlug.replace(
      MODEL_SLUGS_WITH_DASH.ELECTRIC,
      MODEL_SLUGS_WITH_DASH.BEV,
    );
  }
  if (!modelSlug.includes('-')) {
    return modelSlug + MODEL_SLUGS_WITH_DASH.ICE;
  }
  if (modelSlug.includes(MODEL_SLUGS_WITH_DASH.CROSS_COUNTRY)) {
    return modelSlug.replace(
      MODEL_SLUGS_WITH_DASH.CROSS_COUNTRY,
      MODEL_SLUGS_WITH_DASH.CC,
    );
  }
  if (modelSlug.includes(MODEL_SLUGS_WITH_DASH.ELECTRIC)) {
    return modelSlug.replace(
      MODEL_SLUGS_WITH_DASH.ELECTRIC,
      MODEL_SLUGS_WITH_DASH.RECHARGE_PURE_ELECTRIC,
    );
  }
  if (modelSlug.includes(MODEL_SLUGS_WITH_DASH.HYBRID)) {
    return modelSlug.replace(
      MODEL_SLUGS_WITH_DASH.HYBRID,
      MODEL_SLUGS_WITH_DASH.RECHARGE,
    );
  }
  return modelSlug;
};

export const getBusinessModelFromUserInput = (tokenType: string) => {
  if (tokenType) {
    if (tokenType.includes(BusinessModel.WHOLESALE)) {
      return BusinessModel.WHOLESALE;
    }
    if (tokenType.includes(BusinessModel.DIRECT)) {
      return BusinessModel.DIRECT;
    }
  }
  return '';
};
