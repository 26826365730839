import { Question, QuestionWithAnswer } from './part-exchange.types';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import getRockarEndpoints from './api/utils/rockarEndpoints';
import getRockarEnv from './api/utils/getEnv';

export const gtmCategory = 'estimation tool';

export const pageNavigationMap: Record<string, string> = {
  '/': '/car-details',
  '/car-details': '/questions-0',
  '/questions-0': '/questions-1',
  '/questions-1': '/questions-2',
  '/questions-2': '/summary',
  '/summary': '/lead-form',
};

export const getExpirationTime = () => {
  const EXPIRATION_TIME_IN_DAYS = 1;
  return EXPIRATION_TIME_IN_DAYS * 24 * 60 * 60 * 1000;
};

export const setupQuestionsAndAnswers = (questions: Question[]) => {
  let groups: QuestionWithAnswer[][] = [];
  const sortedQuestions = questions.sort(
    (questionA, questionB) =>
      parseInt(questionA.sortOrder) - parseInt(questionB.sortOrder),
  );

  sortedQuestions.forEach((question) => {
    let groupIndex = Math.floor(parseInt(question.sortOrder) / 10);

    if (!groups[groupIndex]) {
      groups[groupIndex] = [];
    }
    groups[groupIndex].push({
      question: question,
      answer: { code: question.slug, answer: '' },
    });
  });

  return groups.filter((group) => group !== undefined);
};

export const isValidRegNumber = '^[a-zA-Z0-9 ]*$';

export function isValidNumber(str: string) {
  return /^\d+$/.test(str);
}

export const createApolloClient = (
  clientToken: string,
  sessionId: string,
  salesChannelCode: string,
) => {
  const env = getRockarEnv(window.location.href);

  const { apolloUrl } = getRockarEndpoints(env);
  const apolloClient = new ApolloClient({
    uri: apolloUrl,
    cache: new InMemoryCache(),
    headers: {
      clientToken: clientToken,
      sessionId: sessionId,
      salesChannelCode: salesChannelCode,
    },
  });

  return apolloClient;
};

// Utility to enable/disable the "Continue" button based on the current path
export const enableContinue = (
  currentPath: string,
  questions: QuestionWithAnswer[][],
  registrationNumber: string,
  mileage: string,
  outstandingFinance: string,
): boolean => {
  if (
    currentPath === '/' &&
    registrationNumber.match(isValidRegNumber) &&
    isValidNumber(mileage) &&
    registrationNumber !== ''
  ) {
    return true;
  }
  if (
    currentPath === '/questions-0' &&
    questions[0]?.every((question) => question.answer.answer !== '')
  ) {
    return true;
  }
  if (
    currentPath === '/questions-1' &&
    questions[1]?.every((question) => question.answer.answer !== '')
  ) {
    return true;
  }
  if (
    (currentPath === '/questions-2' &&
      questions[2]?.every((question) => question.answer.answer === 'no')) ||
    (questions[2]?.every((question) => question.answer.answer === 'yes') &&
      isValidNumber(outstandingFinance))
  ) {
    return true;
  }
  if (
    currentPath === '/summary' ||
    currentPath === '/car-details' ||
    currentPath === '/error'
  ) {
    return true;
  }

  return false; // Default to disabled if none of the conditions are met
};
