/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RequestApiModel } from '../models/RequestApiModel';
import type { RequestResponseApiModel } from '../models/RequestResponseApiModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RequestService {
  /**
   * @returns RequestResponseApiModel Created
   * @throws ApiError
   */
  public static postApiFormsBackendV1Request({
    siteSlug,
    clientId,
    requestBody,
  }: {
    siteSlug: string;
    clientId?: string;
    requestBody?: RequestApiModel;
  }): CancelablePromise<RequestResponseApiModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/forms-backend/v1/{siteSlug}/request',
      path: {
        siteSlug: siteSlug,
      },
      query: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
