import React from 'react';

import { TextWithLink } from './textWithLink';

type ParagraphsProps = {
  paragraphs?: string[];
};

export const ParagraphRenderer = ({ paragraphs }: ParagraphsProps) =>
  paragraphs?.map((p, i) => (
    <p key={i}>
      <TextWithLink>{p}</TextWithLink>
    </p>
  ));
