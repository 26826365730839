/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PurposeType {
  KEEP_ME_UPDATED = 'KEEP_ME_UPDATED',
  OFFER_REQUEST = 'OFFER_REQUEST',
  CONTACT_REQUEST = 'CONTACT_REQUEST',
  ACCESSORY_REQUEST = 'ACCESSORY_REQUEST',
  CBV_CALLBACK = 'CBV_CALLBACK',
}
