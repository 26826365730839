'use client';
import React, { useState } from 'react';

import { cssJoin } from '@volvo-cars/css/utils';
import { IconButton } from '@volvo-cars/react-icons';

export const IntentConst = [
  'Neutral',
  'Positive',
  'Caution',
  'Destructive',
] as const;
type Intent = (typeof IntentConst)[number];

type StylesDictionary = Record<Intent, string>;

type MessageProps = {
  title?: string;
  description: string;
  intent?: Intent;
};

const intentStyles: StylesDictionary = {
  Neutral: 'border-secondary bg-secondary',
  Positive: 'border-feedback-green bg-feedback-green',
  Caution: 'border-feedback-orange bg-feedback-orange',
  Destructive: 'border-feedback-red bg-feedback-red',
};

export default function Message({
  title,
  description,
  intent = 'Neutral',
}: MessageProps) {
  const [closed, setClosed] = useState(false);

  return closed ? null : (
    <div
      className={cssJoin(
        'mb-16 px-24 py-16 flex-col contain-content flex-wrap gap-8 border-l-2',
        intentStyles[intent],
      )}
    >
      {title && <h3 className="font-medium">{title}</h3>}
      <p className="pr-16">{description}</p>
      <IconButton
        icon="x"
        aria-label="Close"
        className="absolute top-8 end-8"
        variant="clear"
        onClick={() => {
          setClosed(true);
        }}
      />
    </div>
  );
}
