'use client';
import React from 'react';

import { useFormContext } from 'react-hook-form';
import type {
  CustomTranslations,
  Translator,
} from '@vcc-package/leads-utils/types';
import { FlexFormLegalTestID } from '@vcc-package/leads-utils/types';
import { FlexFormLegalFieldType } from '@vcc-package/leads-utils/api';
import { getTranslationKey } from '@vcc-package/leads-utils/util';

import { CheckboxField } from '../../components';

type SelectAllOptinProps = {
  translate: Translator;
  defaultValue: boolean;
  disabled: boolean;
  customTranslation: CustomTranslations;
};

export const SelectAllOptinField = ({
  translate,
  defaultValue,
  disabled,
  customTranslation,
}: SelectAllOptinProps): JSX.Element | null => {
  const { control } = useFormContext();
  const customTranslationKeys = getTranslationKey(
    customTranslation,
    FlexFormLegalFieldType.SELECT_ALL_OPTIN,
  );
  return (
    <CheckboxField
      name={FlexFormLegalFieldType.SELECT_ALL_OPTIN}
      testid={FlexFormLegalTestID.SelectAll}
      control={control}
      label={
        customTranslationKeys
          ? translate(customTranslationKeys.label)
          : translate('SelectAllOptinFieldLabel')
      }
      required={false}
      key={FlexFormLegalFieldType.SELECT_ALL_OPTIN}
      defaultValue={defaultValue}
      disabled={disabled}
    />
  );
};
