import React from 'react';
import { Steps } from '@vcc-package/steps';
import { TitleWithBody } from './components/TitleWithBody';
import { ValuationSummary } from './components/ValuationSummary';
import { usePartExchange } from './part-exchange.hook';
import { useTranslate } from './part-exchange.dictionaries-provider';
import { ErrorView } from './components/ErrorView';
interface SummaryViewProps {
  isLoading: boolean;
}

export const SummaryView: React.FC<SummaryViewProps> = ({ isLoading }) => {
  const { error } = usePartExchange();
  const translate = useTranslate();

  if (error) {
    return <ErrorView title={error.title} body={error.body} />;
  }

  return isLoading ? (
    <div className="flex justify-center items-center pb-32">
      <progress className="spinner w-24 text-secondary" />
    </div>
  ) : (
    <div>
      <TitleWithBody
        title={translate('financeOptions.partExchangeTool.summaryTitle')}
      />
      <p className="mb-16">
        Please note that only a preliminary estimate of the value is made until
        the final valuation at the retailer.
      </p>
      <ValuationSummary isLoading={isLoading} />
      <p
        data-testid="part-exchange-tool:summary-tool-steps-title"
        className="font-16 font-medium mt-32 mb-16"
      >
        {translate('financeOptions.partExchangeTool.stepsTitle')}
      </p>
      <Steps
        notCenterAligned
        steps={[
          {
            body: translate('financeOptions.partExchangeTool.stepOneBody'),
          },
          {
            body: translate('financeOptions.partExchangeTool.stepTwoBody'),
          },
          {
            body: translate('financeOptions.partExchangeTool.stepThreeBody'),
          },
        ]}
      />
    </div>
  );
};
