'use client';

import { useCallback, useEffect, useMemo, useState } from 'react';

import type { Query } from '@vcc-package/leads-utils/types';
import type {
  AnalyticsApiModel,
  GaParameterDataApiModel as GTM,
  UtmParameterDataApiModel as UTM,
} from '@vcc-package/leads-utils/api';
import {
  loadGtmParameterData,
  readFromSession,
  saveToSession,
  StorageKey,
  utmCookieReader,
} from '@vcc-package/leads-utils/util';
import type { LeadsApp } from '@vcc-package/leads-utils/constants';

type Props = {
  appId: LeadsApp;
  query: Query;
};

/**
 * Hook providing Analytics data (GTM & UTM) for Leads Applications
 * Info: https://en.wikipedia.org/wiki/UTM_parameters
 */
export const useAnalytics = ({ appId, query }: Props): AnalyticsApiModel => {
  const gtmSession = readFromSession<GTM>(appId, StorageKey.gtmParameterData);
  const [gtmParameterData, setGtmParameterData] = useState(gtmSession);

  const onGtmLoaded = useCallback(
    ({ gaClientId, gaTrackId, gaUserId }: GTM) => {
      const gtmData = {
        gaClientId: gaClientId ?? gtmSession?.gaClientId ?? '',
        gaTrackId: gaTrackId ?? gtmSession?.gaTrackId ?? '',
        gaUserId: gaUserId ?? gtmSession?.gaUserId ?? '',
      };
      setGtmParameterData(gtmData);
      saveToSession(appId, StorageKey.gtmParameterData, gtmData);
    },
    [appId, gtmSession],
  );

  useEffect(() => {
    loadGtmParameterData(onGtmLoaded);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const utmParameterData = useMemo(() => {
    const data = getUtmParameterData({ appId, query });
    saveToSession(appId, StorageKey.utmParameterData, data);
    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { userAgent: null, gtmParameterData, utmParameterData };
};

const getUtmParameterData = ({ appId, query }: Props): UTM => {
  const cookie = utmCookieReader();
  const session = readFromSession<UTM>(appId, StorageKey.utmParameterData);

  return {
    utmCampaign:
      cookie.utmCampaign || query.utm_campaign || session?.utmCampaign || '',
    utmContent:
      cookie.utmContent || query.utm_content || session?.utmContent || '',
    utmMedium: cookie.utmMedium || query.utm_medium || session?.utmMedium || '',
    utmSource: cookie.utmSource || query.utm_source || session?.utmSource || '',
    utmTerm: cookie.utmTerm || query.utm_term || session?.utmTerm || '',
  };
};
