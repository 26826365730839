'use client';

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { abTestingContextMapper } from '@vcc-package/leads-utils/abTesting';
import {
  AnalyticsApiModel,
  FlexFormConfiguration,
  FullConfiguration,
  MarketConfiguration,
  MarketVehicleConfiguration,
  Package,
  PurposeType,
  RequestApiModel,
} from '@vcc-package/leads-utils/api';
import type {
  ApplicationInfo,
  LeadsApp,
} from '@vcc-package/leads-utils/constants';
import { LeadsApplications } from '@vcc-package/leads-utils/constants';
import {
  useAnalytics,
  useTranslator,
  useUrlData,
} from '@vcc-package/leads-utils/hooks';
import type {
  Navigation,
  NavigationFactory,
  Translations,
  Translator,
  UrlData,
} from '@vcc-package/leads-utils/types';
import type { API, PublicRuntimeConfig } from '@vcc-package/leads-utils/util';
import {
  APIClient,
  getFeatureFlags,
  getHeaderFromCookie,
  getTranslationsObject,
  readFromSession,
  StorageKey,
  usePublicConfig,
} from '@vcc-package/leads-utils/util';
import type { CookieSetter } from '@vcc-package/leads-utils/util/cookie';
import type { MarketSite, SiteSlug } from '@volvo-cars/market-sites';

import { ModelContextProvider } from './modelContext';
import { RetailerContextProvider } from './retailerContext';
import { FormValues } from '../../types/flexibleForm';
import { requestApiModelToFormValues } from '../../util/requestApiModelToFormValues';

export interface LeadsContextProperties
  extends Omit<ApplicationInfo, 'purpose'> {
  siteSlug: SiteSlug;
  API: API;
  formConfig: FlexFormConfiguration | null;
  formConfigs?: Partial<Record<PurposeType, FlexFormConfiguration | undefined>>;
  marketConfig: MarketConfiguration;
  purposeConfig: Package | undefined;
  translate: Translator;
  vehicles?: MarketVehicleConfiguration[];
  vehiclesConfigs?: Partial<
    Record<PurposeType, MarketVehicleConfiguration[] | null>
  >;
  /** Selected model based on url query */
  clientId: string | undefined;
  /** Analytics data (GTM & UTM parameters) */
  analytics: AnalyticsApiModel;
  /** Url data (originalUrl & referralUrl) */
  urlData: UrlData;
  /** Data that has been sent with the form */
  submitData: RequestApiModel | null;
  /** Used for the cases when we deliberately need to change the app-purpose (ex: Quote + InsideSales) */
  setPurpose: (purpose: PurposeType | null) => void;
  purpose: PurposeType | null;
  /** Used to check if is EmbeddableForms */
  isEmbeddableForms: boolean;
  consumerApp: string | null;
  publicRuntimeConfig: PublicRuntimeConfig;
  navigation: Navigation;
  features: Record<string, boolean | null>;
  prefilledData: FormValues | null;
}

export function createLeadsContext() {
  const ctx = React.createContext<LeadsContextProperties | undefined>(
    undefined,
  );
  function useCtx() {
    const context = React.useContext(ctx);
    if (!context) throw new Error('Missing LeadsContextProvider');
    return context;
  }
  return [useCtx, ctx] as const;
}

const [useCtx, ctx] = createLeadsContext();

export const LeadsContext = ctx;
export const useLeadsContext = useCtx;

export type LeadsContextProviderProps = React.PropsWithChildren<{
  appId: LeadsApp;
  queryObject: Record<string, string>;
  marketSite: Required<MarketSite>;
  fullConfig: FullConfiguration;
  clientId?: string | undefined;
  ssrUrlData: UrlData;
  ssrCookies?: string;
  consumerApp: string | null;
  navigationFactory: NavigationFactory;
  setCookie: CookieSetter;
}>;

export type contextMapperType = (
  context: LeadsContextProperties,
) => Promise<LeadsContextProperties>;

export type LeadsExternalContextProps = {
  appId: LeadsApp;
  contextMapperFn?: contextMapperType;
  overridePurpose?: PurposeType | null;
  isEmbeddableForms?: boolean;
  inOverlay?: boolean;
  consumerApp: string | null;
  onExitIntentIntroductionComponent?: 'KeepMeUpdatedIntroductionPage';
};

export type LeadsExternalContextProviderProps = LeadsContextProviderProps &
  LeadsExternalContextProps;

export type ConfigStateObject = {
  marketConfig: MarketConfiguration;
  formConfig: FlexFormConfiguration;
  vechiclesConfig: MarketVehicleConfiguration[];
  translationConfig: Translations;
};

export const LeadsExternalContextProvider = ({
  children,
  marketSite,
  fullConfig,
  clientId,
  ssrUrlData,
  ssrCookies,
  appId,
  contextMapperFn,
  overridePurpose,
  isEmbeddableForms,
  consumerApp,
  navigationFactory,
  queryObject,
  setCookie,
}: LeadsExternalContextProviderProps): JSX.Element | null => {
  const publicRuntimeConfig = usePublicConfig({
    isEmbeddableForms: isEmbeddableForms ?? false,
  });

  const applicationInfo = useMemo(() => {
    return LeadsApplications[appId];
  }, [appId]);

  const navigation = navigationFactory({
    query: queryObject,
    siteSlug: marketSite.siteSlug,
    appPath: applicationInfo.appPath,
    appId,
  });
  const query = navigation.query;

  const analytics = useAnalytics({ appId: applicationInfo.appId, query });
  const urlData = useUrlData({ appId: applicationInfo.appId, ssrUrlData });

  const [purpose, setPurpose] = useState<PurposeType | null>(
    overridePurpose ?? (isEmbeddableForms ? null : applicationInfo.purpose),
  );

  const featureFlagHeader =
    getHeaderFromCookie(
      typeof document !== 'undefined' ? document.cookie : null,
      marketSite.siteSlug,
    ) ??
    fullConfig.marketConfiguration?.featureFlagHeader ??
    null;

  const appEnv = publicRuntimeConfig.appEnv;
  const apiClient = APIClient({
    appId: applicationInfo.appId,
    clientId,
    siteSlug: marketSite.siteSlug,
    isTesting: appEnv !== 'prod' && query?.testing === 'true',
    publicRuntimeConfig,
    featureFlagHeader,
    ssrCookies: typeof window !== 'undefined' ? document.cookie : ssrCookies,
    setCookie,
  });

  const translate = useTranslator({
    dictionary: (fullConfig?.translations
      ? getTranslationsObject(fullConfig.translations)
      : {}) as Translations,
    hideMissingTranslationMessage: appEnv === 'prod',
    purpose,
    useExistingDictionary: !!isEmbeddableForms,
  });

  const purposeConfig = useMemo(() => {
    const { purposeConfigs } = fullConfig.marketConfiguration ?? {};
    return purposeConfigs && purpose ? purposeConfigs[purpose] : undefined;
  }, [fullConfig, purpose]);

  const submitDataFetcher = useCallback(() => {
    return readFromSession<RequestApiModel>(
      applicationInfo.appId,
      StorageKey.submitData,
    );
  }, [applicationInfo.appId]);

  const formConfigs = useMemo(
    () =>
      (fullConfig.flexFormConfigurations ?? {}) as Record<
        PurposeType,
        FlexFormConfiguration | undefined
      >,
    [fullConfig.flexFormConfigurations],
  );
  const formConfig = useMemo(
    () => (purpose ? formConfigs[purpose] ?? null : null),
    [formConfigs, purpose],
  );

  const vehiclesConfigs = useMemo(
    () =>
      (fullConfig.vehicleConfigurations &&
        purpose &&
        fullConfig.vehicleConfigurations[purpose]) ??
      undefined,
    [fullConfig.vehicleConfigurations, purpose],
  );

  const marketConfig = useMemo(
    () => (fullConfig.marketConfiguration ?? {}) as MarketConfiguration,
    [fullConfig.marketConfiguration],
  );

  let features: LeadsContextProperties['features'] = useMemo(
    () => getFeatureFlags(marketConfig),
    [marketConfig],
  );

  const [context, setContext] = useState<LeadsContextProperties>(
    abTestingContextMapper({
      ...applicationInfo,
      siteSlug: marketSite.siteSlug,
      API: apiClient,
      formConfig,
      marketConfig,
      translate,
      purposeConfig,
      vehicles: vehiclesConfigs,
      clientId,
      analytics,
      urlData,
      get submitData() {
        return submitDataFetcher();
      },
      purpose,
      setPurpose,
      isEmbeddableForms: isEmbeddableForms ?? false,
      consumerApp,
      publicRuntimeConfig,
      navigation,
      features,
      prefilledData: null,
    }),
  );

  useEffect(() => {
    (async () => {
      const cdbId = query.cdb_id;
      let prefilledData = null;

      if (cdbId) {
        const consumerData = await apiClient.getConsumerData(cdbId);

        if (consumerData)
          prefilledData = requestApiModelToFormValues(consumerData);
      }

      if (!contextMapperFn) {
        setContext({
          ...abTestingContextMapper(context),
          prefilledData: prefilledData,
        });
        return;
      }

      const newContext = await contextMapperFn(
        abTestingContextMapper({
          ...applicationInfo,
          siteSlug: marketSite.siteSlug,
          API: apiClient,
          formConfig,
          formConfigs,
          marketConfig,
          translate,
          purposeConfig,
          vehicles: vehiclesConfigs,
          clientId,
          analytics,
          urlData,
          get submitData() {
            return submitDataFetcher();
          },
          purpose,
          setPurpose,
          isEmbeddableForms: isEmbeddableForms ?? false,
          consumerApp,
          publicRuntimeConfig,
          navigation,
          features,
          prefilledData: prefilledData,
        }),
      );
      setContext({ ...newContext });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purpose]);

  return (
    <LeadsContext.Provider value={context}>
      <ModelContextProvider navigation={navigation}>
        <RetailerContextProvider>{children}</RetailerContextProvider>
      </ModelContextProvider>
    </LeadsContext.Provider>
  );
};

export const LeadsContextProvider = (
  props: LeadsContextProviderProps,
): JSX.Element | null => {
  return <LeadsExternalContextProvider {...props} />;
};
