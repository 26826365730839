/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum SeriesTypes {
  SUV = 'SUV',
  SEDAN = 'SEDAN',
  WAGON = 'WAGON',
  CROSSOVER = 'CROSSOVER',
}
