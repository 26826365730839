import React, { memo, useCallback } from 'react';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
// eslint-disable-next-line n/no-extraneous-import
import { cssJoin } from '@volvo-cars/css/utils';

export type Props = {
  /**
   * Type of pricing phrase to render.
   *
   * - **cash**
   * Purchase from {price}
   *
   * - **from**
   * From {price}
   *
   * - **short-from**
   * Fr. {price}
   *
   * - **subscription**
   * Subscribe from {price}
   *
   * - **lease**
   * Lease from {price}
   *
   * - **loan**
   * Loan from {price}
   *
   * - **pcp**
   * PCP from {price}
   *
   * - **subscriptioncash**
   * Subscribe from {price}
   * Purchase from {price}
   *
   * - **combined-short**
   * from {price1}
   * or {price2}
   *
   * - **combined-pipe**
   * {price1} | {price2}
   */
  type:
    | 'cash'
    | 'from'
    | 'short-from'
    | 'subscription'
    | 'lease'
    | 'loan'
    | 'pcp'
    | 'subscriptioncash'
    | 'combined-short'
    | 'combined-pipe';

  /**
   * Use purchase strings with MSRP included if applicable.
   * Should be used for Car Models not available for online cash purchase.
   */
  msrp?: boolean;

  /**
   * Pricing values including currency and perhaps period if subscription.
   */
  price?:
    | string
    | [string | undefined]
    | [string | undefined, string | undefined];

  /**
   * Text variant for wrapper.
   *
   * @default 'bates'
   */
  variant?: 'columbus' | 'bates' | 'kelly';

  /**
   * Text subStyle for wrapper.
   */
  subStyle?: 'standard' | 'emphasis';

  /**
   * Foreground color of wrapper.
   */
  foreground?: 'foreground.primary' | 'foreground.secondary';

  /**
   * Text subStyle for price.
   */
  priceSubStyle?: 'standard' | 'emphasis';

  /**
   * Foreground color of price.
   */
  priceForeground?: 'foreground.primary' | 'foreground.secondary';

  /**
   * Pass a component or a string that will be rendered as fallback if `price`
   * is missing or the dictionary translation is missing.
   */
  fallback?: React.ReactNode;
};

const DisplayPrice: React.FC<React.PropsWithChildren<Props>> = memo(
  ({
    type,
    price,
    priceSubStyle,
    fallback = null,
    msrp = false,
    variant = 'bates',
    subStyle = 'standard',
    foreground = 'foreground.secondary',
    priceForeground = 'foreground.primary',
    ...props
  }) => {
    const renderPrice = useCallback(
      (price?: string) => (
        <small
          style={{
            whiteSpace: 'nowrap',
          }}
          className={cssJoin(
            priceSubStyle === 'emphasis' ? 'font-medium' : '',
            variant === 'bates' ? 'micro' : 'font-16',
            priceForeground === 'foreground.primary'
              ? 'text-primary'
              : 'text-secondary',
          )}
          key={price}
        >
          {price?.trim()}
        </small>
      ),
      [priceForeground, priceSubStyle, variant],
    );

    let phrase: React.ReactNode;
    if (typeof price === 'string' && price) {
      const renderedPrice = renderPrice(price);
      if (type === 'cash') {
        phrase = (
          <CashPrice msrp={msrp} price={renderedPrice} fallback={fallback} />
        );
      } else if (type === 'subscription') {
        phrase = (
          <SubscriptionPrice price={renderedPrice} fallback={fallback} />
        );
      } else if (type === 'from') {
        phrase = <FromPrice price={renderedPrice} fallback={fallback} />;
      } else if (type === 'lease') {
        phrase = <LeasePrice price={renderedPrice} fallback={fallback} />;
      } else if (type === 'loan') {
        phrase = <LoanPrice price={renderedPrice} fallback={fallback} />;
      } else if (type === 'pcp') {
        phrase = <PcpPrice price={renderedPrice} fallback={fallback} />;
      } else if (type === 'short-from') {
        phrase = <ShortFromPrice price={renderedPrice} fallback={fallback} />;
      }
    }

    if (Array.isArray(price)) {
      const prices = price.map((price?: string) => price?.trim());
      if (type === 'subscriptioncash') {
        const [subscriptionPrice, cashPrice] = prices;
        if (subscriptionPrice && cashPrice) {
          phrase = (
            <SubscriptionCashPrice
              msrp={msrp}
              prices={[renderPrice(subscriptionPrice), renderPrice(cashPrice)]}
              fallback={fallback}
            />
          );
        } else if (subscriptionPrice) {
          phrase = (
            <SubscriptionPrice
              price={renderPrice(subscriptionPrice)}
              fallback={fallback}
            />
          );
        } else if (cashPrice) {
          phrase = (
            <CashPrice
              msrp={msrp}
              price={renderPrice(cashPrice)}
              fallback={fallback}
            />
          );
        }
      } else if (type === 'combined-short') {
        if (prices.filter(Boolean).length === 2) {
          phrase = (
            <CombinedShortPrice
              msrp={msrp}
              prices={[renderPrice(prices[0]), renderPrice(prices[1])]}
              fallback={fallback}
            />
          );
        } else {
          const price = prices.find(Boolean);
          if (price) {
            phrase = (
              <FromShortPrice
                msrp={msrp}
                price={renderPrice(price)}
                fallback={fallback}
              />
            );
          }
        }
      } else if (type === 'combined-pipe') {
        phrase = [renderPrice(prices[0]), ' | ', renderPrice(prices[1])];
      }
    }

    if (!phrase) {
      return <>{fallback}</>;
    }

    return (
      <small
        style={{
          whiteSpace: 'nowrap',
        }}
        className={cssJoin(
          subStyle === 'emphasis' ? 'font-medium' : '',
          variant === 'bates' ? 'micro' : 'font-16',
          foreground === 'foreground.primary'
            ? 'text-primary'
            : 'text-secondary',
        )}
        data-autoid={`display-price:${type}`}
        {...props}
      >
        {phrase}
      </small>
    );
  },
);

DisplayPrice.displayName = 'DisplayPrice';

export default DisplayPrice;

const CashPrice: React.FC<
  React.PropsWithChildren<{
    msrp: boolean;
    price: React.ReactNode;
    fallback: React.ReactNode;
  }>
> = ({ msrp, price, fallback }) => {
  const t = useSharedComponentsTranslate();
  let phrase;
  if (msrp) {
    phrase = t('DisplayPrice.displayPrice.purchaseFromMSRP', { price });
  }
  if (!phrase) {
    phrase = t('DisplayPrice.displayPrice.purchaseFrom', { price });
  }
  return phrase || fallback;
};

const SubscriptionPrice: React.FC<
  React.PropsWithChildren<{
    price: React.ReactNode;
    fallback: React.ReactNode;
  }>
> = ({ price, fallback }) => {
  const t = useSharedComponentsTranslate();
  return t('DisplayPrice.displayPrice.subscribeFrom', { price }) || fallback;
};

const LeasePrice: React.FC<
  React.PropsWithChildren<{
    price: React.ReactNode;
    fallback: React.ReactNode;
  }>
> = ({ price, fallback }) => {
  const t = useSharedComponentsTranslate();
  return t('DisplayPrice.displayPrice.leaseFrom', { price }) || fallback;
};

const LoanPrice: React.FC<
  React.PropsWithChildren<{
    price: React.ReactNode;
    fallback: React.ReactNode;
  }>
> = ({ price, fallback }) => {
  const t = useSharedComponentsTranslate();
  return t('DisplayPrice.displayPrice.loanFrom', { price }) || fallback;
};

const PcpPrice: React.FC<
  React.PropsWithChildren<{
    price: React.ReactNode;
    fallback: React.ReactNode;
  }>
> = ({ price, fallback }) => {
  const t = useSharedComponentsTranslate();
  return t('DisplayPrice.displayPrice.pcpFrom', { price }) || fallback;
};

const SubscriptionCashPrice: React.FC<
  React.PropsWithChildren<{
    msrp: boolean;
    prices: readonly [React.ReactNode, React.ReactNode];
    fallback: React.ReactNode;
  }>
> = ({ msrp, prices, fallback }) => {
  const t = useSharedComponentsTranslate();
  const subscribeText = t('DisplayPrice.displayPrice.subscribeFrom', {
    price: prices[0],
  });
  let purchaseText;
  if (msrp) {
    purchaseText = t('DisplayPrice.displayPrice.purchaseFromMSRP', {
      price: prices[1],
    });
  }
  if (!purchaseText) {
    purchaseText = t('DisplayPrice.displayPrice.purchaseFrom', {
      price: prices[1],
    });
  }
  return subscribeText && purchaseText ? (
    <>
      {subscribeText}
      <br />
      {purchaseText}
    </>
  ) : (
    <>{fallback}</>
  );
};

const FromPrice: React.FC<
  React.PropsWithChildren<{
    price: React.ReactNode;
    fallback: React.ReactNode;
  }>
> = ({ price, fallback }) => {
  const t = useSharedComponentsTranslate();
  return t('DisplayPrice.displayPrice.from', { price }) || fallback;
};

const ShortFromPrice: React.FC<
  React.PropsWithChildren<{
    price: React.ReactNode;
    fallback: React.ReactNode;
  }>
> = ({ price, fallback }) => {
  const t = useSharedComponentsTranslate();
  return (
    t('DisplayPrice.displayPrice.shortFrom', { price }) ||
    t('DisplayPrice.displayPrice.from', { price }) ||
    fallback
  );
};

const FromShortPrice: React.FC<
  React.PropsWithChildren<{
    msrp: boolean;
    price: React.ReactNode;
    fallback: React.ReactNode;
  }>
> = ({ msrp, price, fallback }) => {
  const t = useSharedComponentsTranslate();
  let fromText;
  if (msrp) {
    fromText = t('DisplayPrice.displayPrice.fromPriceMSRP', { price });
  }
  if (!fromText) {
    fromText = t('DisplayPrice.displayPrice.fromPrice', { price });
  }
  return fromText || fallback;
};

const CombinedShortPrice: React.FC<
  React.PropsWithChildren<{
    msrp: boolean;
    prices: readonly [React.ReactNode, React.ReactNode];
    fallback: React.ReactNode;
  }>
> = ({ msrp, prices, fallback }) => {
  const t = useSharedComponentsTranslate();
  const fromText = t('DisplayPrice.displayPrice.fromPrice', {
    price: prices[0],
  });
  let orText;
  if (msrp) {
    orText = t('DisplayPrice.displayPrice.orPriceMSRP', {
      price: prices[1],
    });
  }
  if (!orText) {
    orText = t('DisplayPrice.displayPrice.orPrice', {
      price: prices[1],
    });
  }
  return fromText && orText ? (
    <>
      {fromText}
      <br />
      {orText}
    </>
  ) : (
    <>{fallback}</>
  );
};
