import type { GaParameterDataApiModel as GTM } from '@vcc-package/leads-utils/api';

let fetchGaTries = 0;

/**
 * Awaits Google Analytics (GA) to have finished loading within the browser window.
 * Once GA have loaded, we can access the GTM parameter data, and we fire the Callback function.
 */
export const loadGtmParameterData = (callback: (d: GTM) => void) => {
  fetchGaTries++;

  if (fetchGaTries > 10) {
    return;
  }

  // Check if Google Analytics have finished loading
  // YES: Fetch the GTM parameter data & execute the callback
  // NO: SetTimeout and try again (up to 10 times)
  if ((window as any).ga && (window as any).ga.loaded) {
    const gtmParameterData = fetchGtmParameters();
    callback(gtmParameterData);
  } else {
    setTimeout(() => loadGtmParameterData(callback), 500);
  }
  return;
};

/**
 * Only called once Google Analytics have been loaded in the browser window.
 * @returns GTM Parameter data
 */
const fetchGtmParameters = () => {
  let gtmParameterData: GTM = {
    gaClientId: null,
    gaTrackId: null,
    gaUserId: null,
  };

  (window as any).ga.getAll().forEach((tracker: any) => {
    gtmParameterData = {
      gaClientId: tracker.get('clientId'),
      gaUserId: tracker.get('userId'),
      gaTrackId: tracker.get('trackingId'),
    };
  });

  return gtmParameterData;
};
