import React, { useMemo } from 'react';

import type { CustomTranslation } from '@vcc-package/leads-utils/api';
import { FlexFormLegalFieldType } from '@vcc-package/leads-utils/api';
import { useOptinControlField } from '@vcc-package/leads-utils/hooks';
import type { Translator } from '@vcc-package/leads-utils/types';

import { SectionHeader } from '../../components';
import { ParagraphRenderer } from '../../flexible-forms/components';
import { CbvOptinField } from '../fields/optins/cbvOptin';
import { EmailOptinField } from '../fields/optins/emailOptin';
import GroupingLabel from '../fields/optins/groupingLabel';
import { MailOptinField } from '../fields/optins/maillOptin';
import OneMarketingConsent from '../fields/optins/oneMarketingConsent';
import OneMarketingConsentAutoOptin from '../fields/optins/oneMarketingConsentAutoOptin';
import { OptinControlField } from '../fields/optins/optinControlField';
import { PhoneOptinField } from '../fields/optins/phoneOptin';
import { SelectAllOptinField } from '../fields/optins/selectAllOptin';
import { SmsOptinField } from '../fields/optins/smsOptin';
import { SocialOptinField } from '../fields/optins/socialOptin';
import { ValidatedEmailOptinField } from '../fields/optins/validatedEmailOptin';
import { ValidatedPhoneOptinField } from '../fields/optins/validatedPhoneOptin';
import { ValidatedSmsOptinField } from '../fields/optins/validatedSmsOptin';
import {
  useFlexFormContext,
  useFlexFormLegalSectionContext,
} from '../providers/FlexibleFormsProvider';

type LegalFlexFieldMapperProps = {
  disabled: boolean;
  optinControlFieldHandler: (e: any) => boolean;
  customTranslation: Record<string, CustomTranslation[]>;
  translate: Translator;
  field: FlexFormLegalFieldType;
  required?: boolean;
};

// eslint-disable-next-line react/display-name
const LegalFlexFieldMapper = ({
  customTranslation,
  disabled,
  optinControlFieldHandler,
  translate,
  field,
  required,
}: LegalFlexFieldMapperProps): JSX.Element | null => {
  switch (field) {
    case FlexFormLegalFieldType.SELECT_ALL_OPTIN:
      return (
        <SelectAllOptinField
          translate={translate}
          defaultValue={false}
          key={FlexFormLegalFieldType.SELECT_ALL_OPTIN}
          disabled={disabled}
          customTranslation={customTranslation}
        />
      );

    case FlexFormLegalFieldType.EMAIL_OPTIN:
      return (
        <EmailOptinField
          translate={translate}
          defaultValue={false}
          key={FlexFormLegalFieldType.EMAIL_OPTIN}
          disabled={disabled}
          customTranslation={customTranslation}
        />
      );

    case FlexFormLegalFieldType.SMS_OPTIN:
      return (
        <SmsOptinField
          translate={translate}
          defaultValue={false}
          key={FlexFormLegalFieldType.SMS_OPTIN}
          disabled={disabled}
          customTranslation={customTranslation}
        />
      );

    case FlexFormLegalFieldType.PHONE_OPTIN:
      return (
        <PhoneOptinField
          translate={translate}
          defaultValue={false}
          key={FlexFormLegalFieldType.PHONE_OPTIN}
          disabled={disabled}
          customTranslation={customTranslation}
        />
      );

    case FlexFormLegalFieldType.VALIDATED_EMAIL_OPTIN:
      return (
        <ValidatedEmailOptinField
          translate={translate}
          defaultValue={false}
          key={FlexFormLegalFieldType.VALIDATED_EMAIL_OPTIN}
          disabled={disabled}
          customTranslation={customTranslation}
        />
      );

    case FlexFormLegalFieldType.VALIDATED_SMS_OPTIN:
      return (
        <ValidatedSmsOptinField
          translate={translate}
          defaultValue={false}
          key={FlexFormLegalFieldType.VALIDATED_SMS_OPTIN}
          disabled={disabled}
          customTranslation={customTranslation}
        />
      );

    case FlexFormLegalFieldType.VALIDATED_PHONE_OPTIN:
      return (
        <ValidatedPhoneOptinField
          translate={translate}
          defaultValue={false}
          key={FlexFormLegalFieldType.VALIDATED_PHONE_OPTIN}
          disabled={disabled}
          customTranslation={customTranslation}
        />
      );

    case FlexFormLegalFieldType.MAIL_OPTIN:
      return (
        <MailOptinField
          translate={translate}
          defaultValue={false}
          key={FlexFormLegalFieldType.MAIL_OPTIN}
          disabled={disabled}
          customTranslation={customTranslation}
        />
      );

    case FlexFormLegalFieldType.SOCIAL_OPTIN:
      return (
        <SocialOptinField
          translate={translate}
          defaultValue={false}
          key={FlexFormLegalFieldType.SOCIAL_OPTIN}
          disabled={disabled}
          customTranslation={customTranslation}
        />
      );

    case FlexFormLegalFieldType.OPTIN_CONTROL_FIELD:
      return (
        <OptinControlField
          translate={translate}
          key={FlexFormLegalFieldType.OPTIN_CONTROL_FIELD}
          onChange={optinControlFieldHandler}
          defaultValue={false}
          customTranslation={customTranslation}
        />
      );

    case FlexFormLegalFieldType.CBV_OPTIN:
      return (
        <CbvOptinField
          translate={translate}
          defaultValue={false}
          key={FlexFormLegalFieldType.CBV_OPTIN}
          disabled={disabled}
          customTranslation={customTranslation}
        />
      );

    case FlexFormLegalFieldType.ONE_MARKETING_CONSENT_OPTIN:
      return (
        <OneMarketingConsent
          translate={translate}
          defaultValue={false}
          key={FlexFormLegalFieldType.ONE_MARKETING_CONSENT_OPTIN}
          disabled={disabled}
          customTranslation={customTranslation}
          required={required}
        />
      );
    case FlexFormLegalFieldType.ONE_MARKETING_CONSENT_AUTO_OPTIN:
      return (
        <OneMarketingConsentAutoOptin
          translate={translate}
          key={FlexFormLegalFieldType.ONE_MARKETING_CONSENT_OPTIN}
          customTranslation={customTranslation}
        />
      );

    case FlexFormLegalFieldType.GROUPING_LABEL: {
      return <GroupingLabel />;
    }

    default:
      console.warn(`${field} was not recognized, was it misspelled?`);
      return null;
  }
};

export const LegalSection = (): JSX.Element | null => {
  const legalSectionProps = useFlexFormLegalSectionContext();
  const { translate } = useFlexFormContext();
  if (!legalSectionProps || !translate) {
    throw new Error(
      "dealerPickerProps or rootLegacyProps hasn't been initialized",
    );
  }
  const { title, paragraphs, validation } = legalSectionProps;
  const fields = useMemo(
    () => legalSectionProps.fields ?? [],
    [legalSectionProps.fields],
  );
  const customTranslation = useMemo(
    () => legalSectionProps.customTranslation ?? {},
    [legalSectionProps.customTranslation],
  );

  // Optin control field stuff
  const [disabled, optinControlFieldHandler] = useOptinControlField(fields);

  // Grouping label stuff

  const filteredFields = useMemo(
    () =>
      fields
        .map((field) => ({
          type: field,
          component: (
            <LegalFlexFieldMapper
              disabled={disabled}
              optinControlFieldHandler={optinControlFieldHandler}
              customTranslation={customTranslation}
              translate={translate}
              field={field}
              required={validation?.[field]?.required ?? undefined}
            />
          ),
        }))
        .filter((x) => x && !!x.component),
    [
      fields,
      disabled,
      optinControlFieldHandler,
      customTranslation,
      translate,
      validation,
    ],
  );

  // Set up array with new typing to support "groups"
  const groupedInputs: Array<
    | { type: FlexFormLegalFieldType; component: JSX.Element }
    | Array<{ type: FlexFormLegalFieldType; component: JSX.Element }>
  > = [];

  //Group the fields based on the grouping lable
  filteredFields.forEach((input) => {
    // If the input type is a grouping label, add it as an array of inputs and return.
    if (input.type === FlexFormLegalFieldType.GROUPING_LABEL) {
      groupedInputs.push([input]);
      return;
    }

    // If last thing added to groupedInputs is an array, push the input to that array instead of root one.
    const prevGroupOrInput = groupedInputs[groupedInputs.length - 1];
    if (Array.isArray(prevGroupOrInput)) {
      prevGroupOrInput.push(input);
      return;
    }

    // If not grouping label and last thing in groupedInput is not array, just add input to groupedInput.
    groupedInputs.push(input);
  });

  const hasHeading = title || (paragraphs && paragraphs.length > 0);
  return (
    <div>
      {hasHeading && (
        <>
          {title && (
            <SectionHeader
              title={translate(title)}
              className="mb-16"
              testId="legal-section-header"
            />
          )}
          {paragraphs && paragraphs.length > 0 && (
            <div className="micro mb-16">
              <ParagraphRenderer
                paragraphs={paragraphs.map((p) => translate(p))}
              />
            </div>
          )}
        </>
      )}

      <div className="flex w-full">
        <div className="flex flex-wrap gap-24">
          {groupedInputs.map((input, index) =>
            Array.isArray(input) ? (
              <div key={`optin_${index}`} className="ml-32">
                <div>{input[0].component}</div>
                <div>
                  {/* First element will always be the label so we can ignore it. */}
                  {input.splice(1).map((inp, ind) => (
                    <div key={`optin_group_${index}_item_${ind}`}>
                      {inp.component}
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div key={`component-${index}`}>{input.component}</div>
            ),
          )}
        </div>
      </div>
    </div>
  );
};
