export const supportedRatios = [
  '1:1',
  '2:3',
  '4:5',
  '9:16',
  '3:2',
  '4:3',
  '16:9',
  '21:9',
  // Custom aspect ratios.
  '4:2', // Temporary fix to not escalate aspect ratio changes. Will be addressed when consolidating aspect ratios.
] as const;

export type SupportedRatios = (typeof supportedRatios)[number];

export const ratioToClassName: Record<SupportedRatios, string> = {
  '1:1': 'aspect-1/1',
  '2:3': 'aspect-2/3',
  '4:5': 'aspect-4/5',
  '9:16': 'aspect-9/16',
  '3:2': 'aspect-3/2',
  '4:3': 'aspect-4/3',
  '16:9': 'aspect-16/9',
  '21:9': 'aspect-21/9',
  // Custom aspect ratios.
  '4:2': 'aspect-21/9', // Force 4:2 to be 21:9. Consider it an improvement.
};

/**
 * CSS class finder. This function assumes usage of Volvo Cars CSS.
 * https://developer.volvocars.com/design-system/web/?path=/docs/icons-imagery-aspect-ratios--docs
 *
 * @param ratio string that represents width and height ratio. Format: w:h.
 * @returns object containing all responsive CSS classes for that ratio.
 */
export const findRatioClass = (ratio: SupportedRatios = '16:9') => {
  if (!supportedRatios.includes(ratio)) {
    console.warn(
      `Ratio '${ratio}' is not supported. Using 16:9 instead.`,
      `Please use one of the following: ${supportedRatios.join(', ')}.`,
    );
    ratio = '16:9';
  }

  return {
    sm: ratioToClassName[ratio],
    md: `md:${ratioToClassName[ratio]}`,
    lg: `lg:${ratioToClassName[ratio]}`,
    xl: `xl:${ratioToClassName[ratio]}`,
  };
};
