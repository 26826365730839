'use client';
import React from 'react';

import { useFormContext, useWatch } from 'react-hook-form';
import { FlexFormLegalFieldType } from '@vcc-package/leads-utils/api';
import { getLegalFieldName } from '@vcc-package/leads-utils/util';

export const useOptinControlField = (
  fields: FlexFormLegalFieldType[],
): [boolean, (e: any) => boolean] => {
  const [disabled, setDisabled] = React.useState(
    fields.includes(FlexFormLegalFieldType.OPTIN_CONTROL_FIELD),
  );
  const { setValue } = useFormContext();
  const optinControlFieldHandler = React.useCallback(
    (e: any) => {
      const checked = e.target.checked;
      fields.forEach((field) => {
        if (
          field !== FlexFormLegalFieldType.OPTIN_CONTROL_FIELD &&
          field !== FlexFormLegalFieldType.GROUPING_LABEL
        ) {
          setValue(getLegalFieldName(field), checked);
        }
      });
      setDisabled(!checked);
      return true;
    },
    [fields, setValue],
  );

  const watchFieldNames = React.useMemo(() => {
    return fields
      .filter(
        (f) =>
          f !== FlexFormLegalFieldType.OPTIN_CONTROL_FIELD &&
          f !== FlexFormLegalFieldType.GROUPING_LABEL,
      )
      .map((f) => getLegalFieldName(f));
  }, [fields]);

  const watchFieldValues = useWatch({ name: watchFieldNames });

  React.useEffect(() => {
    const values = Object.values(watchFieldValues);
    const isValueNotEmpty = values.length > 0;
    const isNoValueSelected = !values.some((v) => v);

    if (isValueNotEmpty && isNoValueSelected) {
      setValue(
        getLegalFieldName(FlexFormLegalFieldType.OPTIN_CONTROL_FIELD),
        false,
      );
      setDisabled(true);
    }
  }, [setValue, watchFieldValues]);

  if (!fields.includes(FlexFormLegalFieldType.OPTIN_CONTROL_FIELD)) {
    return [false, optinControlFieldHandler];
  }

  return [disabled, optinControlFieldHandler];
};
