import { FinanceDetailsProps } from '@vcc-package/finance-details/src/finance-details.types';
import { FinanceOptionsQuery } from '@vcc-www/gql-types/FinanceOptionsQuery';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { FinanceOption, FinanceOptions, SalesModelId, Service } from './types';
import {
  EngineType,
  OfferPackage,
  Product,
  ServiceContent,
} from '@vcc-www/federated-graph-types';
import { sortItemsBySortOrder } from '@vcc-package/products/src/products.utils';

// Maps services or packages into an array. If it's a package it will map it into a service
const mapServices = (
  model: string,
  services: OfferPackage[] | Product[],
  financeOption: FinanceOption,
  engineType: EngineType,
) => {
  services?.forEach((service) => {
    const existingService = financeOption?.services?.find(
      (serv) => serv?.id === service?.id,
    );

    const sortedServiceItems = sortItemsBySortOrder({
      items: (service as OfferPackage)?.items ?? [],
    });

    const serviceItem: Service = {
      id: service?.id,
      displayName: service?.content?.displayName?.value ?? '',
      shortDescription: service?.content?.shortDescription?.value ?? '',
      description: service?.content?.description?.value ?? '',
      image: service?.content?.image?.url ?? '',
      thumbnail: service?.content?.thumbnail?.url ?? '',
      disclaimer: (service as Product)?.content?.disclaimer?.value ?? '',
      applicableCarModels: [model],
      isElectricOnly: engineType === 'BEV',
      imageAltText: service?.content?.displayName?.value ?? '',
      items:
        sortedServiceItems?.map((item) => {
          const content = item?.content as ServiceContent;
          return {
            id: item?.id,
            displayName: content?.displayName?.value ?? '',
            shortDescription: content?.shortDescription?.value ?? '',
            description: content?.description?.value ?? '',
            image: content?.image?.url ?? '',
            thumbnail: content?.thumbnail?.url ?? '',
            disclaimer: content?.disclaimer?.value ?? '',
            sortOrder: content?.sortOrder ?? 0,
            imageAltText: content?.displayName?.value ?? '',
            sellingPoints:
              content?.sellingPoints?.map((item) => item?.title?.value) ?? [],
          };
        }) ?? [],
    };

    // Populating the applicableCarModels array so we can know which cars are eligible for each service
    if (
      existingService &&
      !existingService.applicableCarModels?.includes(model)
    ) {
      existingService.applicableCarModels.push(model);
    }

    // Overwrites 'isElectricOnly' to 'false' if current service exists in array, which means it's not unique to engineType 'BEV'
    if (existingService && engineType !== 'BEV') {
      existingService.isElectricOnly = false;
    } else if (
      !existingService &&
      serviceItem?.id &&
      serviceItem?.displayName
    ) {
      financeOption?.services?.push(serviceItem);
    }
  });
};

export const flattenFinanceOptions = (
  data: FinanceOptionsQuery | undefined,
): FinanceOptions => {
  const financeOptions =
    data?.offersByMarket?.customerTypes?.[0]?.offers.reduce((acc, curr) => {
      const salesModel = curr?.salesModel;
      const salesModelId = salesModel?.id as SalesModelId;

      acc[salesModelId] = {
        displayName: salesModel?.content?.displayName?.value ?? '',
        shortDescription: salesModel?.content?.shortDescription?.value ?? '',
        description: salesModel?.content?.description?.value ?? '',
        image: salesModel?.content?.image?.url ?? '',
        thumbnail: salesModel?.content?.thumbnail?.url ?? '',
        disclaimer: salesModel?.content?.disclaimer?.value ?? '',
        endOfContract: salesModel?.content?.endOfContract?.value ?? '',
        contractTerm: salesModel?.content?.contractTerm?.value ?? '',
        annualMileage: salesModel?.content?.annualMileage?.value ?? '',
        paymentMethod: salesModel?.content?.paymentMethod?.value ?? '',
        prepayment: salesModel?.content?.prepayment?.value ?? '',
        excessFees: salesModel?.content?.excessFees?.value ?? '',
        settlementOption: salesModel?.content?.settlementOption?.value ?? '',
        howItWorks:
          salesModel?.content?.howItWorks?.map((howItWorksItem) => ({
            title: howItWorksItem?.title?.value ?? '',
            image: howItWorksItem?.image?.url ?? '',
            video: howItWorksItem?.video?.url ?? '',
            shortDescription: howItWorksItem?.shortDescription?.value ?? '',
          })) ?? [],
        services: [],
      };

      return acc;
    }, {} as FinanceOptions) ?? {};

  data?.carsByMarket?.cars.forEach((car) => {
    car.offerList?.groupBy[0]?.offers.forEach((offer) => {
      const salesModelId = offer?.baseOffer.salesModel as SalesModelId;
      const financeOption = financeOptions?.[salesModelId] as FinanceOption;

      mapServices(
        car.model?.key ?? '',
        offer?.products?.sortedServices as Product[],
        financeOption,
        car.engine?.content?.engineType?.value as EngineType,
      );
      mapServices(
        car.model?.key ?? '',
        offer?.products?.packages as OfferPackage[],
        financeOption,
        car.engine?.content?.engineType?.value as EngineType,
      );
    });
  });

  return financeOptions;
};

export const useFinanceOptionToFinanceDetails = ({
  image,
  displayName,
  description,
  howItWorks,
  services,
  disclaimer,
  translate: translateFromProps,
}: Pick<
  FinanceOption,
  | 'image'
  | 'displayName'
  | 'description'
  | 'howItWorks'
  | 'services'
  | 'disclaimer'
> & { translate?: any }) => {
  const translateShared = useSharedComponentsTranslate();
  const translate = translateFromProps ? translateFromProps : translateShared;
  const hasElectricOnlyServices = services.some(
    ({ isElectricOnly }) => isElectricOnly,
  );

  return {
    salesModelImageUrl: image,
    financialOptionDescriptions: {
      displayName: displayName,
      description: description,
    },
    howItWorks: {
      title: translate('FinanceDetails.howItWorks.title'),
      keyPoints: howItWorks.map(({ video, ...item }) => ({
        ...item,
        videoUrl: video,
      })),
    },
    serviceDescriptions: hasElectricOnlyServices
      ? [
          {
            title: translate('FinanceDetails.includedServices.title'),
            services: services.filter(({ isElectricOnly }) => !isElectricOnly),
          },
          {
            title: translate(
              'FinanceDetails.electricVehiclesOnlyServices.title',
            ),
            services: services.filter(({ isElectricOnly }) => isElectricOnly),
          },
        ]
      : [
          {
            title: translate('FinanceDetails.includedServices.title'),
            services,
          },
        ],
    disclaimers: {
      title: translate('FinanceDetails.disclaimers.title'),
      disclaimers: [
        disclaimer,
        ...services.map(({ disclaimer }) => disclaimer),
      ].filter(Boolean),
    },
    loading: false,
  } as FinanceDetailsProps;
};
