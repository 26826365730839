import React from 'react';

import { Icon } from '@volvo-cars/react-icons';
import {
  type DEPRECATED_IconType,
  getIconPropsFromDeprecatedIconType,
} from '@volvo-cars/react-icons/compat';

export interface HeadingWithIconProps {
  type: DEPRECATED_IconType;
  text: string;
}

export const HeadingWithIcon = ({ text, type }: HeadingWithIconProps) => (
  <div className="flex flex-row justify-start items-center">
    <div className="w-64 h-64 bg-secondary flex items-center justify-center mr-16">
      <Icon {...getIconPropsFromDeprecatedIconType(type)} />
    </div>
    <h3 className="font-16 font-medium text-start">{text}</h3>
  </div>
);

export default HeadingWithIcon;
