/**
 * Type used for hacing media query based props.
 */
export type MediaQueryObject = {
  query: string | null;
  value: any;
};

/**
 * This method will return a progress of 0.0 - 1.0 depending on if given index
 * is + or - 1.
 */
export const activeIndicator = ({
  index,
  total,
  progress,
}: {
  index: number;
  total: number;
  progress: number;
}): number => {
  const val = 1 - Math.abs((total - 1) * progress - index);

  return Math.max(0, val);
};

/**
 * Converts a prop object that reflects a set of media queries to an array of:
 *
 * `[{ query: MediaQuery, value: any }, ...]`
 */
export const convertToMediaQueries = (
  /**
   * An object such as:
   * ```js
   * {
   *   default: 1,
   *   onlyS: 2,
   *   '@media (min-width: 2560px)': 3
   * }
   * ```
   */
  values: any,

  /**
   * Object of media queries, eg. `theme.breapoints` in VCC UI theme.
   */
  presets: any,
): MediaQueryObject[] => {
  const convertedValues: MediaQueryObject[] = [];

  if (values.constructor !== Object) {
    values = { default: values };
  }

  if (typeof values.default !== 'undefined') {
    convertedValues.push({
      query: null,
      value: values.default,
    });
  }

  Object.keys(values).forEach((query) => {
    if (presets[query]) {
      convertedValues.push({
        query: presets[query],
        value: values[query],
      });
    } else if (/^@media /.test(query)) {
      convertedValues.push({
        query,
        value: values[query],
      });
    }
  });

  return convertedValues;
};

/**
 * Loops through a set of media queries and return value from the last matching
 * query.
 */
export const getValueByMediaQuery = (
  mediaQueryObjects: MediaQueryObject[],
): any => {
  let matchedValue = null;

  mediaQueryObjects.forEach((obj) => {
    if (
      !obj.query ||
      window?.matchMedia(obj.query.replace('@media ', '')).matches
    ) {
      matchedValue = obj.value;
    }
  });
  return matchedValue;
};

/**
 * Will create a style block that can be spreaded into a VCC UI extend prop.
 */
export const createMediaQueryStyleBlocks = (
  mediaQueryObjects: MediaQueryObject[],
  callback: (value: any) => any,
): any => {
  const styles: any = {};

  mediaQueryObjects.forEach((mediaQueryObject) => {
    Object.assign(
      styles,
      mediaQueryObject.query
        ? {
            [mediaQueryObject.query]: callback(mediaQueryObject.value),
          }
        : callback(mediaQueryObject.value),
    );
  });

  return styles;
};
