'use client';
import React, { useState } from 'react';

import { useFormContext } from 'react-hook-form';
import type { FieldProps } from '@vcc-package/leads-utils/types';
import {
  FlexFormTestID,
  NonApiFieldName,
  PhoneValidation,
} from '@vcc-package/leads-utils/types';
import {
  FlexFormFieldType,
  TranslationKey,
} from '@vcc-package/leads-utils/api';
import { getTranslationKey } from '@vcc-package/leads-utils/util';
import {
  getInputErrorMessages,
  useValidation,
} from '@vcc-package/leads-utils/hooks';

import { ValidatedField } from '../../flexible-forms/components';

import { HiddenInput } from '.';

interface MobilePhoneAsyncFieldProps extends FieldProps {
  allowManualPhoneValidation: boolean;
}

export const MobilePhoneAsyncField = ({
  translate,
  validation,
  allowManualPhoneValidation,
  customTranslation,
  hideRequired,
}: MobilePhoneAsyncFieldProps): JSX.Element | null => {
  const { control } = useFormContext();
  const { validatePhone } = useValidation();
  const messages = getInputErrorMessages(translate);
  const customTranslationKeys = getTranslationKey(
    customTranslation,
    FlexFormFieldType.MOBILE_PHONE_ASYNC,
  );

  const [phoneValidation, setPhoneValidation] = useState<
    PhoneValidation | undefined
  >(undefined);

  return (
    <>
      <ValidatedField
        hideRequired={hideRequired}
        name={FlexFormFieldType.MOBILE_PHONE}
        testid={FlexFormTestID.MobilePhoneAsync}
        label={
          customTranslationKeys
            ? translate(customTranslationKeys.label)
            : translate(TranslationKey.PHONE_FIELD_LABEL)
        }
        autoComplete="tel"
        inputType="Tel"
        allowManualPhoneValidation={allowManualPhoneValidation}
        setPhoneValidation={setPhoneValidation}
        type="gbgPhone"
        control={control}
        validate={validatePhone}
        messages={messages}
        {...validation.mobilephoneasync}
      />
      {phoneValidation === PhoneValidation.EXTERNAL ? (
        <HiddenInput
          name={NonApiFieldName.PhoneValidation}
          value={PhoneValidation.EXTERNAL}
        />
      ) : phoneValidation === PhoneValidation.INTERNAL ? (
        <HiddenInput
          name={NonApiFieldName.PhoneValidation}
          value={PhoneValidation.INTERNAL}
        />
      ) : (
        <></>
      )}
    </>
  );
};
