import type { MarketVehicleConfiguration } from '@vcc-package/leads-utils/api';

import { getPno34FromModelName } from './getPno34FromModelName';

/**
 * 1. Takes in a proposed model- or PNO34-string value.
 * 2. First we do a direct check if we can obtain a model directly through the proposed string is a PNO34 value
 * 3. If Step 2 fails, we try & obtain the PNO34 entries for the given model-string value.
 *    If no entries are found, returns null.
 * 4. Check the PNO entries & find a matching model in the vehicles configuration
 * 5. Lastly, if no model match has been made, return null
 * @returns VehicleConfiguration | null
 */
export const getModel = ({
  pno,
  modelkey,
  vehicles,
}: {
  pno?: string | null;
  modelkey?: string | null;
  vehicles?: MarketVehicleConfiguration[];
}): MarketVehicleConfiguration | null => {
  if (!vehicles || (!pno && !modelkey)) return null;

  // Sort the vehicles by model year, descending
  const sortedVehicles = [...vehicles].sort(
    (a, b) => b.modelYear - a.modelYear,
  );

  // Step 2
  if (pno) {
    const pno34Model = getModelFromPno34(pno, sortedVehicles);
    if (pno34Model) return pno34Model;
  }

  // Step 3
  if (modelkey) {
    const pno34Entries = getPno34FromModelName(modelkey);
    if (!pno34Entries) return null;

    // Step 4
    for (const pno34 of pno34Entries) {
      const match = getModelFromPno34(pno34, sortedVehicles);
      if (match) return match;
    }
  }

  // Step 5
  return null;
};

const getModelFromPno34 = (
  pno34: string,
  vehicles: MarketVehicleConfiguration[],
  minimumPnoLength: number = 5,
): MarketVehicleConfiguration | null => {
  let numOfCharacters = 4;

  let matches = vehicles.filter(
    (v) =>
      v.pno34.substring(0, numOfCharacters) ===
      pno34.substring(0, numOfCharacters),
  );
  while (matches.length > 1 && pno34.length > numOfCharacters) {
    const newMatches = matches.filter(
      (v) =>
        v.pno34.substring(0, numOfCharacters) ===
        pno34.substring(0, numOfCharacters),
    );

    if (newMatches.length > 0) {
      matches = newMatches;
    }

    numOfCharacters++;
  }
  if (matches.length === 0) {
    return null;
  }

  const minimumPno = matches[0].pno34.substring(0, minimumPnoLength);
  return {
    ...matches[0],
    pno34: pno34.length < minimumPnoLength ? minimumPno : pno34,
    modelYear: matches.length > 1 ? 0 : matches[0].modelYear,
  };
};
