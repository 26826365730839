'use client';
// TODO: Fix eslint issues the next time this file is edited.
/* eslint-disable @volvo-cars/css/no-custom-class */
import React from 'react';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import { useBreakpoints } from '@vcc-www/hooks';
import { Markdown } from '@vcc-package/text';
import { cssJoin } from '@volvo-cars/css/utils';

import { Spacing } from 'src/components/layout/Spacing';
import styles from './styles.module.css';
import { Background, backgroundMap } from './utils';
import { ResponsiveImage } from '@vcc-package/media';
import { type ResponsiveImagesContent } from '@vcc-package/media/content-management';
import type { ImageEntry } from '@volvo-cars/content-management-client';

interface USP {
  title: string;
  body: string;
}

export interface ParallaxImageWithUSPsProps {
  type: 'parallaxImageWithUSPs';
  responsiveImages?: ResponsiveImagesContent;
  imageMobile: ImageEntry;
  imageDesktop: ImageEntry;
  imageDesktopAspectRatio?: string;
  usps: USP[];
  background: Background;
  noPadding?: boolean;
  disclaimer?: string;
}

export const ParallaxImageWithUSPs: React.FC<
  React.PropsWithChildren<ParallaxImageWithUSPsProps>
> = ({
  responsiveImages,
  imageMobile,
  imageDesktop,
  imageDesktopAspectRatio,
  usps,
  background = Background.Warm,
  noPadding,
  disclaimer,
}) => {
  // eslint-disable-next-line vcc-www/use-breakpoints
  const { onlyM } = useBreakpoints();

  usps = usps.slice(0, 3);

  const desktopAspectRatio = (imageDesktopAspectRatio || '4:5').replace(
    ':',
    '/',
  );

  return (
    <Spacing>
      <ParallaxProvider>
        <div
          data-testid="parallax-image-with-usps"
          className={cssJoin(
            'mx-auto container',
            styles['parallax-container'],
            styles['horizontal-padding'],
            !noPadding && styles['vertical-padding'],
          )}
          style={{ background: backgroundMap[background] }}
        >
          <div
            className={cssJoin(
              'flex justify-between',
              styles['inner-container'],
            )}
          >
            <div
              className={cssJoin(
                styles['image-container'],
                styles['flex-shrink'],
              )}
            >
              {responsiveImages && (
                <Parallax speed={onlyM ? -10 : -5} scale={[1.2, 1.2]}>
                  <ResponsiveImage
                    data-testid="parallax-image-with-usps:image"
                    images={responsiveImages}
                    alt={responsiveImages.sm?.alt}
                    sizes={{ md: 1 / 2 }}
                  />
                </Parallax>
              )}
              {/* "old" image fields need to remain here until we've deprecated
                  all Sitecore usage of this component. */}
              {!responsiveImages && imageMobile?.src && imageDesktop?.src && (
                <Parallax speed={onlyM ? -10 : -5} scale={[1.2, 1.2]}>
                  <picture>
                    <source
                      media="(min-width:1024px)"
                      srcSet={imageDesktop.src}
                    />
                    <img
                      data-testid="parallax-image-with-usps:image"
                      src={imageMobile.src}
                      alt={imageMobile.alt || imageDesktop.alt || ''}
                      className={cssJoin(
                        'object-cover',
                        'aspect-4/3',
                        `lg:aspect-${desktopAspectRatio}`,
                      )}
                      loading="eager"
                    />
                  </picture>
                </Parallax>
              )}
            </div>
            <div
              className={cssJoin(
                'py-32 px-24 md:p-48',
                styles['usps-container'],
                styles['flex-shrink'],
              )}
            >
              {usps.map((usp, index) => (
                <div
                  key={index}
                  data-testid="parallax-image-with-usps:usp"
                  className={cssJoin('flex-col', styles['usps-item-container'])}
                >
                  <h2
                    data-testid="parallax-image-with-usps:usp:title"
                    className="heading-2 mb-16"
                  >
                    {usp.title}
                  </h2>
                  <p data-testid="parallax-image-with-usps:usp:body">
                    {usp.body}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        {disclaimer && (
          <Markdown
            className="container micro text-secondary text-center mt-48"
            markdown={disclaimer}
          />
        )}
      </ParallaxProvider>
    </Spacing>
  );
};
