'use client';

import React, { createContext, useCallback, useContext, useState } from 'react';
import { usePartExchangeOverlay } from './overlay/part-exchange.overlay-provider';
import {
  ContentProps,
  LocalStorageItem,
  LocalStorageLabels,
  PartExchangeError,
  QuestionWithAnswer,
} from './part-exchange.types';
import { getExpirationTime, gtmCategory } from './part-exchange.utils';
import { ImageWithCheckpointsProps } from '@vcc-www/image-with-checkpoints/ImageWithCheckpoints.types';
import { PxStepsEntry } from './components/PxSteps';
import { EventAction, useTracker } from '@volvo-cars/tracking';

export const usePartExchangeTool = () => {
  const { showPartExchangeTool } = usePartExchangeOverlay();

  const saveItemToLocalStorage = (label: LocalStorageLabels, data: any) => {
    const now = new Date();
    const item: LocalStorageItem = {
      value: data,
      expiry: now.getTime() + getExpirationTime(),
    };
    localStorage.setItem(label, JSON.stringify(item));
  };

  const getItemFromLocalStorage = useCallback(
    (label: LocalStorageLabels) => getFromLocalStorageByLabel(label),
    [],
  );

  return {
    showPartExchangeTool,
    saveItemToLocalStorage,
    getItemFromLocalStorage,
  };
};

const getFromLocalStorageByLabel = (label: LocalStorageLabels) => {
  if (typeof window !== 'undefined') {
    const item = localStorage.getItem(label);

    if (!item) return undefined;

    const parsedItem = JSON.parse(item) as LocalStorageItem;
    const now = new Date();

    if (now.getTime() > parsedItem.expiry) {
      localStorage.removeItem(label);
      return undefined;
    }
    return parsedItem.value;
  }
};

const PartExchangeContext = createContext<{
  questions: QuestionWithAnswer[][];
  setQuestions: React.Dispatch<React.SetStateAction<QuestionWithAnswer[][]>>;
  registrationNumber: string;
  setRegNumber: React.Dispatch<React.SetStateAction<string>>;
  mileage: string;
  setMileage: React.Dispatch<React.SetStateAction<string>>;
  pageIndex: number;
  setPageIndex: React.Dispatch<React.SetStateAction<number>>;
  groupIndex: number;
  setGroupIndex: React.Dispatch<React.SetStateAction<number>>;
  outstandingFinance: string;
  setOutstandingFinance: React.Dispatch<React.SetStateAction<string>>;
  error: PartExchangeError | null;
  setError: React.Dispatch<React.SetStateAction<PartExchangeError | null>>;
  loginRoute: string;
  authTokenRoute: string;
  imageWithCheckpointsData: ImageWithCheckpointsProps;
  stepsData: PxStepsEntry;
  sendTrackingInfo: (eventLabel: string, eventAction: EventAction) => void;
}>({
  questions: [],
  setQuestions: () => {},
  registrationNumber: '',
  setRegNumber: () => {},
  mileage: '',
  setMileage: () => {},
  pageIndex: 0,
  setPageIndex: () => {},
  groupIndex: 0,
  setGroupIndex: () => {},
  outstandingFinance: '',
  setOutstandingFinance: () => {},
  error: null,
  setError: () => {},
  loginRoute: '',
  authTokenRoute: '',
  imageWithCheckpointsData: {},
  stepsData: {},
  sendTrackingInfo: () => {},
});

export const PartExchangeProvider: React.FC<
  React.PropsWithChildren<{
    loginRoute: string;
    authTokenRoute: string;
    content: ContentProps;
  }>
> = ({ loginRoute, authTokenRoute, content, children }) => {
  const regNumberFromLS = getFromLocalStorageByLabel('regNumber') || '';
  const mileageFromLS = getFromLocalStorageByLabel('mileage') || '';
  const outstandingFinanceFromLS =
    getFromLocalStorageByLabel('outstandingFinance');

  const [questions, setQuestions] = useState([] as QuestionWithAnswer[][]);
  const [registrationNumber, setRegNumber] = useState(regNumberFromLS);
  const [mileage, setMileage] = useState(mileageFromLS);
  const [pageIndex, setPageIndex] = useState(0);
  const [groupIndex, setGroupIndex] = useState(0);
  const [outstandingFinance, setOutstandingFinance] = useState(
    outstandingFinanceFromLS || '',
  );
  const [error, setError] = useState<PartExchangeError | null>(null);

  const ga4Tracker = useTracker({}, { mode: 'ga4' });

  const sendTrackingInfo = (eventLabel: string, eventAction: EventAction) => {
    ga4Tracker.customEvent({
      eventCategory: gtmCategory,
      eventAction: eventAction,
      eventLabel: eventLabel,
    });
  };

  return (
    <PartExchangeContext.Provider
      value={{
        questions,
        setQuestions,
        registrationNumber,
        setRegNumber,
        mileage,
        setMileage,
        pageIndex,
        setPageIndex,
        groupIndex,
        setGroupIndex,
        outstandingFinance,
        setOutstandingFinance,
        error,
        setError,
        loginRoute,
        authTokenRoute,
        imageWithCheckpointsData: content?.imageWithCheckpointsData,
        stepsData: content?.stepsData,
        sendTrackingInfo,
      }}
    >
      {children}
    </PartExchangeContext.Provider>
  );
};

export const usePartExchange = () => {
  const value = useContext(PartExchangeContext);

  if (!value?.loginRoute && !value?.authTokenRoute) {
    throw new Error('PartExchangeContext is missing in the component tree');
  }

  return value;
};

export const useValuationSummary = () => {
  const { getItemFromLocalStorage } = usePartExchangeTool();

  const questionsWithAnswers = getItemFromLocalStorage(
    'questionsWithAnswers',
  ) as QuestionWithAnswer[][];

  const carValuationFromLS = getItemFromLocalStorage('carValuation') as number;

  const regNumberFromLS: string = getItemFromLocalStorage('regNumber');

  const mileageFromLS: string = getItemFromLocalStorage('mileage');

  const outstandingFinance =
    questionsWithAnswers
      ?.flat()
      .find(
        (question) =>
          question.question.slug ===
            'outstandingFinanceVolvoUkVolvoExcitement' ||
          question.question.slug ===
            'outstandingFinanceVolvoVolvoUkVolvoCareFifteenSure',
      )?.answer.answer === 'yes'
      ? parseInt(getItemFromLocalStorage('outstandingFinance') || '0')
      : 0;

  const equity = carValuationFromLS
    ? carValuationFromLS - outstandingFinance
    : 0;

  const formattedEquity =
    equity < 0
      ? `-£${(Math.abs(equity) as number).toLocaleString()}`
      : `£${(equity as number).toLocaleString()}`;

  return {
    outstandingFinance: outstandingFinance.toLocaleString(),
    formattedEquity: formattedEquity,
    equity: equity.toLocaleString(),
    questionsWithAnswers,
    carValuationFromLS: carValuationFromLS?.toLocaleString(),
    regNumberFromLS,
    mileageFromLS,
  };
};
