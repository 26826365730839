import React, { FC, MouseEventHandler } from 'react';
import { Step, StepInternalProps, StepProps } from './step/Step';
import { cssMerge } from '@volvo-cars/css/utils';
import { StepsCTAButton, StepsCTAButtonProps } from './StepsCTAButton';
import { StepsList } from './StepsList';
import type { IStep, TCTAButton, TStep } from '../types';
import type { LinkField } from '@vcc-www/content-management-jss-client';
import { isChildType } from '../utils';
import { StepsActions } from './StepsActions';
import { StepsHeader } from './StepsHeader';

export interface StepsProps {
  body?: string;
  children?: React.ReactNode;
  className?: string;
  /**
   * @deprecated not used anymore
   */
  containerBackground?: string;
  cta?: LinkField;
  notCenterAligned?: boolean;
  onClickCta?: MouseEventHandler<HTMLAnchorElement>;
  setTestId?: (name?: string | string[], index?: number | undefined) => string;
  steps?: IStep[];
  stepStyle?: 'numbers' | 'ticks';
  title?: string;
}

/**
 * Steps component, cotnains title, body, list of steps & a CTA button
 *
 * @example
 * // with steps prop
 * const steps = [
 *  { title: 'Step 1', body: 'Body 1' },
 *  { title: 'Step 2', body: 'Body 2' },
 * ];
 * return <Steps
 *  title="Title"
 *  body="Body"
 *  steps={steps}
 *  stepStyle="ticks"
 *  cta={{
 *    href: 'https://www.volvocars.com',
 *    text: 'CTA'
 *  }}
 * />
 *
 * @example
 * // with `Steps.Item` & `Steps.CTAButton` children components (preferred)
 * return <Steps
 *  title="Title"
 *  body="Body"
 *  stepStyle="ticks"
 * >
 *  <Steps.Item title="Step 1" body="Body 1" subBody="Sub Body 1" />
 *  <Steps.Item title="Step 2" body="Body 2" subBody="Sub Body 2" />
 *  <Steps.Item title="Step 3" body="Body 3" />
 *  <Steps.CTAButton href="https://volvocars.com" text="CTA" />
 * </Steps>;
 * @param {StepsProps} props component properties
 * @returns {React.ReactElement} steps component
 */
const Steps: FC<StepsProps> & {
  Item: FC<Omit<StepProps, StepInternalProps>>;
  CTAButton: FC<StepsCTAButtonProps>;
} = ({
  body,
  children,
  className,
  cta,
  notCenterAligned,
  onClickCta,
  setTestId,
  steps,
  stepStyle = 'numbers',
  title,
}) => {
  const stepDefaultProps = (stepChild: TStep, i: number, length: number) => ({
    // props that shouldn't be overriden by the child
    key: `item-${i}`,
    stepStyle,
    // props that should be defaulted if not provided
    index: i + 1,
    isLast: stepChild.props.isLast || i === length - 1,
    'data-testid': stepChild.props['data-testid'] || `step-${i}`,
    setTestId: stepChild.props.setTestId || setTestId,
    // props to be merged with the child's props
    className: cssMerge('flex-row gap-x-16', stepChild.props.className),
  });
  const ctaDefaultProps = (ctaButtonChild: TCTAButton, i: number) => ({
    // props that shouldn't be overriden by the child
    key: `cta-button-${i}`,
    // props that should be defaulted if not provided
    'data-testid': ctaButtonChild.props['data-testid'] || `cta-button-${i}`,
    href: ctaButtonChild.props.href || cta?.href,
    onClick: ctaButtonChild.props.onClick || onClickCta,
    text: ctaButtonChild.props.text || cta?.text,
    // props to be merged with the child's props
    className: cssMerge('flex-row gap-x-16', ctaButtonChild.props.className),
  });

  const childrenArray = React.Children.toArray(children);
  const childrenSteps = childrenArray
    .filter(isChildType(Step))
    .map((child, i, stepsList) =>
      React.cloneElement(child, stepDefaultProps(child, i, stepsList.length)),
    );
  const childrenCTAButton = childrenArray
    .filter(isChildType(StepsCTAButton))
    .map((child, i) => React.cloneElement(child, ctaDefaultProps(child, i)));

  return (
    <section
      data-testid={setTestId?.()}
      className={cssMerge(
        !notCenterAligned && 'container-md',
        'flex-shrink md:px-32 px-24 stack-text',
        className,
      )}
    >
      <StepsHeader title={title} body={body} />
      <StepsList
        items={steps}
        setTestId={setTestId}
        stepStyle={stepStyle}
        data-testid={setTestId?.(`list`)}
      >
        {childrenSteps}
      </StepsList>

      <StepsActions
        className="flex-shrink mt-24 md:mt-48 md:mx-auto"
        data-testid={setTestId?.('cta')}
        href={cta?.href}
        onClick={onClickCta}
        text={cta?.text}
      >
        {childrenCTAButton}
      </StepsActions>
    </section>
  );
};

Steps.Item = Step;
Steps.CTAButton = StepsCTAButton;

export default Steps;
