import { Breakpoints } from './getAspectRatioCSS';

export type Source = {
  src: string;
  media: string;
};

type MediaQueryKey = `@media ${string}`;

export type Sources = {
  [P in keyof Breakpoints]?: string;
} & { [key in MediaQueryKey]?: string } & { default: string };

export const getMediaSources: (
  sources: string | Sources | undefined,
  breakpoints: Breakpoints,
) => Source[] = (sources, breakpoints) => {
  if (!sources) {
    return [];
  }

  if (typeof sources === 'string') {
    return [{ src: sources, media: '' }];
  }

  // Get all sources that matches any theme breakpoint and convert them into an
  // array of media query and src.
  const breakpointMediaQueries = Object.keys(breakpoints as Breakpoints)
    .filter((key) => sources[key as keyof Sources] && key !== 'default')
    .map(
      (key): Source => ({
        src: sources[key as keyof Sources] || '',
        media: breakpoints[key as keyof Breakpoints].replace('@media ', ''),
      }),
    );

  // Get all sources that matches any custom media query and convert them into
  // an array of media query and src.
  const customMediaQueries = Object.keys(sources)
    .filter((key) => key.startsWith('@media '))
    .map((key) => ({
      media: key.replace('@media ', ''),
      src: (sources as any)[key],
    }));

  return [...breakpointMediaQueries, ...customMediaQueries];
};
