'use client';
import React from 'react';
import SharedOfferComparison from '@vcc-www/offer-comparison';
import {
  type OfferComparisonProps as BaseOfferComparisonProps,
  type ScrollDirection,
} from '@vcc-www/offer-comparison/OfferComparison.types';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { Spacing } from 'src/components/layout/Spacing';
import { TrackingProvider, useTracker } from '@volvo-cars/tracking';
import { formatTextWithCarDetails } from 'src/utils/queries';
import { EngineType } from 'src/types/queries';
import { useQueries } from 'src/hooks/useQueries';
import { useFeatureFlags } from '@vcc-www/feature-flags';
import { usePathname } from 'next/navigation';
import { getSiteSlug } from '@volvo-cars/market-sites/src/getSiteSlug';
import { type SiteSlug } from '@volvo-cars/market-sites';

export interface OfferComparisonProps
  extends Omit<BaseOfferComparisonProps, 'cars'> {
  type: 'offerComparison';
  ctaText?: string;
  disclaimer?: string;
  hybridDisclaimer?: string;
  electricDisclaimer?: string;
  generalDisclaimer?: string;
}

export const OfferComparison: React.FC<
  React.PropsWithChildren<OfferComparisonProps>
> = ({
  title,
  ctaText,
  disclaimer,
  hybridDisclaimer,
  electricDisclaimer,
  generalDisclaimer,
  detailsText,
  ...props
}) => {
  const pathname = usePathname();
  const market = getSiteSlug(pathname) as SiteSlug;
  const ga4Tracker = useTracker({}, { mode: 'ga4' });
  const eventCategory = 'offer comparison';
  const { graphCars } = useQueries();
  const { financeOptions } = useQueries();
  const { flags } = useFeatureFlags(); // Feature flag for setting the order of the BEV cars
  const useTranslate = useSharedComponentsTranslate();

  const sortedCars = graphCars.sort((a, b) => {
    if (
      flags.offerComparisonBEVSortOrder &&
      a.engineType === EngineType.BEV &&
      b.engineType === EngineType.BEV
    ) {
      return (
        flags.offerComparisonBEVSortOrder.indexOf(a.familyId) -
        flags.offerComparisonBEVSortOrder.indexOf(b.familyId)
      );
    }
    return 0;
  });

  const showMoreText = useTranslate('ReactFaqs.labels.showMore');
  const showLessText = useTranslate('ReactFaqs.labels.showLess');

  if (financeOptions?.SUB?.services?.length === 0 || graphCars.length === 0)
    return null;

  const makeCta: OfferComparisonProps['makeCta'] = ({ modelName }) => ({
    text: ctaText?.replace('{model}', modelName ?? ''),
    href: `/${market}/shop`,
  });

  const makeDisclaimer: OfferComparisonProps['makeDisclaimer'] = ({
    engineType,
    details,
    modelName,
  }) =>
    formatTextWithCarDetails(
      engineType === EngineType.BEV
        ? electricDisclaimer || ''
        : engineType === EngineType.PHEV
          ? hybridDisclaimer || ''
          : disclaimer || '',
      {
        ...details,
        modelName: {
          key: 'modelName',
          label: '',
          value: modelName ?? '',
        },
      },
    ) + (generalDisclaimer ? `\n${generalDisclaimer}` : '');

  return (
    <TrackingProvider eventCategory={eventCategory}>
      <Spacing>
        <SharedOfferComparison
          {...props}
          financeOptions={financeOptions}
          cars={sortedCars}
          title={title}
          detailsText={detailsText}
          makeCta={ctaText ? makeCta : undefined}
          makeDisclaimer={makeDisclaimer}
          showMoreText={showMoreText}
          showLessText={showLessText}
          overlayCloseEventLabel="view details"
          onCarClick={(car) => {
            ga4Tracker.customEvent({
              eventCategory,
              eventAction: 'card|select',
              eventLabel: `car click|${car.modelName}`,
            });
          }}
          onCtaClick={({ modelName }) => {
            ga4Tracker.customEvent({
              eventCategory,
              eventAction: 'button|click',
              eventLabel: `cta text|${ctaText?.replace(
                '{model}',
                modelName ?? '',
              )}`,
            });
          }}
          onOverlayClick={(isClosing?: boolean) => {
            ga4Tracker.customEvent({
              eventCategory,
              eventAction: isClosing ? 'overlay|close' : 'overlay|open',
              eventLabel: 'view details',
            });
          }}
          onExpandClick={(isExpanding?: boolean) => {
            ga4Tracker.customEvent({
              eventCategory,
              eventAction: 'accordion|expand',
              eventLabel: isExpanding ? 'show more' : 'show less',
            });
          }}
          onArrowClick={(direction: ScrollDirection) => {
            ga4Tracker.customEvent({
              eventCategory,
              eventAction:
                direction === 'right' ? 'arrow|next' : 'arrow|previous',
              eventLabel: `direction|${direction}`,
            });
          }}
        />
      </Spacing>
    </TrackingProvider>
  );
};
