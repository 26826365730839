/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConfigurationHistorySummary } from '../models/ConfigurationHistorySummary';
import type { GetAllTranslationsResponse } from '../models/GetAllTranslationsResponse';
import type { StoredTranslation } from '../models/StoredTranslation';
import type { StoredTranslationsHistory } from '../models/StoredTranslationsHistory';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TranslationService {
  /**
   * @returns GetAllTranslationsResponse OK
   * @throws ApiError
   */
  public static getAllTranslations({
    siteSlug,
    clientId,
  }: {
    siteSlug: string;
    clientId?: string;
  }): CancelablePromise<GetAllTranslationsResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/forms-backend/v1/{siteSlug}/translation/all',
      path: {
        siteSlug: siteSlug,
      },
      query: {
        clientId: clientId,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns ConfigurationHistorySummary OK
   * @throws ApiError
   */
  public static getTranslationHistory({
    siteSlug,
  }: {
    siteSlug: string;
  }): CancelablePromise<Array<ConfigurationHistorySummary>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/forms-backend/v1/{siteSlug}/translation/history/all',
      path: {
        siteSlug: siteSlug,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns StoredTranslationsHistory OK
   * @throws ApiError
   */
  public static getTranslationHistoryById({
    siteSlug,
    id,
  }: {
    siteSlug: string;
    id?: string;
  }): CancelablePromise<StoredTranslationsHistory> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/forms-backend/v1/{siteSlug}/translation/history/by-id',
      path: {
        siteSlug: siteSlug,
      },
      query: {
        id: id,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns StoredTranslation OK
   * @throws ApiError
   */
  public static getFallback({
    siteSlug,
  }: {
    siteSlug: string;
  }): CancelablePromise<StoredTranslation> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/forms-backend/v1/{siteSlug}/translation/get-fallback',
      path: {
        siteSlug: siteSlug,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }
}
