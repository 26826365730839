import { cssMerge } from '@volvo-cars/css/utils';
import React from 'react';
import { StepsProps } from './Steps';

export type StepsCTAButtonProps = {
  className?: string;
  'data-testid'?: string;
  href?: string;
  onClick?: StepsProps['onClickCta'];
  text?: string;
};

export const StepsCTAButton = ({
  className,
  'data-testid': testID,
  href,
  onClick: handleClick,
  text,
}: StepsCTAButtonProps) => {
  if (!href && !text) return null;

  return (
    <a
      className={cssMerge('button-outlined', className)}
      data-testid={testID}
      data-color="accent"
      href={href}
      onClick={handleClick}
    >
      {text}
    </a>
  );
};
