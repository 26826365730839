import type { PublicRuntimeConfig } from './config';

export const appEnvByHostname = (
  hostname: string,
): PublicRuntimeConfig['appEnv'] => {
  const cleanedHostname = hostname
    .replace('http://', '')
    .replace('https://', '')
    .replace(':3000', '')
    .replace(/[/]+$/, '');

  switch (cleanedHostname) {
    case 'www.volvocars.com':
      return 'prod';
    case 'qawww.volvocars.com':
      return 'qa';
    case 'localhost':
      return 'dev';
    case 'testwww.volvocars.com':
    default:
      return 'test';
  }
};

export const hostnameByAppEnv: Record<PublicRuntimeConfig['appEnv'], string> = {
  prod: 'www.volvocars.com',
  qa: 'qawww.volvocars.com',
  test: 'testwww.volvocars.com', //NOTE: this could also be a review app or localdev embeddable form
  dev: 'localhost',
};
