import { useMemo } from 'react';

import type { Translations, Translator } from '@vcc-package/leads-utils/types';
import type { PurposeType } from '@vcc-package/leads-utils/api';
import { TranslationKey } from '@vcc-package/leads-utils/api';
import { PurposePrefixLookup } from '@vcc-package/leads-utils/constants';

import type { Messages } from '../src/flexible-forms/components/types';
import { useRetailer } from '../src/context';

let dictionary: Translations | null = null;

const uppercaseDictionaryKeys = (dictionary: Translations) => {
  const newDictionary: Translations = {};
  for (const key in dictionary) {
    newDictionary[key.toUpperCase()] = dictionary[key];
  }
  return newDictionary;
};

export const useTranslator = ({
  dictionary: dictionaryFromProps,
  hideMissingTranslationMessage,
  purpose,
  useExistingDictionary = false,
}: {
  dictionary: Translations;
  hideMissingTranslationMessage: boolean;
  purpose: PurposeType | null;
  useExistingDictionary?: boolean;
}): Translator => {
  const { isInsideSales } = useRetailer();
  const retailerString = isInsideSales ? 'INSIDE_SALES_' : '';
  const purposePrefix = purpose ? PurposePrefixLookup[purpose] : undefined;
  dictionary = uppercaseDictionaryKeys(
    useExistingDictionary
      ? dictionary ?? dictionaryFromProps
      : dictionaryFromProps,
  );

  const translator: Translator = useMemo(() => {
    return (key: string, ...args: string[]) => {
      const upperCaseKey = key.toUpperCase();
      if (!dictionary) {
        console.error("Can't find translations");
        throw Error('Dictionary is missing');
      }

      const retailerLevelTranslation = `${purposePrefix}_${retailerString}${key}`;
      const purposeLevelTranslation = `${purposePrefix}_${upperCaseKey}`;

      let str = '';
      if (dictionary[retailerLevelTranslation]) {
        str = dictionary[retailerLevelTranslation];
      } else if (dictionary[purposeLevelTranslation]) {
        str = dictionary[purposeLevelTranslation];
      } else {
        str = dictionary[upperCaseKey];
      }

      if (str === undefined) {
        if (hideMissingTranslationMessage) {
          str = ' ';
        } else {
          str = notFoundMessage(upperCaseKey);
        }
        return str;
      }

      args.length > 0
        ? args.forEach((item, index) => {
            str = str.split('$' + index).join(item);
          })
        : str; // Fallback if no arguments are given

      return str;
    };
  }, [hideMissingTranslationMessage, purposePrefix, retailerString]) as any;
  translator.hideMissingTranslationMessage = hideMissingTranslationMessage;
  translator.customArgumentRender = ({
    key,
    args,
    renderArgument,
  }): string[] => {
    if (!dictionary) {
      console.error("Can't find translations");
      throw Error('Dictionary is missing');
    }

    const upperCaseKey = key.toUpperCase();

    let str = dictionary[upperCaseKey] as string | undefined;
    if (str === undefined) {
      if (hideMissingTranslationMessage) {
        str = ' ';
      } else {
        str = notFoundMessage(upperCaseKey);
      }
      return [str];
    }

    const splitString: { text: string; argument: boolean }[] = str
      .split(/(\$\d+)/)
      .map((s) => ({ text: s, argument: false }));

    if (args && args.length > 0) {
      args.forEach((item, index) => {
        const element = splitString.find((s) => s.text === `$${index}`);
        if (element) {
          element.text = item;
          element.argument = true;
        }
      });
    }

    return splitString.map((p, i) =>
      p.argument ? renderArgument(p.text, i) : p.text,
    );
  };
  translator.setDictionary = (newDictionary: Translations) => {
    dictionary = uppercaseDictionaryKeys(newDictionary);
  };
  translator.getDictionary = (): Translations | null => {
    return dictionary;
  };
  return translator as Translator;
};

export const useFallback =
  (translator: Translator, fallback: string) =>
  (key: string, ...args: string[]) => {
    const upperCaseKey = key.toUpperCase();
    const result = translator(upperCaseKey, ...args);

    if (translator.hideMissingTranslationMessage)
      return result === ' ' ? fallback : result;
    return result === notFoundMessage(upperCaseKey) ? fallback : result;
  };

export const useFallbacks =
  (translator: Translator, fallback: string) =>
  (key: string[], ...args: string[]) => {
    let result = ' ';

    for (let i = 0; i < key.length; i++) {
      const upperCaseKey = key[i].toUpperCase();
      const translation = translator(upperCaseKey, ...args);

      if (
        translator.hideMissingTranslationMessage
          ? translation !== ' '
          : translation !== notFoundMessage(upperCaseKey)
      ) {
        result = translation;
        break;
      }
    }

    return result === ' ' ? fallback : result;
  };

export const isTranslationFound = (
  translator: Translator,
  translation: string,
  translationKey: string,
): boolean => {
  if (translator.hideMissingTranslationMessage && translation !== ' ')
    return true;

  return (
    !translator.hideMissingTranslationMessage &&
    translation !== notFoundMessage(translationKey)
  );
};

const notFoundMessage = (key: string) => `<No transl. [${key}]>`;

export const getInputErrorMessages = (translate: Translator): Messages => {
  return {
    required: (...args) => translate(TranslationKey.REQUIRED_FIELD, ...args),
    length: (...args) => translate(TranslationKey.LENGTH_FIELD, ...args),
    invalid: (...args) => translate(TranslationKey.INVALID_FIELD, ...args),
    digitsOnly: (...args) => translate(TranslationKey.DIGITS_ONLY, ...args),
    fourDigitsOnly: (...args) =>
      translate(TranslationKey.FOUR_DIGITS_ONLY, ...args),
  };
};
