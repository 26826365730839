import React, { ReactNode } from 'react';

export const StepSubBody = ({
  children,
  'data-testid': testID,
}: {
  children?: ReactNode;
  'data-testid'?: string;
}) => {
  if (!children) return null;

  return (
    <small className="micro text-secondary" data-testid={testID}>
      {children}
    </small>
  );
};
