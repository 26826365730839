import gql from 'graphql-tag';

export const PART_EXCHANGE_QUESTIONS = gql`
  query GetPartExchangeQuestions {
    getPartExchangeQuestions {
      success
      messages {
        type
        message
      }
      payload {
        questions {
          text
          type
          slug
          required
          sortOrder
          title
          errorMessage
          tooltip
          options {
            text
            slug
            sortOrder
            title
            tooltip
            description
            defaultSelected
            acceptable
          }
        }
      }
    }
  }
`;
