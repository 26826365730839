import React from 'react';

import { cssJoin } from '@volvo-cars/css/utils';
import { globalStyling } from '@vcc-package/leads-utils/constants';

import styles from './rowContainer.module.css';

export type RowContainerProps = React.PropsWithChildren<{
  useSingleColumn?: boolean;
}>;

export const RowContainer = ({
  children,
  useSingleColumn,
}: RowContainerProps): JSX.Element | null => {
  return (
    <div
      className={cssJoin(
        'flex flex-col',
        globalStyling.fieldGap,
        !useSingleColumn && 'lg:flex-row w-full',
        styles.row,
      )}
    >
      {React.Children.toArray(children).map((child, i) =>
        React.isValidElement(child) ? (
          child
        ) : (
          <div key={i} className="hidden lg:flex" />
        ),
      )}
    </div>
  );
};
