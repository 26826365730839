import React from 'react';
import { BaseRowProps, ColumnProps } from './ComparisonTable.types';
import { Icon } from '@volvo-cars/react-icons';

export function getData<TEntity extends BaseRowProps>(
  getValue: ColumnProps<TEntity>['getValue'],
  item: TEntity,
) {
  const value = getValue(item);
  if (typeof value === 'boolean') {
    return <Icon icon={value ? 'checkmark' : 'x'} size={16} />;
  }
  return value;
}
