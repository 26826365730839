'use client';
import React from 'react';

import { useFormContext } from 'react-hook-form';
import type { FieldProps } from '@vcc-package/leads-utils/types';
import {
  FlexFormTestID,
  NonApiFieldName,
  PhoneValidation,
} from '@vcc-package/leads-utils/types';
import {
  FlexFormFieldType,
  TranslationKey,
} from '@vcc-package/leads-utils/api';
import { getTranslationKey } from '@vcc-package/leads-utils/util';
import {
  getInputErrorMessages,
  useValidation,
} from '@vcc-package/leads-utils/hooks';

import { ValidatedField } from '../../flexible-forms/components';

import { HiddenInput } from '.';

export const MobilePhoneField = ({
  translate,
  validation,
  customTranslation,
  hideRequired,
}: FieldProps): JSX.Element => {
  const { control } = useFormContext();
  const messages = getInputErrorMessages(translate);
  const customTranslationKeys = getTranslationKey(
    customTranslation,
    FlexFormFieldType.MOBILE_PHONE,
  );
  const { regexValidate } = useValidation(validation?.mobilephone);

  return (
    <>
      <ValidatedField
        hideRequired={hideRequired}
        key="mobilephone"
        name={FlexFormFieldType.MOBILE_PHONE}
        testid={FlexFormTestID.MobilePhone}
        label={
          customTranslationKeys
            ? translate(customTranslationKeys.label)
            : translate(TranslationKey.PHONE_FIELD_LABEL)
        }
        inputType="Tel"
        autoComplete="tel"
        control={control}
        validate={regexValidate}
        messages={messages}
        {...validation.mobilephone}
      />
      <HiddenInput
        name={NonApiFieldName.PhoneValidation}
        value={PhoneValidation.IGNORED}
      />
    </>
  );
};
