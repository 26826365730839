import { useCallback, useEffect, useRef, useState } from 'react';
import { SelectableCardProps } from './selectable-card.types';

export const useSelectableCard = ({
  children,
  disabled,
  href,
  onClick,
}: Pick<SelectableCardProps, 'children' | 'href' | 'onClick' | 'disabled'>) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const linkRef = useRef<HTMLDivElement>(null);
  const [activeHeight, setActiveHeight] = useState(0);

  useEffect(() => {
    setActiveHeight(contentRef.current?.scrollHeight || 0);
  }, [children]);

  const onClickHandler = useCallback(
    (e: React.MouseEvent | React.TouchEvent) => {
      if (typeof onClick === 'function' && !disabled) {
        !href && e.preventDefault();
        onClick(e);
      }
    },
    [disabled, href, onClick],
  );

  return { activeHeight, contentRef, linkRef, onClickHandler };
};
