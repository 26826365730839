import { getAuthToken } from './getAuthToken';
import { getPasswordlessLoginCode } from './getPasswordlessLoginCode';

export const initiatePasswordlessLogin = async (
  loginRoute: string,
  authTokenRoute: string,
) => {
  try {
    const { loginCode } = await getPasswordlessLoginCode(loginRoute);
    const { authToken } = await getAuthToken(authTokenRoute, loginCode);
    return { authToken, authError: '' };
  } catch (error) {
    console.error(error);
    return { authToken: '', authError: error };
  }
};
