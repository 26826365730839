export type FinanceOptions = Partial<Record<SalesModelId, FinanceOption>>;

export enum SalesModelId {
  Sub = 'SUB',
  SubFixed = 'SUB_FIXED',
  Cash = 'CASH',
  Loan = 'LOAN',
  Lease = 'LEASE',
}

export interface FinanceOption {
  displayName: string;
  shortDescription: string;
  description: string;
  image: string;
  thumbnail: string;
  disclaimer: string;
  endOfContract: string;
  contractTerm: string;
  annualMileage: string;
  paymentMethod: string;
  prepayment: string;
  excessFees: string;
  settlementOption: string;
  howItWorks: HowItWorks[];
  services: Service[];
}

interface HowItWorks {
  title: string;
  image: string;
  video: string;
  shortDescription: string;
}

export interface Service {
  id: string;
  displayName: string;
  shortDescription: string;
  description: string;
  image: string;
  thumbnail: string;
  disclaimer: string;
  applicableCarModels: string[];
  isElectricOnly: boolean;
  imageAltText: string;
  items?: Omit<Service, 'items' | 'applicableCarModels' | 'isElectricOnly'>[];
}
