interface AuthResponseType {
  loginCode: string;
}

export const getPasswordlessLoginCode: (apiRoute: string) => Promise<{
  loginCode: string;
}> = async (apiRoute: string) => {
  try {
    const res = await fetch(apiRoute, {
      method: 'POST',
    });

    if (res.ok) {
      const loginCode: AuthResponseType = await res.json();
      return loginCode;
    } else {
      const loginError = await res.json();
      const error = new Error(loginError.message);
      return Promise.reject(error);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
