export interface Messages {
  required?: (...args: any) => string;
  length?: (...args: any) => string;
  email?: (...args: any) => string;
  phone?: (...args: any) => string;
  address?: (...args: any) => string;
  invalid?: (...args: any) => string;
  digitsOnly?: (...args: any) => string;
  fourDigitsOnly?: (...args: any) => string;
}

export interface SelectOption {
  value: string;
  label: string | null;
}

export interface FieldValidations {
  [key: string]: FieldValidation;
}

export interface FieldValidation {
  type?: ValidationMethod | null;
  args?: string[] | null;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
}

export const ValidationMethods = {
  gbgEmail: 'gbgEmail',
  gbgPhone: 'gbgPhone',
  gbgPowersearch: 'gbgPowersearch',
  gbgAddress: 'gbgAddress',
  regex: 'regex',
  empty: '',
} as const;

export type ValidationMethod =
  (typeof ValidationMethods)[keyof typeof ValidationMethods];
