import React, { useRef, useState } from 'react';

import type { DealerModel } from '@vcc-package/leads-utils/api';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@volvo-cars/react-forms';

import { AddressAutocompleteInput } from '../../../../components';

import type { UseGetRetailersProps } from './hooks/useGetRetailers';
import { useGetRetailers } from './hooks/useGetRetailers';
import { RetailerItem } from './retailerItem';
import type { RetailerPickerProps } from './types';

const PAGE_SIZE = 4;

const defaultGetRetailerProps: UseGetRetailersProps = {
  retailerListType: 'Address',
  address: '',
  offset: 0,
  limit: PAGE_SIZE,
};

export const RetailerPicker = ({
  name,
  labels,
  messages,
  control,
  required,
  onSelect,
}: RetailerPickerProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const [selectedRetailerState, setSelectedRetailer] =
    useState<DealerModel | null>(null);

  const [getRetailersProps, setGetRetailersProps] =
    useState<UseGetRetailersProps>(defaultGetRetailerProps);

  const { retailers, isLoading, canLoadMore } =
    useGetRetailers(getRetailersProps);

  const updateAddress = (address: string) => {
    setGetRetailersProps((prev) => ({
      ...prev,
      ...defaultGetRetailerProps,
      address,
    }));
  };

  const selectRetailer = (dealer: DealerModel | null) => {
    setSelectedRetailer(dealer);
    onSelect(dealer);
    if (dealer === null) return;
    if (!ref?.current) return;
    ref.current.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const onGeolocationRetrievalSuccess = ({
    latitude,
    longitude,
  }: GeolocationCoordinates) => {
    setGetRetailersProps((prev) => ({
      ...prev,
      ...defaultGetRetailerProps,
      latitude,
      longitude,
      retailerListType: 'Coordinates',
    }));
  };

  return (
    <Controller
      control={control}
      defaultValue={undefined}
      name={name}
      rules={{
        required: {
          value: required,
          message: messages?.required
            ? messages.required(labels.selectRetailerCloseToAddress)
            : '',
        },
      }}
      render={({
        field: { onChange, ref: textFieldRef },
        fieldState: { error, invalid },
      }) => (
        <div
          className="flex flex-col"
          ref={ref}
          style={{ scrollMarginTop: '140px' }}
        >
          {selectedRetailerState === null && (
            <div>
              <AddressAutocompleteInput
                onSelect={updateAddress}
                onGeolocationRetrievalSuccess={onGeolocationRetrievalSuccess}
                testId="search-retailer"
                textFieldRef={textFieldRef}
                invalid={invalid}
              />
            </div>
          )}
          {retailers.length > 0 && (
            <div className="flex flex-col">
              {selectedRetailerState ? (
                <>
                  <RetailerItem
                    id={selectedRetailerState.dealerId}
                    key={selectedRetailerState.dealerId}
                    item={selectedRetailerState}
                    onClick={() => {
                      selectRetailer(null);
                      onChange(null);
                    }}
                    selected
                  />
                  <button
                    type="button"
                    className="button-text text-accent-blue w-fit"
                    data-arrow="none"
                    data-color="blue"
                    data-testid="change-retailer"
                    onClick={() => {
                      selectRetailer(null);
                      onChange(null);
                    }}
                  >
                    {labels.change}
                  </button>
                </>
              ) : (
                <div className="flex flex-col gap-16">
                  {retailers.map((item, i) => (
                    <RetailerItem
                      item={item}
                      key={item.dealerId + i.toString()}
                      onClick={(dealer) => {
                        selectRetailer(dealer);
                        onChange(dealer.dealerId);
                      }}
                      id={item.dealerId}
                    />
                  ))}
                </div>
              )}
            </div>
          )}

          {canLoadMore && !selectedRetailerState && (
            <button
              type="button"
              className="button-text text-accent-blue w-fit"
              data-arrow="none"
              data-color="blue"
              aria-disabled={isLoading}
              onClick={(ev) => {
                ev.preventDefault();
                setGetRetailersProps((prev) => ({
                  ...prev,
                  offset: prev.offset + PAGE_SIZE,
                }));
              }}
            >
              {labels.selectRetailerLoadMore}
            </button>
          )}

          {isLoading && (
            <div className="flex flex-col items-center">
              <progress aria-label="Loading" className="spinner w-32 h-32" />
            </div>
          )}

          {error && (
            <ErrorMessage errorMessage={error.message} id="retailer-error" />
          )}
        </div>
      )}
    />
  );
};
