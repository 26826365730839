import _ from 'lodash';
import type { MarketVehicleConfiguration } from '@vcc-package/leads-utils/api';

import { useLeadsContext } from '../src/context';

type Features = Record<string, boolean | null>;

export const groupAndFilterVehicles = (
  features: Features,
  vehicles: MarketVehicleConfiguration[] | undefined,
) => {
  const res = _(vehicles)
    .filter((x) => !x.presentation.hide)
    .value();

  return res;
};
export const useVisibleVehicles = (): MarketVehicleConfiguration[] => {
  const { vehicles, features } = useLeadsContext();

  return groupAndFilterVehicles(features, vehicles);
};
