import React from 'react';

type Props = {
  title?: string;
  body?: string;
};

export const StepsHeader = ({ title, body }: Props) => {
  if (!title && !body) return null;

  return (
    <header>
      {title && <h2 className="heading-2">{title}</h2>}
      {body && <p className="font-20 text-secondary">{body}</p>}
    </header>
  );
};
