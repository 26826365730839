export const volvoCarsProdUrl = 'https://www.volvocars.com';

export const NON_BREAKING_SPACE = '\u00A0'; // https://en.wikipedia.org/wiki/Non-breaking_space
export const OCSPMinValue = 112;

export const preferredColors = {
  ex90: '74300',
  c40: '73400',
  xc40: '71700',
  xc60: '70700',
  xc90: '72600',
  v40: '71400',
  v60: '71400',
  v90: '72300',
  s60: '72500',
  s90: '72400',
};

const isBrowserTest =
  typeof window !== 'undefined' &&
  ['cbv-learn.test-emea.ccdp-origin.io', 'testwww.volvocars.com'].includes(
    window.location.hostname,
  );

const isBrowserQA =
  typeof window !== 'undefined' &&
  (['cbv-learn.qa-emea.ccdp-origin.io', 'qawww.volvocars.com'].includes(
    window.location.hostname,
  ) ||
    /pr-\d+\.westeurope\.cloudapp\.azure\.com/.test(window.location.hostname));

export const environment =
  process.env.NODE_ENV === 'development'
    ? 'dev'
    : isBrowserTest || process.env.NODE_ENV === 'test'
      ? 'test'
      : isBrowserQA
        ? 'qa'
        : 'prod';

export const NEXT_PUBLIC_HOST =
  process.env.NEXT_PUBLIC_HOST ||
  (isBrowserTest
    ? 'https://testwww.volvocars.com'
    : isBrowserQA
      ? 'https://qawww.volvocars.com'
      : 'https://www.volvocars.com');

export enum CarSalesType {
  Stock = 'stock-cars', // Cars at dealers (cash only)
  Subscription = 'subscription-cars', // Available for CbV subscription (sub and sub_fixed only)
  Online = 'online-cars', // Available for online sale (cash only)
}

export const detailsMap = {
  co2Emissions: 'co2EmissionsWltpTotal',
  energyConsumption: 'electricEnergyConsumptionWltpTotal',
  electricRange: 'electricRangeWltpTotal',
  fuelConsumption: 'fuelConsumptionWltpTotal',
  wltpStatus: 'wltpStatus',
  model: 'modelName',
  driveline: 'driveline',
};

export const detailsRegex = new RegExp(
  `{(${Object.keys(detailsMap).join('|')})}`,
  'g',
);

export const detailsWithOnlyValue = ['wltpStatus', 'model', 'driveline'];

export const models = [
  'EX90',
  'EX30',
  'EC40',
  'C40',
  'EX40',
  'XC40',
  'XC60',
  'XC90',
  'V60',
  'V90',
  'S60',
  'S90',
];

export enum CacheControl {
  NoStore = 'no-store',
  Zero = 'max-age=0, must-revalidate, public',
  OneMinute = 'max-age=60, must-revalidate, public',
  OneHour = 'max-age=3600, must-revalidate, public',
  OneDay = 'max-age=86400, must-revalidate, public',
}
