import React from 'react';
import { CarPriceView } from './car-price.view';
import { useCarPrice } from './car-price.hook';
import type { CarPriceProps } from './car-price.types';

export const CarPrice = (props: CarPriceProps) => {
  const viewProps = useCarPrice(props);

  return <CarPriceView {...viewProps} />;
};
