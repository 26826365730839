import type { LeadsContextProperties } from '../../../src/context';

export const informAboutOptionalFields = (context: LeadsContextProperties) => {
  if (!context.features.InformAboutOptionalFields) return;

  console.log('Running experiment: informAboutOptionalFields');

  const wrapper = document.querySelector('form');
  const initiated = 'data-abTest-informAboutOptionalFields-initiated';

  if (!wrapper || wrapper.getAttribute(initiated)) {
    return;
  }

  wrapper
    .querySelectorAll<HTMLLabelElement | HTMLLegendElement>('label, legend')
    .forEach((el) => {
      if (!el?.innerText) return;

      if (el.innerText.includes(' *')) {
        el.innerText = el.innerText.replace(' *', '');
      } else if (!el.parentElement?.querySelector('[type="radio"]')) {
        el.innerText = el.innerText + ' (opcional)';
      }
    });
  wrapper.setAttribute(initiated, 'true');
  document.querySelector('[data-testid="required"]')?.remove();
};
