import React, { useState } from 'react';
import { Block, Text, Click, ClickProps } from 'vcc-ui';
import { ContentBlock, TitledOverlay } from '@volvo-cars/react-overlay';
import { OffersOverlayWithLinkProps } from './OffersOverlayWithLink.types';
import { useBreakpoints } from '@vcc-www/hooks';

const OffersOverlayWithLink: React.FC<
  React.PropsWithChildren<OffersOverlayWithLinkProps>
> = ({
  localization,
  offers,
  overlayOffersTitle,
  overlayTitle,
  detailsText,
  children,
  onOverlayClick,
  centerAlignOnDesktop,
  index,
}) => {
  const [openOverlay, setOpenOverlay] = useState(false);
  const { fromM } = useBreakpoints(); // eslint-disable-line vcc-www/use-breakpoints

  return (
    <Container centerAlignOnDesktop={centerAlignOnDesktop}>
      {children}
      <DetailsLink
        onClick={() => {
          setOpenOverlay(true);
          onOverlayClick?.(false, index);
        }}
      >
        {detailsText}
      </DetailsLink>
      {openOverlay && (
        <TitledOverlay
          title={overlayTitle}
          minimisableTitle={false}
          close={() => {
            setOpenOverlay(false);
            onOverlayClick?.(true);
          }}
          colSpan={fromM ? 8 : undefined}
        >
          <ContentBlock>
            <ModalContent>
              <Title>{localization?.name}</Title>
              <ShortDescription>{localization?.description}</ShortDescription>
              <Description>{localization?.fullDescription}</Description>
              <Title>{overlayOffersTitle}</Title>
              <OffersWrapper>
                {offers.map((offer) => (
                  <React.Fragment key={offer.id}>
                    <OfferName>{offer.name}</OfferName>
                    <OfferDescription>{offer.fullDescription}</OfferDescription>
                  </React.Fragment>
                ))}
              </OffersWrapper>
            </ModalContent>
          </ContentBlock>
        </TitledOverlay>
      )}
    </Container>
  );
};

const Container: React.FC<
  React.PropsWithChildren<{
    centerAlignOnDesktop?: OffersOverlayWithLinkProps['centerAlignOnDesktop'];
  }>
> = ({ centerAlignOnDesktop, ...props }) => (
  <Block
    extend={{
      extend: {
        condition: centerAlignOnDesktop,
        style: { fromL: { textAlign: 'center' } },
      },
    }}
    {...props}
  />
);

const DetailsLink: React.FC<
  React.PropsWithChildren<Pick<ClickProps, 'onClick'>>
> = ({ onClick, ...props }) => (
  <Click extend={{ fromM: { margin: '0 auto' } }} onClick={onClick}>
    <Text
      variant="kelly"
      extend={({ theme }) => ({
        color: theme.color.foreground.action,

        '&:hover': {
          color: theme.color.foreground.primary,
        },
      })}
      {...props}
    />
  </Click>
);

const ModalContent: React.FC<React.PropsWithChildren<unknown>> = (props) => (
  <Block
    extend={{
      maxWidth: 600,
      margin: '0 auto',
    }}
    {...props}
  />
);

const Title: React.FC<React.PropsWithChildren<unknown>> = (props) => (
  <Text variant="ootah" subStyle="emphasis" {...props} />
);

const ShortDescription: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Text
    variant="columbus"
    extend={({ theme }) => ({
      color: theme.color.foreground.secondary,
      marginTop: theme.baselineGrid,
    })}
    {...props}
  />
);

const Description: React.FC<React.PropsWithChildren<unknown>> = (props) => (
  <Text
    extend={({ theme }) => ({
      marginTop: theme.baselineSubGrid * 7,
      marginBottom: theme.baselineSubGrid * 13,
    })}
    {...props}
  />
);

const OffersWrapper: React.FC<React.PropsWithChildren<unknown>> = (props) => (
  <Block
    extend={({ theme }) => ({
      marginTop: theme.baselineGrid * 6,
    })}
    {...props}
  />
);

const OfferName: React.FC<React.PropsWithChildren<unknown>> = (props) => (
  <Text variant="columbus" subStyle="emphasis" {...props} />
);

const OfferDescription: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Text
    variant="kelly"
    extend={({ theme }) => ({
      color: theme.color.foreground.secondary,
      paddingBottom: theme.baselineGrid * 2,
      marginBottom: theme.baselineGrid * 2,
      borderBottom: `1px solid ${theme.color.ornament.divider}`,
      ':last-child': {
        borderBottom: 'none',
      },
    })}
    {...props}
  />
);

export default OffersOverlayWithLink;
