import React from 'react';
import type {
  Service,
  ServiceDescriptionsProps,
} from '../finance-details.types';
import { ListItem } from '@vcc-package/list-item';
import { ServiceIcon } from '@vcc-package/products/src/overlay/products-overlay.view';

export const FinanceDetailsViewServiceDescriptions = ({
  title,
  services,
}: ServiceDescriptionsProps) => {
  if (!services || services?.length === 0) return null;

  return (
    <div>
      {title && (
        <h2 className="font-20 font-medium pb-16 border-b border-ornament">
          {title}
        </h2>
      )}
      <ul className="flex flex-col gap-16 pt-24">
        {services?.map((service: Service, index: number) => (
          <li key={`service-${index}`}>
            <ListItem
              thumbnail={
                <ServiceIcon
                  displayName={
                    service?.thumbnailAltText ?? service?.displayName
                  }
                  thumbnail={service?.thumbnail}
                />
              }
              title={service?.displayName}
              titleSources={[
                `meta:services[${service.displayName}].displayName`,
              ]}
              testId="included-service"
              description={service?.shortDescription}
              onClick={service?.onClick}
              linkVariant="chevron-title"
              descriptionVariant="font-16"
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
